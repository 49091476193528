import { CampaignType } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { BadgePercent, PackageOpen, Plus, Sparkle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import DraperButton from "~/components/core/buttons/DraperButton";

const HomeActionPanel = ({ title }: { title?: string }) => {
  const navigate = useNavigate();
  return (
    <Flex
      gap="4"
      direction="column"
      style={{
        borderRadius: "16px",
        border: "1px solid #DDD7D7",
        backgroundColor: "white",
        padding: "32px",
        width: "90%",
        minWidth: "fit-content",
        overflow: "scroll",
      }}
    >
      {!!title && (
        <Text
          as="span"
          style={{
            fontSize: "20px",
            color: "#353539CC",
            fontWeight: 400,
          }}
        >
          {title}
        </Text>
      )}
      <Flex gap="4">
        {Object.values(CampaignType)
          .filter((campaignType) => campaignType !== CampaignType.other)
          .map((campaignType) => {
            const icon =
              campaignType === CampaignType.new_products ? (
                <Sparkle />
              ) : campaignType === CampaignType.inventory_clearance ? (
                <PackageOpen />
              ) : CampaignType.sales_event ? (
                <BadgePercent />
              ) : (
                <Plus color="white" />
              );
            return (
              <DraperButton
                onClick={() =>
                  navigate("/campaigns/wizard/new", {
                    state: {
                      campaignType: campaignType,
                    },
                  })
                }
                text={campaignType
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
                icon={icon}
                variant="light"
              />
            );
          })}
        <DraperButton
          onClick={() => navigate("/campaigns/wizard/new")}
          text={"New Custom Campaign"}
          variant="dark"
          icon={<Plus color="white" />}
        />
      </Flex>
    </Flex>
  );
};
export default HomeActionPanel;

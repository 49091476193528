import InternAdCreativeComponent from "../../components/ads/intern/InternAdCreativeComponent";
import InternAdCreativesComponent from "../../components/ads/intern/InternAdCreativesComponent";
import InternAdMediaComponent from "../../components/ads/intern/InternAdMediaComponent";
import InternAdMediaTemplateComponent from "../../components/ads/intern/InternAdMediaTemplateComponent";
import InternAdMediaTemplateVariantComponent from "../../components/ads/intern/InternAdMediaTemplateVariantComponent";
import InternAdMediaTemplatesComponent from "../../components/ads/intern/InternAdMediaTemplatesComponent";
import InternCampaignsComponent from "../../components/campaign/intern/InternCampaignsComponent";
import PrivateRoute from "../../components/core/auth/PrivateRoute";
import AdsRoute from "../intern/ads";
import InternCampaignsRoute from "../intern/campaigns";
import CherryRoute from "../intern/cherry";
import EditorRoute from "../intern/editor";
import EmailRoute from "../intern/email";
import { EmailContextProvider } from "../intern/email_editor/context/EmailEditorContext";
import GrapesRoute from "../intern/grapes";
import RecommenderRoute from "../intern/recommender";
import InternRoute from "../intern/root";
import StylesRoute from "../intern/styles";
import { Theme } from "@radix-ui/themes";
import { RouteObject, Navigate } from "react-router-dom";
import { AdMediaContextProvider } from "~/components/ads/AdMediaContext";

export default function getInternRoutes(): RouteObject[] {
  return [
    {
      path: "/intern",
      element: (
        <Theme>
          <PrivateRoute isInternal>
            <InternRoute />
          </PrivateRoute>
        </Theme>
      ),
      children: [
        {
          path: "recommender",
          element: <RecommenderRoute />,
        },
        {
          path: "styles",
          element: <StylesRoute />,
        },
        {
          path: "email",
          element: <EmailRoute />,
        },
        {
          path: "editor",
          element: <EditorRoute />,
        },
        {
          path: "grapes",
          element: <GrapesRoute />,
        },
        {
          path: "cherry",
          element: (
            <EmailContextProvider>
              <CherryRoute />
            </EmailContextProvider>
          ),
        },
        {
          path: "ads",
          element: <AdsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="creatives" />,
            },
            {
              path: "templates",
              element: <InternAdMediaTemplatesComponent />,
            },
            {
              path: "template/:id",
              element: <InternAdMediaTemplateComponent />,
            },
            {
              path: "template/:templateId/variant/:variantId",
              element: <InternAdMediaTemplateVariantComponent />,
            },
            {
              path: "creatives",
              element: <InternAdCreativesComponent />,
            },
            {
              path: "creative/:id",
              element: <InternAdCreativeComponent />,
            },
            {
              path: "media/:id",
              element: (
                <AdMediaContextProvider>
                  <InternAdMediaComponent />
                </AdMediaContextProvider>
              ),
            },
          ],
        },
        {
          path: "campaigns",
          element: <InternCampaignsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="campaigns" />,
            },
            {
              path: "campaigns",
              element: <InternCampaignsComponent />,
            },
          ],
        },
      ],
    },
  ];
}

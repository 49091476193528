import { Flex, Select } from "@radix-ui/themes";
import { CSSProperties } from "react";
import NumberInput from "~/components/core/inputs/NumberInput";

export interface AddButtonBorderProps {
  borderWidth: number;
  borderStyle: CSSProperties["borderStyle"];
  onBorderWidthChange: (borderWidth: number) => void;
  onBorderStyleChange: (borderStyle: CSSProperties["borderStyle"]) => void;
}

const AddButtonBorder = ({
  borderStyle,
  borderWidth,
  onBorderStyleChange,
  onBorderWidthChange,
}: AddButtonBorderProps) => {
  return (
    <Flex gap="16px">
      <NumberInput
        style={{ width: "50%" }}
        radius="large"
        value={borderWidth}
        onValueChange={(v) => onBorderWidthChange(v)}
      />
      <Select.Root
        value={borderStyle}
        onValueChange={(val) => onBorderStyleChange(val)}
        size="3"
        defaultValue="solid"
      >
        <Select.Trigger radius="large" style={{ width: "50%" }} />
        <Select.Content color="gray" highContrast>
          <Select.Item value="solid">Solid</Select.Item>
          <Select.Item value="dotted">Dotted</Select.Item>
          <Select.Item value="none">None</Select.Item>
        </Select.Content>
      </Select.Root>
    </Flex>
  );
};

export default AddButtonBorder;

import { operations } from "@openapi";
import { UndefinedInitialDataOptions, useQueries } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export type AdMediaApiResponse =
  operations["ads_api_get_ad_media"]["responses"][200]["content"]["application/json"];

export type AdMediaElementsApiResponse =
  operations["ads_api_get_ad_media_elements"]["responses"][200]["content"]["application/json"];

export const getAdCreativeMediaElementsQueryOptions = (
  mediaId: string | undefined,
  options?: UndefinedInitialDataOptions<AdMediaElementsApiResponse>
): UndefinedInitialDataOptions<AdMediaElementsApiResponse> => {
  return {
    queryKey: ["ad-media-elements", mediaId],
    queryFn: async (): Promise<AdMediaElementsApiResponse> => {
      const { data } = await axios.get(
        `/api/v1/ads/media/${mediaId}/elements`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    enabled: !!mediaId,
    retry: false,
    staleTime: 1000 * 60 * 60,
    ...options,
  };
};

const useGetAdCreativeMediaWithElements = (
  mediaId: string | undefined,
  { skipMediaQuery }: { skipMediaQuery: boolean } = { skipMediaQuery: false }
) => {
  const queries = useQueries({
    queries: [
      {
        queryKey: ["ad-media", mediaId],
        queryFn: async (): Promise<AdMediaApiResponse> => {
          const { data } = await axios.get(`/api/v1/ads/media/${mediaId}`, {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          });
          return data;
        },
        enabled: !!mediaId && !skipMediaQuery,
        retry: false,
        staleTime: 1000 * 60 * 60,
      },
      getAdCreativeMediaElementsQueryOptions(mediaId),
    ],
  });

  return {
    mediaQuery: queries[0],
    elementsQuery: queries[1],
  };
};

export default useGetAdCreativeMediaWithElements;

import { Separator } from "@radix-ui/themes";

const DialogSeparator: React.FC = () => {
  return (
    <Separator
      size={"1"}
      style={{
        width: "auto",
        margin: "1rem calc(-1 * var(--dialog-content-padding))",
      }}
      orientation="horizontal"
    />
  );
};

export default DialogSeparator;

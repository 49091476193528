import { useAuthenticatedUserState } from "../../../contexts/CurrentUserContext";
import { operations } from "@openapi";
import { Container, Heading, Table } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const StyledTable = styled(Table.Root)`
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
`;

const StyledHeader = styled(Table.Header)`
  backgroundColor: '#f5f5f5',
  textAlign: 'left',
`;

const StyledHeaderCell = styled(Table.ColumnHeaderCell)`
  padding: "10px 15px";
`;

const StyledRow = styled(Table.Row)`
  borderBottom: '1px solid #ececec',
  '&:last-child': { borderBottom: 'none' },
`;

const StyledCell = styled(Table.Cell)`
  padding: '10px 15px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
`;

type AdCreativesApiResponse =
  operations["ads_api_get_ad_creatives"]["responses"][200]["content"]["application/json"];

const InternAdCreativesComponent = () => {
  const styles = {
    header: {
      display: "flex",
      justifyContent: "space-between",
      margin: "5px 0px 25px",
    },
  };

  const navigate = useNavigate();

  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const query = useQuery<AdCreativesApiResponse>({
    queryKey: ["ad-creatives"],
    queryFn: async (): Promise<AdCreativesApiResponse> => {
      const { data } = await axios.get("/api/v1/ads/ad-creatives", {
        params: {
          brand_id: activeBrandID,
        },
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const handleTableRowClick = (creativeId: string) => {
    navigate(`/intern/ads/creative/${creativeId}`);
  };

  return (
    <Container>
      <div style={styles.header}>
        <Heading>Creatives</Heading>
      </div>
      <StyledTable variant="surface">
        <StyledHeader>
          <Table.Row>
            <StyledHeaderCell>ID</StyledHeaderCell>
            <StyledHeaderCell>Campaign ID</StyledHeaderCell>
            <StyledHeaderCell>Brand</StyledHeaderCell>
            <StyledHeaderCell>Scheduled Offset</StyledHeaderCell>
            <StyledHeaderCell>Item Count</StyledHeaderCell>
            <StyledHeaderCell>Headline</StyledHeaderCell>
            <StyledHeaderCell>Primary Text</StyledHeaderCell>
            <StyledHeaderCell>Templates</StyledHeaderCell>
            <StyledHeaderCell></StyledHeaderCell>
          </Table.Row>
        </StyledHeader>
        <Table.Body>
          {query.data?.creatives.map((creative) => (
            <StyledRow
              key={creative.id}
              style={{ cursor: "pointer" }}
              onClick={() => handleTableRowClick(creative.id)}
            >
              <StyledCell>{creative.id}</StyledCell>
              <StyledCell>{creative.campaign.id}</StyledCell>
              <StyledCell>{creative.brand_name}</StyledCell>
              <StyledCell>{creative.campaign_scheduling_offset}</StyledCell>
              <StyledCell>
                {creative.campaign_recommended_items.length}
              </StyledCell>
              <StyledCell>{creative.headline}</StyledCell>
              <StyledCell>{creative.primary_text}</StyledCell>
              <StyledCell>{creative.media_templates.length}</StyledCell>
              <StyledCell>
                <ChevronRight
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    paddingLeft: "0.25rem",
                  }}
                />
              </StyledCell>
            </StyledRow>
          ))}
        </Table.Body>
      </StyledTable>
    </Container>
  );
};

export default InternAdCreativesComponent;

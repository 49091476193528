import TextEditorToolbar from "./TextEditorToolbar";
import Quill from "quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import ReactDOMServer from "react-dom/server";

export const quillImports = `
<script src="https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.js"></script>
<link href="https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css" rel="stylesheet">
`;

export const replaceEditableText = (
  html: any,
  field_name: string,
  value: string,
  sectionId: string,
  emailId?: string
) => {
  if (!emailId) return;
  const key = `${emailId}_${sectionId}_${field_name}`.replace(/-/g, "_");
  const toolBarHtml = ReactDOMServer.renderToString(
    <TextEditorToolbar id={`toolbar_${key}`} />
  );
  const editableTextHtml = `
          <div style="position:relative;">
            ${toolBarHtml}
            <div id="editor_${key}" style="text-align:center;">
              ${value}
            </div>
          </div>`;
  html = html.replaceAll(`{${field_name}}`, editableTextHtml);
  return html;
};

export const getQuillHtml = (quill: Quill | null) => {
  const contents = quill?.getContents();
  var converter = new QuillDeltaToHtmlConverter(contents?.ops ?? [], {
    inlineStyles: true,
  });
  return converter.convert();
};

export const mapFontSizeToQuillSize = (html: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  const elements = tempDiv.querySelectorAll<HTMLElement>(
    '[style*="font-size"]'
  );

  elements.forEach((el) => {
    const fontSize = el.style.fontSize;
    switch (fontSize) {
      case "2.5em":
        el.classList.add("ql-size-huge");
        break;
      case "1.5em":
        el.classList.add("ql-size-large");
        break;
      case "0.75em":
        el.classList.add("ql-size-small");
        break;
      default:
        console.log("Size not recognized: ", fontSize);
    }
    el.style.fontSize = "";

    const fontFamily = el.style.fontFamily;
    if (fontFamily) {
      const fontFamilyList = fontFamily
        .split(",")
        .map((f) => f.trim().replace(/['"]/g, ""));
      const lastFontFamily = fontFamilyList[fontFamilyList.length - 1];
      switch (lastFontFamily) {
        case "monospace":
          el.classList.add("ql-font-monospace");
          break;
        case "roboto":
          el.classList.add("ql-font-roboto");
          break;
        case "serif":
          el.classList.add("ql-font-serif");
          break;
        case "sans-serif":
          el.classList.add("ql-font-sans");
          break;
        default:
          console.log("Font family not recognized:", lastFontFamily);
          break;
      }
      el.style.fontFamily = "";
    }
  });

  return tempDiv.innerHTML;
};

//#region Handlebars

export const wrapEditableHandlebarsValue = ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  const toolBarHtml = ReactDOMServer.renderToString(
    <TextEditorToolbar id={`toolbar_${key}`} />
  );
  return `
      <div style="position:relative;">
        ${toolBarHtml}
        <div id="editor_${key}" style="text-align:center;">
          ${mapFontSizeToQuillSize(value)}
        </div>
      </div>
    `;
};

//#endregion Handlebars

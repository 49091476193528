import { Button } from "../blocks/base/Button";
import { Column } from "../blocks/base/Column";
import { Section } from "../blocks/base/Section";
import Text from "../blocks/base/Text";
import { Element, useEditor } from "@craftjs/core";
import { Box, Grid } from "@radix-ui/themes";
import {
  CaseSensitive,
  RectangleHorizontal,
  MousePointerClick,
  Columns2,
  Columns3,
} from "lucide-react";

const enum BlockType {
  BUTTON = "button",
  TEXT = "text",
  SECTION = "section",
  TWO_COLUMNS = "two_columns",
  THREE_COLUMNS = "three_columns",
}

const BLOCKS = [
  {
    type: BlockType.BUTTON,
    icon: MousePointerClick,
    block: <Button buttonStyle="outline" background={"black"} />,
    props: {
      buttonStyle: "outline",
      background: "b",
    },
  },
  {
    type: BlockType.TEXT,
    icon: CaseSensitive,
    block: <Element is={Text} fontSize="24" textAlign="center" />,
  },
  {
    type: BlockType.SECTION,
    icon: RectangleHorizontal,
    block: (
      <Element canvas is={Section}>
        <Element canvas is={Column} width={`${100.0}`}>
          <Element is={Text} fontSize="24" textAlign="center" />
          <Element is={Text} textAlign="center" tagName="h2" />
        </Element>
      </Element>
    ),
  },
  {
    type: BlockType.TWO_COLUMNS,
    icon: Columns2,
    block: (
      <Element canvas is={Section}>
        <Element canvas is={Column} width={`${100.0 / 2}`}>
          <Element is={Text} fontSize="24" textAlign="center" />
          <Element is={Text} textAlign="center" tagName="h2" />
        </Element>
        <Element canvas is={Column} width={`${100.0 / 2}`}>
          {" "}
          <Element is={Text} fontSize="24" textAlign="center" />
          <Element is={Text} textAlign="center" tagName="h2" />
        </Element>
      </Element>
    ),
  },
  {
    type: BlockType.THREE_COLUMNS,
    icon: Columns3,
    block: (
      <Element canvas is={Section}>
        <Element canvas is={Column} width={`${100.0 / 3}`}>
          <Element is={Text} fontSize="24" textAlign="center" />
          <Element is={Text} textAlign="center" tagName="h2" />
        </Element>
        <Element canvas is={Column} width={`${100.0 / 3}`}>
          <Element is={Text} fontSize="24" textAlign="center" />
          <Element is={Text} textAlign="center" tagName="h2" />
        </Element>
        <Element canvas is={Column} width={`${100.0 / 3}`}>
          <Element is={Text} fontSize="24" textAlign="center" />
          <Element is={Text} textAlign="center" tagName="h2" />
        </Element>
      </Element>
    ),
  },
];

const Toolbox = () => {
  const {
    connectors: { create },
  } = useEditor();

  return (
    <Grid columns="2" gap="3" rows="repeat(2, 90px)" width="auto">
      {BLOCKS.map((v) => (
        <Box
          style={{
            border: "1px solid rgba(0, 0, 0, .2)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90px",
            width: "120px",
            boxShadow: "0 1px 0 0 rgba(0, 0, 0, .15)",
            cursor: "grab",
          }}
          ref={(ref) => ref && create(ref, v.block)}
        >
          <v.icon />
        </Box>
      ))}
    </Grid>
  );
};

export default Toolbox;

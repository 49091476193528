import {
  ButtonProps,
  Flex,
  SegmentedControl,
  Slider,
  Text,
} from "@radix-ui/themes";
import React from "react";

export interface AddButtonShapeProps {
  radius: ButtonProps["radius"];
  value?: number;
  onChange: (radius: ButtonProps["radius"], value?: number) => void;
}

const AddButtonShape = ({ radius, value, onChange }: AddButtonShapeProps) => {
  return (
    <Flex
      style={{ backgroundColor: "#F0EDEB", borderRadius: "10px" }}
      direction="column"
      gap="16px"
      p="8px"
    >
      <SegmentedControl.Root
        value={radius}
        onValueChange={(val) =>
          onChange(
            val as ButtonProps["radius"],
            val === "large" ? 8 : undefined
          )
        }
        radius="large"
        size="3"
      >
        <SegmentedControl.Item value="none">Square</SegmentedControl.Item>
        <SegmentedControl.Item value="large">Rounded</SegmentedControl.Item>
        <SegmentedControl.Item value="full">Pill</SegmentedControl.Item>
      </SegmentedControl.Root>
      {radius === "large" && (
        <Flex gap="16px" align="center">
          <Slider
            onValueChange={(val) => onChange(radius, val[0])}
            color="gray"
            highContrast
            defaultValue={[value ?? 0]}
            size="3"
          />
          <Flex
            p="12px"
            gap="4px"
            style={{
              backgroundColor: "white",
              borderRadius: "12px",
              border: "1px solid #DDD7D7",
            }}
          >
            <Text>{value ?? 0}</Text>
            <Text style={{ color: "#888282" }}>px</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default AddButtonShape;

import { getQuillHtml } from "../../text_editor/htmlUtils";
import { setToolbarPosition } from "../../utils/utils";
import useGetHeroSectionTextEditors, {
  HeroTextEditors,
} from "./useGetHeroSectionTextEditors";
import { useEffect } from "react";

const useHandleHeroEditableText = ({
  renderedEmail,
  sectionsState,
  emailId,
}: {
  renderedEmail: JSX.Element[] | null;
  sectionsState?: {
    [key: string]: any;
  };
  emailId?: string;
}) => {
  const heroTextEditors = useGetHeroSectionTextEditors(
    sectionsState,
    renderedEmail,
    emailId
  );

  useEffect(() => {
    if (!heroTextEditors) return;
    Object.entries(heroTextEditors).map(
      ([sectionId, { quillTitleEditor, quillSubtitleEditor }], index: any) => {
        setToolbarPosition(quillTitleEditor.editor, quillTitleEditor.toolbar);
        setToolbarPosition(
          quillSubtitleEditor.editor,
          quillSubtitleEditor.toolbar
        );
      }
    );
  }, [heroTextEditors]);
  return heroTextEditors;
};

export const printEditorContent = (heroTextEditors: HeroTextEditors) => {
  Object.entries(heroTextEditors).map(
    ([sectionId, { quillTitleEditor, quillSubtitleEditor }], index: any) => {
      console.log(getQuillHtml(quillTitleEditor?.quill));
      console.log(getQuillHtml(quillSubtitleEditor?.quill));
    }
  );
};

export default useHandleHeroEditableText;

import {
  mapFontSizeToQuillSize,
  quillImports,
  replaceEditableText,
} from "../../text_editor/htmlUtils";

export const getHeroTextFieldsUpdatedHtml = (
  section: any,
  emailId?: string
) => {
  let html = section.html_template;
  html = html.replace(/{quill_imports}/g, quillImports);
  html = replaceEditableText(
    html,
    "title_text",
    mapFontSizeToQuillSize(section?.title?.text) || "",
    section?.id,
    emailId
  );
  html = replaceEditableText(
    html,
    "subtitle_text",
    mapFontSizeToQuillSize(section?.subtitle?.text) || "",
    section?.id,
    emailId
  );
  return html;
};

import BackgroundModifier from "./modifiers/BackgroundModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import HeadingModifier from "./modifiers/HeadingModifier";
import TextModifier from "./modifiers/TextModifier";
import { Flex } from "@radix-ui/themes";
import { SparklesIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import DraperButton from "~/components/core/buttons/DraperButton";
import TextAreaWithLabel from "~/components/core/inputs/TextAreaWithLabel";

const TextContainer = styled(Flex)`
  box-shadow: 0px 12px 48px 0px #00000014;
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 20px;
`;

const AddTextView = () => {
  const [hasGeneratedOrSkipped, setHasGeneratedOrSkipped] = useState(false);

  if (!hasGeneratedOrSkipped) {
    return (
      <Flex direction="column" gap="24px">
        <Flex gap="16px">
          <MarkAvatar />
          <TextContainer>
            Please input what content should be generated.
          </TextContainer>
        </Flex>
        <TextAreaWithLabel labelColor="var(--text-tertiary)" label="Prompt" />
        <Flex justify="end" gap="16px">
          <DraperButton
            onClick={() => setHasGeneratedOrSkipped(true)}
            variant="outlined"
            text="Write manually"
            radius="large"
          />
          <DraperButton
            onClick={() => setHasGeneratedOrSkipped(true)}
            variant="dark"
            text="Generate"
            icon={<SparklesIcon />}
            radius="large"
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="24px" mb="24px">
      <GeneratedVariantSelector />

      <HeadingModifier />

      <TextModifier />

      <BackgroundModifier hideSwitch />
    </Flex>
  );
};

export default AddTextView;

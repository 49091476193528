import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CampaignWizardCardContent from "../../../components/campaign/wizard/cards/CampaignWizardCardContent";
import ActionAlert from "../../../components/campaign/wizard/products/components/ActionAlert";
import ProductCard from "../../../components/campaign/wizard/products/components/ProductCard";
import NumberedPaginator from "../../../components/campaign/wizard/products/components/numberedPaginator/NumberedPaginator";
import ProductSelectorDialog, {
  MAX_FEATURED_PRODUCTS,
} from "../../../components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { Product } from "../../../components/campaign/wizard/products/components/productSelector/hooks/useGetFilteredProducts";
import {
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useShopifyCollectionProductsQuery from "~/hooks/shopify/useShopifyCollectionProductsQuery";

export type MaybeFeaturedProduct = Product & {
  is_featured: boolean;
};

const PAGE_SIZE = 9;

const CampaignProducts = () => {
  const dispatch = useCampaignWizardDispatch();
  const { wizardType, campaignData } = useCampaignWizardState();
  const destination = campaignData?.destination;
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();

  const {
    campaignRecommendation,
    isLoading: isLoadingRecommendation,
    isSuccess: isSuccessRecommendation,
    isError: isErrorRecommendation,
  } = useCampaignRecommendationQuery(
    activeBrandID,
    wizardType === "RECOMMENDED" ? recommendationID ?? null : null
  );

  const {
    collectionProductsData,
    isLoading: isLoadingCollectionProducts,
    isSuccess: isSuccessCollectionProducts,
    isError: isErrorCollectionProducts,
  } = useShopifyCollectionProductsQuery(
    activeBrandID,
    wizardType === "NEW" ? destination?.id ?? null : null
  );

  const isDataLoading = isLoadingCollectionProducts || isLoadingRecommendation;

  const handleNextClick = () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: {
        currentStep:
          wizardType === "NEW"
            ? NewCampaignWizardSteps.PROMOTION
            : RecommendedCampaignWizardSteps.DESTINATION,
      },
    });
  };
  const handleBackClick = () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: {
        currentStep:
          wizardType === "NEW"
            ? NewCampaignWizardSteps.AUDIENCE
            : RecommendedCampaignWizardSteps.PROMOTION,
      },
    });
  };
  const [selectedProductsPage, setSelectedProductsPage] = useState(0);
  const description =
    isErrorCollectionProducts || isErrorRecommendation
      ? "There was an error loading the associated products. Please feel free to add products manually."
      : "Based on your choices, these are the products that will be included. Feel free to remove or add new products.";

  //products
  const selectProduct = (selectedProduct?: MaybeFeaturedProduct | null) => {
    if (!selectedProduct) {
      return;
    }
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(selectedProduct.product_id, selectedProduct);
      return updatedMap;
    });
  };
  const selectProducts = (
    selectedProducts: Map<string, MaybeFeaturedProduct>
  ) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map([...prev, ...selectedProducts]);
      return updatedMap;
    });
  };
  const handleDeselect = (id: string) => {
    if (!selectedProducts.has(id)) {
      return;
    }
    handleShowAlert(selectedProducts.get(id));
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(id);
      return updatedMap;
    });
    setSelectedProductsPage(
      Math.floor((selectedProducts.size - 2) / PAGE_SIZE)
    );
  };
  const [selectedProducts, setSelectedProducts] = useState<
    Map<string, MaybeFeaturedProduct>
  >(new Map<string, MaybeFeaturedProduct>());

  const onFeatureToggle = (product: MaybeFeaturedProduct) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(product.product_id, {
        ...product,
        is_featured: !product.is_featured,
      });
      return updatedMap;
    });
  };

  useEffect(() => {
    if (
      wizardType === "NEW" &&
      isSuccessCollectionProducts &&
      collectionProductsData
    ) {
      selectProducts(
        new Map(
          collectionProductsData.products.map((product) => [
            product.product_id,
            {
              ...product,
              is_featured: false,
            },
          ])
        )
      );
    }
  }, [collectionProductsData, isSuccessCollectionProducts, wizardType]);

  useEffect(() => {
    if (
      wizardType === "RECOMMENDED" &&
      isSuccessRecommendation &&
      campaignRecommendation
    ) {
      selectProducts(
        new Map(
          (campaignRecommendation.products ?? []).map((product) => [
            product.product_id,
            {
              ...product,
              is_featured: false,
            },
          ])
        )
      );
    }
  }, [campaignRecommendation, isSuccessRecommendation, wizardType]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        products: Array.from(selectedProducts.values()).map((product) => ({
          id: product.product_id,
          commerce_platform_id: product.product_id,
          is_featured: product.is_featured,
        })),
      },
    });
  }, [selectedProducts, dispatch, wizardType]);

  //popup
  const [showAlert, setShowAlert] = useState(false);
  const [removedProduct, setRemovedProduct] =
    useState<MaybeFeaturedProduct | null>();
  const [timeoutTimeId, setTimeoutTimeId] = useState<NodeJS.Timeout>();
  const handleShowAlert = (product?: MaybeFeaturedProduct) => {
    setRemovedProduct(product);
    setShowAlert(true);
    clearTimeout(timeoutTimeId);
    const timeId = setTimeout(() => {
      setRemovedProduct(null);
      setShowAlert(false);
    }, 3000);
    setTimeoutTimeId(timeId);
  };
  const onUndo = () => {
    selectProduct(removedProduct);
    setRemovedProduct(null);
    setShowAlert(false);
  };

  const featuredProductsCount = useMemo(() => {
    return Array.from(selectedProducts.values()).filter((p) => p.is_featured)
      .length;
  }, [selectedProducts]);

  const featuredProductsSection = Array.from(selectedProducts).filter(
    ([_, product]) => product.is_featured
  ).length ? (
    <>
      <Text size="1">Featured Product(s)</Text>
      <Grid gap="3" columns="3" width="auto">
        {Array.from(selectedProducts)
          .filter(([_, product]) => product.is_featured)
          .map(([_, product]) => (
            <ProductCard
              onClick={handleDeselect}
              data={product}
              isFeatureDisabled={featuredProductsCount >= MAX_FEATURED_PRODUCTS}
              onFeatureToggle={onFeatureToggle}
            />
          ))}
      </Grid>
    </>
  ) : null;

  return (
    <CampaignWizardCard
      badgeTitle="Products"
      nextButtonProps={{
        onClick: handleNextClick,
        text: "Looks good",
        disabled: !selectedProducts.size,
      }}
      backButtonProps={{
        onClick: handleBackClick,
        text: "Back",
      }}
    >
      <CampaignWizardCardContent>
        <Text as="span" size="3">
          {description}
        </Text>

        {featuredProductsSection}

        <Text size="1">{selectedProducts.size} products selected</Text>
        {isDataLoading ? (
          <LoadingDots />
        ) : (
          <>
            <Flex direction="column" gap="4">
              <Grid gap="3" columns="3" width="auto">
                {Array.from(selectedProducts)
                  .slice(
                    selectedProductsPage * PAGE_SIZE,
                    selectedProductsPage * PAGE_SIZE + PAGE_SIZE
                  )
                  .map(([id, product], index) => (
                    <ProductCard
                      key={index}
                      onClick={handleDeselect}
                      onFeatureToggle={onFeatureToggle}
                      data={product}
                      isFeatureDisabled={
                        featuredProductsCount >= MAX_FEATURED_PRODUCTS
                      }
                    />
                  ))}
              </Grid>
            </Flex>
            <Flex direction="row" justify="between">
              {!!Math.ceil(selectedProducts.size / 9) ? (
                <NumberedPaginator
                  totalPages={Math.ceil(selectedProducts.size / PAGE_SIZE)}
                  currentPage={selectedProductsPage}
                  setCurrentPage={setSelectedProductsPage}
                />
              ) : (
                <div></div>
              )}
              <ProductSelectorDialog
                onAddProducts={(products) => {
                  selectProducts(products);
                  setSelectedProductsPage(
                    Math.floor((products.size - 1) / PAGE_SIZE)
                  );
                }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                onFeatureToggle={onFeatureToggle}
              />
            </Flex>
          </>
        )}
      </CampaignWizardCardContent>

      {showAlert && (
        <ActionAlert
          title={"Product removed"}
          subtitle={removedProduct?.title ?? ""}
          undo={onUndo}
        />
      )}
    </CampaignWizardCard>
  );
};

export default CampaignProducts;

import { useNode } from "@craftjs/core";
import styled from "styled-components";

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0px;
`;

const Td = styled.td`
  font-size: 0px;
  word-break: break-word;
`;

const Img = styled.img`
  border: 0;
  display: block;
  outline: none;
  text-decoration: none;
  height: auto;
  font-size: 13px;
`;

const Image = ({ imgUrl }: { imgUrl: string }) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <tr ref={(ref) => ref && connect(drag(ref))}>
      <Td align="center">
        <Table border={0} cellPadding="0" cellSpacing="0" role="presentation">
          <tbody>
            <tr>
              <td>
                <Img height="auto" src={imgUrl} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Td>
    </tr>
  );
};

export default Image;

import { Box, Flex, Text } from "@radix-ui/themes";
import { CSSProperties } from "react";

export interface AddButtonColorsProps {
  backgroundColor: CSSProperties["backgroundColor"];
  textColor: CSSProperties["color"];
  borderColor: CSSProperties["borderColor"];
  onBackgroundColorChange: (color: CSSProperties["backgroundColor"]) => void;
  onTextColorChange: (color: CSSProperties["color"]) => void;
  onBorderColorChange: (color: CSSProperties["borderColor"]) => void;
}

const AddButtonColors = ({
  backgroundColor,
  textColor,
  borderColor,
  onBackgroundColorChange,
  onTextColorChange,
  onBorderColorChange,
}: AddButtonColorsProps) => {
  return (
    <Flex justify="between">
      <Flex
        align="center"
        gap="8px"
        p="8px"
        style={{
          border: "1px solid #DDD7D7",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <input
          value={backgroundColor}
          onChange={(e) => onBackgroundColorChange(e.target.value)}
          type="color"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
            border: "none",
            padding: 0,
          }}
        />
        <Box
          width="36px"
          height="36px"
          style={{
            borderRadius: "8px",
            backgroundColor,
            border: "1px solid #00000014",
          }}
        />
        <Text>Button</Text>
      </Flex>
      <Flex
        align="center"
        gap="8px"
        p="8px"
        style={{
          border: "1px solid #DDD7D7",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <input
          value={textColor}
          onChange={(e) => onTextColorChange(e.target.value)}
          type="color"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
            border: "none",
            padding: 0,
          }}
        />
        <Box
          width="36px"
          height="36px"
          style={{
            borderRadius: "8px",
            backgroundColor: textColor,
            border: "1px solid #00000014",
          }}
        />
        <Text>Text</Text>
      </Flex>
      <Flex
        align="center"
        gap="8px"
        p="8px"
        style={{
          border: "1px solid #DDD7D7",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <input
          value={borderColor}
          onChange={(e) => onBorderColorChange(e.target.value)}
          type="color"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
            border: "none",
            padding: 0,
          }}
        />
        <Box
          width="36px"
          height="36px"
          style={{
            borderRadius: "8px",
            backgroundColor: borderColor,
            border: "1px solid #00000014",
          }}
        />
        <Text>Border</Text>
      </Flex>
    </Flex>
  );
};

export default AddButtonColors;

import classes from "./SearchableExpandedSelect.module.css";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Box, Flex, Skeleton, Text, TextField } from "@radix-ui/themes";
import { SquareArrowOutUpRightIcon } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";
import useShopifyCollectionsQuery, {
  Collection,
} from "~/hooks/shopify/useShopifyCollectionsQuery";

const SearchableExpandedSelect = <T,>({
  onSelect,
  search,
  setSearch,
}: {
  onSelect: (collection: Collection) => void;
  search: string;
  setSearch: (search: string) => void;
}) => {
  const {
    data: collections,
    fetchNextPage,
    hasNextPage,
  } = useShopifyCollectionsQuery({});

  return (
    <Flex
      direction="column"
      style={{
        border: "1px solid#DDD7D7",
        borderRadius: "16px",
      }}
    >
      <Box style={{ padding: "16px", borderBottom: "1px solid #DDD7D7" }}>
        <TextField.Root
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          style={{ backgroundColor: "#F1F1F0", width: "100%" }}
          radius="large"
          color="teal"
          variant="soft"
          placeholder="Search…"
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
      </Box>

      <InfiniteScroll
        height={"196px"}
        next={() => {
          console.log("fetching next page");
          fetchNextPage();
        }}
        hasMore={hasNextPage ?? false}
        dataLength={
          collections?.pages.flatMap((page) => page.collections).length ?? 0
        }
        loader={
          <Skeleton
            height="32px"
            width="auto"
            mx="4"
            my="2"
            style={{ borderRadius: "12px" }}
          />
        }
        scrollThreshold={"90%"}
      >
        <Flex direction="column" gap="8px" py="12px" px="16px">
          {collections?.pages
            .flatMap((page) => page.collections)
            .filter((collection) =>
              collection.title.toLowerCase().includes(search.toLowerCase())
            )
            .map((collection) => (
              <Flex
                onClick={() => onSelect(collection)}
                className={classes.option}
                justify="between"
                align="center"
                key={collection.id}
              >
                <Text>{collection.title}</Text>
                <SquareArrowOutUpRightIcon
                  style={{
                    color: "#136EC1",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Flex>
            ))}
        </Flex>
      </InfiniteScroll>
      {/* </Flex> */}
    </Flex>
  );
};

export default SearchableExpandedSelect;

import {
  AdMediaAspectRatioStrings,
  AdMediaTemplateVariant,
} from "../../../types/ads";
import { getNumericAdMediaAspectRatio } from "../../../utils/ads/helpers";
import InternAdMediaTemplateElementsTab from "./InternAdMediaTemplateElementsTab";
import InternAdMediaTemplateInfoTab from "./InternAdMediaTemplateInfoTab";
import InternAdMediaTemplateVariantCreationDialog from "./InternAdMediaTemplateVariantCreationDialog";
import { operations } from "@openapi";
import {
  AlertDialog,
  Box,
  Button,
  Card,
  DropdownMenu,
  Flex,
  Grid,
  Heading,
  Spinner,
} from "@radix-ui/themes";
import { useQueries, useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { ArrowLeft, ChevronRight, Ellipsis } from "lucide-react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

type AdMediaTemplateApiResponse =
  operations["ads_api_get_ad_media_template"]["responses"][200]["content"]["application/json"];

type AdMediaTemplateVariantsApiResponse =
  operations["ads_api_get_ad_media_template_variants"]["responses"][200]["content"]["application/json"];

type DeleteAdMediaTemplateApiResponse =
  operations["ads_api_delete_ad_media_template"]["responses"][200]["content"]["application/json"];

const InternAdMediaTemplateComponent = () => {
  const { id } = useParams();

  const [variants, setVariants] = useState<AdMediaTemplateVariant[]>([]);

  const [isVariantDialogOpen, setIsVariantDialogOpen] = useState(false);

  const [isMoreMenuDialogOpen, setIsMoreMenuDialogOpen] = useState(false);

  const navigate = useNavigate();

  const templateQueries = useQueries({
    queries: [
      {
        queryKey: ["ad-media-template", id],
        queryFn: async (): Promise<AdMediaTemplateApiResponse> => {
          const { data } = await axios.get(
            `/api/v1/ads/ad-media-template/${id}`,
            {
              headers: {
                "X-CSRFToken": Cookies.get("csrftoken") ?? "",
              },
            }
          );
          return data;
        },
        retry: false,
        staleTime: Infinity,
      },
      {
        queryKey: ["ad-media-template-variants", id],
        queryFn: async (): Promise<AdMediaTemplateVariantsApiResponse> => {
          const { data } = await axios.get(
            `/api/v1/ads/ad-media-template/${id}/variants`,
            {
              headers: {
                "X-CSRFToken": Cookies.get("csrftoken") ?? "",
              },
            }
          );
          return data;
        },
        retry: false,
        staleTime: Infinity,
      },
    ],
  });

  const mediaTemplateResponse = templateQueries[0].data;

  const mediaTemplateIsLoading = templateQueries[0].isLoading;

  const mediaTemplateVariantsResponse = templateQueries[1].data;

  useEffect(() => {
    if (mediaTemplateVariantsResponse) {
      setVariants(mediaTemplateVariantsResponse.variants);
    }
  }, [mediaTemplateResponse, mediaTemplateVariantsResponse]);

  const deleteTemplateMutation = useMutation<
    DeleteAdMediaTemplateApiResponse,
    Error
  >({
    mutationFn: async (): Promise<DeleteAdMediaTemplateApiResponse> => {
      const response = await axios.delete(
        `/api/v1/ads/ad-media-template/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return response.data;
    },
    onSuccess: (data) => {
      navigate(-1);
    },
    onError: (error) => {
      alert(error);
      console.error("Error deleting template:", error);
    },
  });

  if (mediaTemplateIsLoading || !id) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner loading={true} />
      </Box>
    );
  }

  const deleteTemplateDialog = (
    <AlertDialog.Root open={isMoreMenuDialogOpen}>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Delete Template</AlertDialog.Title>
        <AlertDialog.Description size="2">
          Are you sure? This template and all of its variants will be
          permanently deleted.
        </AlertDialog.Description>
        <Flex gap="3" mt="4" justify="end">
          <Button
            variant="soft"
            color="gray"
            onClick={() => {
              setIsMoreMenuDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <AlertDialog.Action>
            <Button
              variant="solid"
              color="red"
              onClick={() => {
                deleteTemplateMutation.mutate();
              }}
            >
              Delete Template
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );

  return (
    <Flex align="start" gap="0.5rem">
      <Card
        style={{
          width: "350px",
          flexGrow: 0,
          flexShrink: 1,
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Flex direction="column" gap="0.5rem">
          <Flex align="center" gap="0.5rem" justify="between">
            <Flex align="center" gap="0.5rem">
              <Button
                variant="ghost"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowLeft></ArrowLeft>
              </Button>
              <Heading size="4">Media Template</Heading>
            </Flex>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="ghost">
                  <Ellipsis></Ellipsis>
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <DropdownMenu.Item
                  color="red"
                  onClick={() => {
                    setIsMoreMenuDialogOpen(true);
                  }}
                >
                  Delete
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            {deleteTemplateDialog}
          </Flex>
          <InternAdMediaTemplateInfoTab
            template={mediaTemplateResponse?.template ?? null}
          />
        </Flex>
      </Card>
      <Card
        style={{
          flexGrow: 1,
          flexShrink: 1,
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          minHeight: "350px",
        }}
      >
        <InternAdMediaTemplateVariantCreationDialog
          mediaTemplateId={id!}
          isDialogOpen={isVariantDialogOpen}
          setIsDialogOpen={setIsVariantDialogOpen}
        />
        <Flex direction="column" overflow="auto">
          <Flex
            direction="row"
            justify="between"
            align="center"
            style={{ marginBottom: "20px" }}
          >
            <Heading size="4">Variants</Heading>
            <Button
              style={{ right: "0px" }}
              onClick={() => {
                setIsVariantDialogOpen(true);
              }}
            >
              + Create Variant
            </Button>
          </Flex>
          <Grid
            columns={`${Math.min(Math.max(variants.length, 2), 3)}`}
            gap="2rem"
          >
            {variants.map((variant) => (
              <Box key={variant.id}>
                <Flex
                  direction="column"
                  align="center"
                  gap="1rem"
                  onClick={() => {
                    navigate(
                      `/intern/ads/template/${id}/variant/${variant.id}`
                    );
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      paddingBottom: `${
                        getNumericAdMediaAspectRatio(
                          variant.media_aspect_ratio
                        ) * 100
                      }%`,
                    }}
                  >
                    <img
                      src={variant.media_template_file!}
                      alt={
                        AdMediaAspectRatioStrings[variant.media_aspect_ratio]
                      }
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <Button variant="outline">
                    <Heading size="1">
                      {AdMediaAspectRatioStrings[variant.media_aspect_ratio]}
                    </Heading>
                    <ChevronRight size="14px" />
                  </Button>
                </Flex>
              </Box>
            ))}
          </Grid>
        </Flex>
      </Card>
      <Card
        style={{
          width: "350px",
          flexGrow: 0,
          flexShrink: 1,
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          flexDirection: "column",
        }}
      >
        <InternAdMediaTemplateElementsTab templateId={id} />
      </Card>
    </Flex>
  );
};

export default InternAdMediaTemplateComponent;

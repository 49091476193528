import { Flex, Switch, Text, TextField } from "@radix-ui/themes";
import { GripVerticalIcon } from "lucide-react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import styled from "styled-components";
import { EmailSectionSocialLinkState } from "~/types/template-editor";

const Container = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  gap: 8px;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
`;

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #888282;
`;

const SocialLinkContainer = ({
  dragHandleProps,
  values,
  onChange,
}: {
  values: EmailSectionSocialLinkState;
  onChange: (values: EmailSectionSocialLinkState) => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
}) => {
  return (
    <Container>
      <Flex justify="between" align="center">
        <div {...dragHandleProps}>
          <GripVerticalIcon />
        </div>
        <Flex gap="8px" align="center">
          <Text size="2" weight="regular">
            Show
          </Text>
          <Switch
            checked={values.enabled}
            onCheckedChange={(c) =>
              onChange({
                ...values,
                enabled: c,
              })
            }
            color="gray"
            highContrast
          />
        </Flex>
      </Flex>
      <Flex direction="column" gap="4px">
        <Label as="label">{values.text}</Label>
        <TextField.Root
          value={values.link_url}
          onChange={(e) =>
            onChange({
              ...values,
              link_url: e.target.value,
            })
          }
          size="3"
          radius="large"
          color="gray"
        />
      </Flex>
      <Flex gap="16px" align="center">
        <Text size="2" weight="regular">
          Open in new tab
        </Text>
        <Switch
          checked={values.opens_new_tab}
          onCheckedChange={(c) =>
            onChange({
              ...values,
              opens_new_tab: c,
            })
          }
          color="gray"
          highContrast
        />
      </Flex>
    </Container>
  );
};

export default SocialLinkContainer;

import { useNode, Node } from "@craftjs/core";
import { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";

export type TextProps = {
  fontSize: string;
  textAlign: string;
  fontWeight: string;
  shadow: number;
  text: string;
  margin: [string, string, string, string];
  tagName?: string;
};

const Tr = styled.tr`
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1;
  text-align: left;
  color: #000000;
`;

const Td = styled.td<{ textAlign: string }>`
  font-size: 0px;
  padding: 10px 25px;
  word-break: break-word;
  text-align: ${(props) => props.textAlign};
`;

const Text = ({
  fontSize,
  textAlign = "center",
  fontWeight,
  shadow,
  text,
  margin,
  tagName = "h1",
}: Partial<TextProps>) => {
  const {
    connectors: { connect, drag },
    setProp,
    selected,
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <Tr
      onClick={() => selected && setEditable(true)}
      ref={(ref) => ref && connect(drag(ref))}
    >
      <Td textAlign={textAlign}>
        <ContentEditable
          innerRef={connect}
          html={text ?? ""} // innerHTML of the editable div
          disabled={!editable}
          onChange={(e) => {
            setProp((prop) => (prop.text = e.target.value), 500);
          }} // use true to disable editing
          tagName={tagName} // Use a custom HTML tag (uses a div by default)
          style={{
            width: "100%",
            margin: margin
              ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`
              : undefined,
            fontSize: `${fontSize}px`,
            textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
            fontWeight,
            textAlign,
          }}
        />
      </Td>
    </Tr>
  );
};

Text.craft = {
  displayName: "Text",
  props: {
    fontSize: "15",
    fontWeight: "500",
    margin: [0, 0, 0, 0],
    shadow: 0,
    text: "Text",
  },
  rules: {
    canDrop: (targetNode: Node, currentNode: Node) => {
      return targetNode.data.name === "Column";
    },
  },
};

export default Text;

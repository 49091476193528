import { Avatar, Badge, Box, Flex, Text } from "@radix-ui/themes";

const AssistantMessage = ({ withBackground }: { withBackground: boolean }) => {
  return (
    <Flex direction="row" gap="24px" align="center">
      <Flex direction="column" align="center">
        <Avatar size="4" radius="full" fallback="M" />
        <Text as="span" style={{ color: "black" }}>
          Mark
        </Text>
      </Flex>
      <Box
        style={
          withBackground
            ? {
                opacity: "100%",
                padding: "20px",
                background: "white",
                backgroundColor: "white",
                borderRadius: "6px 24px 24px 24px",
                border: "1px solid #DDD7D7",
                boxShadow: "0px 12px 48px 0px #00000014",
              }
            : {}
        }
      >
        <Text as="span" style={{ color: "black" }}>
          Hello James, here are your latest campaign opportunities
        </Text>
      </Box>
    </Flex>
  );
};
export default AssistantMessage;

import { Text, Button } from "@radix-ui/themes";
import { Responsive } from "@radix-ui/themes/props";
import { Plus } from "lucide-react";
import { CSSProperties } from "styled-components";

export default function PlusButton({
  onClick,
  text,
  selected,
  customSize,
  customRadius,
  customStyles,
  disabled,
}: {
  onClick?: () => void;
  text: string;
  selected?: boolean;
  customSize?: Responsive<"3" | "1" | "2" | "4">;
  customRadius?: "small" | "medium" | "none" | "full" | "large";
  customStyles?: CSSProperties;
  disabled?: boolean;
}) {
  return (
    <Button
      style={{
        width: "fit-content",
        backgroundColor: disabled
          ? "#F0EDEB"
          : selected
          ? "#F7ECDC80"
          : "transparent",
        color: "#4C4747",
        border: "1px solid #7F7776",
        cursor: disabled ? "default" : "pointer",
        ...customStyles,
      }}
      disabled={disabled}
      size={customSize ?? "3"}
      radius={customRadius ?? "full"}
      onClick={onClick}
    >
      <Plus strokeWidth="2" size="20" />
      <Text as="span">{text}</Text>
    </Button>
  );
}

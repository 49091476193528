import { Flex } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const Content = styled(Flex)`
  border-top: 1px solid #ddd7d7;
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
`;

const EmailEditorLayout = ({
  children,
}: {
  children: [React.ReactNode, React.ReactNode, React.ReactNode];
}) => {
  return (
    <Flex direction="column" width="100%" maxHeight="100dvh" height="100dvh">
      {children[0]}
      <Content width="100%" justify="between">
        {children[1]}
        {children[2]}
      </Content>
    </Flex>
  );
};

export default EmailEditorLayout;

import CustomizationTypeSelect from "./customization/CustomizationTypeSelect";
import MenuLinksCustomization from "./customization/MenuLinksCustomization";
import SocialLinksCustomization from "./customization/SocialLinksCustomization";
import TemplateCustomization from "./customization/TemplateCustomization";
import { Flex, ScrollArea, Text } from "@radix-ui/themes";
import { ChevronLeftIcon } from "lucide-react";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  useTemplateEditorDispatch,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";
import {
  CustomizationLabels,
  CustomizationViews,
} from "~/types/template-editor";
import { getParentView } from "~/utils/template-editor";

const Aside = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  height: 100%;
  box-shadow: 0px 12px 120px 0px #00000014;
  max-width: 432px;
  width: 100%;
  flex-direction: column;
`;

const Header = styled(Flex)`
  border-bottom: 1px solid #ddd7d7;
  padding: 24px 0px;
  justify-content: center;
  position: relative;
  width: 100%;
  height: min-content;
`;

const DoneButton = styled(Flex)`
  cursor: pointer;
  color: #136ec1;
  gap: 6px;
  align-items: center;
  position: absolute;
  left: 16px;
`;

const Content = styled(ScrollArea)`
  padding: 24px;
`;

const TemplateEditorViewComponents: Record<
  CustomizationViews,
  React.ReactNode
> = {
  [CustomizationViews.TypeSelect]: <CustomizationTypeSelect />,
  [CustomizationViews.FooterOptions]: <TemplateCustomization />,
  [CustomizationViews.HeaderOptions]: <TemplateCustomization />,
  [CustomizationViews.MenuLinks]: <MenuLinksCustomization />,
  [CustomizationViews.SocialLinks]: <SocialLinksCustomization />,
};

const TemplateEditorModifierAside = () => {
  const { currentView, viewType, footerOptions, headerOptions } =
    useTemplateEditorState();
  const dispatch = useTemplateEditorDispatch();

  const isCustomHTMLActive = useMemo(
    () =>
      (currentView === CustomizationViews.HeaderOptions &&
        headerOptions.customHTML.enabled) ||
      (currentView === CustomizationViews.FooterOptions &&
        footerOptions.customHTML.enabled),
    [currentView, footerOptions, headerOptions]
  );

  return (
    <Aside>
      <Header>
        {currentView !== CustomizationViews.TypeSelect && (
          <DoneButton
            onClick={() =>
              dispatch({
                type: "CHANGE_CURRENT_VIEW",
                payload: getParentView(currentView, viewType),
              })
            }
          >
            <ChevronLeftIcon />
            <Text size="4" weight="medium">
              Done
            </Text>
          </DoneButton>
        )}
        <Text size="4" weight="medium">
          {CustomizationLabels[currentView]}
        </Text>
      </Header>
      <Content style={{ padding: isCustomHTMLActive ? "0px" : undefined }}>
        {TemplateEditorViewComponents[currentView]}
      </Content>
    </Aside>
  );
};

export default TemplateEditorModifierAside;

import CampaignCardContent from "./CampaignCardContent";
import CampaignCardHeader from "./CampaignCardHeader";
import { components, operations } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import React, { useState } from "react";
import styled from "styled-components";
import { timestampToDateString } from "~/utils/helpers";

const Card = styled(Flex)`
  border-radius: 12px;
  border: 1px solid var(--border);
  transition: height 0.3s;
`;

const Transition = styled.div`
  overflow: hidden;
  transition: max-height 0.5s;
`;

type CampaignType = components["schemas"]["CampaignListSchema"];
type EmailMetricType =
  operations["cdp_api_klaviyo_metrics"]["responses"][200]["content"]["application/json"];

const CampaignCard = ({
  campaign,
  metrics,
}: {
  campaign: CampaignType;
  metrics: EmailMetricType;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Flex direction="column" gap="12px" width="100%">
      <Text weight="medium">
        {campaign.status === "active" ? "Started on " : ""}{" "}
        {timestampToDateString(campaign.schedule_date)}
      </Text>
      <Card direction="column">
        <CampaignCardHeader
          isExpanded={isExpanded}
          onExpandChange={() => setIsExpanded(!isExpanded)}
          campaign={campaign}
        />
        <Transition style={{ maxHeight: isExpanded ? "3000px" : "0px" }}>
          <CampaignCardContent campaign={campaign} metrics={metrics} />
        </Transition>
      </Card>
    </Flex>
  );
};

export default CampaignCard;

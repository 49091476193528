import CampaignCardContentRow from "./CampaignCardContentRow";
import { components, operations } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { MailIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import { ReactComponent as MetaIcon } from "~/assets/MetaIcon.svg";
import { getAudiencesFromEmailAssets } from "~/utils/my-campaigns/helpers";

type CampaignType = components["schemas"]["CampaignListSchema"];
type EmailMetricType =
  operations["cdp_api_klaviyo_metrics"]["responses"][200]["content"]["application/json"];

const Content = styled(Flex)`
  padding: 24px;
  border-top: 1px solid var(--border);
  color: var(--text-secondary);
`;

const AudiencePill = styled(Flex)`
  padding: 6px 12px;
  border-radius: 12px;
  background-color: #dff4f5;
  font-size: 14px;
`;

const adCells = {
  orders: 53,
  revenue: 500,
  spent: 100,
  estimated_profit: 1000,
};

const scheduledCells = {
  date: new Date().toISOString(),
};

const CampaignCardContent = ({
  campaign,
  metrics,
}: {
  campaign: CampaignType;
  metrics: EmailMetricType;
}) => {
  if (campaign.email_assets.length + campaign.ad_assets.length === 0) {
    return (
      <Content justify="center" align="center">
        <Text align="center">No Emails or Ads</Text>
      </Content>
    );
  }

  const isScheduledCampaign = campaign.status === "scheduled";

  return (
    <Content direction="column" gap="16px">
      {campaign.email_assets.length > 0 && (
        <Flex gap="16px" direction="column">
          <Flex gap="8px" align="center">
            <MailIcon strokeWidth="1" width={20} height={20} />
            <Text>Email</Text>
            {getAudiencesFromEmailAssets(campaign.email_assets).map(
              (audience) => (
                <AudiencePill key={audience}>
                  <Text>{audience}</Text>
                </AudiencePill>
              )
            )}
          </Flex>
          {campaign.email_assets.map((emailAsset) => (
            <CampaignCardContentRow
              key={emailAsset.id}
              title={emailAsset.title}
              cells={metrics?.[emailAsset?.klaviyo_campaign_id ?? ""]}
              showEditButton={isScheduledCampaign}
            />
          ))}
        </Flex>
      )}
      {campaign.ad_assets.length > 0 && (
        <Flex gap="16px" direction="column">
          <Flex gap="8px" align="center">
            <MetaIcon width={20} height={20} />
            <Text>Meta Ads</Text>
          </Flex>
          {campaign.ad_assets.map((adAsset) => (
            <CampaignCardContentRow
              key={adAsset.id}
              title={adAsset.title}
              cells={isScheduledCampaign ? scheduledCells : adCells}
              showEditButton={isScheduledCampaign}
            />
          ))}
        </Flex>
      )}
    </Content>
  );
};

export default CampaignCardContent;

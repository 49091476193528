import { Box, Button, Text } from "@radix-ui/themes";
import { Pencil } from "lucide-react";
import { styled } from "styled-components";

const Root = styled.div`
  border-radius: 12px;
  border: 1px solid #ddd7d7;
  width: 450px;
  min-height: 200px;
  margin: 16px 32px 16px 0;
  display: flex;
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ddd7d7;
`;

const CampaignOverviewCard = ({ title }: { title: string }) => {
  return (
    <Root>
      <Box width={"100%"}>
        <Header>
          <Text>{title}</Text>
          <Button size={"1"} variant="ghost">
            <Pencil size={16} /> Edit
          </Button>
        </Header>
      </Box>
    </Root>
  );
};

export default CampaignOverviewCard;

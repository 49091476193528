import useEmbeddedEmail from "./email_editor/EmbeddedEmail";
import ButtonEditor from "./email_editor/button_editor/ButtonEditor";
import {
  useEmailDispatch,
  useEmailsState,
} from "./email_editor/context/EmailEditorContext";
import useGetEmailCreativeData from "./email_editor/hooks/useGetEmailCreativeData";
import useGetEmailCreativesList from "./email_editor/hooks/useGetEmailCreativesList";
import useUpdateEmailCreative from "./email_editor/hooks/useUpdateEmailCreative";
import { printEditorContent } from "./email_editor/sections/hero/useHandleHeroEditableText";
import ToggleShowEditorButton from "./email_editor/text_editor/ToggleShowEditorButton";
import { getQuillHtml } from "./email_editor/text_editor/htmlUtils";
import { EmailSectionType, operations } from "@openapi";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  SelectViewport,
  SelectGroup,
} from "@radix-ui/react-select";
import { useEffect } from "react";

export default function Cherry() {
  const { dispatch, handleInputChangeCurrentEmail } = useEmailDispatch();
  const emailsState = useEmailsState();
  const currentEmail = emailsState?.emails[emailsState?.selectedEmailId];
  //queries data
  const { creativesData, creativesError, isCreativesLoading } =
    useGetEmailCreativesList();
  const { emailCreativeData, emailCreativeError, isEmailCreativeLoading } =
    useGetEmailCreativeData(emailsState?.selectedEmailId);

  const { embeddedEmail, heroTextEditors } = useEmbeddedEmail();
  //only if an email doesn't exist in the state, it is added
  useEffect(() => {
    if (!emailCreativeData || !dispatch) return;
    dispatch({
      type: "ADD_EMAIL",
      payload: emailCreativeData,
    });
  }, [emailCreativeData]);

  //mutations
  const updateEmailMutation = useUpdateEmailCreative(
    currentEmail
      ? {
          ...currentEmail,
          sections: Object.values(currentEmail?.sections),
        }
      : undefined
  );

  const handleUpdateHeroEditableTexts = () => {
    Object.entries(heroTextEditors).map(
      ([sectionId, { quillTitleEditor, quillSubtitleEditor }], index: any) => {
        handleInputChangeCurrentEmail({
          sectionId: sectionId,
          field: "title",
          innerField: "text",
          value: getQuillHtml(quillTitleEditor?.quill),
        });
        handleInputChangeCurrentEmail({
          sectionId: sectionId,
          field: "subtitle",
          innerField: "text",
          value: getQuillHtml(quillSubtitleEditor?.quill),
        });
      }
    );
  };

  const handleSelectCreative = (id: string) => {
    handleUpdateHeroEditableTexts();
    dispatch({
      type: "SET_SELECTED_EMAIL",
      payload: id,
    });
  };

  const heroHideToolbarButtons = Object.entries(heroTextEditors).map(
    ([id, { quillSubtitleEditor, quillTitleEditor }], index) => {
      return (
        <div>
          <ToggleShowEditorButton
            quillData={quillTitleEditor}
            title="show/hide Title editor"
          ></ToggleShowEditorButton>
          <ToggleShowEditorButton
            quillData={quillSubtitleEditor}
            title="show/hide Subitle editor"
          ></ToggleShowEditorButton>
        </div>
      );
    }
  );
  const renderSectionInputs = (section: any) => {
    if (section.type === EmailSectionType.hero) {
      return (
        <>
          <div>
            <label>Background Image URL:</label>
            <input
              type="text"
              value={
                currentEmail?.sections[section.id]?.background?.image || ""
              }
              onChange={(e) =>
                handleInputChangeCurrentEmail({
                  sectionId: section.id,
                  field: "background",
                  innerField: "image",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label>Background Color:</label>
            <input
              type="text"
              value={
                currentEmail?.sections[section.id]?.background?.color || ""
              }
              onChange={(e) =>
                handleInputChangeCurrentEmail({
                  sectionId: section.id,
                  field: "background",
                  innerField: "color",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <p style={{ fontSize: "24px" }}>Button:</p>
            <label>CTA Button Text:</label>
            <input
              type="text"
              value={currentEmail?.sections[section.id]?.cta_button?.text || ""}
              onChange={(e) =>
                handleInputChangeCurrentEmail({
                  sectionId: section.id,
                  field: "cta_button",
                  innerField: "text",
                  value: e.target.value,
                })
              }
            />
            <ButtonEditor
              sectionId={section.id}
              existingStyles={currentEmail?.sections[section.id]?.cta_button}
              handleInputChange={handleInputChangeCurrentEmail}
            ></ButtonEditor>
            {heroHideToolbarButtons}
            <button
              onClick={() => {
                printEditorContent(heroTextEditors);
              }}
            >
              print content
            </button>
            <button
              onClick={() => {
                handleUpdateHeroEditableTexts();
                updateEmailMutation.mutate();
              }}
            >
              update
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Select
        onValueChange={handleSelectCreative}
        value={emailsState?.selectedEmailId || undefined}
      >
        <SelectTrigger
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px 12px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#fff",
            cursor: "pointer",
            width: "fit-content",
            minWidth: "250px",
          }}
        >
          <SelectValue placeholder="Select an Email Creative..." />
          <ChevronDownIcon />
        </SelectTrigger>
        <SelectContent
          style={{
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          <SelectViewport>
            <SelectGroup>
              {isCreativesLoading ? (
                <SelectItem value="loading">Loading...</SelectItem>
              ) : creativesError ? (
                <SelectItem value="error">Error loading creatives</SelectItem>
              ) : creativesData && creativesData.length > 0 ? (
                creativesData.map((creative) => (
                  <SelectItem key={creative.id} value={creative.id}>
                    {creative.name}
                  </SelectItem>
                ))
              ) : (
                <SelectItem value="empty">No creatives available</SelectItem>
              )}
            </SelectGroup>
          </SelectViewport>
        </SelectContent>
      </Select>

      {emailsState?.selectedEmailId && (
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div
            style={{
              flex: 1,
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f9f9f9",
              marginRight: "10px",
            }}
          >
            <h2>Rendered Email</h2>
            {isEmailCreativeLoading ? (
              <p>Loading...</p>
            ) : emailCreativeError ? (
              <p>Error loading email</p>
            ) : (
              <div>{embeddedEmail}</div>
            )}
          </div>
          <div
            style={{
              flex: 1,
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <h2>Email Sections</h2>
            {emailCreativeData?.sections.map((section, index) => (
              <div
                key={index}
                style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
              >
                <strong>{section.type}</strong> - Index: {section.index}
                {renderSectionInputs(section)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import * as Avatar from "@radix-ui/react-avatar";
import { Text, Flex, IconButton } from "@radix-ui/themes";
import { X, Star } from "lucide-react";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

export default function ProductCard({
  data,
  onClick,
  isFeatureDisabled,
  onFeatureToggle,
}: {
  onClick: (id: string) => void;
  data: MaybeFeaturedProduct;
  isFeatureDisabled: boolean;
  onFeatureToggle: (product: MaybeFeaturedProduct) => void;
}) {
  const featureButton = (
    <IconButton
      variant="ghost"
      radius="full"
      size={"1"}
      color="gray"
      style={{
        cursor: data.is_featured
          ? "pointer"
          : isFeatureDisabled
          ? "not-allowed"
          : "pointer",
      }}
      disabled={!data.is_featured && isFeatureDisabled}
      onClick={() => onFeatureToggle(data)}
    >
      <Star
        size={16}
        fill={data.is_featured ? "#595D62" : "none"}
        strokeWidth={1}
        stroke="#595D62"
      />
    </IconButton>
  );

  return (
    <Flex
      key={data.product_id}
      style={{
        position: "relative",
        backgroundColor: "transparent",
        border: "1px solid #DDD7D7",
        borderRadius: "12px",
        padding: "12px",
        alignItems: "center",
      }}
      gap="2"
    >
      {featureButton}
      <Avatar.Root
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar.Image
          src={data.image_url ?? ""}
          alt="image"
          width="40px"
          height="40px"
          style={{ border: "1px solid #0000000A" }}
        />
        <Avatar.Fallback
          style={{ width: "40px", height: "40px", background: "grey" }}
        />
      </Avatar.Root>
      <Flex direction="column" overflow="hidden">
        <Text
          as="span"
          style={{
            color: "#4C4747",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {data.title}
        </Text>
        <Text as="span" style={{ color: "#888282", fontSize: "12px" }}>
          {`${data.price}$`}
        </Text>
      </Flex>
      <X
        style={{
          position: "absolute",
          zIndex: 1,
          backgroundColor: "white",
          opacity: 100,
          top: -7,
          right: -7,
          borderRadius: "50%",
          border: "1px solid #DDD7D7",
          padding: "4px",
          cursor: "pointer",
        }}
        onClick={() => onClick(data.product_id)}
      />
    </Flex>
  );
}

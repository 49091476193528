import useTopBarActions from "../hooks/useTopBarActions";
import { Flex, IconButton, SegmentedControl, Select } from "@radix-ui/themes";
import { ArrowLeftIcon, MonitorIcon, SmartphoneIcon } from "lucide-react";
import styled from "styled-components";
import DraperButton from "~/components/core/buttons/DraperButton";
import { useEmailsState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const Bar = styled(Flex)`
  padding: 12px 16px 12px 16px;
`;

const SelectTrigger = styled(Select.Trigger)`
  font-size: 18px;
  font-weight: 500;
`;

const EmailEditorTopBar = () => {
  const { creativesData, updateEmailMutation, onEmailChange } =
    useTopBarActions();
  const { selectedEmailId } = useEmailsState();

  return (
    <Bar justify="between">
      <Flex gap="18px" align="center">
        <IconButton size="3" radius="large" variant="outline" color="gray">
          <ArrowLeftIcon />
        </IconButton>
        <Select.Root
          onValueChange={onEmailChange}
          value={selectedEmailId}
          size="3"
        >
          <SelectTrigger
            disabled={!creativesData}
            variant="ghost"
            color="gray"
          />
          <Select.Content color="gray" highContrast>
            {creativesData?.map((creative) => (
              <Select.Item key={creative.id} value={creative.id}>
                {creative.name}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Flex>

      <SegmentedControl.Root
        style={{ paddingTop: "4px", backgroundColor: "#F0EDEB" }}
        size="3"
        defaultValue="desktop"
      >
        <SegmentedControl.Item value="desktop">
          <MonitorIcon />
        </SegmentedControl.Item>
        <SegmentedControl.Item value="mobile">
          <SmartphoneIcon />
        </SegmentedControl.Item>
      </SegmentedControl.Root>

      <Flex gap="12px">
        <Select.Root defaultValue="preview" size="3">
          <Select.Trigger radius="large" color="gray" />
          <Select.Content color="gray" highContrast>
            <Select.Item value="preview">Preview</Select.Item>
            <Select.Item value="testEmail">Send a test email</Select.Item>
          </Select.Content>
        </Select.Root>
        <DraperButton
          loading={updateEmailMutation.isPending}
          onClick={() => updateEmailMutation.mutate()}
          variant="dark"
          text="Save"
          radius="large"
          size="3"
        />
      </Flex>
    </Bar>
  );
};

export default EmailEditorTopBar;

import { useAuthenticatedUserState } from "../../../contexts/CurrentUserContext";
import { operations, CampaignMarketingChannelType } from "@openapi";
import {
  Button,
  CheckboxGroup,
  Container,
  Dialog,
  Flex,
  Select,
  Text,
} from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";

type CreateCampaignQuickDraftParams =
  operations["campaign_api_create_quick_draft"]["requestBody"]["content"]["application/json"];

type CreateCampaignQuickDraftApiResponse =
  operations["campaign_api_create_quick_draft"]["responses"][200]["content"]["application/json"];

type CampaignRecommendationsResponse =
  operations["recommender_api_get_brand_campaign_recommendations"]["responses"][200]["content"]["application/json"];

interface InternCampaignQuickDraftCreationDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  onCreate: () => void;
}

const InternCampaignQuickDraftCreationDialog: React.FC<
  InternCampaignQuickDraftCreationDialogProps
> = ({ isDialogOpen, setIsDialogOpen, onCreate }) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const [
    selectedCampaignRecommendationID,
    setSelectedCampaignRecommendationID,
  ] = useState<string | null>(null);

  const [
    selectedCampaignMarketingChannels,
    setSelectedCampaignMarketingChannels,
  ] = useState<CampaignMarketingChannelType[]>([
    CampaignMarketingChannelType.ads,
    CampaignMarketingChannelType.email,
    CampaignMarketingChannelType.sms,
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const { data: campaignRecommendations } = useQuery({
    queryKey: ["brand-campaign-recommendations", activeBrandID],
    queryFn: async (): Promise<CampaignRecommendationsResponse> => {
      const { data } = await axios.get(
        "/api/v1/recommender/brand-campaign-recommendations",
        {
          params: {
            brand_id: activeBrandID,
          },
        }
      );
      return data;
    },
    enabled: !!activeBrandID,
    retry: false,
    staleTime: 1000 * 60 * 60,
  });

  const mutation = useMutation<
    CreateCampaignQuickDraftApiResponse,
    Error,
    CreateCampaignQuickDraftParams
  >({
    mutationFn: async (
      params: CreateCampaignQuickDraftParams
    ): Promise<CreateCampaignQuickDraftApiResponse> => {
      setIsLoading(true);
      const { data } = await axios.post(
        `/api/v1/campaign/create-quick-draft`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setIsDialogOpen(false);
      alert("Campaign created successfully!");
      onCreate();
    },
    onError: (error) => {
      setIsLoading(false);
      alert(error);
      console.error("Error creating campaign:", error);
    },
  });

  const handleCreateClick = () => {
    if (
      !selectedCampaignRecommendationID ||
      selectedCampaignRecommendationID.length === 0
    ) {
      alert("Campaign recommendation selection is required.");
      return;
    }
    mutation.mutate({
      brand_id: activeBrandID,
      campaign_recommendation_id: selectedCampaignRecommendationID,
      channels: selectedCampaignMarketingChannels,
    });
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Container>
        <Dialog.Content>
          <Dialog.Title>Quick Generate Campaign</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Quickly enerate a campaign with the selected campaign
            recommendation. This will also generate a campaign brief, ad, email,
            and sms creatives.
          </Dialog.Description>
          <Flex direction="column" gap="3">
            <label>
              <Text as="div" size="1" mb="1" weight="bold">
                Campaign Recommendation
              </Text>
              <Select.Root
                onValueChange={(value) =>
                  setSelectedCampaignRecommendationID(value)
                }
              >
                <Select.Trigger />
                <Select.Content>
                  <Select.Group>
                    {campaignRecommendations?.campaign_recommendations.map(
                      (recommendation) => (
                        <Select.Item
                          key={recommendation.id}
                          value={recommendation.id}
                        >
                          {recommendation.creative_recommendations[0]?.title ??
                            recommendation.summary ??
                            recommendation.id}
                        </Select.Item>
                      )
                    )}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </label>
            <label>
              <Text as="div" size="1" mb="1" weight="bold">
                Channels
              </Text>
              <CheckboxGroup.Root
                defaultValue={selectedCampaignMarketingChannels}
                onValueChange={(value) => {
                  setSelectedCampaignMarketingChannels(
                    value.map(
                      (v) =>
                        CampaignMarketingChannelType[
                          v as CampaignMarketingChannelType
                        ]
                    )
                  );
                }}
              >
                <Flex direction="row" align="center" gap="0.5rem">
                  {Object.values(CampaignMarketingChannelType).map(
                    (channel) => (
                      <CheckboxGroup.Item key={channel} value={channel}>
                        {channel}
                      </CheckboxGroup.Item>
                    )
                  )}
                </Flex>
              </CheckboxGroup.Root>
            </label>
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button onClick={handleCreateClick} loading={isLoading}>
              Create
            </Button>
          </Flex>
        </Dialog.Content>
      </Container>
    </Dialog.Root>
  );
};

export default InternCampaignQuickDraftCreationDialog;

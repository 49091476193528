import PlusButton from "../../../components/campaign/wizard/audience/common/PlusButton";
import SelectedButton from "../../../components/campaign/wizard/audience/common/SelectedButton";
import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CampaignWizardCardContent from "../../../components/campaign/wizard/cards/CampaignWizardCardContent";
import CategorizedSelect from "../../../components/campaign/wizard/inputs/CategorizedSelect";
import {
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import { useActiveBrandID } from "../../../contexts/CurrentUserContext";
import useCenterCampaignCard from "../../../hooks/campaign/useCenterCampaignCard";
import { CDPAudienceType, CDPType, operations } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import DraperButton from "~/components/core/buttons/DraperButton";

export type GetAudiencesData =
  operations["cdp_api_cdps"]["responses"][200]["content"]["application/json"];
export type Audience =
  operations["cdp_api_cdps"]["responses"][200]["content"]["application/json"][0]["audiences"][0];

const CampaignAudience = () => {
  const dispatch = useCampaignWizardDispatch();
  const { centerCurrentCard } = useCenterCampaignCard();
  const activeBrandId = useActiveBrandID();

  const { wizardType } = useCampaignWizardState();
  const { data, isLoading } = useQuery({
    queryKey: ["cdp-audiences", activeBrandId],
    queryFn: async (): Promise<GetAudiencesData> => {
      const response = await axios.get("/api/v1/cdp/list", {
        params: {
          brand_id: activeBrandId,
        },
      });
      return response.data;
    },
    enabled: !!activeBrandId,
  });
  const handleNextClick = () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: {
        currentStep:
          wizardType === "NEW"
            ? NewCampaignWizardSteps.PRODUCT
            : RecommendedCampaignWizardSteps.PROMOTION,
      },
    });
  };
  const handleBackClick = () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: {
        currentStep:
          wizardType === "NEW"
            ? NewCampaignWizardSteps.CHANNEL
            : RecommendedCampaignWizardSteps.CHANNEL,
      },
    });
  };

  const [selectedAudiences, setSelectedAudiences] = useState<
    Map<string, { audienceType: string; cdpType: string; audienceName: string }>
  >(new Map());
  const isForEveryone = selectedAudiences.size === 0;
  const [selectingAudiences, setSelectingAudiences] = useState(false);

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        audiences: Array.from(selectedAudiences).map(
          ([id, { audienceName, cdpType, audienceType }]) => ({
            audience_name: audienceName,
            cdp_type: cdpType.toLowerCase() as CDPType,
            audience_type: audienceType.toLowerCase() as CDPAudienceType,
            audience_id: id,
          })
        ),
      },
    });
  }, [selectedAudiences, wizardType]);

  // Actions
  const handleSelect = (
    id: string,
    name: string,
    cdpType: string,
    audienceType: string
  ) => {
    if (selectedAudiences.has(id)) {
      return;
    }
    setSelectedAudiences((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(id, { audienceName: name, cdpType, audienceType });
      return updatedMap;
    });
    centerCurrentCard();
  };
  const handleDeselect = (id: string) => {
    if (!selectedAudiences.has(id)) {
      return;
    }
    setSelectedAudiences((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(id);
      return updatedMap;
    });
    centerCurrentCard();
  };

  return (
    <CampaignWizardCard
      badgeTitle="Audience"
      nextButtonProps={{
        onClick: handleNextClick,
        text: "Looks good",
        disabled: !(isForEveryone || selectedAudiences.size),
      }}
      backButtonProps={{
        onClick: handleBackClick,
        text: "Back",
      }}
      additionalButtons={
        <DraperButton
          text={"Target Everyone"}
          onClick={() => {
            setSelectedAudiences(new Map());
            setSelectingAudiences(false);
            centerCurrentCard();
          }}
          variant="light"
          disabled={isForEveryone}
        />
      }
    >
      <CampaignWizardCardContent>
        <Text as="span">
          We recommend sending this campaign to all customers for maximum reach,
          but you may want to limit it to just a certain set.
        </Text>
        <Flex direction="row" gap="2" wrap="wrap">
          {selectedAudiences.size === 0 && (
            <SelectedButton id="everyone" text="Everyone" />
          )}
          {Array.from(selectedAudiences).map(([id, audience], index) => (
            <SelectedButton
              key={index}
              id={id}
              onClick={handleDeselect}
              text={audience.audienceName}
            />
          ))}
          <PlusButton
            onClick={() => {
              centerCurrentCard();
              setSelectingAudiences((prev) => !prev);
            }}
            text="Add audience"
            selected={selectingAudiences}
          />
        </Flex>

        {selectingAudiences && (
          <CategorizedSelect
            data={data}
            onSelect={handleSelect}
            loading={isLoading}
          />
        )}
      </CampaignWizardCardContent>
    </CampaignWizardCard>
  );
};
export default CampaignAudience;

import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CheckboxWithQuantity from "../../../components/campaign/wizard/inputs/CheckboxWithQuantity";
import useCampaignChannelProps, {
  NewCampaignChannelCountDefaults,
  RecommendedCampaignChannelCountDefaults,
} from "./hooks/useCampaignChannelProps";
import { CampaignType, ItemSetType } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";

const CampaignChannel = () => {
  const {
    wizardType,
    campaignData: { campaign_type },
  } = useCampaignWizardState();
  const { cardProps, checkboxProps, campaignRecommendation } =
    useCampaignChannelProps();

  let emailCount = 2;
  let adsCount = 2;
  if (wizardType === "NEW" && campaign_type) {
    emailCount =
      NewCampaignChannelCountDefaults[campaign_type as CampaignType]["Email"];
    adsCount =
      NewCampaignChannelCountDefaults[campaign_type as CampaignType][
        "Meta Ads"
      ];
  } else if (campaignRecommendation) {
    emailCount =
      RecommendedCampaignChannelCountDefaults[
        campaignRecommendation?.item_set_type as ItemSetType
      ]["Email"];
    adsCount =
      RecommendedCampaignChannelCountDefaults[
        campaignRecommendation?.item_set_type as ItemSetType
      ]["Meta Ads"];
  }

  return (
    <CampaignWizardCard {...cardProps}>
      <Flex direction="column" gap="24px">
        <Text size="5" style={{ lineHeight: "24px", color: "#4C4747" }}>
          {`We propose sending ${emailCount} emails and ${adsCount} ads as part of this campaign but
          you can modify the channels and touchpoints if you want.`}
        </Text>
        {checkboxProps.map((props) => (
          <CheckboxWithQuantity key={props.label} {...props} />
        ))}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignChannel;

import { AdMediaUpdateableElement, useAdMediaContext } from "../AdMediaContext";
import InternAdMediaElementsTab from "../intern/InternAdMediaElementsTab";
import { Flex } from "@radix-ui/themes";
import { AdMediaElementUnionSchema } from "~/types/ads";

const EditorSidePanel: React.FC = () => {
  const { elements, setElements } = useAdMediaContext();

  const handleElementUpdate = (updatedElement: AdMediaUpdateableElement) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  };

  return (
    <Flex
      width="430px"
      flexGrow="0"
      flexShrink="0"
      p="4"
      style={{
        borderLeft: "1px solid var(--border, #ddd7d7)",
      }}
    >
      <InternAdMediaElementsTab
        elements={elements}
        onElementUpdate={handleElementUpdate}
      />
    </Flex>
  );
};

export default EditorSidePanel;

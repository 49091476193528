import AddButtonBorder from "./AddButtonBorder";
import AddButtonColors from "./AddButtonColors";
import AddButtonFont from "./AddButtonFont";
import AddButtonPadding from "./AddButtonPadding";
import AddButtonSection from "./AddButtonSection";
import AddButtonShape from "./AddButtonShape";
import { components, operations } from "@openapi";
import {
  Button,
  Dialog,
  Flex,
  Heading,
  ScrollArea,
  Spinner,
  Switch,
  Text,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import BrandButton from "~/components/core/buttons/BrandButton";
import CloseIconButton from "~/components/core/buttons/CloseIconButton";
import ConfirmationAlert from "~/components/core/dialog/ConfirmationAlert";
import { useBrandStylingDispatch } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useButtonStyleProps from "~/hooks/style-library/useButtonStyleProps";
import useDeleteButtonStyle from "~/hooks/style-library/useDeleteButtonStyle";

type UpsertButtonStyleParams =
  operations["brand_api_update_button_style"]["requestBody"]["content"]["application/json"];
type UpsertButtonStyleResponse =
  operations["brand_api_update_button_style"]["responses"][200]["content"]["application/json"];

interface ButtonStyleDialogProps {
  buttonStyle?: components["schemas"]["BrandButtonStyleSchema"];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  allowDelete?: boolean;
}

const ButtonStyleDialog: React.FC<ButtonStyleDialogProps> = ({
  buttonStyle,
  open,
  onOpenChange,
  allowDelete = true,
}) => {
  const {
    state,
    shapeProps,
    colorProps,
    fontProps,
    borderProps,
    paddingProps,
    toButtonStyle,
  } = useButtonStyleProps(buttonStyle);
  const [serverErrors, setServerErrors] = useState<string | null>(null);
  const activeBrandID = useActiveBrandID();
  const brandStylingDispatch = useBrandStylingDispatch();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const upsertButtonStyle = useMutation<
    UpsertButtonStyleResponse,
    AxiosError,
    UpsertButtonStyleParams
  >({
    mutationFn: async (params) => {
      const { data } = await axios.post(
        `/api/v1/brand/${activeBrandID}/stylebook/button${
          buttonStyle?.id ? `/${buttonStyle.id}` : ""
        }`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      brandStylingDispatch({
        type: "UPSERT_BUTTON_STYLE",
        payload: {
          isNew: !buttonStyle?.id,
          style: data,
        },
      });
      onOpenChange(false);
    },
    onError: (error) => {
      console.log("Button style upsert failed:", error);
      const errorMessage =
        error.status != 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      setServerErrors(errorMessage);
    },
  });

  const handleSave = () => {
    setServerErrors(null);
    upsertButtonStyle.mutate(toButtonStyle());
  };

  const deleteButtonStyle = useDeleteButtonStyle({
    buttonStyleId: buttonStyle?.id ?? "",
    onSuccess: () => {
      onOpenChange(false);
    },
    onError: (message) => {
      setServerErrors(message);
    },
  });

  const handleDelete = () => {
    deleteButtonStyle.mutate();
  };

  const isUpdating = upsertButtonStyle.isPending || deleteButtonStyle.isPending;

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        style={{ padding: 0 }}
        onInteractOutside={(e) => {
          if (isUpdating) {
            e.preventDefault();
          }
        }}
      >
        <Dialog.Description />
        <Flex
          justify="between"
          align="center"
          p="16px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="medium" mb="0">
            {`${buttonStyle ? "Edit" : "Add"} Button Style`}
          </Dialog.Title>
          <Dialog.Close disabled={isUpdating}>
            <CloseIconButton />
          </Dialog.Close>
        </Flex>
        <Flex direction="column" pb="24px" px="0">
          <Flex
            mb="24px"
            style={{
              borderBottom: "1px solid #E2E2E2",
              backgroundColor: "#F0EDEB",
            }}
          >
            <BrandButton
              {...state}
              style={{
                ...state.style,
                margin: "24px auto",
              }}
            >
              Shop Now
            </BrandButton>
          </Flex>
          <ScrollArea>
            <Flex direction="column" maxHeight="40vh">
              <AddButtonSection title="Shape">
                <AddButtonShape {...shapeProps} />
              </AddButtonSection>
              <AddButtonSection title="Colors">
                <AddButtonColors {...colorProps} />
              </AddButtonSection>
              <AddButtonSection title="Font">
                <AddButtonFont {...fontProps} />
              </AddButtonSection>
              <AddButtonSection title="Border">
                <AddButtonBorder {...borderProps} />
              </AddButtonSection>
              <AddButtonSection
                title="Padding"
                rightSlot={
                  <Text as="label">
                    Apply to all sides
                    <Switch
                      checked={paddingProps.isAppliedForAllSides}
                      onClick={paddingProps.toggleSides}
                      color="gray"
                      highContrast
                      ml="16px"
                      mt="1"
                    />
                  </Text>
                }
              >
                <AddButtonPadding {...paddingProps} />
              </AddButtonSection>
            </Flex>
          </ScrollArea>
        </Flex>
        <Flex p="24px" style={{ borderTop: "1px solid #DDD7D7" }}>
          {allowDelete && buttonStyle?.id && (
            <Button
              color="gray"
              style={{ color: "#4C4747" }}
              size="4"
              radius="large"
              variant="outline"
              disabled={isUpdating}
              onClick={() => setIsDeleteConfirmOpen(true)}
            >
              {deleteButtonStyle.isPending && <Spinner />}
              Delete
            </Button>
          )}
          <Flex ml="auto" gap="3">
            <Dialog.Close>
              <Button
                color="gray"
                style={{ color: "#4C4747" }}
                size="4"
                radius="large"
                variant="outline"
                disabled={isUpdating}
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              size="4"
              color="gray"
              highContrast
              radius="large"
              disabled={isUpdating}
              onClick={handleSave}
            >
              {upsertButtonStyle.isPending && <Spinner />}
              {upsertButtonStyle.isPending ? "Saving" : "Save Changes"}
            </Button>
          </Flex>
        </Flex>
        {serverErrors && (
          <Heading
            mt="-24px"
            style={{
              padding: "24px",
            }}
            size="2"
            color="red"
            align={"right"}
          >
            {serverErrors}
          </Heading>
        )}
      </Dialog.Content>
      <ConfirmationAlert
        open={isDeleteConfirmOpen}
        onOpenChange={setIsDeleteConfirmOpen}
        title="Delete Button Style"
        description="Are you sure? This action cannot be undone."
        onConfirm={handleDelete}
        confirmText="Delete"
      />
    </Dialog.Root>
  );
};

export default ButtonStyleDialog;

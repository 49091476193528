import { Text } from "@radix-ui/themes";
import styled from "styled-components";

const ValidationText = styled(Text)`
  color: red;
`;

const FormErrorText = ({ children }: { children: React.ReactNode }) => {
  return (
    <ValidationText size="1" color="red">
      {children}
    </ValidationText>
  );
};

export default FormErrorText;

import { PublicRootRouteBase } from "../root";
import ConfirmEmail from "@components/auth/ConfirmEmail";
import CreateAccount from "@components/auth/CreateAccount";
import Login from "@components/auth/Login";
import ResetPassword from "@components/auth/ResetPassword";
import { Theme } from "@radix-ui/themes";
import { RouteObject } from "react-router-dom";
import ConfirmResetPassword from "~/components/auth/ConfirmResetPassword";
import MetaCallback from "~/components/auth/MetaCallback";
import VerifyEmail from "~/components/auth/VerifyEmail";
import ShopifyInstall from "~/views/shopify/ShopifyInstall";

export default function getPublicRoutes(): RouteObject[] {
  return [
    {
      path: "/",
      element: (
        <Theme radius="large">
          <PublicRootRouteBase />
        </Theme>
      ),
      children: [
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "verify-email",
          element: <VerifyEmail />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/confirm-reset-password",
          element: <ConfirmResetPassword />,
        },
        {
          path: "/create-account",
          element: <CreateAccount />,
        },
        {
          path: "/confirm-email",
          element: <ConfirmEmail />,
        },
      ],
    },
    {
      path: "/auth/meta/callback",
      element: <MetaCallback />,
    },
    {
      path: "/shopify/install",
      element: <ShopifyInstall />,
    },
  ];
}

import DialogSeparator from "./DialogSeparator";
import { Box } from "@radix-ui/themes";

const DialogActions: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <DialogSeparator />
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default DialogActions;

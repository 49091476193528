import { components, FontOrigin } from "@openapi";
import { rgbToHex } from "@shopify/polaris";
import { parse } from "path";
import { useMemo, useState } from "react";
import {
  BrandButtonStyleProps,
  getBrandButtonStyleProps,
} from "~/components/core/buttons/BrandButton";
import { AddButtonBorderProps } from "~/components/style-library/button-styles/dialogs/AddButtonBorder";
import { AddButtonColorsProps } from "~/components/style-library/button-styles/dialogs/AddButtonColors";
import { AddButtonFontProps } from "~/components/style-library/button-styles/dialogs/AddButtonFont";
import { AddButtonPaddingProps } from "~/components/style-library/button-styles/dialogs/AddButtonPadding";
import { AddButtonShapeProps } from "~/components/style-library/button-styles/dialogs/AddButtonShape";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

type BrandButtonStyleNumProps = Omit<BrandButtonStyleProps, "style"> & {
  style: Omit<
    BrandButtonStyleProps["style"],
    | "borderRadius"
    | "fontSize"
    | "paddingTop"
    | "paddingRight"
    | "paddingBottom"
    | "paddingLeft"
  > & {
    // design only supports px values atm
    borderRadius: number;
    fontSize: number;
    paddingTop: number;
    paddingRight: number;
    paddingBottom: number;
    paddingLeft: number;
    fontOrigin?: FontOrigin;
  };
};

const initialState: Omit<BrandButtonStyleNumProps, "style"> & {
  style: Omit<BrandButtonStyleNumProps["style"], "fontFamily">;
} = {
  radius: "large",
  style: {
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 0,
    borderColor: "#FFFFFF",
    backgroundColor: "#136EC1",
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 400,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
  },
};

const parsePxValue = (value?: string | number | null): number | undefined => {
  if (!value) {
    return undefined;
  }
  if (typeof value === "number") {
    return value;
  }
  if (value.endsWith("px")) {
    return parseFloat(value);
  }
  const numericValue = Number(value);
  if (!Number.isNaN(numericValue)) {
    return numericValue;
  }
  return undefined;
};

const toPxValue = (value: number | string): string => {
  if (typeof value === "number") {
    return `${value}px`;
  }
  if (value.endsWith("px")) {
    return value;
  }
  const numValue = Number(value);
  if (Number.isNaN(numValue)) {
    return value;
  }
  return `${numValue}px`;
};

const getInitialState = (
  buttonStyle: components["schemas"]["BrandButtonStyleSchema"]
): BrandButtonStyleNumProps => {
  const style = getBrandButtonStyleProps(buttonStyle);
  return {
    ...style,
    style: {
      ...style.style,
      borderRadius:
        parsePxValue(style.style.borderRadius) ??
        initialState.style.borderRadius,
      borderWidth: parsePxValue(style.style.borderWidth),
      fontSize:
        parsePxValue(style.style.fontSize) ?? initialState.style.fontSize,
      paddingTop:
        parsePxValue(style.style.paddingTop) ?? initialState.style.paddingTop,
      paddingBottom:
        parsePxValue(style.style.paddingBottom) ??
        initialState.style.paddingBottom,
      paddingLeft:
        parsePxValue(style.style.paddingLeft) ?? initialState.style.paddingLeft,
      paddingRight:
        parsePxValue(style.style.paddingRight) ??
        initialState.style.paddingRight,
    },
  };
};

const useButtonStyleProps = (
  buttonStyle?: components["schemas"]["BrandButtonStyleSchema"]
) => {
  const { data: brandStyle } = useBrandStyle();
  const [state, setState] = useState<BrandButtonStyleNumProps>(
    buttonStyle
      ? getInitialState(buttonStyle)
      : {
          ...initialState,
          style: {
            ...initialState.style,
            fontFamily:
              brandStyle?.typography.paragraph_font_family?.font_face?.name ??
              "Arial",
          },
        }
  );

  const updateStyles = (
    newStyles: Partial<Omit<BrandButtonStyleNumProps, "style">> & {
      style?: Partial<BrandButtonStyleNumProps["style"]>;
    }
  ) => {
    setState((prevState) => ({
      ...prevState,
      ...newStyles,
      style: {
        ...prevState.style,
        ...newStyles.style,
      },
    }));
  };

  const shapeProps = useMemo<AddButtonShapeProps>(() => {
    return {
      radius: state.radius,
      value: Number(state.style?.borderRadius),
      onChange: (radius, value) => {
        updateStyles({
          radius,
          style: value ? { borderRadius: value } : {},
        });
      },
    };
  }, [state.radius, state?.style?.borderRadius]);

  const colorProps = useMemo<AddButtonColorsProps>(
    () => ({
      backgroundColor: state?.style?.backgroundColor,
      textColor: state?.style?.color,
      borderColor: state?.style?.borderColor,
      onBackgroundColorChange: (color) => {
        updateStyles({
          style: {
            backgroundColor: color,
          },
        });
      },
      onTextColorChange: (color) => {
        updateStyles({
          style: {
            color,
          },
        });
      },
      onBorderColorChange: (color) => {
        updateStyles({
          style: {
            borderColor: color,
          },
        });
      },
    }),
    [
      state?.style?.backgroundColor,
      state?.style?.color,
      state?.style?.borderColor,
    ]
  );

  const fontProps = useMemo<AddButtonFontProps>(
    () => ({
      fontSize: Number(state?.style?.fontSize),
      isBold:
        state?.style?.fontWeight &&
        (state.style.fontWeight === "bold" ||
          String(state.style.fontWeight) === "700"),
      isItalic: state?.style?.fontStyle === "italic",
      fontFamily: state?.style?.fontFamily,
      onFontSizeChange: (fontSize) => {
        updateStyles({
          style: {
            fontSize,
          },
        });
      },
      onBoldChange: (isBold) => {
        updateStyles({
          style: {
            fontWeight: isBold ? 700 : 400,
          },
        });
      },
      onItalicChange: (isItalic) => {
        updateStyles({
          style: {
            fontStyle: isItalic ? "italic" : "normal",
          },
        });
      },
      onFontFamilyChange: (fontFamily) => {
        updateStyles({
          style: {
            fontFamily: fontFamily.name,
          },
        });
      },
    }),
    [
      state?.style?.fontSize,
      state?.style?.fontWeight,
      state?.style?.fontStyle,
      state?.style?.fontFamily,
    ]
  );

  const borderProps = useMemo<AddButtonBorderProps>(
    () => ({
      borderWidth: Number(state?.style?.borderWidth),
      borderStyle: state?.style?.borderStyle,
      onBorderWidthChange: (borderWidth) => {
        updateStyles({
          style: {
            borderWidth,
          },
        });
      },
      onBorderStyleChange: (borderStyle) => {
        updateStyles({
          style: {
            borderStyle,
          },
        });
      },
    }),
    [state?.style?.borderWidth, state?.style?.borderStyle]
  );

  const [isAppliedForAllSides, setIsAppliedForAllSides] = useState(
    state.style.paddingTop === state.style.paddingRight &&
      state.style.paddingTop === state.style.paddingBottom &&
      state.style.paddingTop === state.style.paddingLeft
  );
  const paddingProps = useMemo<
    AddButtonPaddingProps & { toggleSides: () => void }
  >(
    () => ({
      padding: {
        top: Number(state?.style?.paddingTop),
        right: Number(state?.style?.paddingRight),
        bottom: Number(state?.style?.paddingBottom),
        left: Number(state?.style?.paddingLeft),
      },
      isAppliedForAllSides,
      onPaddingChange: (padding) => {
        updateStyles({
          style: {
            paddingTop: padding.top,
            paddingRight: padding.right,
            paddingBottom: padding.bottom,
            paddingLeft: padding.left,
          },
        });
      },
      toggleSides: () => {
        setIsAppliedForAllSides((prev) => !prev);
      },
    }),
    [
      state?.style?.paddingTop,
      state?.style?.paddingRight,
      state?.style?.paddingBottom,
      state?.style?.paddingLeft,
      isAppliedForAllSides,
    ]
  );

  const toButtonStyle =
    (): components["schemas"]["UpdateButtonStyleRequestData"] => {
      var borderRadius: string;
      if (state.radius === "none") {
        borderRadius = "0px";
      } else if (state.radius === "full") {
        borderRadius = "100px";
      } else {
        borderRadius = toPxValue(state.style.borderRadius);
      }
      return {
        background_color: state.style.backgroundColor,
        border_color: state.style.borderColor ?? null,
        border_radius: borderRadius,
        border_style: state.style.borderStyle,
        border_width: state.style.borderWidth
          ? toPxValue(state.style.borderWidth)
          : null,
        color: state.style.color,
        font: {
          font_family: state.style.fontFamily,
          size: toPxValue(state.style.fontSize),
          style: state.style.fontStyle ?? null,
          weight: Number(state.style.fontWeight),
        },
        padding: `${state.style.paddingTop}px ${state.style.paddingRight}px ${state.style.paddingBottom}px ${state.style.paddingLeft}px`,
        font_origin: state.style.fontOrigin ?? null,
      };
    };

  return {
    state,
    shapeProps,
    colorProps,
    fontProps,
    borderProps,
    paddingProps,
    toButtonStyle,
  };
};

export default useButtonStyleProps;

import ChatMessageContainer from "../chat/ChatMessageContainer";
import KinLogo from "../core/KinLogo";
import DraperButton from "../core/buttons/DraperButton";
import OnboardingCard from "./OnboardingCard";
import { operations } from "@openapi";
import * as Form from "@radix-ui/react-form";
import { Box, Flex, Text, TextField } from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { CircleCheck } from "lucide-react";
import styled from "styled-components";
import {
  useActiveBrandID,
  useAuthenticatedUserState,
} from "~/contexts/CurrentUserContext";

type BrandShopifyConfigResponse =
  operations["brand_api_get_brand_shopify_config"]["responses"][200]["content"]["application/json"];

type LoginParams =
  operations["shopify_integration_api_login"]["requestBody"]["content"]["application/json"];
type LoginResponse =
  operations["shopify_integration_api_login"]["responses"][200]["content"]["application/json"];

const TextInput = styled(TextField.Root)`
  height: 48px;
`;

const ConnectedViewRoot = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 16px;
`;

const OnboardingShopify = ({ onNext }: { onNext: () => void }) => {
  const activeBrandID = useActiveBrandID();

  const login = useMutation<LoginResponse, Error, LoginParams>({
    mutationFn: async (params: LoginParams): Promise<LoginResponse> => {
      const { data } = await axios.post("/api/v1/shopify/login", params, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return data;
    },
    onSuccess: (data) => {
      const redirect = data.redirect_url;
      if (redirect) {
        window.location.href = redirect;
      }
    },
    onError: (error) => {
      alert(error);
      console.error("Error connecting Shopify store", error);
    },
  });
  const {
    data: shopifyConfigData,
    isLoading,
    isSuccess,
    error,
  } = useQuery<BrandShopifyConfigResponse>({
    queryKey: ["brand-shopify-config-", activeBrandID],
    queryFn: async (): Promise<BrandShopifyConfigResponse> => {
      const { data } = await axios.get(
        `/api/v1/brand/${activeBrandID}/shopify-config`
      );
      return data;
    },
    enabled: !!activeBrandID,
  });

  const form = (
    <Form.Root
      onSubmit={(e) => {
        e.preventDefault();
        const data = Object.fromEntries(new FormData(e.currentTarget));

        login.mutate({
          brand_id: activeBrandID,
          shop: data?.store as string,
        });
      }}
    >
      <Flex gap="4" direction={"row"} align={"end"}>
        <Box width={"100%"}>
          <Form.Field name="store" autoFocus>
            <Form.Label>
              <Text size={"1"}>Shopify Store</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextInput required placeholder="store-name.myshopify.com" />
            </Form.Control>
            <Form.Message match="valueMissing" style={{ color: "red" }}>
              <Text size={"1"}> Please enter your Shopify Store</Text>
            </Form.Message>
          </Form.Field>
        </Box>

        <Form.Submit asChild>
          <DraperButton
            variant="dark"
            text={login.isPending || login.isSuccess ? "Connecting" : "Connect"}
            disabled={login.isPending || login.isSuccess}
          />
        </Form.Submit>
      </Flex>
    </Form.Root>
  );

  const isShopifyConnected =
    activeBrandID !== null && shopifyConfigData?.id != null;

  const connectedView = (
    <ConnectedViewRoot justify={"between"} align={"center"} gap="8" p="5">
      <Flex align={"center"} gap="2">
        <Flex
          p="2"
          width={"48px"}
          height={"48px"}
          style={{
            backgroundColor: "#FF6B6B",
            borderRadius: "8px",
            border: "1px solid #DDD7D7",
          }}
        >
          <KinLogo color="white" />
        </Flex>

        <Text size={"2"} weight={"bold"}>
          Kin App
        </Text>
      </Flex>
      <Flex align={"center"} gap="2">
        <CircleCheck size={24} color="#50A044" />
        <Text size={"2"}>Shopify app installed.</Text>
      </Flex>
    </ConnectedViewRoot>
  );

  return (
    <Flex direction={"column"} justify={"center"} align={"center"} gap="8">
      <OnboardingCard
        primary={
          <DraperButton
            variant="dark"
            text="Continue"
            onClick={onNext}
            disabled={!isShopifyConnected}
          />
        }
        pillText="Shopify Account"
      >
        <ChatMessageContainer message="You need to install our Shopify app so we can extract your brand assets for generating marketing campaigns." />
        <Box width={"100%"}>{isShopifyConnected ? connectedView : form}</Box>
      </OnboardingCard>
    </Flex>
  );
};

export default OnboardingShopify;

import classes from "./LoadingDots.module.css";
import { Box } from "@radix-ui/themes";
import React from "react";

const LoadingDots = () => (
  <Box className={classes.wrapper}>
    <Box className={classes["dot-flashing"]} />
  </Box>
);

export default LoadingDots;

import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

type UpdateEmailMutationResponse =
  operations["emails_api_update_email_creative"]["responses"]["200"]["content"]["application/json"];
export type Email =
  operations["emails_api_get_email_creative"]["responses"][200]["content"]["application/json"];
const useUpdateEmailCreative = (email: Email | undefined) => {
  const queryClient = useQueryClient();
  const updateEmailMutation = useMutation<UpdateEmailMutationResponse, Error>({
    mutationFn: async (): Promise<UpdateEmailMutationResponse> => {
      const response = await axios.put(`/api/v1/emails/${email?.id}`, email, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return response.data;
    },
    onError: (error) => {
      alert(error);
      console.error("Error updating email:", error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["emailCreative", email?.id ?? ""],
      });
    },
  });
  return updateEmailMutation;
};
export default useUpdateEmailCreative;

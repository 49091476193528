import { useEditor } from "@craftjs/core";
import { Tooltip } from "@radix-ui/themes";
import { FileJson, Undo, Redo } from "lucide-react";
import styled from "styled-components";

const HeaderDiv = styled.div`
  width: 100%;
  height: 39px;
  z-index: 99999;
  position: relative;
  display: flex;
  border-bottom: 1px solid rgb(228, 228, 231);
`;

const Btn = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  svg {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    fill: #fff;
    opacity: 0.9;
  }
`;

const Item = styled.a<{ disabled?: boolean }>`
  margin-right: 10px;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    opacity:0.5;
    cursor: not-allowed;
  `}
`;

export const Header = () => {
  const { enabled, canUndo, canRedo, actions, query } = useEditor(
    (state, query) => ({
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo(),
    })
  );

  return (
    <HeaderDiv className="header text-white transition w-full">
      <div
        className="items-center flex w-full px-4 justify-end"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          width: "100%",
        }}
      >
        {enabled && (
          <div
            style={{
              display: "flex",
            }}
          >
            <Tooltip content="Undo">
              <Item disabled={!canUndo} onClick={() => actions.history.undo()}>
                <Undo />
              </Item>
            </Tooltip>
            <Tooltip content="Redo">
              <Item disabled={!canRedo} onClick={() => actions.history.redo()}>
                <Redo />
              </Item>
            </Tooltip>
          </div>
        )}
        <div
          style={{
            display: "flex",
          }}
        >
          <Tooltip content="Export">
            <Item onClick={() => console.log(query.serialize())}>
              <FileJson />
            </Item>
          </Tooltip>
        </div>
        {/* <div className="flex">
          <Btn
            className={cx([
              "transition cursor-pointer",
              {
                "bg-green-400": enabled,
                "bg-primary": !enabled,
              },
            ])}
            onClick={() => {
              actions.setOptions((options) => (options.enabled = !enabled));
            }}
          >
            {enabled ? <Apple /> : <Apple />}
            {enabled ? "Finish Editing" : "Edit"}
          </Btn>
        </div> */}
      </div>
    </HeaderDiv>
  );
};

import Quill from "quill";
import { Delta } from "quill/core";

export type QuillData = {
  quill: Quill | null;
  toolbar: HTMLElement | null;
  editor: HTMLElement | null;
};

const FontAttributor: any = Quill.import("attributors/class/font");
FontAttributor.whitelist = ["monospace", "roboto"];
Quill.register(FontAttributor, true);

export const getTextEditorByKey = (
  key: string,
  existingContent?: Delta
): QuillData => {
  const editorId = `editor_${key}`;
  const toolbarId = `toolbar_${key}`;

  const editor = document.getElementById(editorId);
  const toolbar = document.getElementById(toolbarId);

  if (!editor || !toolbar) {
    console.warn("Could not find editor or toolbar for quill!");
    return { quill: null, toolbar: toolbar, editor: editor };
  }
  const quill = addQuill(editor, toolbar, existingContent);

  return { quill: quill, toolbar: toolbar, editor: editor };
};

const getTextEditor = (
  emailId: string,
  sectionId: string,
  field_name: string,
  existingContent?: Delta
): QuillData => {
  const key = `${emailId}_${sectionId}_${field_name}`.replace(/-/g, "_");
  return getTextEditorByKey(key, existingContent);
};

const addQuill = (
  editor?: string | HTMLElement | null,
  toolbar?: string | HTMLElement | null,
  existingContent?: Delta
) => {
  if (!editor || !toolbar) {
    return null;
  }
  const quill = new Quill(editor, {
    modules: {
      toolbar: {
        container: toolbar,
      },
    },
    theme: "snow",
  });
  if (existingContent) {
    quill.setContents(existingContent);
  }
  return quill;
};

export default getTextEditor;

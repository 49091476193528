import { Avatar, Button, Flex, Grid, Separator, Text } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon, StarsIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import DraperButton from "~/components/core/buttons/DraperButton";
import TextAreaWithLabel from "~/components/core/inputs/TextAreaWithLabel";
import TextFieldWithLabel from "~/components/core/inputs/TextFieldWithLabel";

const TextContainer = styled(Flex)`
  box-shadow: 0px 12px 48px 0px #00000014;
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 20px;
`;

const GenerateButton = styled(Button)`
  color: #4c4747;
  width: max-content;
`;

const SectionSeparator = styled(Separator)`
  width: 100%;
`;

const DescriptionText = styled(Text)`
  color: var(--text-tertiary);
`;

const SectionTitle = styled(Text)`
  color: var(--text-secondary);
`;

const VariantSelector = styled(Grid)`
  gap: 8px;
  padding: 8px;
  border-radius: 12px;
  background-color: #f0edeb;
`;

const GenerateVariantView = () => {
  return (
    <Flex direction="column" gap="24px">
      <Flex gap="16px">
        <MarkAvatar />
        <TextContainer>
          Select any block you want modify, or generate a new option.
        </TextContainer>
      </Flex>
      <GenerateButton size="4" radius="large" variant="outline" color="gray">
        <StarsIcon /> Generate another variant
      </GenerateButton>
      <Flex direction="column" gap="16px">
        <SectionTitle weight="medium">Generated Variant</SectionTitle>
        <VariantSelector columns="2">
          <DraperButton
            style={{ backgroundColor: "#ffffff" }}
            radius="large"
            variant="outlined"
            text="Previous"
            icon={<ChevronLeftIcon />}
          />
          <DraperButton
            iconPosition="right"
            style={{ backgroundColor: "#ffffff" }}
            radius="large"
            variant="outlined"
            text="Next"
            icon={<ChevronRightIcon />}
          />
        </VariantSelector>
      </Flex>
      <SectionSeparator />
      <TextFieldWithLabel label="Email Subject" />
      <Flex gap="8px" direction="column">
        <TextAreaWithLabel
          labelColor="var(--text-tertiary)"
          label="Preheader Text"
        />
        <DescriptionText size="2" weight="regular">
          A preheader is the short summary text that follows the subject line
          when viewing an email from the inbox.
        </DescriptionText>
      </Flex>
    </Flex>
  );
};

export default GenerateVariantView;

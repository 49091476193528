import Handlebars from "handlebars";
import {
  EmailBrandFooterSectionHandlebars,
  EmailBrandHeaderSectionHandlebars,
} from "~/types/template-vars";

Handlebars.registerHelper("default", (option: String, defaultValue: String) => {
  return option ?? defaultValue;
});

type EmailSectionPreviewProps = {
  template: Handlebars.TemplateDelegate;
  vars: EmailBrandHeaderSectionHandlebars | EmailBrandFooterSectionHandlebars;
};

const EmailSectionPreview: React.FC<EmailSectionPreviewProps> = ({
  template,
  vars,
}) => {
  return (
    <div
      style={{
        // TODO: this should hold the default email style
        padding: "16px",
        fontFamily: "Arial",
        fontSize: "16px",
        lineHeight: "24px",
      }}
      dangerouslySetInnerHTML={{
        __html: template(vars),
      }}
    />
  );
};

export default EmailSectionPreview;

import { useActiveBrandID } from "../../contexts/CurrentUserContext";
import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type BrandStylingResponse =
  operations["brand_api_get_styling"]["responses"][200]["content"]["application/json"];

const useBrandStyleQuery = () => {
  const activeBrandID = useActiveBrandID();
  const result = useQuery({
    queryKey: ["brand-style", activeBrandID],
    queryFn: async (): Promise<BrandStylingResponse> => {
      const { data } = await axios.get("/api/v1/brand/top-styling", {
        params: {
          id: activeBrandID,
        },
      });
      return data;
    },
    enabled: !!activeBrandID,
  });

  return result;
};

export default useBrandStyleQuery;

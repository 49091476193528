import { Header } from "./Header";
import Sidepane from "./Sidepane";
import { useEditor } from "@craftjs/core";
import cx from "classnames";
import React, { useEffect } from "react";

const Viewport: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    enabled,
    connectors,
    actions: { setOptions },
  } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  useEffect(() => {
    if (!window) {
      return;
    }

    window.requestAnimationFrame(() => {
      // Notify doc site
      window.parent.postMessage(
        {
          LANDING_PAGE_LOADED: true,
        },
        "*"
      );

      setTimeout(() => {
        setOptions((options) => {
          options.enabled = true;
        });
      }, 200);
    });
  }, [setOptions]);

  return (
    <div className="viewport-root">
      <div id="page-container" className="viewport-pageContainer">
        <Header />
        <div
          id="main-scrollable"
          style={{
            overflow: "auto",
          }}
          ref={
            (ref) => ref && connectors.select(connectors.hover(ref, ""), "") // TODO: ashley if this doesnt work check this, used to be null
          }
        >
          <div className="viewport-children">{children}</div>
        </div>
      </div>
      <Sidepane />
    </div>
  );
};

export default Viewport;

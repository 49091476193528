import { ProductCategory } from "../hooks/useGetProductCategories";
import { Select, Text } from "@radix-ui/themes";

const CategorySelector = ({
  categories,
  setCategoryId,
  selectedCategoryId,
}: {
  categories: Map<string, ProductCategory>;
  setCategoryId: (id: string | null) => void;
  selectedCategoryId: string | null;
}) => {
  return (
    <Select.Root
      size="2"
      value={categories.get(selectedCategoryId ?? "")?.name}
      onValueChange={(value) => setCategoryId(value)}
    >
      <Select.Trigger
        radius="large"
        style={{
          padding: "5px 8px",
          border: "1px solid #DDD7D7",
          fontWeight: 500,
          color: "#DDD7D7",
          fontSize: "16px",
          width: "30%",
        }}
        color="gold"
        variant="ghost"
        placeholder="Category"
      >
        <Text as="span" color="gray" size="2">
          {categories.get(selectedCategoryId ?? "")?.name}
        </Text>
      </Select.Trigger>
      <Select.Content variant="soft" color="gold" position="popper">
        {Array.from(categories).map(([id, category]) => (
          <Select.Item value={id}>{category.name}</Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};
export default CategorySelector;

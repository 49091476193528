import CampaignWizardLayout from "../../../components/campaign/wizard/layout/CampaignWizardLayout";
import {
  NewCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import { NewCampaignCards } from "../../../views/campaign/wizard/CampaignCards";
import { CampaignType } from "@openapi";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const NewCampaignWizardPage = () => {
  const campaignType = useLocation().state?.campaignType ?? null;
  const { currentRequiredStep, currentStep } = useCampaignWizardState();
  const dispatch = useCampaignWizardDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_WIZARD_TYPE",
      payload: { wizardType: "NEW" },
    });
    if (campaignType) {
      dispatch({
        type: "UPDATE_CAMPAIGN_DATA",
        payload: {
          campaign_type: campaignType as CampaignType,
        },
      });
      dispatch({
        type: "SET_CURRENT_STEP",
        payload: { currentStep: NewCampaignWizardSteps.DETAILS }, // Move to next step if entered from /home type button
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CampaignWizardLayout>
      {Object.entries(NewCampaignWizardSteps).map(([key, value]) => (
        <div
          style={{
            opacity: currentRequiredStep >= Number(value) ? undefined : 0,
            pointerEvents: currentStep === Number(value) ? undefined : "none",
            width: "800px",
          }}
          id={`step-${value}`}
          key={key}
        >
          {NewCampaignCards[value as NewCampaignWizardSteps]}
        </div>
      ))}
    </CampaignWizardLayout>
  );
};

export default NewCampaignWizardPage;

import { useCampaignWizardState } from "../../contexts/CampaignWizardContext";
import { useCallback } from "react";

const useCenterCampaignCard = () => {
  const { currentStep } = useCampaignWizardState();

  const centerCurrentCard = useCallback(() => {
    const element = document.getElementById(`step-${currentStep}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 50);
    }
  }, [currentStep]);

  return { centerCurrentCard };
};

export default useCenterCampaignCard;

import { replaceButtonStyles } from "../button_editor/htmlUtils";
import { EmailSectionType } from "@openapi";

export const getUpdatedSectionHtml = (section: any, html: string) => {
  if (section.type === EmailSectionType.hero) {
    html = html.replace(
      /{background_image_url}/g,
      section?.background?.image || ""
    );
    html = html.replace(
      /{background_color}/g,
      section?.background?.color || ""
    );
    html = html.replace(/{cta_button_text}/g, section?.cta_button?.text || "");
    html = replaceButtonStyles(html, section?.cta_button);
  }
  return html;
};

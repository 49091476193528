import { useAdMediaContext } from "../AdMediaContext";
import AdMediaSVGCanvas from "../AdMediaSVGCanvas";
import { Flex, Spinner } from "@radix-ui/themes";
import { useEffect } from "react";
import {
  useAdEditorSelectedMediaState,
  useAdEditorState,
} from "~/contexts/AdEditorContext";
import useGetAdCreativeMediaWithElements from "~/hooks/ads/useGetAdCreativeMediaWithElements";
import { AdMediaElementUnionSchema } from "~/types/ads";

const EditorPreview: React.FC = () => {
  const media = useAdEditorSelectedMediaState();
  const state = useAdEditorState();
  const { elements, setElements } = useAdMediaContext();
  const { elementsQuery } = useGetAdCreativeMediaWithElements(media?.id, {
    skipMediaQuery: true,
  });

  useEffect(() => {
    if (!elementsQuery.data || elements.length > 0) {
      return;
    }
    const data = elementsQuery.data;
    // schema doesn't correctly discriminate the type
    setElements(data.elements as unknown[] as AdMediaElementUnionSchema[]);
  }, [elementsQuery, elements.length, setElements]);

  const isLoading = !media || !elementsQuery.data;

  return (
    <Flex
      p="4"
      flexGrow="1"
      flexShrink="1"
      direction="column"
      gap="1"
      overflow="auto"
      minWidth="400px"
      style={{
        backgroundColor: "#FCF9F7",
      }}
      {...(isLoading ? { align: "center", justify: "center" } : {})}
    >
      {isLoading ? (
        <Spinner size="3" />
      ) : (
        <Flex gap="4" wrap="wrap" justify="center">
          {media?.variants.map((variant) => (
            <AdMediaSVGCanvas
              key={variant.id}
              svgUrl={variant.file}
              zoom={state.zoom}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default EditorPreview;

import HomeSection from "../../HomeSection";
import useGetCampaignRecommendations from "../../hooks/recommendations/useGetCampaignRecommendations";
import CampaignIdeasCard from "./CampaignIdeasCard";
import { Grid, Skeleton } from "@radix-ui/themes";
import InfiniteScroll from "react-infinite-scroll-component";

const CampaignIdeasSection = ({}: {}) => {
  const { data, hasNextPage, fetchNextPage } = useGetCampaignRecommendations({
    page: 1,
  });

  return (
    <HomeSection
      title="Campaign Ideas"
      content={
        <InfiniteScroll
          style={{ overflow: "visible" }}
          next={fetchNextPage}
          hasMore={hasNextPage ?? false}
          dataLength={
            data?.pages.flatMap((page) => page.campaign_recommendations)
              .length ?? 0
          }
          loader={
            <Grid
              columns={{ initial: "1", md: "2", lg: "3" }}
              gap="8"
              pr={"8"}
              my="8"
            >
              <Skeleton
                height="414px"
                width="100%"
                style={{ borderRadius: "12px" }}
              />
              <Skeleton
                height="414px"
                width="100%"
                style={{ borderRadius: "12px" }}
              />
              <Skeleton
                height="414px"
                width="100%"
                style={{ borderRadius: "12px" }}
              />
            </Grid>
          }
          scrollThreshold={"90%"}
        >
          <Grid columns={{ initial: "1", md: "2", lg: "3" }} gap="8" pr={"8"}>
            {data?.pages.flatMap((page) =>
              page.campaign_recommendations.map((recommendation) => (
                <CampaignIdeasCard
                  key={recommendation.id}
                  recommendation={recommendation}
                />
              ))
            )}
          </Grid>
        </InfiniteScroll>
      }
    />
  );
};
export default CampaignIdeasSection;

import { Flex, Skeleton } from "@radix-ui/themes";
import useEmbeddedEmail from "~/routes/intern/email_editor/EmbeddedEmail";

const EmailEditorPreview = () => {
  const { embeddedEmail } = useEmbeddedEmail();

  return (
    <Flex justify="center" width="70%" my="24px">
      <Flex direction="column">{embeddedEmail}</Flex>
    </Flex>
  );
};

export default EmailEditorPreview;

import { fadedDark } from "../../../../utils/constants";
import { Box } from "@radix-ui/themes";
import { Dispatch, SetStateAction } from "react";

const RecommenderSelector = ({
  index,
  selectedCardIndex,
  setSelectedCardIndex,
}: {
  index: number;
  selectedCardIndex?: number;
  setSelectedCardIndex: Dispatch<SetStateAction<number>>;
}) => {
  const onSelect = () => {
    setSelectedCardIndex(index);
  };
  return (
    <Box
      width="88px"
      height="13px"
      style={{
        background: index === selectedCardIndex ? fadedDark : "#CBD2D4",
        borderRadius: "10px",
      }}
      onClick={onSelect}
    ></Box>
  );
};
export default RecommenderSelector;

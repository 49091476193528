import EmailSectionPreview from "../preview/EmailSectionPreview";
import { Flex, ScrollArea, Skeleton, Text } from "@radix-ui/themes";
import Handlebars from "handlebars";
import { useEffect, useMemo, useState } from "react";
import { useTemplateEditorState } from "~/contexts/TemplateEditorContext";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";
import {
  getTextEditorByKey,
  QuillData,
} from "~/routes/intern/email_editor/text_editor/getTextEditor";
import {
  getQuillHtml,
  wrapEditableHandlebarsValue,
} from "~/routes/intern/email_editor/text_editor/htmlUtils";
import { setToolbarPosition } from "~/routes/intern/email_editor/utils/utils";
import {
  getEmailFooterSectionHandlebarsFromState,
  getEmailHeaderSectionHandlebarsFromState,
} from "~/types/template-vars";

const TemplateEditorPreview = () => {
  const { headerOptions, footerOptions, brandData, previewMode } =
    useTemplateEditorState();
  const updateHeaderOptions = useUpdateTemplateEditorState(headerOptions.type);
  const isLoading =
    headerOptions.availableTemplates.length === 0 ||
    footerOptions.availableTemplates.length === 0;
  const headerTemplate = useMemo(() => {
    const template = headerOptions.availableTemplates[
      headerOptions.selectedTemplateIndex
    ] ?? {
      templateHtml: "<p>Loading...</p>",
    };
    return Handlebars.compile(
      headerOptions.customHTML.enabled
        ? headerOptions.customHTML.html
        : template.templateHtml
    );
  }, [headerOptions]);
  const footerTemplate = useMemo(() => {
    const template = footerOptions.availableTemplates[
      footerOptions.selectedTemplateIndex
    ] ?? {
      templateHtml: "<p>Loading...</p>",
    };
    return Handlebars.compile(
      footerOptions.customHTML.enabled
        ? footerOptions.customHTML.html
        : template.templateHtml
    );
  }, [footerOptions]);
  const [editors, setEditors] = useState<Record<string, QuillData>>({});
  const taglineEditorKey = `${headerOptions.id.replace(/-/g, "_")}_tagline`;
  const isEditingTagline =
    headerOptions.tagline.isEditing && headerOptions.tagline.enabled;
  useEffect(() => {
    if (!isEditingTagline) {
      const editor = editors[taglineEditorKey];
      if (editor) {
        updateHeaderOptions({
          ...headerOptions,
          tagline: {
            ...headerOptions.tagline,
            text: getQuillHtml(editor.quill),
          },
        });
      }
      return;
    }
    const quill = getTextEditorByKey(taglineEditorKey);
    setToolbarPosition(quill.editor, quill.toolbar);
    if (quill.toolbar) {
      quill.toolbar.style.visibility = "visible";
    }
    setEditors((prev) => ({
      ...prev,
      [taglineEditorKey]: quill,
    }));
  }, [isEditingTagline, taglineEditorKey]);

  const constrainedMaxWidth = previewMode === "mobile" ? "320px" : undefined;
  const headerHandleBars = useMemo(
    () => getEmailHeaderSectionHandlebarsFromState(headerOptions),
    [headerOptions]
  );
  const footerHandleBars = useMemo(
    () => getEmailFooterSectionHandlebarsFromState(footerOptions, brandData),
    [footerOptions, brandData]
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <ScrollArea mt="32px">
      <EmailSectionPreview
        template={headerTemplate}
        vars={{
          ...headerHandleBars,
          ...(isEditingTagline
            ? {
                tagline: wrapEditableHandlebarsValue({
                  key: taglineEditorKey,
                  value: headerHandleBars.tagline ?? "",
                }),
              }
            : {}),
          ...(constrainedMaxWidth
            ? { max_width_size: constrainedMaxWidth }
            : {}),
        }}
      />
      <Flex
        direction="column"
        mx="auto"
        maxWidth={constrainedMaxWidth ?? "600px"}
        gap="5"
      >
        <Flex gap="5">
          <Skeleton height="140px" width="140px" minWidth="140px" />
          <Text size="5" style={{ maxHeight: "140px", overflow: "hidden" }}>
            <Skeleton>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              accusantium voluptates recusandae vero ipsum reiciendis mollitia
              impedit autem est odit rerum dignissimos, voluptatem iure.
            </Skeleton>
          </Text>
        </Flex>
        <Text size="5" style={{ maxHeight: "140px", overflow: "hidden" }}>
          <Skeleton>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
            accusantium voluptates recusandae vero ipsum reiciendis mollitia
            impedit autem est odit rerum dignissimos, voluptatem iure.
          </Skeleton>
        </Text>
      </Flex>
      <EmailSectionPreview
        template={footerTemplate}
        vars={{
          ...footerHandleBars,
          ...(constrainedMaxWidth
            ? { max_width_size: constrainedMaxWidth }
            : {}),
        }}
      />
    </ScrollArea>
  );
};

export default TemplateEditorPreview;

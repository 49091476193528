import { Button } from "../base/Button";
import { Column } from "../base/Column";
import Image from "../base/Image";
import { Section } from "../base/Section";
import Text from "../base/Text";
import { Element, useNode } from "@craftjs/core";

const Products2 = () => {
  // TODO: Why doesn't creating with non function component work?
  // TODO: Why doesn't <Text> itself work? Needs to be wrapped?
  return (
    <Element canvas is={Section}>
      <Element canvas is={Column} width={`${100.0 / 2}`}>
        <Image imgUrl="https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/6b4bfbdf-052b-48e2-9473-e20619c3839e.png" />
        <Element is={Text} fontSize="24" textAlign="center" />
        <Element is={Text} textAlign="center" tagName="h2" />
        <Element is={Button} />
      </Element>
      <Element canvas is={Column} width={`${100.0 / 2}`}>
        <Image imgUrl="https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/6b4bfbdf-052b-48e2-9473-e20619c3839e.png" />
        <Element is={Text} fontSize="24" textAlign="center" />
        <Element is={Text} textAlign="center" tagName="h2" />
        <Element is={Button} />
      </Element>
    </Element>
  );
};

export default Products2;

import DropzoneContainer, {
  DropzoneContainerCustomProps,
} from "./DropzoneContainer";
import { Box } from "@radix-ui/themes";
import { useDropzone, Accept } from "react-dropzone";

interface DropzoneCommonProps {
  onDrop: (files: File[]) => void;
  acceptedFileTypes?: Accept;
  disabled?: boolean;
}
type DropzoneProps = DropzoneCommonProps & DropzoneContainerCustomProps;

export const DROPZONE_IMAGE_TYPES: Accept = {
  "image/jpeg": [],
  "image/png": [],
  "image/svg+xml": [],
  "image/gif": [],
} as const;

const Dropzone = ({
  onDrop,
  acceptedFileTypes,
  disabled = false,
  ...props
}: DropzoneProps) => {
  const dropzoneProps = useDropzone({
    accept: acceptedFileTypes || DROPZONE_IMAGE_TYPES,
    onDrop: onDrop,
    disabled: disabled,
  });

  return (
    <Box style={{ width: "100%" }}>
      <DropzoneContainer {...dropzoneProps} {...props} />
    </Box>
  );
};

export default Dropzone;

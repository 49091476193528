import { Text } from "@radix-ui/themes";
import React from "react";
import { useParams } from "react-router-dom";
import { AdEditorComponents } from "~/components/ads/editor";
import { AdEditorProvider } from "~/contexts/AdEditorContext";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";

const AdEditorPage = () => {
  const { id } = useParams();
  if (!id) {
    return <Text>Invalid URL</Text>;
  }

  return (
    // used for custom fonts
    <BrandStylingProvider>
      <AdEditorProvider adCreativeId={id}>
        <AdEditorComponents.Layout>
          <AdEditorComponents.TopBar />
          <AdEditorComponents.Preview />
          <AdEditorComponents.SidePanel />
        </AdEditorComponents.Layout>
      </AdEditorProvider>
    </BrandStylingProvider>
  );
};

export default AdEditorPage;

import StyleLibraryCardContent from "../StyleLibraryCardContent";
import BrandAssets from "./BrandAssets";
import { ImageAssetCategory } from "@openapi";
import { Button } from "@radix-ui/themes";
import { PencilIcon, PlusIcon } from "lucide-react";
import { createRef } from "react";
import { DropzoneRef } from "react-dropzone";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

const LogoAssetsCardSection = () => {
  const { data: brandStyle, isLoading } = useBrandStyle();
  const logos = brandStyle?.logos ?? [];
  const dropzoneRef = createRef<DropzoneRef>();

  const triggerUpload = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <StyleLibraryCardContent
      title="Logo Assets"
      isLoading={isLoading}
      actions={
        <Button variant="ghost" size={"3"} onClick={triggerUpload}>
          {!!logos.length ? (
            <>
              <PencilIcon
                style={{
                  padding: "0.1rem",
                }}
              />
              Change Logo
            </>
          ) : (
            <>
              <PlusIcon
                style={{
                  padding: "0.1rem",
                }}
              />
              Add Logo
            </>
          )}
        </Button>
      }
    >
      <BrandAssets
        assets={logos}
        category={ImageAssetCategory.logo}
        ref={dropzoneRef}
        multiple={false}
      />
    </StyleLibraryCardContent>
  );
};

export default LogoAssetsCardSection;

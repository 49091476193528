import { MutationKey, useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export const useDraperApiPostMutation = <T, V>({
  path,
  onSuccess,
  onError,
  onMutate,
  mutationKey,
  onSettled,
}: {
  path: string;
  onSuccess?: (data: T) => void;
  onError?: (error: any, vars: V, context?: any) => void;
  onMutate?: (params: V) => void;
  onSettled?: () => void;
  mutationKey?: MutationKey;
}) =>
  useMutation({
    mutationKey: mutationKey,
    mutationFn: async (params: V): Promise<T> => {
      const response = await axios.post("/api/v1" + path, params, {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return response.data;
    },
    onMutate: (params) => {
      onMutate?.(params);
    },
    onSuccess: (data: T, params) => {
      onSuccess?.(data);
    },
    onError: (error, vars, context) => {
      onError?.(error, vars, context);
    },
    onSettled: () => {
      onSettled?.();
    },
  });

export const useDraperApiPatchMutation = <T, V>({
  path,
  onSuccess,
  onError,
  onMutate,
  mutationKey,
  onSettled,
}: {
  path: string;
  onSuccess?: (data: T) => void;
  onError?: (error: any, vars: V, context?: any) => void;
  onMutate?: (params: V) => void;
  onSettled?: () => void;
  mutationKey?: MutationKey;
}) =>
  useMutation({
    mutationKey: mutationKey,
    mutationFn: async (params: V): Promise<T> => {
      const response = await axios.patch("/api/v1" + path, params, {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return response.data;
    },
    onMutate: (params) => {
      onMutate?.(params);
    },
    onSuccess: (data: T, params) => {
      onSuccess?.(data);
    },
    onError: (error, vars, context) => {
      onError?.(error, vars, context);
    },
    onSettled: () => {
      onSettled?.();
    },
  });

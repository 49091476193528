import { components } from "@openapi";

type EmailAssetsType =
  components["schemas"]["CampaignListSchema"]["email_assets"];

export const getAudiencesFromEmailAssets = (emailAssets: EmailAssetsType) =>
  emailAssets
    .flatMap((emailAsset) =>
      emailAsset.audiences.map((audience) => audience.name)
    )
    .filter((value, index, self) => self.indexOf(value) === index); // removes duplicates

import {
  AdMediaTextElementSchema,
  AdMediaTextElementTypeStrings,
} from "../../../types/ads";
import { AdMediaUpdateableElement } from "../AdMediaContext";
import AdEditorCard from "../editor/AdEditorCard";
import {
  Flex,
  Heading,
  SegmentedControl,
  Text,
  TextField,
} from "@radix-ui/themes";
import { AlignCenter, AlignLeft, AlignRight } from "lucide-react";
import React from "react";
import NumberInput from "~/components/core/inputs/NumberInput";
import BrandFontSelect from "~/components/style-library/typography/BrandFontSelect/BrandFontSelect";
import { FontSelectItem } from "~/components/style-library/typography/BrandFontSelect/FontSelect";

interface InternAdMediaTextElementComponentProps {
  element: Extract<AdMediaUpdateableElement, { type: "text" }>;
  onElementUpdate: (
    element: Extract<AdMediaUpdateableElement, { type: "text" }>
  ) => void;
}

const InternAdMediaTextElementComponent: React.FC<
  InternAdMediaTextElementComponentProps
> = ({ element, onElementUpdate }) => {
  const handleTextChange = (e: { target: { value: any } }) => {
    const newText = e.target.value;
    const updatedElement = { ...element, text: newText };
    onElementUpdate(updatedElement);
  };

  const handleSwitchEnabled = (checked: boolean) => {
    const updatedElement = { ...element, is_enabled: checked };
    onElementUpdate(updatedElement);
  };

  const handleFontSizeChange = (fontSize: number) => {
    const updatedElement: AdMediaTextElementSchema = {
      ...element,
      font_size: `${fontSize}px`,
    };
    onElementUpdate(updatedElement);
  };
  const handleFontFamilyChange = (font: FontSelectItem) => {
    const updatedElement: AdMediaTextElementSchema = {
      ...element,
      font_family: font.name,
    };
    onElementUpdate(updatedElement);
  };

  return (
    <AdEditorCard
      checkable
      checked={element.is_enabled}
      onCheckedChange={handleSwitchEnabled}
      title={
        element.description || AdMediaTextElementTypeStrings[element.text_type]
      }
    >
      <Flex direction="column" gap="10px">
        <TextField.Root value={element.text} onChange={handleTextChange} />
        <Heading size="1">Font</Heading>
        <Flex direction="row" gap="16px">
          <NumberInput
            radius="large"
            value={parseInt(element.font_size)}
            onValueChange={handleFontSizeChange}
            style={{
              flex: 1,
            }}
          />
          <BrandFontSelect
            loadSelectedGoogleFont
            size={"3"}
            value={element.font_family}
            onChange={handleFontFamilyChange}
            containerStyle={{
              flex: "auto",
            }}
            style={{
              width: "100%",
            }}
          />
        </Flex>
        {/* TODO: check if the templates will actually support this in first version
            Should have a bounding rect to be able to align the text in any way
            Also, probably should have option to set this per variant */}
        <Heading size="1">Text Alignment</Heading>
        <Flex>
          <SegmentedControl.Root
            defaultValue="center"
            size="3"
            style={{ width: "100%" }}
          >
            <SegmentedControl.Item value="left">
              <Flex align="center" gap="8px">
                <AlignLeft size="20px" />
                <Text size="2">Left</Text>
              </Flex>
            </SegmentedControl.Item>
            <SegmentedControl.Item value="center">
              <Flex align="center" gap="8px">
                <AlignCenter size="20px" />
                <Text size="2">Center</Text>
              </Flex>
            </SegmentedControl.Item>
            <SegmentedControl.Item value="right">
              <Flex align="center" gap="8px">
                <AlignRight size="20px" />
                <Text size="2">Right</Text>
              </Flex>
            </SegmentedControl.Item>
          </SegmentedControl.Root>
        </Flex>
      </Flex>
    </AdEditorCard>
  );
};

export default InternAdMediaTextElementComponent;

import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

const Ellipse = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? "#4f4343" : "#d2c3c3")};
  height: ${({ active }) => (active ? "12px" : "8px")};
  width: ${({ active }) => (active ? "12px" : "8px")};
  border-radius: 50%;
  transition: background-color 0.1s ease, height 0.1s ease, width 0.1s ease;
`;

const OnboardingStepper = ({ activeStep }: { activeStep: number }) => {
  return (
    <Flex direction={"column"} gap="4" align={"center"}>
      {[0, 1, 2].map((x) => (
        <Ellipse key={x} active={x === activeStep} />
      ))}
    </Flex>
  );
};

export default OnboardingStepper;

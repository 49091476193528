import DraperButton from "../core/buttons/DraperButton";
import LogoAssetsCardSection from "../style-library/assets/LogoAssetsCardSection";
import ButtonStylesCardSection from "../style-library/button-styles/ButtonStylesCardSection";
import ColorPaletteCardSection from "../style-library/color-palette/ColorPaletteCardSection";
import TypographyCardSection from "../style-library/typography/TypographyCardSection";
import OnboardingCard from "./OnboardingCard";
import { Flex, SegmentedControl, Text } from "@radix-ui/themes";
import { Check, X } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandUpdateMutation from "~/hooks/brand/useBrandUpdateMutation";
import { HOME_ROUTE } from "~/routes/constants";

enum StyleTabStep {
  LOGO = "0",
  PALETTE = "1",
  TYPOGRAPHY = "2",
  BUTTON = "3",
}

const StyleLibraryReview = () => {
  const activeBrandID = useActiveBrandID();
  const [tab, setTab] = useState(StyleTabStep.LOGO);
  const { data } = useBrandStyle();
  const navigate = useNavigate();

  const { updateBrand, isLoading: isUpdatingBrand } = useBrandUpdateMutation({
    brandID: activeBrandID,
    onSuccess: () => {
      localStorage.removeItem("draper_onboarding_step");
      navigate(HOME_ROUTE);
    },
  });

  const logo = data?.logos?.[0]?.url ?? null;
  const colors = data?.palettes ?? [];
  const typography = data?.typography ?? null;
  const buttons = data?.button_styles ?? [];

  const isLogoSet = logo !== null;
  const isColorPaletteSet = colors.length > 0;
  const isTypographySet = typography !== null;
  const isButtonSet = buttons.length > 0;

  const header = (
    <SegmentedControl.Root value={tab} defaultValue={StyleTabStep.LOGO}>
      <SegmentedControl.Item value={StyleTabStep.LOGO}>
        <Flex align={"center"} gap="2">
          Logo
          {isLogoSet ? (
            <Check color="green" size={20} />
          ) : (
            <X color="red" size={20} />
          )}
        </Flex>
      </SegmentedControl.Item>
      <SegmentedControl.Item value={StyleTabStep.PALETTE}>
        <Flex align={"center"} gap="2">
          Color Palette
          {isColorPaletteSet ? (
            <Check color="green" size={20} />
          ) : (
            <X color="red" size={20} />
          )}
        </Flex>
      </SegmentedControl.Item>
      <SegmentedControl.Item value={StyleTabStep.TYPOGRAPHY}>
        <Flex align={"center"} gap="2">
          Typography
          {isTypographySet ? (
            <Check color="green" size={20} />
          ) : (
            <X color="red" size={20} />
          )}
        </Flex>
      </SegmentedControl.Item>
      <SegmentedControl.Item value={StyleTabStep.BUTTON}>
        <Flex align={"center"} gap="2">
          Buttons
          {isButtonSet ? (
            <Check color="green" size={20} />
          ) : (
            <X color="red" size={20} />
          )}
        </Flex>
      </SegmentedControl.Item>
    </SegmentedControl.Root>
  );

  let content = null;
  let onNext = null;
  let disabled = true;
  switch (tab) {
    case StyleTabStep.LOGO:
      content = <LogoAssetsCardSection />;
      onNext = () => setTab(StyleTabStep.PALETTE);
      disabled = !isLogoSet;
      break;
    case StyleTabStep.PALETTE:
      content = <ColorPaletteCardSection />;
      onNext = () => setTab(StyleTabStep.TYPOGRAPHY);
      disabled = !isColorPaletteSet;
      break;
    case StyleTabStep.TYPOGRAPHY:
      content = <TypographyCardSection />;
      onNext = () => setTab(StyleTabStep.BUTTON);
      disabled = !isButtonSet;
      break;
    case StyleTabStep.BUTTON:
      content = <ButtonStylesCardSection />;
      onNext = () =>
        updateBrand({
          is_onboarded: true,
        });
      disabled = !isTypographySet;
      break;
  }

  return (
    <Flex direction={"column"} justify={"start"} align={"center"} gap="6">
      <Text size={"3"} weight={"bold"}>
        Review Style Library
      </Text>
      <OnboardingCard
        width="60vw"
        primary={
          <DraperButton
            variant="dark"
            text="Continue"
            disabled={disabled}
            onClick={onNext}
            loading={isUpdatingBrand}
          />
        }
        header={header}
      >
        {content}
      </OnboardingCard>
    </Flex>
  );
};

export default StyleLibraryReview;

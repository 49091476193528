import AuthPageBase from "./AuthPageBase";
import { Flex, Link, Text } from "@radix-ui/themes";
import { MailCheck } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Card = styled(Flex)`
  width: 480px;
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  background: white;
`;

const Footer = styled(Flex)`
  width: 100%;
  padding: 24px;
  border-top: 1px solid #ddd7d7;
`;

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation().state as { email: string };
  return (
    <AuthPageBase>
      <Flex p="6" direction={"column"} gap={"2"} align={"center"}>
        <MailCheck size={64} />
        <Text>
          To start using Kin, activate your account with the link sent to:
        </Text>
        <Text size={"2"}>{location.email}</Text>
      </Flex>

      <Footer justify={"center"} align={"center"}>
        <Text size={"1"}>
          Didn't receive an email?{" "}
          <Link href="" underline="hover" onClick={() => navigate("/")}>
            Resend code
          </Link>
        </Text>
      </Footer>
    </AuthPageBase>
  );
};

export default VerifyEmail;

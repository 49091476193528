import { SHOPIFY_CLIENT_ID, APP_BASE_URL } from "../../utils/constants";
import { Provider } from "@shopify/app-bridge-react";
import { AppProvider, Page, LegacyCard } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { useLocation } from "react-router-dom";

const ShopifyEmbedded = () => {
  const search = useLocation().search;
  const host = new URLSearchParams(search).get("host") || "";
  const config = {
    apiKey: SHOPIFY_CLIENT_ID,
    host: host,
    forceRedirect: false,
  };

  return (
    <Provider config={config}>
      <AppProvider i18n={enTranslations}>
        <Page>
          <LegacyCard
            sectioned
            title="Draper Account"
            primaryFooterAction={{
              content: "Go to your Draper Dashboard",
              url: APP_BASE_URL,
              external: true,
            }}
          >
            <p>Log into your Draper account.</p>
          </LegacyCard>
        </Page>
      </AppProvider>
    </Provider>
  );
};

export default ShopifyEmbedded;

import CampaignPublishDialogAdCard from "./CampaignPublishDialogAdCard";
import CampaignPublishDialogEmailCard from "./CampaignPublishDialogEmailCard";
import {
  Dialog,
  Flex,
  Text,
  SegmentedControl,
  Grid,
  IconButton,
  ScrollArea,
} from "@radix-ui/themes";
import { X } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import ChatContainer from "~/components/chat/ChatContainer";
import DraperButton from "~/components/core/buttons/DraperButton";

const Header = styled.div`
  padding: 12px;
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ChatWindow = styled.div`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  width: 500px;
  height: 100%;
`;

const CampaignPublishDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [campaignType, setCampaignType] = useState("email");

  const emailContent = (
    <Flex direction={"column"} width={"100%"} gap={"3"} overflow={"auto"}>
      {[0, 1, 2, 3, 4, 5].map(() => (
        <CampaignPublishDialogEmailCard
          title="Email title"
          contentHeader="header"
          contentSubheader="subheader"
        />
      ))}
    </Flex>
  );

  const adContent = (
    <Grid width={"100%"} gap={"3"} overflow={"auto"} columns={"2"}>
      {[0, 1, 2, 3, 4, 5].map(() => (
        <CampaignPublishDialogAdCard
          title="Email title"
          contentHeader="header"
          contentSubheader="subheader"
        />
      ))}
    </Grid>
  );

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Trigger>
        <DraperButton
          variant="light"
          text={"Publish"}
          onClick={() => setIsOpen(true)}
        />
      </Dialog.Trigger>

      <Dialog.Content maxWidth={"95%"} maxHeight={"95%"} height={"90vh"}>
        <Flex direction={"column"} height={"100%"}>
          <Header>
            <Text weight={"bold"}>Publish Campaign</Text>
            <SegmentedControl.Root
              defaultValue={campaignType}
              radius="full"
              onValueChange={(value) => {
                if (value) {
                  setCampaignType(value);
                }
              }}
            >
              <SegmentedControl.Item value="email">
                <Text>Email</Text>
              </SegmentedControl.Item>
              <SegmentedControl.Item value="ads">Ads</SegmentedControl.Item>
            </SegmentedControl.Root>
            <IconButton variant="ghost" onClick={() => setIsOpen(false)}>
              <X size={16} />
            </IconButton>
          </Header>

          <Flex direction="row" gap="3" height={"100%"} minHeight={"0"}>
            <ChatContainer />

            <ScrollArea scrollbars="vertical">
              {campaignType === "email" ? emailContent : adContent}
            </ScrollArea>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CampaignPublishDialog;

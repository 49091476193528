import { fadedDark } from "../../../../utils/constants";
import { getTimeDifference } from "../../../../utils/timeUtils";
import { CampaignRecommendation } from "../../hooks/recommendations/useGetCampaignRecommendations";
import DraperText from "@components/core/DraperText";
import { ItemSetType } from "@openapi";
import { Flex, Skeleton, Text } from "@radix-ui/themes";
import { ArrowRight, Clock4, PackageOpen } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CSSProperties } from "styled-components";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useDebug } from "~/contexts/DebugContext";
import useShopifyProductsQuery from "~/hooks/shopify/useShopifyProductsQuery";

export const campaignRecommendationTypeContent: Partial<{
  [key in ItemSetType]: {
    icon: React.ComponentType<{
      color?: string | undefined;
      size?: string | number | undefined;
      style?: CSSProperties;
    }>;
    title: string;
    background: string;
  };
}> = {
  [ItemSetType.new_products]: {
    icon: PackageOpen,
    title: "New Products",
    background: "#EBD6BD",
  },
  [ItemSetType.best_sellers]: {
    icon: PackageOpen,
    title: "Best Sellers",
    background: "#EBD6BD",
  },
  [ItemSetType.discounted_products]: {
    icon: PackageOpen,
    title: "Discounted Products",
    background: "#EBD6BD",
  },
  [ItemSetType.new_collection]: {
    icon: PackageOpen,
    title: "New Collection",
    background: "#EBD6BD",
  },
  [ItemSetType.new_hero_product]: {
    icon: PackageOpen,
    title: "New Hero Product",
    background: "#EBD6BD",
  },
  [ItemSetType.purchased_together]: {
    icon: PackageOpen,
    title: "Purchased Together",
    background: "#EBD6BD",
  },
  [ItemSetType.slower_moving_goods]: {
    icon: PackageOpen,
    title: "Slower Moving Goods",
    background: "#EBD6BD",
  },
  [ItemSetType.slowest_moving_goods]: {
    icon: PackageOpen,
    title: "Slowest Moving Goods",
    background: "#EBD6BD",
  },
};

const backgroundImage = (
  src: string | undefined | null,
  left: string,
  top: string,
  width: string,
  height: string
) => {
  if (!src) {
    return null;
  }
  return (
    <img
      alt="img"
      src={src}
      style={{
        background: "black",
        objectFit: "cover",
        position: "absolute",
        width: width,
        height: height,
        borderRadius: "8px",
        left: left,
        top: top,
      }}
      loading="lazy"
    />
  );
};

const RecommenderCard = ({
  recommendation,
}: {
  recommendation: CampaignRecommendation;
}) => {
  const debug = useDebug();
  const productsData = recommendation.products ?? [];

  const navigate = useNavigate();

  const { days, hours } = getTimeDifference(recommendation.created_at);
  const IconComponent =
    campaignRecommendationTypeContent[recommendation.item_set_type]?.icon;
  const background =
    campaignRecommendationTypeContent[recommendation.item_set_type]?.background;

  return (
    <Flex
      id={recommendation.id}
      style={{
        width: "826px",
        height: "286px",
        flexShrink: "0",
        borderRadius: "12px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {debug && debug.debug ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 5,
            fontSize: 10,
          }}
        >
          {recommendation.id}
        </div>
      ) : null}
      <div
        style={{
          position: "relative",
          width: "34%",
          height: "100%",
          background: background,
          overflow: "hidden",
        }}
      >
        {IconComponent && (
          <IconComponent
            color="black"
            size="100"
            style={{ opacity: "7%", position: "absolute", top: 30, left: 30 }}
          />
        )}
        {backgroundImage(
          productsData?.[0]?.image_url,
          "6%",
          "35%",
          "37%",
          "37%"
        )}
        {backgroundImage(
          productsData?.[1]?.image_url,
          "55%",
          "0%",
          "37%",
          "37%"
        )}
        {backgroundImage(
          productsData?.[2]?.image_url,
          "55%",
          "45%",
          "37%",
          "50%"
        )}
      </div>
      <Flex
        style={{ padding: "16px 24px 30px 24px", background: fadedDark }}
        width="66%"
        align="start"
        justify="between"
        direction="column"
        gap="3"
      >
        <Flex gap="2" align="start" height="100%" direction="column">
          <Text style={{ fontWeight: 600, fontSize: "20px", color: "white" }}>
            {recommendation.creative_recommendations?.[0]?.title}
          </Text>
          <Flex gap="2">
            <Flex
              gap="1"
              align="center"
              style={{
                border: "1px solid #FFFFFFA3",
                borderRadius: "49px",
                padding: "2px 8px",
              }}
            >
              {IconComponent && <IconComponent color="#FFFFFFA3" size="16" />}
              <Text as="span" style={{ color: "#FFFFFFA3" }}>
                {
                  campaignRecommendationTypeContent[
                    recommendation.item_set_type
                  ]?.title
                }
              </Text>
            </Flex>
            <Flex
              gap="1"
              align="center"
              style={{
                border: "1px solid #FFFFFFA3",
                borderRadius: "49px",
                padding: "2px 8px",
              }}
            >
              <Clock4 color="#FFFFFFA3" size="16" />
              <Text as="span" style={{ color: "#FFFFFFA3" }}>
                {days
                  ? `${days} ${days === 1 ? "day" : "days"} ago`
                  : `${hours} ${hours === 1 ? "hour" : "hours"} ago`}
              </Text>
            </Flex>
          </Flex>
          <DraperText clamp={5} size={"3"} colorOverride="#ffffff">
            {recommendation?.summary ?? "Lorem ipsum dolor sit amet "}
          </DraperText>
        </Flex>
        <Flex
          gap="2"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/campaigns/wizard/${recommendation.id}`)}
        >
          <ArrowRight color="white" />
          <Text
            as="span"
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "white",
            }}
          >
            Open Campaign
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default RecommenderCard;

import { operations, ImageAssetCategory } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

type UploadImageAssetsParams =
  operations["media_api_upload_image_assets"]["requestBody"]["content"]["multipart/form-data"];
export type UploadImageAssetsResponse =
  operations["media_api_upload_image_assets"]["responses"][200]["content"]["application/json"];

const useMediaBrandImagesUpload = ({
  data: { brandID, category },
  onSuccess,
  onError,
}: {
  data: {
    brandID: string;
    category: ImageAssetCategory;
  };
  onSuccess?: (data: UploadImageAssetsResponse) => void;
  onError?: (error: any) => void;
}) => {
  const uploadImageAssets = useMutation<
    UploadImageAssetsResponse,
    AxiosError,
    UploadImageAssetsParams
  >({
    mutationFn: async (params) => {
      const formData = new FormData();
      params.images?.forEach((file) => {
        formData.append("images", file);
      });
      const { data } = await axios.post(
        `/api/v1/media/${brandID}/images/${category}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      console.log("Images uploaded successfully");
      onSuccess?.(data);
    },
    onError: (error) => {
      console.error("Error uploading images:", error);
      onError?.(error);
    },
  });

  return { ...uploadImageAssets };
};

export default useMediaBrandImagesUpload;

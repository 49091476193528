import { Text, Button } from "@radix-ui/themes";
import { X } from "lucide-react";

export default function SelectedButton({
  id,
  onClick,
  text,
  selected,
}: {
  id: string;
  onClick?: (id: string) => void;
  text: string;
  selected?: boolean;
}) {
  return (
    <Button
      key={id}
      style={{
        width: "fit-content",
        backgroundColor: selected ? "#F7ECDC80" : "transparent",
        color: "#4C4747",
        border: "1px solid #7F7776",
        cursor: "pointer",
      }}
      size="3"
      radius="full"
      onClick={() => onClick?.(id)}
    >
      <Text as="span">{text}</Text>
      {onClick && (
        <X
          color="white"
          style={{
            width: 24,
            height: 24,
            backgroundColor: "#A09E98",
            borderRadius: "50%",
            padding: "4px",
          }}
        />
      )}
    </Button>
  );
}

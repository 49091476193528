import { toggleShowElement } from "../utils/utils";
import { QuillData } from "./getTextEditor";

const ToggleShowEditorButton = ({
  quillData,
  title,
}: {
  quillData: QuillData;
  title: string;
}) => {
  return (
    <button onClick={() => toggleShowElement(quillData.toolbar)}>
      {title}
    </button>
  );
};

export default ToggleShowEditorButton;

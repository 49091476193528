import LoadingDots from "../campaign/wizard/misc/LoadingDots";
import ChatMessageContainer from "../chat/ChatMessageContainer";
import DraperButton from "../core/buttons/DraperButton";
import OnboardingCard from "./OnboardingCard";
import { operations } from "@openapi";
import * as Form from "@radix-ui/react-form";
import { Box, Flex, Link, Separator, Text, TextField } from "@radix-ui/themes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";

type UpdateBrandParams =
  operations["brand_api_update_brand"]["requestBody"]["content"]["application/json"];
type UpdateBrandResponse =
  operations["brand_api_update_brand"]["responses"][200]["content"]["application/json"];

const TextInput = styled(TextField.Root)`
  height: 48px;
`;

const KlaviyoRoot = styled(Box)`
  width: 100%;
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  padding: 24px;
`;

const HelpNumber = styled.span`
  width: 24px;
  height: 24px;
  background-color: #f2f5f6;
  color: #4c4747;
  border: 1px solid #ddd7d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;

const NumberText = ({ number, text }: { number: number; text: string }) => {
  return (
    <Flex align={"center"} gap="2">
      <HelpNumber>{number}</HelpNumber>
      <Text size={"2"}>{text}</Text>
    </Flex>
  );
};

const OnboardingKlaviyo = ({
  onNext,
  onPrev,
}: {
  onNext: () => void;
  onPrev: () => void;
}) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const updateBrand = useMutation<
    UpdateBrandResponse,
    Error,
    UpdateBrandParams
  >({
    mutationFn: async (
      params: UpdateBrandParams
    ): Promise<UpdateBrandResponse> => {
      const { data } = await axios.patch(
        `/api/v1/brand/${activeBrandID}`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["brand", activeBrandID], data);
      setIsHelpOpen(false);
    },
    onError: (error) => {
      setIsKlaviyoValid(false);
    },
  });

  const { brandData, isLoading, isSuccess } = useBrandQuery(activeBrandID);

  const [klaviyoAPIKey, setKlaviyoAPIKey] = useState<string | undefined>(
    undefined
  );
  const [isKlaviyoValid, setIsKlaviyoValid] = useState<boolean | null>(null);

  const hasKlaviyoAPIKeyChanged =
    klaviyoAPIKey !== brandData?.klaviyo_api_token;

  useEffect(() => {
    if (isSuccess && brandData) {
      const initKlaviyoAPIKey = brandData?.klaviyo_api_token ?? undefined;
      setKlaviyoAPIKey(initKlaviyoAPIKey);
      setIsKlaviyoValid(initKlaviyoAPIKey != null);
    }
  }, [isSuccess, brandData]);

  const form = (
    <Form.Root
      onSubmit={(e) => {
        e.preventDefault();
        const data = Object.fromEntries(new FormData(e.currentTarget));

        updateBrand.mutate({
          klaviyo_api_token: data.api_key as string,
        });
      }}
    >
      <Flex gap="3">
        <Box width={"100%"}>
          <Form.Field name="api_key" autoFocus>
            <Form.Label>
              <Text size={"1"}>Private Key</Text>
            </Form.Label>
            <Flex align="end" gap="3">
              <Flex flexGrow={"1"}>
                <Form.Control asChild>
                  <TextInput
                    style={{ width: "100%" }}
                    required
                    placeholder="Private Key"
                    value={klaviyoAPIKey}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue === brandData?.klaviyo_api_token) {
                        setIsKlaviyoValid(true);
                      } else {
                        setIsKlaviyoValid(null);
                      }
                      setKlaviyoAPIKey(newValue);
                    }}
                  >
                    {
                      <>
                        <TextField.Slot></TextField.Slot>
                        <TextField.Slot pr="3">
                          {isKlaviyoValid === true &&
                          !hasKlaviyoAPIKeyChanged ? (
                            <Check color="#3F9D27" size={20} />
                          ) : isKlaviyoValid === false ? (
                            <X color="#D00000" size={20} />
                          ) : null}
                        </TextField.Slot>
                      </>
                    }
                  </TextInput>
                </Form.Control>
              </Flex>
              <Form.Submit asChild>
                <DraperButton
                  variant="secondary"
                  text={
                    updateBrand.isPending
                      ? "Connecting"
                      : isKlaviyoValid === true && !hasKlaviyoAPIKeyChanged
                      ? "Connected"
                      : "Connect Key"
                  }
                  disabled={!hasKlaviyoAPIKeyChanged || updateBrand.isPending}
                />
              </Form.Submit>
            </Flex>
            <Form.Message match="valueMissing" style={{ color: "red" }}>
              <Text size={"1"}>Please enter your Klaviyo Private API Key.</Text>
            </Form.Message>
          </Form.Field>
        </Box>
      </Flex>
    </Form.Root>
  );

  const klaviyoRoot = (
    <KlaviyoRoot>
      <Flex gap="4" direction={"column"}>
        {form}
        <Text
          color="blue"
          size={"1"}
          weight={"bold"}
          onClick={() => setIsHelpOpen(!isHelpOpen)}
          style={{
            cursor: "pointer",
          }}
        >
          Help me get the Private Key
        </Text>
        {isHelpOpen && (
          <Box width={"100%"}>
            <Separator size={"4"} my="2" />

            <Flex gap="2" direction={"column"} mt="4">
              <NumberText
                number={1}
                text="Click your organization name in
                      the bottom left."
              />
              <NumberText number={2} text="Navigate to Settings." />
              <NumberText number={3} text="Click API keys." />
              <NumberText number={4} text="Click Create Private API Key." />
              <NumberText
                number={5}
                text="Name it and select access level
                      to Full Access."
              />
              <NumberText number={6} text="Click Create on top right." />
              <NumberText
                number={7}
                text="Copy and use the generated
                      private key."
              />
              <Text size={"1"}>
                For detailed instructions visit{" "}
                <Link
                  href="https://help.klaviyo.com/hc/en-us/articles/7423954176283"
                  target="_blank"
                >
                  https://help.klaviyo.com/hc/en-us/articles/7423954176283
                </Link>
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>
    </KlaviyoRoot>
  );
  return (
    <Flex direction={"column"} justify={"center"} align={"center"} gap="8">
      <OnboardingCard
        primary={
          <DraperButton
            disabled={
              isKlaviyoValid === false ||
              updateBrand.isPending ||
              hasKlaviyoAPIKeyChanged
            }
            variant="dark"
            text="Continue"
            onClick={onNext}
          />
        }
        secondary={
          <DraperButton variant="outlined" text="Back" onClick={onPrev} />
        }
        pillText="Klaviyo Account"
      >
        <ChatMessageContainer
          message={
            <>
              Let’s connect your Klaviyo account. <br /> This is required to
              analyze all your past emails and build a model for generating &
              publishing emails in your brand voice and style.
            </>
          }
        />
        {isLoading ? <LoadingDots /> : klaviyoRoot}
      </OnboardingCard>
    </Flex>
  );
};

export default OnboardingKlaviyo;

import getTextEditor, { QuillData } from "../../text_editor/getTextEditor";
import { EmailSectionType } from "@openapi";
import { useEffect, useState } from "react";

export type HeroTextEditors = {
  [key: string]: {
    quillTitleEditor: QuillData;
    quillSubtitleEditor: QuillData;
  };
};

const useGetHeroSectionTextEditors = (
  sectionsState: { [key: string]: any } | undefined,
  renderedEmail: any,
  emailId?: string
) => {
  const [editors, setEditors] = useState<HeroTextEditors>({});

  useEffect(() => {
    if (!sectionsState || !emailId) return;
    const heroSections = Object.values(sectionsState).filter(
      (section) => section.type === EmailSectionType.hero
    );

    const newEditors = heroSections.reduce((acc, section) => {
      const id = section.id;
      const existingTitleContent =
        editors[id]?.quillTitleEditor?.quill?.getContents();
      const existingSubtitleContent =
        editors[id]?.quillSubtitleEditor?.quill?.getContents();
      acc[id] = {
        quillTitleEditor: getTextEditor(
          emailId,
          id,
          "title_text",
          existingTitleContent
        ),
        quillSubtitleEditor: getTextEditor(
          emailId,
          id,
          "subtitle_text",
          existingSubtitleContent
        ),
      };
      return acc;
    }, {} as HeroTextEditors);

    setEditors(newEditors);
  }, [sectionsState, renderedEmail]);

  return editors;
};

export default useGetHeroSectionTextEditors;

import KinLogo from "../core/KinLogo";
import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

const Card = styled(Flex)`
  width: 480px;
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  background: white;
`;

const AuthPageBase = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex direction={"column"} gap={"9"} align={"center"} mt="9">
      <KinLogo height="48px" />

      <Card direction={"column"} gap={"2"} align={"center"}>
        {children}
      </Card>
    </Flex>
  );
};

export default AuthPageBase;

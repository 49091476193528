import {
  Audience,
  GetAudiencesData,
} from "../../../../views/campaign/wizard/CampaignAudience";
import { capitalizeFirstLetter } from "../../../editor/utils";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  Box,
  Flex,
  ScrollArea,
  SegmentedControl,
  Skeleton,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useMemo, useState } from "react";

const CategorizedSelect = ({
  data,
  onSelect,
  loading = false,
}: {
  data?: GetAudiencesData;
  onSelect: (
    id: string,
    name: string,
    type: string,
    categoryName: string
  ) => void;
  loading?: boolean;
}) => {
  //mapping audiences so that they can be separated by audience type as well
  const mappedAudiences = useMemo(() => {
    const mappedData = data?.reduce((map, categoryType) => {
      const innerMap = categoryType.audiences.reduce(
        (audienceInnerMap, audience) => {
          if (audienceInnerMap.has(audience.type)) {
            audienceInnerMap.get(audience.type)?.push(audience);
          } else {
            audienceInnerMap.set(audience.type, [audience]);
          }
          return audienceInnerMap;
        },
        new Map<string, [Audience]>()
      );
      map.set(categoryType.type, innerMap);
      return map;
    }, new Map<string, Map<string, [Audience]>>());
    return mappedData;
  }, [data]);

  const [selectedType, setSelectedType] = useState<string>(
    data?.[0].type ?? ""
  );
  const [search, setSearch] = useState("");

  return (
    <Flex
      direction="column"
      style={{ border: "1px solid #DDD7D7", borderRadius: "16px" }}
    >
      <Flex
        style={{ padding: "16px", borderBottom: "1px solid #DDD7D7" }}
        gap="12px"
      >
        <Skeleton loading={loading}>
          <TextField.Root
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            style={{ backgroundColor: "#F1F1F0", width: "100%" }}
            radius="large"
            color="teal"
            variant="soft"
            placeholder="Search…"
          >
            <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
        </Skeleton>
        <Skeleton loading={loading} height="30px">
          <SegmentedControl.Root
            onValueChange={(value) => {
              setSelectedType(value);
            }}
            value={selectedType}
            radius="large"
          >
            {data?.map((item, idx) => (
              <SegmentedControl.Item key={item.type} value={item.type}>
                {capitalizeFirstLetter(item.type)}
              </SegmentedControl.Item>
            ))}
          </SegmentedControl.Root>
        </Skeleton>
      </Flex>
      <Box pl="4" pr="2" py="4">
        <ScrollArea
          size="2"
          type="auto"
          scrollbars="vertical"
          style={{ height: "196px" }}
        >
          <Flex direction="column" gap="12px">
            {Array.from(mappedAudiences?.get(selectedType) ?? [])?.map(
              ([audienceType, audiences]) => (
                <>
                  <Text mb="3" style={{ color: "#838690" }}>
                    {audienceType.toUpperCase()}
                  </Text>
                  {audiences
                    .filter((audience) =>
                      audience.name
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                    )
                    .map((audience) => (
                      <Skeleton loading={loading}>
                        <Text
                          onClick={() =>
                            onSelect(
                              audience.id,
                              audience.name,
                              selectedType,
                              audienceType
                            )
                          }
                          ml="2"
                          style={{
                            cursor: "pointer",
                            maxWidth: "max-content",
                            color: "#332E2E",
                          }}
                        >
                          {audience.name}
                        </Text>
                      </Skeleton>
                    ))}
                </>
              )
            )}
          </Flex>
        </ScrollArea>
      </Box>
    </Flex>
  );
};

export default CategorizedSelect;

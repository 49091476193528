import { fadedDark } from "../../../utils/constants";
import { Button, ButtonProps } from "@radix-ui/themes";
import { Responsive } from "@radix-ui/themes/props";
import { CSSProperties } from "react";

export default function ProgressionButton({
  text,
  textColor,
  backgroundColor,
  onClick,
  customSize,
  customStyles,
  icon,
  isSelected,
  disabled,
  isDarkMode,
  ...props
}: {
  text?: string | number | React.ReactNode;
  textColor?: string;
  backgroundColor?: string;
  onClick?: () => void;
  customSize?: Responsive<"3" | "1" | "2" | "4">;
  customStyles?: CSSProperties;
  icon?: React.ReactNode;
  isSelected?: boolean;
  disabled?: boolean;
  isDarkMode?: boolean;
} & ButtonProps) {
  return (
    <Button
      {...props}
      onClick={onClick}
      disabled={disabled}
      style={{
        border: isSelected ? "2px solid black" : "unset",
        background: disabled
          ? "#F0EDEB"
          : backgroundColor ?? isDarkMode
          ? fadedDark
          : "#F3E6D3",
        color: disabled
          ? undefined
          : textColor ?? isDarkMode
          ? "white"
          : "#332E2E",
        fontSize: "16px",
        fontWeight: 500,
        cursor: disabled ? "default" : "pointer",
        ...customStyles,
      }}
      size={customSize ?? "4"}
    >
      {icon}
      {text}
    </Button>
  );
}

import { Button, Flex } from "@radix-ui/themes";
import { useDropzone } from "react-dropzone";
import ColorBox from "~/components/style-library/color-palette/ColorBox";
import { EmailTemplateStateCommon } from "~/types/template-editor";

const BackgroundSelector = ({
  color,
  onColorChange,
  onUpload,
}: {
  color: EmailTemplateStateCommon["colors"][number];
  onColorChange: (color: string) => void;
  onUpload: (img: File) => void;
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/svg+xml": [],
      "image/gif": [],
    },
    onDrop: (acceptedFiles) => {
      onUpload(acceptedFiles[0]);
    },
    maxFiles: 1,
  });

  return (
    <Flex gap="16px">
      <ColorBox
        style={{ flexDirection: "row-reverse", paddingLeft: 8 }}
        color={color.background}
        label="Color"
        onSaveColor={onColorChange}
      />
      <Button
        {...getRootProps()}
        size="4"
        radius="large"
        variant="outline"
        color="gray"
      >
        <input {...getInputProps()} />
        Add Background Image
      </Button>
    </Flex>
  );
};

export default BackgroundSelector;

import { createContext, useContext, useState, ReactNode } from "react";

interface DynamicHeaderContextType {
  setDynamicHeader: (header: ReactNode) => void;
  dynamicHeader: ReactNode;
}

const DynamicHeaderContext = createContext<
  DynamicHeaderContextType | undefined
>(undefined);

export const DynamicHeaderProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [dynamicHeader, setDynamicHeader] = useState<ReactNode>(<></>); // Default to an empty fragment

  return (
    <DynamicHeaderContext.Provider value={{ setDynamicHeader, dynamicHeader }}>
      {children}
    </DynamicHeaderContext.Provider>
  );
};

export const useDynamicHeader = () => {
  const context = useContext(DynamicHeaderContext);
  if (context === undefined) {
    throw new Error(
      "useDynamicHeader must be used within a DynamicHeaderProvider"
    );
  }
  return context;
};

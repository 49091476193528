import AddBlockView from "../editor/sidepanel-views/AddBlockView";
import AddTextView from "../editor/sidepanel-views/AddTextView";
import FeaturedProductView from "../editor/sidepanel-views/FeaturedProductView";
import GenerateVariantView from "../editor/sidepanel-views/GenerateVariantView";
import HeroView from "../editor/sidepanel-views/HeroView";
import { Flex, ScrollArea, Text } from "@radix-ui/themes";
import { ChevronLeftIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import {
  useEmailDispatch,
  useEmailsState,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const Aside = styled(Flex)`
  border-left: 1px solid #ddd7d7;
  height: 100%;
  box-shadow: 0px 12px 120px 0px #00000014;
  max-width: 432px;
  width: 100%;
  flex-direction: column;
`;

const Header = styled(Flex)`
  border-bottom: 1px solid #ddd7d7;
  padding: 24px 0px;
  justify-content: center;
  position: relative;
  width: 100%;
  height: min-content;
`;

const DoneButton = styled(Flex)`
  cursor: pointer;
  color: #136ec1;
  gap: 6px;
  align-items: center;
  position: absolute;
  left: 16px;
`;

const Content = styled(ScrollArea)`
  padding: 24px;
`;

export enum EmailEditorViews {
  GenerateVariant = "Generate Variant",
  Hero = "Hero",
  Button = "Button",
  AddText = "Add Text",
  FeatureProduct = "Feature Product",
  AddBlock = "Add Block",
}

const ViewsWithoutHeader = [EmailEditorViews.GenerateVariant];

const EmailEditorViewComponents: Record<EmailEditorViews, React.ReactNode> = {
  [EmailEditorViews.GenerateVariant]: <GenerateVariantView />,
  [EmailEditorViews.Hero]: <HeroView />,
  [EmailEditorViews.Button]: <></>,
  [EmailEditorViews.AddText]: <AddTextView />,
  [EmailEditorViews.FeatureProduct]: <FeaturedProductView />,
  [EmailEditorViews.AddBlock]: <AddBlockView />,
};

const EmailEditorAside = () => {
  const { currentEditorView } = useEmailsState();
  const { dispatch } = useEmailDispatch();

  return (
    <Aside>
      {!ViewsWithoutHeader.includes(currentEditorView) && (
        <Header>
          <DoneButton
            onClick={() =>
              dispatch({
                type: "SET_CURRENT_EDITOR_VIEW",
                payload: EmailEditorViews.GenerateVariant,
              })
            }
          >
            <ChevronLeftIcon />
            <Text size="4" weight="medium">
              Done
            </Text>
          </DoneButton>
          <Text size="4" weight="medium">
            {currentEditorView}
          </Text>
        </Header>
      )}
      <Content>{EmailEditorViewComponents[currentEditorView]}</Content>
    </Aside>
  );
};

export default EmailEditorAside;

import { operations } from "../../../openapi";
import { BrandStylingResponse } from "../data/useBrandStyleQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type UpdateBrandStylingPaletteResponse =
  operations["brand_api_update_color_palette"]["responses"][200]["content"]["application/json"];

type UpdateBrandStylingPaletteRequestData =
  operations["brand_api_update_color_palette"]["requestBody"]["content"]["application/json"];

const useBrandStylingPaletteUpdateMutation = ({
  brandID,
  onSuccess,
  onError,
}: {
  brandID: string;
  onSuccess?: (data: UpdateBrandStylingPaletteResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();

  const updateBrandStylingPalette = useDraperApiPostMutation<
    UpdateBrandStylingPaletteResponse,
    UpdateBrandStylingPaletteRequestData
  >({
    mutationKey: ["brand-styling-palette-update"],
    path: `/brand/${brandID}/stylebook/color`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onMutate: (data) => {
      const prevStyle = queryClient.getQueryData<BrandStylingResponse>([
        "brand-style",
        brandID,
      ]);
      queryClient.setQueryData(["brand-style", brandID], {
        ...prevStyle,
        palettes: [data],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["brand-style", brandID],
      });
    },
  });

  return {
    updateBrandStylingPalette: updateBrandStylingPalette.mutate,
    isLoading: updateBrandStylingPalette.isPending,
  };
};

export default useBrandStylingPaletteUpdateMutation;

import {
  useCurrentUserDispatch,
  useCurrentUserState,
} from "../../contexts/CurrentUserContext";
import DraperButton from "../core/buttons/DraperButton";
import PanelPage from "../core/layout/PanelPage";
import AuthPageBase from "./AuthPageBase";
import * as Form from "@radix-ui/react-form";
import {
  Box,
  Button,
  Separator,
  Flex,
  Heading,
  Link,
  Text,
  TextField,
} from "@radix-ui/themes";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TextInput = styled(TextField.Root)`
  height: 48px;
`;

const SeparatorFlex = styled(Separator)`
  display: flex;
  width: 100%;
`;

const GoogleIcon = styled.div`
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
  margin: 0;
`;

const Login = () => {
  let navigate = useNavigate();

  const [emailErrors, setEmailErrors] = useState("");
  const [passwordErrors, setPasswordErrors] = useState("");
  const [nonFormErrors, setNonFormErrors] = useState("");

  const { user } = useCurrentUserState();
  useEffect(() => {
    if (user.status === "LOGGED_IN") {
      navigate("/");
    }
  }, [navigate, user.status]);

  const currentUserDispatch = useCurrentUserDispatch();

  const openForgotPassword = (e: any) => {
    e.preventDefault();
    navigate("/reset-password");
  };

  const onSubmit = (data: FormData) => {
    // TODO: typing for FormData
    const values = Object.fromEntries(data);
    const user = {
      email: values["email"],
      password: values["password"],
    };
    fetch("/api/v1/user/auth/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        setEmailErrors("");
        setPasswordErrors("");
        setNonFormErrors("");
        if (data.key) {
          currentUserDispatch({
            type: "SET_USER_NEEDS_REFETCH",
            payload: {
              needsRefetch: true,
            },
          });
          navigate("/");
        } else {
          if ("email" in data) {
            setEmailErrors(data.email.join(","));
          }

          if ("password" in data) {
            setPasswordErrors(data.password.join(","));
          }

          if ("non_field_errors" in data) {
            setNonFormErrors(data.non_field_errors.join(","));
          }
        }
      });
  };

  const content = (
    <Form.Root
      onSubmit={(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        onSubmit(data);
      }}
      onClearServerErrors={() => setNonFormErrors("")}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Flex
        direction={"column"}
        justify={"center"}
        gap={"1rem"}
        align={"stretch"}
        p="8"
      >
        <Text weight={"bold"} size={"4"}>
          Log In
        </Text>
        {nonFormErrors ? (
          <Box>
            <Heading mb="1" size="2" color="red">
              {nonFormErrors}
            </Heading>
          </Box>
        ) : (
          <></>
        )}
        <Form.Field name="email" autoFocus>
          <Form.Label>
            <Text size={"1"}>Email</Text>
          </Form.Label>
          <Form.Control asChild>
            <TextInput
              type="email"
              required
              placeholder="Email"
              autoComplete="email"
            />
          </Form.Control>
          <Form.Message match="valueMissing" style={{ color: "red" }}>
            Please enter your email.
          </Form.Message>
          <Form.Message
            match="typeMismatch"
            forceMatch={!!emailErrors}
            style={{ color: "red" }}
          >
            {emailErrors}
          </Form.Message>
        </Form.Field>
        <Form.Field name="password">
          <Form.Label>
            <Text size={"1"}>Password</Text>
          </Form.Label>
          <Form.Control asChild>
            <TextInput
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="current-password"
            />
          </Form.Control>
          <Form.Message match="valueMissing" style={{ color: "red" }}>
            Please enter your password.
          </Form.Message>
          <Form.Message
            match="typeMismatch"
            forceMatch={!!passwordErrors}
            style={{ color: "red" }}
          >
            {passwordErrors}
          </Form.Message>
        </Form.Field>

        <Text size={"2"}>
          <Link href="" onClick={openForgotPassword} underline="hover">
            Forgot password?
          </Link>
        </Text>

        <Form.Submit asChild>
          <DraperButton text="Log in" variant="dark" />
        </Form.Submit>

        {/* <Flex dir="row" align={"center"} gap={"2"} width={"100%"}>
          <SeparatorFlex />
          <Text size={"1"}>or</Text>
          <SeparatorFlex />
        </Flex>

        <Button variant="outline" size={"4"} color="gray">
          <GoogleIcon>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                display: "block",
              }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </GoogleIcon>
          <Text size={"3"}>Log in with Google</Text>
        </Button> */}

        <Box
          style={{
            textAlign: "center",
          }}
          mb={"4"}
        >
          <Text size={"1"}>
            Don't have an account?{" "}
            <Link
              href=""
              underline="hover"
              onClick={(e: any) => {
                e.preventDefault();
                navigate("/create-account");
              }}
            >
              Register
            </Link>
          </Text>
        </Box>
      </Flex>
    </Form.Root>
  );

  return <AuthPageBase>{content}</AuthPageBase>;
};

export default Login;

import { useNode, NodeHelpers, Node } from "@craftjs/core";

export const Body = ({ children }: { children?: React.ReactNode }) => {
  const {
    connectors: { connect },
  } = useNode();
  return <div ref={(ref) => ref && connect(ref)}>{children}</div>;
};

Body.craft = {
  displayName: "Body",
  rules: {
    canDrag: () => false,
    canMoveIn: (
      incomingNodes: Node[],
      currentNode: Node,
      helpers: typeof NodeHelpers
    ) => {
      return incomingNodes.every(
        (incomingNode) =>
          incomingNode.data.name === "Section" ||
          incomingNode.data.name === "Columns" ||
          incomingNode.data.name === "Header"
      );
    },
  },
};

import {
  Button,
  Flex,
  IconButton,
  SegmentedControl,
  Select,
  Text,
} from "@radix-ui/themes";
import { MonitorIcon, RedoIcon, SmartphoneIcon, UndoIcon } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DraperButton from "~/components/core/buttons/DraperButton";
import ConfirmationAlert from "~/components/core/dialog/ConfirmationAlert";
import {
  useTemplateEditorDispatch,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";
import useUpdateBrandEmailSections from "~/hooks/style-library/useUpdateBrandEmailSections";
import { CustomizationViews } from "~/types/template-editor";

const Bar = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 12px 120px 0px #00000014;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const IconBtn = styled(IconButton)`
  background-color: rgba(229, 222, 202, 0.4);
  color: #999999;
  border-radius: 10px;
  width: 40px;
  height: 40px;
`;

const TemplateEditorTopControlBar = () => {
  const { currentView, previewMode, headerOptions } = useTemplateEditorState();
  const dispatch = useTemplateEditorDispatch();
  const isInitialView = currentView === CustomizationViews.TypeSelect;
  const navigate = useNavigate();
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);
  const updateBrandEmailSections = useUpdateBrandEmailSections({
    onSuccess: () => {
      navigate("/style-library");
    },
    onError: (errorMessage, axiosError) => {
      console.log(
        "Error updating brand email sections:",
        errorMessage,
        axiosError
      );
      // TODO: error toast
    },
  });

  const handleCancel = () => {
    navigate("/style-library");
  };

  const isEditingInline =
    headerOptions.tagline.enabled && headerOptions.tagline.isEditing;
  const handleSave = () => {
    if (isEditingInline) {
      dispatch({
        type: "UPDATE_EDITOR_STATE",
        payload: {
          headerOptions: {
            ...headerOptions,
            tagline: {
              ...headerOptions.tagline,
              isEditing: false,
            },
          },
        },
      });
      return;
    }
    updateBrandEmailSections.mutate();
  };

  return (
    <Bar>
      {isInitialView ? (
        <Flex gap="12px">
          <IconBtn>
            <UndoIcon />
          </IconBtn>
          <IconBtn>
            <RedoIcon />
          </IconBtn>
        </Flex>
      ) : (
        <Text ml="12px" size="4" weight="medium">
          Template
        </Text>
      )}

      <SegmentedControl.Root
        style={{ paddingTop: "6px", backgroundColor: "#F0EDEB" }}
        size="3"
        value={previewMode}
        onValueChange={(val) =>
          dispatch({
            type: "UPDATE_EDITOR_STATE",
            payload: {
              previewMode: val as "desktop" | "mobile",
            },
          })
        }
      >
        <SegmentedControl.Item value="desktop">
          <MonitorIcon />
        </SegmentedControl.Item>
        <SegmentedControl.Item value="mobile">
          <SmartphoneIcon />
        </SegmentedControl.Item>
      </SegmentedControl.Root>

      {isInitialView ? (
        <DraperButton text="Save & Exit" variant="dark" onClick={handleSave} />
      ) : (
        <Flex gap="12px" align="center">
          <Select.Root defaultValue="preview" size="3">
            <Select.Trigger mr="20px" variant="ghost" color="gray" />
            <Select.Content color="gray" highContrast>
              <Select.Item value="preview">Preview</Select.Item>
              <Select.Item value="live">Live</Select.Item>
            </Select.Content>
          </Select.Root>
          {!isEditingInline && (
            <Button
              size="4"
              variant="outline"
              color="gray"
              radius="large"
              onClick={() => setIsCancelConfirmOpen(true)}
            >
              Cancel
            </Button>
          )}
          <DraperButton
            radius="large"
            variant="dark"
            text={isEditingInline ? "Done" : "Save"}
            onClick={handleSave}
          />
        </Flex>
      )}
      <ConfirmationAlert
        open={isCancelConfirmOpen}
        onOpenChange={setIsCancelConfirmOpen}
        title="Cancel current changes?"
        description="Are you sure? Some data might get lost"
        onConfirm={handleCancel}
        confirmText="Discard changes"
      />
    </Bar>
  );
};

export default TemplateEditorTopControlBar;

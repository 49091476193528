import {
  EmailSection,
  useEmailDispatch,
  useEmailsState,
} from "./context/EmailEditorContext";
import { sectionTypeToEnum } from "./context/stateUtils";
import useGetEmailCreativeData from "./hooks/useGetEmailCreativeData";
import { getHeroTextFieldsUpdatedHtml } from "./sections/hero/htmlUtils";
import useHandleHeroEditableText from "./sections/hero/useHandleHeroEditableText";
import { getUpdatedSectionHtml } from "./utils/htmlUtils";
import { EmailSectionType } from "@openapi";
import { useEffect, useMemo, useState } from "react";

const useEmbeddedEmail = () => {
  //context
  const { dispatch } = useEmailDispatch();
  const emailsState = useEmailsState();
  const currentEmail = emailsState?.emails[emailsState?.selectedEmailId];

  //queries
  const { emailCreativeData, emailCreativeError, isEmailCreativeLoading } =
    useGetEmailCreativeData(emailsState?.selectedEmailId);

  const [sectionsHtmlState, setSectionsHtmlState] = useState<
    Map<string, string>
  >(new Map());
  const handleSectionSelect = (sectionId: string, sectionType: string) => {
    dispatch({
      type: "SET_SELECTED_SECTION",
      payload: sectionId,
    });
    const enumValue = sectionTypeToEnum(sectionType);
    dispatch({
      type: "SET_CURRENT_EDITOR_VIEW",
      payload: !!enumValue ? enumValue : emailsState.currentEditorView,
    });
  };

  useEffect(() => {
    if (emailCreativeData) {
      setSectionsHtmlState(
        emailCreativeData?.sections.reduce(
          (acc: Map<string, string>, section: EmailSection) => {
            if (section.type === EmailSectionType.hero) {
              acc.set(
                section.id,
                getHeroTextFieldsUpdatedHtml(
                  currentEmail?.sections[section.id] ?? section,
                  emailCreativeData?.id
                ) ?? ""
              );
            }
            return acc;
          },
          new Map()
        )
      );
    }
  }, [emailCreativeData]);
  const renderedEmail = useMemo(() => {
    if (!currentEmail?.sections) return null;
    return Object.entries(currentEmail?.sections).map(
      ([sectionId, section], index: any) => (
        <div
          style={{
            border:
              emailsState.selectedSectionId === sectionId
                ? "3px solid grey"
                : "unset",
          }}
          onClick={(event) => {
            handleSectionSelect(sectionId, section.type);
            event.stopPropagation();
          }}
          key={sectionId}
          dangerouslySetInnerHTML={{
            __html: getUpdatedSectionHtml(
              section,
              sectionsHtmlState.get(section.id) ?? section.html_template
            ),
          }}
        />
      )
    );
  }, [
    sectionsHtmlState,
    currentEmail?.sections,
    emailsState.selectedSectionId,
  ]);

  //textEditor
  const heroTextEditors = useHandleHeroEditableText({
    renderedEmail: renderedEmail,
    sectionsState: currentEmail?.sections,
    emailId: currentEmail?.id,
  });

  return { embeddedEmail: renderedEmail, heroTextEditors: heroTextEditors };
};

export default useEmbeddedEmail;

import { Theme } from "@radix-ui/themes";
import React from "react";
import { TemplateEditorComponents } from "~/components/style-library/campaign-template/template-editor";
import { TemplateEditorProvider } from "~/contexts/TemplateEditorContext";

const TemplateEditorPage = () => {
  return (
    <Theme>
      <TemplateEditorProvider includeQuillImports>
        <TemplateEditorComponents.Layout>
          <TemplateEditorComponents.TopBar />
          <TemplateEditorComponents.Preview />
          <TemplateEditorComponents.Aside />
        </TemplateEditorComponents.Layout>
      </TemplateEditorProvider>
    </Theme>
  );
};

export default TemplateEditorPage;

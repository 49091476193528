import StyleLibraryCardContent from "../StyleLibraryCardContent";
import ButtonStyleDialog from "./dialogs/ButtonStyleDialog";
import { components } from "@openapi";
import { Box, Button, Flex } from "@radix-ui/themes";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import BrandButton from "~/components/core/buttons/BrandButton";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

const ButtonStylesCardSection = () => {
  const { data: brandStyle, isLoading } = useBrandStyle();
  const buttonStyles = brandStyle?.button_styles ?? [];
  const [editingStyle, setEditingStyle] = useState<
    components["schemas"]["BrandButtonStyleSchema"] | null
  >(null);
  const [open, setOpen] = useState(false);

  const handleAddEdit =
    (style: components["schemas"]["BrandButtonStyleSchema"] | null) => () => {
      setEditingStyle(style);
      setOpen(true);
    };

  const handleOpen = (open: boolean) => {
    if (!open) {
      setEditingStyle(null);
    }
    setOpen(open);
  };

  return (
    <StyleLibraryCardContent
      title="Button Styles"
      isLoading={isLoading}
      actions={
        <Button onClick={handleAddEdit(null)} variant="ghost" size={"3"}>
          <PlusIcon
            style={{
              padding: "0.1rem",
            }}
          />
          Add Button
        </Button>
      }
    >
      {open && (
        <ButtonStyleDialog
          open={open}
          onOpenChange={handleOpen}
          buttonStyle={editingStyle ?? undefined}
          allowDelete={buttonStyles.length > 1}
        />
      )}
      <Flex
        m="2"
        p="4"
        direction="row"
        align="center"
        wrap="wrap"
        style={{
          background: "white",
          border: "1px solid rgb(228, 228, 231)",
          borderRadius: "var(--radius-3)",
        }}
      >
        {buttonStyles.map((style) => {
          return (
            <BrandButton
              brandButtonStyle={style}
              style={{
                margin: "4px",
              }}
              key={style.id}
              onClick={handleAddEdit(style)}
            >
              Shop Now
            </BrandButton>
          );
        })}
      </Flex>
    </StyleLibraryCardContent>
  );
};

export default ButtonStylesCardSection;

import AnnouncementTemplate from "../../components/templates/AnnouncementTemplate";
import BlogPostTemplate from "../../components/templates/BlogPostTemplate";
import CollectionUnitTemplate from "../../components/templates/CollectionUnitTemplate";
import DiscountTemplate from "../../components/templates/DiscountTemplate";
import EventTemplate from "../../components/templates/EventTemplate";
import KVEmailTemplate2 from "../../components/templates/KVProducts2Template";
import KVEmailTemplate3 from "../../components/templates/KVProducts3Template";
import KVEmailTemplate from "../../components/templates/KVProductsTemplate";
import NewsletterTemplate from "../../components/templates/NewsletterTemplate";
import ProductUnitTemplate from "../../components/templates/ProductUnitTemplate";
import TestimonialTemplate from "../../components/templates/TestimonialTemplate";
import WelcomeTemplate from "../../components/templates/WelcomeTemplate";
import HeroTemplate1 from "../../components/templates/hero/HeroTemplate1";
import ProductFlippedTemplate1 from "../../components/templates/product_flipped/ProductFlippedTemplate1";
import { useAuthenticatedUserState } from "../../contexts/CurrentUserContext";
import { parseFontFamily } from "./styles";
import { operations } from "@openapi";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";
import { Box, Button } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

enum EmailTemplateType {
  ANNOUNCEMENT = "announcement",
  BLOG_POST = "blog_post",
  COLLECTION_UNIT = "collection_unit",
  DISCOUNT = "discount",
  EVENT = "event",
  HERO = "hero",
  NEWSLETTER = "newsletter",
  PRODUCT_UNIT = "product_unit",
  PRODUCT_UNIT_FLIPPED = "product_unit_flipped",
  TESTIMONIAL = "testimonial",
  WELCOME = "welcome",
  KV_PRODUCT = "kv_product",
  KV_PRODUCT_2 = "kv_product_2",
  KV_PRODUCT_3 = "kv_product_3",
}

const templateTypes = [
  EmailTemplateType.HERO,
  EmailTemplateType.DISCOUNT,
  EmailTemplateType.PRODUCT_UNIT_FLIPPED,
  EmailTemplateType.PRODUCT_UNIT,
  EmailTemplateType.COLLECTION_UNIT,
  EmailTemplateType.ANNOUNCEMENT,
  EmailTemplateType.BLOG_POST,
  EmailTemplateType.EVENT,
  EmailTemplateType.NEWSLETTER,
  EmailTemplateType.TESTIMONIAL,
  EmailTemplateType.WELCOME,
  EmailTemplateType.KV_PRODUCT,
  EmailTemplateType.KV_PRODUCT_2,
  EmailTemplateType.KV_PRODUCT_3,
];

interface RecommendedCampaign {
  id: string;
  itemSetType: string;
}

const renderDefaultTemplate = (
  templateType: EmailTemplateType,
  logo?: React.ReactElement
) => {
  switch (templateType) {
    case EmailTemplateType.ANNOUNCEMENT:
      return (
        <AnnouncementTemplate
          title="Announcement"
          content="We have an important announcement."
          buttonText="Read More"
          buttonLink="#"
        />
      );
    case EmailTemplateType.BLOG_POST:
      return (
        <BlogPostTemplate
          title="Blog Post"
          content="This is a blog post."
          buttonText="Read More"
          buttonLink="#"
        />
      );
    case EmailTemplateType.COLLECTION_UNIT:
      return (
        <CollectionUnitTemplate
          items={[
            { title: "Item 1", content: "Content 1" },
            { title: "Item 2", content: "Content 2" },
          ]}
        />
      );
    case EmailTemplateType.DISCOUNT:
      return <DiscountTemplate link="#" />;
    case EmailTemplateType.EVENT:
      return (
        <EventTemplate
          title="Event"
          content="Join our upcoming event."
          buttonText="Register"
          buttonLink="#"
        />
      );
    case EmailTemplateType.HERO:
      return <HeroTemplate1 link="#" />;
    case EmailTemplateType.NEWSLETTER:
      return (
        <NewsletterTemplate
          title="Newsletter"
          content="Subscribe to our newsletter."
          buttonText="Subscribe"
          buttonLink="#"
        />
      );
    case EmailTemplateType.PRODUCT_UNIT:
      return <ProductUnitTemplate link="#" />;
    case EmailTemplateType.PRODUCT_UNIT_FLIPPED:
      return <ProductFlippedTemplate1 link="#" />;
    case EmailTemplateType.TESTIMONIAL:
      return (
        <TestimonialTemplate
          title="Testimonial"
          content="This is a testimonial."
          buttonText="Read More"
          buttonLink="#"
        />
      );
    case EmailTemplateType.WELCOME:
      return (
        <WelcomeTemplate
          title="Welcome"
          content="Welcome to our site."
          buttonText="Learn More"
          buttonLink="#"
        />
      );
    case EmailTemplateType.KV_PRODUCT:
      return (
        <KVEmailTemplate
          logo={logo}
          header="Preview text"
          subheader="now 20% off!"
          products={[
            {
              text: "product 1",
              url: "https://google.com",
              image:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/586b511a-e041-4695-9028-28926ce1aef0.jpeg",
            },
            {
              text: "product 1",
              url: "https://google.com",
              image:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/586b511a-e041-4695-9028-28926ce1aef0.jpeg",
            },
          ]}
        />
      );
    case EmailTemplateType.KV_PRODUCT_2:
      return (
        <KVEmailTemplate2
          logo={logo}
          header="Header"
          subheader="Subheader"
          button="button"
          products={[
            {
              name: "product 1",
              subheader: "description",
              imageUrl:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/3ebcd7e8-613f-47f1-bcfe-4cd7c32f067e.png",
            },
            {
              name: "product 1",
              subheader: "description",
              imageUrl:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/3ebcd7e8-613f-47f1-bcfe-4cd7c32f067e.png",
            },
            {
              name: "product 1",
              subheader: "description",
              imageUrl:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/3ebcd7e8-613f-47f1-bcfe-4cd7c32f067e.png",
            },
            {
              name: "product 1",
              subheader: "description",
              imageUrl:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/3ebcd7e8-613f-47f1-bcfe-4cd7c32f067e.png",
            },
            {
              name: "product 1",
              subheader: "description",
              imageUrl:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/3ebcd7e8-613f-47f1-bcfe-4cd7c32f067e.png",
            },
            {
              name: "product 1",
              subheader: "description",
              imageUrl:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/3ebcd7e8-613f-47f1-bcfe-4cd7c32f067e.png",
            },
          ]}
        />
      );
    case EmailTemplateType.KV_PRODUCT_3:
      return (
        <KVEmailTemplate3
          header="Header"
          subheader="Subheader"
          button="Shop Now"
          image="https://d3k81ch9hvuctc.cloudfront.net/company/ULeTx2/images/34422daa-3284-4bba-b666-4fff64a8a809.jpeg"
          logo={logo}
        />
      );
    default:
      return null;
  }
};

const renderTemplate = (templateType: EmailTemplateType, props: any) => {
  switch (templateType) {
    case EmailTemplateType.ANNOUNCEMENT:
      return <AnnouncementTemplate {...props} />;
    case EmailTemplateType.BLOG_POST:
      return <BlogPostTemplate {...props} />;
    case EmailTemplateType.COLLECTION_UNIT:
      return <CollectionUnitTemplate {...props} />;
    case EmailTemplateType.DISCOUNT:
      return <DiscountTemplate {...props} />;
    case EmailTemplateType.EVENT:
      return <EventTemplate {...props} />;
    case EmailTemplateType.HERO:
      return <HeroTemplate1 {...props} />;
    case EmailTemplateType.NEWSLETTER:
      return <NewsletterTemplate {...props} />;
    case EmailTemplateType.PRODUCT_UNIT:
      return <ProductUnitTemplate {...props} />;
    case EmailTemplateType.PRODUCT_UNIT_FLIPPED:
      return <ProductFlippedTemplate1 {...props} />;
    case EmailTemplateType.TESTIMONIAL:
      return <TestimonialTemplate {...props} />;
    case EmailTemplateType.WELCOME:
      return <WelcomeTemplate {...props} />;
    case EmailTemplateType.KV_PRODUCT:
      return (
        <KVEmailTemplate
          header="Preview text"
          subheader="wow!"
          products={[
            {
              text: "product 1",
              url: "https://google.com",
              image:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/586b511a-e041-4695-9028-28926ce1aef0.jpeg",
            },
            {
              text: "product 1",
              url: "https://google.com",
              image:
                "https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/586b511a-e041-4695-9028-28926ce1aef0.jpeg",
            },
          ]}
        />
      );
    default:
      return null;
  }
};

const recommenderResult = {
  campaign_title: "Travel Essentials: Lashify On-the-Go",
  campaign_description:
    "Promote the convenience and versatility of Lashify products with our 'Travel Essentials' campaign. Highlight the Double-G-Unit Gossamer Storage Case (Soft Touch) and Lashicurl® as the ultimate companions for any summer adventure. Showcase how these products ensure your lashes stay flawless whether you're at the beach or exploring a new city. Include tips on packing light and maintaining beauty routines while traveling. Add a special travel-ready discount code to boost sales.",
  discount: {
    amount: "20%",
    code: "20OFF",
  },
  products: [
    {
      title: "Flush Cheek by Lashify Labs",
      description:
        "Flush Get the Flush. A perfect addition to the Lashify no makeup makeup look. Introducing the first from Lashify Labs. Flush. A cheek jelly stick. A PH reactive gel formula that reveals a unique shade that’s all your own. Enjoy a scent that will remind you of all things lovely. Vegan. color: universal rose shade. “How To”: To activate, simply swipe on and blend. Reapply as desired. Made in Italy Ingredients: Polybutene, Octyldodecanol , Silica dimethyl silylate , Dibutyl ethylhexanoyl glutamide, Dibutyl lauroyl glutamide, Ethyl vanillin, Saccharin, Citric acid, Tocopheryl acetate, Ethylhexyl palmitate, CI 45380, CI 15850, Butylene glycol, Sodium hyaluronate",
      amount: "32.0",
      featured_image:
        "https://cdn.shopify.com/s/files/1/1832/9335/files/Flush-5355-E.jpg?v=1704492943",
    },
    {
      title: "Control Wax Sealer",
      description:
        "IT'S ALL UNDER CONTROL. Say hello to the ultimate Lashify task-master: Control Wax. One product, endless uses. Introduce total control into your beauty routine with our multifunctional anti-stick wax that works hard, so you don't have to. How to Details Ingredients Shape and define brows: run the micro-mascara applicator through brows to shape, define, and hold them right where you want them... in place! Create a non-stick fuse: apply inside the Fuse Control™ Wand or Wandom™ to create the ultimate non-stick surface for a precise, non-stick fuse. Prime lower lashes: a coat on the lower lashes doubles as a primer to create longer, fuller-looking bottom lashes (with or without mascara!) and prevents bottom lashes from sticking during bond application. Control fly-aways: tame rogue hairs in no time with a dab! The micro-mascara applicator perfectly targets, styles, and keeps those mischievous strands in check. Color: ClearCruelty-freeMade in Korea Ingredients: isododecane, water, beeswax, trimethylmet hoxysilicate, trimethylmethoxy silicate(and), polypropylsilsesquioxane, vp/eicosene copolymer, oryza sativa (rice) bran wax, copernicia cerifera(carnauba) wax, paraffin, ethylenediamine/stearyl, dimer dilinoleate copolymer, disteardimonium hectorite, alcohol denat, polybutene, propylene carbonate, rayon, phenoxyethanol, panthenol, ethylhexandiol",
      amount: "12.0",
      featured_image:
        "https://cdn.shopify.com/s/files/1/1832/9335/products/control-wax-sealers-lashify-default-title-493870.jpg?v=1693616923",
    },
    {
      title: "Double-G-Unit Gossamer Storage Case (Soft Touch)",
      description:
        "ON THE DOUBLE. Double dare to double up on your lash stash. Whether stashing at home or stashing on the go, this double-sided travel case will make sure your Gossamers are tucked away safely— designed to make sure no lash is left behind. Details Holds up to 16 Gossamer® cartridgesAvailable colors: Black and White Gossamer® lashes sold separately",
      amount: "36.0",
      featured_image:
        "https://cdn.shopify.com/s/files/1/1832/9335/files/SofttouchG-UnitCaseWhite-Full-19285.jpg?v=1695059825",
    },
    {
      title: "Lashicurl®",
      description:
        "COMING IN HOT. Take control of your curl. Less curl, more curl, the choice is always a touch away with three adjustable temperature levels. The Lashicurl® heated silicone curler allows you to re-activate your bonds and re-curl your G’s. How to Details 1. Curling Gossamers® in cartridge: Remove protective cap. Press and hold power button to turn on. Let device heat up for 15 secs. With the Gossamer still in the cartridge, hold the heating comb to the Gossamer fibers. Focus on the tip of the Gossamer when curling. 2. Curling Gossamers® by heat-fusing: Remove protective cap. Press and hold power button to turn on. Let device heat up for 15 secs. Press and hold the large heating comb to a set of applied Gossamers against the root for approximately 3 secs. Comb slowly from the root toward the tip. Hold position for another 3 secs to curl. Repeat 2-3 times. • USB rechargeable battery• USB cable provided• 3 adjustable temperature levels• Easy to use• Arched comb with double-sided curling bristles• Heated comb gently and safely distributes heat• Silicone bristles separate and lift Gossamer lashes to desired shape• 30 day limited warranty",
      amount: "45.0",
      featured_image:
        "https://cdn.shopify.com/s/files/1/1832/9335/products/lashicurl-heated-silicone-curler-extras-lashify-493096.jpg?v=1693617068",
    },
  ],
};

const dataINeedToGiveGPT = [
  {
    type: EmailTemplateType.DISCOUNT,
    fields: "title, subtitle",
    discount: recommenderResult.discount.amount,
    discountCode: recommenderResult.discount.code,
  },
  {
    type: EmailTemplateType.PRODUCT_UNIT,
    fields: "title, subtitle, button",
    discount: recommenderResult.discount.amount,
    discountCode: recommenderResult.discount.code,
    productName: recommenderResult.products[0].title,
    productDescription: recommenderResult.products[0].description,
    productImage: recommenderResult.products[0].featured_image,
  },
  {
    type: EmailTemplateType.PRODUCT_UNIT_FLIPPED,
    fields: "title, subtitle, button",
    discount: recommenderResult.discount.amount,
    discountCode: recommenderResult.discount.code,
    productName: recommenderResult.products[1].title,
    productDescription: recommenderResult.products[1].description,
    productImage: recommenderResult.products[1].featured_image,
  },
  {
    type: EmailTemplateType.PRODUCT_UNIT,
    fields: "title, subtitle, button",
    discount: recommenderResult.discount.amount,
    discountCode: recommenderResult.discount.code,
    productName: recommenderResult.products[2].title,
    productDescription: recommenderResult.products[2].description,
    productImage: recommenderResult.products[2].featured_image,
  },
  {
    type: EmailTemplateType.PRODUCT_UNIT_FLIPPED,
    fields: "title, subtitle, button",
    discount: recommenderResult.discount.amount,
    discountCode: recommenderResult.discount.code,
    productName: recommenderResult.products[3].title,
    productDescription: recommenderResult.products[3].description,
    productImage: recommenderResult.products[3].featured_image,
  },
];

// const emailTemplateMapping: { [key in EmailTemplateType]: string } = {
//   [EmailTemplateType.ANNOUNCEMENT]: "title, subtitle, content",
//   [EmailTemplateType.BLOG_POST]: "title, subtitle, content, author",
//   [EmailTemplateType.COLLECTION_UNIT]: "title, subtitle, collection_items",
//   [EmailTemplateType.DISCOUNT]: "title, subtitle, button",
//   [EmailTemplateType.EVENT]: "title, subtitle, date, location",
//   [EmailTemplateType.HERO]: "title, subtitle, background_image",
//   [EmailTemplateType.NEWSLETTER]: "title, subtitle, content, articles",
//   [EmailTemplateType.PRODUCT_UNIT]: "title, content, buttonText",
//   [EmailTemplateType.TESTIMONIAL]: "title, subtitle, testimonial_text, author",
//   [EmailTemplateType.WELCOME]: "title, subtitle, welcome_message",
// };

type SuccessResponse =
  operations["brand_api_get_styling"]["responses"][200]["content"]["application/json"];

type CampaignRecommendationsResponse =
  operations["recommender_api_get_brand_campaign_recommendations"]["responses"][200]["content"]["application/json"];

type EmailTemplateContentResponse =
  operations["recommender_api_get_email_text_for_campaign"]["responses"][200]["content"]["application/json"];

function EntireEmailWrapper({
  recommendedCampaignId,
  itemSetType,
  backgroundImage,
  brandLogo,
}: {
  recommendedCampaignId: string;
  itemSetType: string;
  backgroundImage?: string;
  brandLogo?: string;
}) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["template-" + recommendedCampaignId],
    queryFn: async (): Promise<EmailTemplateContentResponse> => {
      const { data } = await axios.get("/api/v1/recommender/gen-email-text", {
        params: {
          campaign_id: recommendedCampaignId,
        },
      });
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  if (isLoading) {
    const spinnerContainerStyle: React.CSSProperties = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    };

    const spinnerStyle: React.CSSProperties = {
      display: "inline-block",
      position: "relative",
      width: "80px",
      height: "80px",
    };

    const dotStyle: React.CSSProperties = {
      position: "absolute",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      background: "#4d90fe",
      animation: "spin 1.2s linear infinite",
    };

    const dots: React.CSSProperties[] = Array.from({ length: 8 }).map(
      (_, i) => ({
        ...dotStyle,
        top: `${35 + 30 * Math.cos((i * Math.PI) / 4)}px`,
        left: `${35 + 30 * Math.sin((i * Math.PI) / 4)}px`,
        animationDelay: `${-1.2 + i * 0.15}s`,
      })
    );

    const keyframes = `
      @keyframes spin {
        0% { transform: scale(1); }
        50% { transform: scale(0.5); }
        100% { transform: scale(1); }
      }
    `;

    return (
      <div style={spinnerContainerStyle}>
        <div style={spinnerStyle}>
          {dots.map((style, index) => (
            <div key={index} style={style} />
          ))}
        </div>
        <style>{keyframes}</style>
      </div>
    );
  }
  if (isError) return <div>Is Error</div>;
  if (!data) return <div>No Data</div>;

  const content = data.content;
  if (content === null) return <div>Error</div>;

  if (itemSetType === "new_products") {
    return (
      <KVEmailTemplate3
        header={content.header}
        subheader={content.subheader}
        button={content.cta_button_text_1}
        image={content.product_descriptions[0].image_url}
        logo={
          <div
            dangerouslySetInnerHTML={{
              __html: brandLogo ?? "",
            }}
          />
        }
      />
    );
  }

  if (itemSetType === "new_collection") {
    return (
      <KVEmailTemplate2
        header={content.header}
        subheader={content.subheader}
        button={content.cta_button_text_1}
        products={content.product_descriptions.map((product) => ({
          name: product.name,
          subheader: product.description,
          imageUrl: product.image_url,
        }))}
        logo={
          <div
            dangerouslySetInnerHTML={{
              __html: brandLogo ?? "",
            }}
          />
        }
      />
    );
  }

  return (
    <KVEmailTemplate
      header={content.header}
      subheader={content.subheader}
      logo={
        <div
          dangerouslySetInnerHTML={{
            __html: brandLogo ?? "",
          }}
        />
      }
      products={content.product_descriptions.map((product) => ({
        text: product.name,
        image: product.image_url,
        url: product.url,
      }))}
    />
  );
}

function EmailWrapper({
  type,
  campaignTitle,
  campaignDescription,
  discount,
  discountCode,
  productName,
  productDescription,
  fields,
  productImage,
}: {
  type: EmailTemplateType;
  campaignTitle: string;
  campaignDescription: string;
  fields: string;
  discount?: string;
  discountCode?: string;
  productName?: string;
  productDescription?: string;
  productImage?: string;
}) {
  const { isLoading, data, isError } = useQuery({
    queryKey: ["section-" + type + "-" + productName],
    queryFn: async () => {
      const data = await axios.get("/api/v1/brand/gen-email-content", {
        params: {
          campaign_title: campaignTitle,
          campaign_description: campaignDescription,
          section_type: type,
          discount: discount,
          product_name: productName,
          product_description: productDescription,
          fields: fields,
        },
      });
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  if (isLoading) return <div>Is Loading</div>;
  if (isError) return <div>Is Error</div>;
  if (!data) return <div>No Data</div>;
  const jsonData = JSON.parse(data.data);

  const templateUI = renderTemplate(type, {
    ...jsonData,
    backgroundImage: productImage,
    discount: type === EmailTemplateType.DISCOUNT ? discount : undefined,
    discountCode:
      type === EmailTemplateType.DISCOUNT ? discountCode : undefined,
  });

  return (
    <div
      style={{
        borderBottom: "1px solid black",
      }}
    >
      {templateUI}
    </div>
  );
}

export default function EmailRoute() {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();
  const [recommendedCampaign, setRecommendedCampaign] =
    useState<RecommendedCampaign | null>(null);
  const { data, isError } = useBrandStyle();
  const isSuccess = !isError && data !== null;
  let maybeColor =
    data?.palettes?.[2] && data?.palettes?.[1]
      ? data?.palettes?.[1]
      : data?.palettes?.[0];
  let maybeButtonColor =
    data?.palettes?.[2] && data?.palettes?.[1]
      ? data?.palettes?.[2]
      : data?.palettes?.[1];
  const maybeButtonStyle = data?.button_styles?.[0];

  // const maybeBackgroundAsset = data?.scraped_assets[0]
  //   ? data?.scraped_assets[0]
  //   : undefined;
  // const maybeLogo = data?.scraped_logos[0] ?? undefined;

  // useEffect(() => {
  //   if (isSuccess) {
  //     console.log("useEffect: adding newStyleElement on success");
  //     const styleElement = document.createElement("style");
  //     styleElement.appendChild(
  //       document.createTextNode(data?.scraped_fonts.join("") ?? "")
  //     );
  //     styleElement.appendChild(
  //       document.createTextNode(`
  //       button {
  //         background-color: ${maybeButtonColor?.background};
  //         border-color: ${maybeButtonStyle?.border_color};
  //         border-style: ${maybeButtonStyle?.border_style};
  //         border-width: ${maybeButtonStyle?.border_width};
  //         border-radius: ${maybeButtonStyle?.border_radius};
  //         color: ${maybeButtonColor?.text_color};
  //         font-family: ${maybeButtonStyle?.font?.font_family};
  //         font-size: ${maybeButtonStyle?.font?.size};
  //         font-weight: ${maybeButtonStyle?.font?.weight};
  //         text-transform: ${maybeButtonStyle?.text_transform};
  //         text-align: ${maybeButtonStyle?.text_align};
  //         padding: ${maybeButtonStyle?.padding};
  //         display: inline-block;
  //       }

  //       .email-accent {
  //         background-color: ${data?.palettes?.[0].background};
  //         color: ${data?.palettes?.[0].text_color};
  //       }

  //       .email-temp {
  //         border-color: ${data?.palettes?.[0].background};
  //       }

  //       .email-palette {
  //         color: ${data?.palettes?.[0].text_color};
  //         background-color: ${data?.palettes?.[0].background};
  //       }

  //       .email-announcement-background {
  //         background-image: url(${maybeBackgroundAsset});
  //         background-size: cover;
  //         background-position: cenver;
  //         background-repeat: no-repeat
  //       }

  //     h1 {
  //       font-family: ${
  //         data?.scraped_fonts[0]
  //           ? parseFontFamily(data.scraped_fonts[0]) ?? undefined
  //           : undefined
  //       };
  //     }

  //      h2 {
  //       font-family: ${
  //         data?.scraped_fonts[1]
  //           ? parseFontFamily(data.scraped_fonts[1]) ?? undefined
  //           : undefined
  //       };
  //     }

  //     p {
  //        font-family: ${
  //          data?.scraped_fonts[2]
  //            ? parseFontFamily(data.scraped_fonts[2])
  //              ? data?.scraped_fonts[1]
  //              : parseFontFamily(data?.scraped_fonts[1])
  //            : undefined
  //        };
  //     }
  //       `)
  //     );
  //     document.head.appendChild(styleElement);
  //   }
  // }, [isSuccess, data?.scraped_fonts]);

  const {
    data: campaignRecommendationsData,
    isSuccess: isCampaignRecommendationSuccess,
  } = useQuery({
    queryKey: ["brand-campaign-recommendations", activeBrandID],
    queryFn: async (): Promise<CampaignRecommendationsResponse> => {
      const { data } = await axios.get(
        "/api/v1/recommender/brand-campaign-recommendations",
        {
          params: {
            brand_id: activeBrandID,
          },
        }
      );
      return data;
    },
    enabled: !!activeBrandID,
    retry: false,
    staleTime: 1000 * 60 * 60,
  });

  const updateRecommendedCampaign = (newId: string | null) => {
    if (campaignRecommendationsData == null || newId == null) {
      return;
    }

    const campaignRecommendation =
      campaignRecommendationsData.campaign_recommendations.find(
        (recommendation) => recommendation.id === newId
      );

    const newItemSetType = campaignRecommendation
      ? campaignRecommendation.item_set_type
      : "new_products";

    setRecommendedCampaign({
      id: newId,
      itemSetType: newItemSetType,
    });
  };

  const [emailSections, setEmailSections] = useState<any[]>([]);
  const defaultTemplates = templateTypes.map((type) => {
    return (
      <div
        style={{
          border: "1px solid #ddd",
          margin: "10px 0",
        }}
      >
        {/* {renderDefaultTemplate(
          type,
          maybeLogo ? (
            <div
              dangerouslySetInnerHTML={{
                __html: maybeLogo,
              }}
            />
          ) : undefined
        )} */}
      </div>
    );
  });
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          flex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          borderRight: "1px solid #ccc",
        }}
      >
        <h2>Email</h2>
        <div>
          {isCampaignRecommendationSuccess &&
            campaignRecommendationsData?.campaign_recommendations.length >
              0 && (
              <Select.Root onValueChange={updateRecommendedCampaign}>
                <Select.Trigger
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    padding: "0 15px",
                    fontSize: "13px",
                    lineHeight: "1",
                    height: "35px",
                    gap: "5px",
                    backgroundColor: "white",
                  }}
                >
                  <Select.Value placeholder="Select a campaign..." />
                  <Select.Icon>
                    <ChevronDownIcon />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Content
                  style={{
                    overflow: "hidden",
                    backgroundColor: "white",
                    borderRadius: "6px",
                  }}
                >
                  <Select.ScrollUpButton className="flex items-center justify-center h-6 bg-gray-100">
                    <ChevronUpIcon />
                  </Select.ScrollUpButton>
                  <Select.Viewport
                    style={{
                      padding: "5px",
                    }}
                  >
                    {campaignRecommendationsData?.campaign_recommendations.map(
                      (campaign) => (
                        <Select.Item
                          key={campaign.id}
                          value={campaign.id}
                          style={{
                            fontSize: "13px",
                            lineHeight: "1",
                            borderRadius: "3px",
                            display: "flex",
                            alignItems: "center",
                            height: "25px",
                            padding: "0 35px 0 25px",
                            position: "relative",
                            userSelect: "none",
                          }}
                        >
                          <Select.ItemText>
                            {campaign.creative_recommendations?.[0]?.title}
                          </Select.ItemText>
                        </Select.Item>
                      )
                    )}
                  </Select.Viewport>
                  <Select.ScrollDownButton className="flex items-center justify-center h-6 bg-gray-100">
                    <ChevronDownIcon />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Root>
            )}
        </div>
        <Button
          my={"4"}
          onClick={() => {
            setEmailSections(dataINeedToGiveGPT);
          }}
        >
          Get Email
        </Button>
        <Box
          m={"2"}
          p="4"
          style={{
            borderRadius: "var(--radius-3)",
            rowGap: "2rem",
            display: "flex",
            alignItems: "center",
            gap: "2rem",
            background: "white",
            border: "1px solid rgb(228, 228, 231)",
          }}
        >
          <h1>Typography</h1>
          {/* <div
            dangerouslySetInnerHTML={{
              __html: maybeLogo ?? "",
            }}
          /> */}
          <span
            style={{
              color: data?.palettes?.[0].text_color ?? "white",
              backgroundColor: data?.palettes?.[0].background ?? "black",
              height: 36,
              width: 36,
              display: "flex",
              borderRadius: 4,
              justifyContent: "center",
              alignItems: "center",
              margin: 1,
            }}
          >
            T
          </span>
          <button
            style={{
              margin: 4,
            }}
          >
            Style
          </button>
        </Box>
        {/* {recommendedCampaign && (
          <EntireEmailWrapper
            recommendedCampaignId={recommendedCampaign.id}
            itemSetType={recommendedCampaign.itemSetType}
            backgroundImage={maybeBackgroundAsset ?? ""}
            brandLogo={data?.logos[0].url ?? ""}
          />
        )} */}
        {/* {emailSections.length > 0 && (
          <div
            style={{
              width: 600,
              border: "1px solid black",
            }}
          >
            <Header
              logo={
                <div
                  style={{
                    width: "200px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: data?.logos[0] ?? "",
                  }}
                />
              }
            />

            {emailSections.map((d, i) => (
              <EmailWrapper
                key={i}
                type={d.type}
                campaignTitle={recommenderResult.campaign_title}
                campaignDescription={recommenderResult.campaign_description}
                discount={d.discount}
                discountCode={d.discountCode}
                productName={d.productName}
                productDescription={d.productDescription}
                productImage={d.productImage}
                fields={d.fields}
              />
            ))}
          </div>
        )} */}
      </div>
      <div
        style={{
          flex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          overflowY: "auto",
        }}
      >
        <h2>Templates</h2>
        <div
          style={{
            flex: 1,
            width: 600,
          }}
        >
          {defaultTemplates}
        </div>
      </div>
    </div>
  );
}

import ProgressionButton from "../buttons/ProgressionButton";
import "./ColorPicker.css";
import * as Popover from "@radix-ui/react-popover";
import { Flex, Text, Box, Button, Theme } from "@radix-ui/themes";
import parseColor from "parse-color";
import { useCallback, useState } from "react";
import { HexColorPicker } from "react-colorful";

const ColorPicker = ({
  children,
  defaultColor,
  onSaveColor,
}: {
  defaultColor?: string;
  onSaveColor: (color: string) => void;
  children: React.ReactNode;
}) => {
  const [color, setColor] = useState("");
  const [open, setOpen] = useState(false);

  const resetState = useCallback(() => {
    const defaultHexColor = defaultColor
      ? parseColor(defaultColor).hex
      : undefined;
    setColor(defaultHexColor ?? "#32A5E");
  }, [defaultColor]);

  return (
    <Popover.Root
      open={open}
      onOpenChange={(value) => {
        resetState();
        setOpen(value);
      }}
    >
      <Popover.Trigger asChild>{children}</Popover.Trigger>
      <Popover.Portal>
        <Theme>
          <Popover.Content sideOffset={4} className="color-picker-content">
            <HexColorPicker
              color={color}
              onChange={setColor}
              className="color-picker-saturation"
            />
            <Flex mt="12px" gap="12px">
              <Flex direction="column" gap="4px" width="95px">
                <Text color="gray">Hex</Text>
                <Box
                  p="12px"
                  style={{
                    border: "1px solid #DDD7D7",
                    borderRadius: "12px",
                    color: "#4C4747",
                    fontSize: "16px",
                  }}
                >
                  {color}
                </Box>
              </Flex>
              <Flex direction="column" gap="4px" width="53px">
                <Text color="gray">R</Text>
                <Box
                  p="12px"
                  style={{
                    border: "1px solid #DDD7D7",
                    borderRadius: "12px",
                    color: "#4C4747",
                    fontSize: "16px",
                  }}
                >
                  {parseInt(color.slice(1, 3), 16)}
                </Box>
              </Flex>
              <Flex direction="column" gap="4px" width="53px">
                <Text color="gray">G</Text>
                <Box
                  p="12px"
                  style={{
                    border: "1px solid #DDD7D7",
                    borderRadius: "12px",
                    color: "#4C4747",
                    fontSize: "16px",
                  }}
                >
                  {parseInt(color.slice(3, 5), 16)}
                </Box>
              </Flex>
              <Flex direction="column" gap="4px" width="53px">
                <Text color="gray">B</Text>
                <Box
                  p="12px"
                  style={{
                    border: "1px solid #DDD7D7",
                    borderRadius: "12px",
                    color: "#4C4747",
                    fontSize: "16px",
                  }}
                >
                  {parseInt(color.slice(5, 7), 16)}
                </Box>
              </Flex>
            </Flex>
            <Flex mt="16px" justify="between">
              <Button
                onClick={() => {
                  setOpen(false);
                  setColor(defaultColor ?? "#32A5E");
                }}
                mr="16px"
                size="4"
                variant="outline"
                color="gray"
                style={{ color: "#4C4747" }}
              >
                Cancel
              </Button>
              <ProgressionButton
                onClick={() => {
                  onSaveColor(color);
                  setOpen(false);
                }}
                isDarkMode
                text="Save Changes"
              />
            </Flex>
          </Popover.Content>
        </Theme>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default ColorPicker;

import { components, operations, TypographyCategory } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { PlusIcon } from "lucide-react";
import DraperButton from "~/components/core/buttons/DraperButton";

interface ChangeFontCardProps {
  type: TypographyCategory;
  font: components["schemas"]["BrandStylingFontFamilySchema"] | null;
  title: string;
  onClick: (type: TypographyCategory) => void;
}

const ChangeFontFamilyCard: React.FC<ChangeFontCardProps> = ({
  type,
  title,
  font,
  onClick,
}) => {
  return (
    <Flex
      direction={"row"}
      justify={"between"}
      align={"center"}
      p={"4"}
      gap={"2"}
      style={{
        borderRadius: "var(--radius-3)",
        border: "1px solid rgb(228, 228, 231)",
      }}
    >
      <Flex direction={"column"}>
        <Text size={"5"} weight={"bold"}>
          {title}
        </Text>
        <Text
          size={"5"}
          style={{
            color: "var(--text-secondary)",
          }}
        >
          {font ? font.name : "Not set"}
        </Text>
      </Flex>

      <DraperButton
        onClick={() => onClick(type)}
        {...(font
          ? {
              variant: "light",
              text: "Change Font",
            }
          : {
              variant: "dark",
              text: "Add Font",
              icon: <PlusIcon />,
            })}
      />
    </Flex>
  );
};

export default ChangeFontFamilyCard;

import { components } from "@openapi";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import useGetAdCreativeWithMedia from "~/hooks/ads/useGetAdCreativeWithMedia";
import { assertNever } from "~/utils/typeUtils";

interface ErrorState {
  status: "error";
  message: string;
}

interface LoadingState {
  status: "loading";
}

interface AdEditorMediaStateLoaded {
  status: "loaded";
  // TODO: will this ever contain more than 1 item? if so, design needs to be updated to allow switching between them
  list: components["schemas"]["AdMediaSchema"][];
  selectedMediaIndex: number;
}

interface AdState {
  data: components["schemas"]["AdCreativeSchema"];
  media: ErrorState | LoadingState | AdEditorMediaStateLoaded;
}

interface AdEditorStateLoaded {
  status: "loaded";
  // TODO: need to clarify which ads the user can toggle between (e.g. the ones linked to same campaign?)
  availableAds: AdState[];
  selectedAdIndex: number;
}

// TODO: ignore this for now, and apply directly on states
interface AdEditorPatchState {
  ads: Record<string, Partial<components["schemas"]["AdCreativeSchema"]>>;
  media: Record<string, Partial<components["schemas"]["AdMediaSchema"]>>;
}

export const AD_ZOOM_OPTIONS = [33, 50, 75, 100] as const;
interface AdEditorPermanentState {
  zoom: (typeof AD_ZOOM_OPTIONS)[number];
  updates: AdEditorPatchState;
}

export type AdEditorState = (ErrorState | LoadingState | AdEditorStateLoaded) &
  AdEditorPermanentState;

const initialState: AdEditorState = {
  status: "loading",
  zoom: 50,
  updates: {
    ads: {},
    media: {},
  },
};

interface ActionChangeZoom {
  type: "CHANGE_ZOOM";
  payload: AdEditorState["zoom"];
}
interface ActionSelectAdIndex {
  type: "SELECT_AD_INDEX";
  payload: number;
}
interface ActionResetAdMediaState {
  type: "RESET_AD_MEDIA_STATE";
  payload: {
    adCreativeId: string;
    media: AdState["media"];
  };
}
interface ActionResetState {
  type: "RESET_STATE";
  payload: AdEditorState;
}

export type AdEditorActions =
  | ActionChangeZoom
  | ActionSelectAdIndex
  | ActionResetState
  | ActionResetAdMediaState;
function reducer(state: AdEditorState, action: AdEditorActions): AdEditorState {
  switch (action.type) {
    case "CHANGE_ZOOM":
      return {
        ...state,
        zoom: action.payload,
      };
    case "SELECT_AD_INDEX":
      if (state.status !== "loaded") {
        return state;
      }

      return {
        ...state,
        selectedAdIndex: action.payload,
      };
    case "RESET_AD_MEDIA_STATE":
      if (state.status !== "loaded") {
        return state;
      }

      return {
        ...state,
        availableAds: state.availableAds.map((ad) => {
          if (ad.data.id !== action.payload.adCreativeId) {
            return ad;
          }

          return {
            ...ad,
            media: action.payload.media,
          };
        }),
      };
    case "RESET_STATE":
      return action.payload;
    default:
      assertNever(action);
  }
}

const StateContext = createContext<AdEditorState>(initialState);
const DispatchContext = createContext<React.Dispatch<AdEditorActions> | null>(
  null
);

export const AdEditorProvider = ({
  children,
  adCreativeId,
}: {
  children: React.ReactElement;
  adCreativeId: string | undefined;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { creativeQuery, mediaQuery } = useGetAdCreativeWithMedia(adCreativeId);

  // update state from ad data response
  useEffect(() => {
    if (creativeQuery.data) {
      const ad = creativeQuery.data;
      dispatch({
        type: "RESET_STATE",
        payload: {
          ...state,
          status: "loaded",
          availableAds: [
            {
              data: ad,
              media: {
                status: "loading",
              },
            },
          ],
          selectedAdIndex: 0,
        },
      });
    } else if (creativeQuery.isError) {
      dispatch({
        type: "RESET_STATE",
        payload: {
          ...state,
          status: "error",
          message: creativeQuery.error.message,
        },
      });
    } else {
      dispatch({
        type: "RESET_STATE",
        payload: {
          ...state,
          status: "loading",
        },
      });
    }
  }, [creativeQuery.data, creativeQuery.isError, creativeQuery.error]);

  // update state from ad media response
  useEffect(() => {
    if (state.status !== "loaded") {
      return;
    }

    if (mediaQuery.data) {
      dispatch({
        type: "RESET_AD_MEDIA_STATE",
        payload: {
          adCreativeId: adCreativeId ?? "",
          media: {
            status: "loaded",
            list: mediaQuery.data.media,
            selectedMediaIndex: 0,
          },
        },
      });
    } else if (mediaQuery.isError) {
      dispatch({
        type: "RESET_AD_MEDIA_STATE",
        payload: {
          adCreativeId: adCreativeId ?? "",
          media: {
            status: "error",
            message: mediaQuery.error.message,
          },
        },
      });
    } else {
      dispatch({
        type: "RESET_AD_MEDIA_STATE",
        payload: {
          adCreativeId: adCreativeId ?? "",
          media: {
            status: "loading",
          },
        },
      });
    }
  }, [state.status, mediaQuery.data, mediaQuery.isError, mediaQuery.error]);

  const selectedMediaId: string | undefined = useMemo(() => {
    if (state.status !== "loaded" || state.availableAds.length === 0) {
      return undefined;
    }
    const media = state.availableAds[state.selectedAdIndex].media;
    if (media.status !== "loaded" || media.list.length === 0) {
      return undefined;
    }
    return media.list[media.selectedMediaIndex].id;
  }, [state]);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <StateContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useAdEditorState() {
  return useContext(StateContext);
}

export function useAdEditorSelectedAdState(): AdState {
  const state = useAdEditorState();
  if (state.status !== "loaded" || !state.availableAds.length) {
    throw new Error(
      "useAdEditorLoadedState must be used within a loaded state"
    );
  }
  return useMemo(() => {
    return state.availableAds[state.selectedAdIndex];
  }, [state.availableAds, state.selectedAdIndex]);
}

export function useAdEditorSelectedMediaState():
  | components["schemas"]["AdMediaSchema"]
  | undefined {
  const state = useAdEditorSelectedAdState();
  return useMemo(() => {
    if (state.media.status !== "loaded" || !state.media.list.length) {
      return;
    }
    return state.media.list[state.media.selectedMediaIndex];
  }, [state]);
}

export function useAdEditorDispatch(): React.Dispatch<AdEditorActions> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "useAdEditorDispatch must be used within a AdEditorProvider"
    );
  }
  return dispatch;
}

import {
  AdMediaTemplate,
  AdMediaTemplateCampaignTypeStrings,
  AdMediaTemplateCategoryStrings,
} from "../../../types/ads";
import {
  AdMediaTemplateCampaignType,
  AdMediaTemplateCategory,
  AdMediaType,
  operations,
} from "@openapi";
import {
  Flex,
  Heading,
  Text,
  TextField,
  Select,
  TextArea,
  Button,
  Spinner,
  Box,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

interface InternAdMediaTemplateInfoTabProps {
  template: AdMediaTemplate | null;
}

type UpdateAdMediaTemplateApiResponse =
  operations["ads_api_update_ad_media_template"]["responses"][200]["content"]["application/json"];

type UpdateAdMediaTemplateParams =
  operations["ads_api_update_ad_media_template"]["requestBody"]["content"]["application/json"];

const InternAdMediaTemplateInfoTab: React.FC<
  InternAdMediaTemplateInfoTabProps
> = ({ template }) => {
  const [id, setId] = useState<string>("");

  const [createdAt, setCreatedAt] = useState<string>("");

  const [name, setName] = useState<string>("");

  const [description, setDescription] = useState<string>("");

  const [category, setCategory] = useState<AdMediaTemplateCategory>(
    AdMediaTemplateCategory.product
  );

  const [campaignType, setCampaignType] = useState<AdMediaTemplateCampaignType>(
    AdMediaTemplateCampaignType.custom
  );

  const [isUpdateAdTemplateLoading, setIsUpdateAdTemplateLoading] =
    useState(false);

  useEffect(() => {
    if (template) {
      setId(template.id);
      setCreatedAt(template.created_at);
      setName(template.name);
      setCategory(template.category);
      setDescription(template.description);
      setCampaignType(template.campaign_type);
    }
  }, [template]);

  const updateTemplateMutation = useMutation<
    UpdateAdMediaTemplateApiResponse,
    Error,
    UpdateAdMediaTemplateParams
  >({
    mutationFn: async (
      params: UpdateAdMediaTemplateParams
    ): Promise<UpdateAdMediaTemplateApiResponse> => {
      setIsUpdateAdTemplateLoading(true);
      const { data } = await axios.put(
        `/api/v1/ads/ad-media-template/${id}`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsUpdateAdTemplateLoading(false);
      alert("Template updated successfully!");
    },
    onError: (error) => {
      setIsUpdateAdTemplateLoading(false);
      alert(error);
      console.error("Error updating template:", error);
    },
  });

  const handleSaveClick = () => {
    if (!name || name.length === 0) {
      alert("Name is required");
      return;
    }
    if (!description || description.length === 0) {
      alert("Description is required");
      return;
    }
    if (!campaignType || campaignType.length === 0) {
      alert("Campaign type is required");
      return;
    }
    updateTemplateMutation.mutate({
      name,
      description,
      category,
      campaign_type: campaignType,
      type: AdMediaType.image, // Update when we support video templates
    });
  };

  if (!template) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner loading={true} />
      </Box>
    );
  }

  return (
    <Box>
      <Heading size="3" style={{ marginBottom: "10px" }}>
        Info
      </Heading>
      <Flex direction="column" gap="0.5rem">
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            ID
          </Text>
          <TextField.Root value={template.id} disabled />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Created at
          </Text>
          <TextField.Root value={createdAt} disabled />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Name
          </Text>
          <TextField.Root
            placeholder="Enter a name (i.e Single Product Testimonial)"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Description
          </Text>
          <TextArea
            placeholder="Enter a description to help with better AI matching"
            defaultValue={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Type
          </Text>
          <Select.Root defaultValue={"Image"} disabled>
            <Select.Trigger />
            <Select.Content>
              <Select.Group>
                <Select.Item key={"image"} value={"Image"}>
                  {"Image"}
                </Select.Item>
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Category
          </Text>
          <Select.Root
            value={category}
            onValueChange={(value) =>
              setCategory(value as AdMediaTemplateCategory)
            }
          >
            <Select.Trigger />
            <Select.Content>
              <Select.Group>
                {Object.values(AdMediaTemplateCategory).map((category) => (
                  <Select.Item key={category} value={category}>
                    {AdMediaTemplateCategoryStrings[category]}
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Campaign type
          </Text>
          <Select.Root
            value={campaignType}
            onValueChange={(value) =>
              setCampaignType(value as AdMediaTemplateCampaignType)
            }
          >
            <Select.Trigger />
            <Select.Content>
              <Select.Group>
                {Object.values(AdMediaTemplateCampaignType).map((type) => (
                  <Select.Item key={type} value={type}>
                    {AdMediaTemplateCampaignTypeStrings[type]}
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        </label>
        <Flex gap="3" mt="4" justify="end">
          <Button onClick={handleSaveClick} loading={isUpdateAdTemplateLoading}>
            Save
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InternAdMediaTemplateInfoTab;

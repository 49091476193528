import { AdMediaUpdateableElement, useAdMediaContext } from "../AdMediaContext";
import AdMediaSVGCanvas from "../AdMediaSVGCanvas";
import InternAdMediaElementsTab from "./InternAdMediaElementsTab";
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Heading,
  Spinner,
} from "@radix-ui/themes";
import { ArrowLeft } from "lucide-react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useGetAdCreativeMediaWithElements from "~/hooks/ads/useGetAdCreativeMediaWithElements";
import { AdMediaElementUnionSchema } from "~/types/ads";

const InternAdMediaComponent = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { elements, setElements } = useAdMediaContext();

  const handleElementUpdate = (updatedElement: AdMediaElementUnionSchema) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  };

  const { mediaQuery, elementsQuery } = useGetAdCreativeMediaWithElements(id);

  useEffect(() => {
    if (elementsQuery.data && elements.length === 0) {
      const data = elementsQuery.data;
      setElements(data.elements as unknown[] as AdMediaUpdateableElement[]);
    }
  }, [elementsQuery, elements.length, setElements]);

  if (mediaQuery.isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner loading={true} />
      </Box>
    );
  }

  return (
    <Flex style={{ height: "100%" }} align="start">
      <Flex
        flexGrow="1"
        flexShrink="1"
        direction="column"
        gap="1rem"
        style={{
          padding: "12px",
          margin: "0px 10px 0px 10px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          overflow: "auto",
        }}
      >
        <Flex justify="between">
          <Flex gap="8px">
            <Button
              variant="ghost"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeft></ArrowLeft>
            </Button>
            <Heading size="5">Ad Media</Heading>
          </Flex>
          <Flex justify="end" gap="1rem">
            <Button
              variant="solid"
              color="blue"
              style={{ bottom: 20, right: 20 }}
            >
              ---
            </Button>
          </Flex>
        </Flex>
        <Container size="4">
          <Grid columns="2" gap="2rem">
            {mediaQuery.data?.media?.variants.map((variant) => (
              <AdMediaSVGCanvas key={variant.id} svgUrl={variant.file} />
            ))}
          </Grid>
        </Container>
      </Flex>
      <Card
        style={{
          width: "430px",
          flexGrow: 0,
          flexShrink: 0,
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <InternAdMediaElementsTab
          elements={elements}
          onElementUpdate={handleElementUpdate}
        />
      </Card>
    </Flex>
  );
};

export default InternAdMediaComponent;

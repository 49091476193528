import { UserComponent, useNode, Node } from "@craftjs/core";
import styled from "styled-components";

const TdRoot = styled.td`
  font-size: 0px;
  padding: 10px 25px;
  word-break: break-word;
`;

const Table = styled.table`
  border-collapse: separate;
  line-height: 100%;
`;

const TdButton = styled.td`
  border: none;
  border-radius: 3px;
  cursor: auto;
  mso-padding-alt: 10px 25px;
  background: #414141;
`;

const ButtonText = styled.p`
  display: inline-block;
  background: #414141;
  color: #ffffff;
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 120%;
  margin: 0;
  text-decoration: none;
  text-transform: none;
  padding: 10px 25px;
  mso-padding-alt: 0px;
  border-radius: 3px;
`;

type ButtonProps = {
  background?: string;
  color?: string;
  buttonStyle?: string;
  margin?: any[];
  text?: string;
  textComponent?: any;
};

export const Button: UserComponent<ButtonProps> = ({
  backgroundColor = "black",
  color = "white",
  text = "BUTTON",
}: {
  backgroundColor?: string;
  color?: string;
  text?: string;
}) => {
  const {
    connectors: { connect, drag },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  return (
    <tr ref={(ref) => ref && connect(drag(ref))}>
      <TdRoot align="center" vertical-align="middle">
        <Table border={0} cellPadding="0" cellSpacing="0" role="presentation">
          <tr>
            <TdButton align="center" role="presentation" valign="middle">
              <ButtonText>{text}</ButtonText>
            </TdButton>
          </tr>
        </Table>
      </TdRoot>
    </tr>
  );
};

Button.craft = {
  displayName: "Button",
  rules: {
    canDrop: (targetNode: Node, currentNode: Node) => {
      return targetNode.data.name === "Column";
    },
  },
};

export default Button;

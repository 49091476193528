import HomeSection from "../../HomeSection";
import useGetCampaigns, { Campaign } from "../../hooks/useGetCampaigns";
import useGetLazyCampaigns from "../../hooks/useGetLazyCampaigns";
import CampaignDraftCard from "./CampaignDraftCard";
import { CampaignState } from "@openapi";
import { Button, Flex, Grid } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";

const DRAFTS_PER_BATCH = 4;
const CampaignDraftSection = () => {
  const isloading = useRef(false);
  const [draftsOffset, setDraftsOffset] = useState(0);
  const [campaignDrafts, setCampaignDrafts] = useState<Array<Campaign>>([]);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    if (!campaignDrafts.length && !isloading.current) {
      fetchNextBatch();
    }
  }, []);
  const refetch = useGetLazyCampaigns({
    status: CampaignState.draft,
    limit: DRAFTS_PER_BATCH,
    offset: draftsOffset,
  });
  const fetchNextBatch = async () => {
    isloading.current = true;
    const data = await refetch();
    if (!data.isSuccess) {
      alert(data.error);
      return;
    }
    setCampaignDrafts((prev) => {
      const newArray = Array.from(prev);
      newArray.push(...data.data.campaigns);
      return newArray;
    });
    setDraftsOffset((prev) => prev + DRAFTS_PER_BATCH);
    setHasMore(data.data.has_more);
    isloading.current = false;
  };
  return (
    <HomeSection
      title="Drafts"
      content={
        <Flex direction="column" gap="4">
          <Grid
            style={{ overflow: "visible" }}
            columns="2"
            width="fit-content"
            gap="5"
          >
            {campaignDrafts?.map((campaignDraft) => (
              <CampaignDraftCard campaign={campaignDraft} />
            ))}
          </Grid>
          {hasMore && (
            <Button
              onClick={fetchNextBatch}
              variant="ghost"
              style={{
                background: "transparent",
                width: "fit-content",
              }}
            >
              Load more
            </Button>
          )}
        </Flex>
      }
    />
  );
};
export default CampaignDraftSection;

export const SHOPIFY_CLIENT_ID =
  process.env.REACT_APP_SHOPIFY_CLIENT_ID || "1e16b9e72215016a56d1e93ee15c7cda";
export const APP_BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://draper.getkinn.com";

export const META_APP_ID = process.env.REACT_APP_META_APP_ID;
export const META_CONFIG_ID = process.env.REACT_APP_META_CONFIG_ID;
export const META_API_KEY = process.env.META_API_KEY;

// colors
export const fadedDark = "var(--button-solid-dark-gradient)";

import { AdMediaAspectRatioStrings } from "../../../types/ads";
import { AdMediaAspectRatio } from "@openapi";
import { Flex, Text, TextField, Select } from "@radix-ui/themes";
import React from "react";

interface InternAdMediaTemplateVariantInfoTabProps {
  templateId?: string;
  variantId?: string;
  createdAt?: string;
  mediaAspectRatio?: AdMediaAspectRatio;
}

const InternAdMediaTemplateVariantInfoTab: React.FC<
  InternAdMediaTemplateVariantInfoTabProps
> = ({
  templateId,
  variantId,
  createdAt = "",
  mediaAspectRatio = AdMediaAspectRatio.full_screen_vertical,
}) => {
  return (
    <Flex direction="column" gap="0.5rem">
      <label>
        <Text as="div" size="1" mb="1" weight="bold">
          Template ID
        </Text>
        <TextField.Root value={templateId} disabled />
      </label>
      <label>
        <Text as="div" size="1" mb="1" weight="bold">
          Variant ID
        </Text>
        <TextField.Root value={variantId} disabled />
      </label>
      <label>
        <Text as="div" size="1" mb="1" weight="bold">
          Created at
        </Text>
        <TextField.Root value={createdAt} disabled />
      </label>
      <label>
        <Text as="div" size="1" mb="1" weight="bold">
          Aspect Ratio
        </Text>
        <Select.Root defaultValue={mediaAspectRatio} disabled>
          <Select.Trigger />
          <Select.Content>
            <Select.Group>
              {Object.values(AdMediaAspectRatio).map((ratio) => (
                <Select.Item key={ratio} value={ratio}>
                  {AdMediaAspectRatioStrings[ratio]}
                </Select.Item>
              ))}
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </label>
    </Flex>
  );
};

export default InternAdMediaTemplateVariantInfoTab;

import { useCurrentUserState } from "../../../contexts/CurrentUserContext";
import ErrorPageNotFound from "../errors/ErrorPageNotFound";
import ErrorPageUnauthorized from "../errors/ErrorPageUnauthorized";
import { Grid } from "@radix-ui/themes";
import { Navigate } from "react-router-dom";
import useBrandQuery from "~/hooks/brand/useBrandQuery";
import OnboardingRoute from "~/routes/onboarding";

interface Props {
  isInternal?: boolean;
  children: JSX.Element;
}

const PrivateRoute = ({ isInternal, children }: Props) => {
  const { user } = useCurrentUserState();
  const maybeActiveBrandID =
    user.status === "LOGGED_IN" ? user.activeBrand?.id : null;
  const { brandData, isLoading } = useBrandQuery(maybeActiveBrandID);

  if (user.status === "LOADING" || isLoading) {
    return (
      <Grid align="center" justify="center" style={{ minHeight: "100vh" }} />
    );
  }

  if (user.status === "LOGGED_IN") {
    if (isInternal) {
      if (!user.isInternal) {
        return <ErrorPageNotFound />;
      } else {
        return children;
      }
    }

    if (brandData?.is_onboarded === true) {
      return children;
    }
    return <OnboardingRoute />;
  } else if (user.status === "UNAUTHORIZED") {
    return <ErrorPageUnauthorized />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;

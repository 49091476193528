import { Flex } from "@radix-ui/themes";
import React from "react";

const TemplateEditorLayout = ({
  children,
}: {
  children: [React.ReactNode, React.ReactNode, React.ReactNode];
}) => {
  return (
    <Flex p="16px" width="100%" maxHeight="100dvh" height="100dvh" gap="16px">
      <Flex width="100%" direction="column">
        {children[0]}
        {children[1]}
      </Flex>
      {children[2]}
    </Flex>
  );
};

export default TemplateEditorLayout;

import { Flex, Text, TextArea } from "@radix-ui/themes";
import React from "react";

const TextAreaWithLabel = ({
  label,
  labelColor,
  ...props
}: {
  label: string;
  labelColor?: string;
} & React.ComponentProps<typeof TextArea>) => {
  return (
    <Flex direction="column" gap="4px">
      <Text style={{ color: labelColor }} as="label" size="2" weight="regular">
        {label}
      </Text>
      <TextArea size="3" color="gray" radius="large" {...props} />
    </Flex>
  );
};

export default TextAreaWithLabel;

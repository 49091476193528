import { operations } from "../../../../openapi";
import { useActiveBrandID } from "../../../contexts/CurrentUserContext";
import {
  Campaigns,
  CampaignStatus,
  GetCampaignsResponse,
} from "./useGetCampaigns";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useGetLazyCampaigns = ({
  status,
  limit,
  offset,
}: {
  status: CampaignStatus;
  limit?: number;
  offset?: number;
}) => {
  const activeBrandID = useActiveBrandID();
  const { refetch } = useQuery({
    queryKey: ["campaign-drafts-lazy", activeBrandID],
    queryFn: async (): Promise<GetCampaignsResponse> => {
      const response = await axios.get("/api/v1/campaign/list", {
        params: {
          brand_id: activeBrandID,
          status: status,
          limit: limit,
          offset: offset,
        },
      });
      return response.data;
    },
    enabled: false,
  });
  return refetch;
};
export default useGetLazyCampaigns;

export const getButtonSeparatedPaddings = (padding?: string) => {
  return padding
    ?.replaceAll("px", "")
    .split(" ")
    .map((str) => Number(str));
};

export const getButtonStringPadding = (paddings?: number[]) => {
  return paddings
    ?.reduce((acc, cur) => {
      return acc + String(cur) + "px ";
    }, "")
    .trim();
};

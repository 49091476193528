import EmailEditorAside from "./EmailEditorAside";
import EmailEditorLayout from "./EmailEditorLayout";
import EmailEditorPreview from "./EmailEditorPreview";
import EmailEditorTopBar from "./EmailEditorTopBar";

export const EmailEditorComponents = {
  Layout: EmailEditorLayout,
  TopBar: EmailEditorTopBar,
  Preview: EmailEditorPreview,
  Aside: EmailEditorAside,
};

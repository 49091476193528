import { useActiveBrandID } from "../../contexts/CurrentUserContext";
import GjsEditor from "@grapesjs/react";
import { operations } from "@openapi";
import { DropdownMenu, IconButton } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import grapesjs, { Editor } from "grapesjs";
import juice from "juice";
import { Grape } from "lucide-react";
import { useEffect, useState } from "react";

type SuccessResponse =
  operations["emails_api_get_branded_template"]["responses"][200]["content"]["application/json"];

type TemplatesSuccessResponse =
  operations["emails_api_get_templates"]["responses"][200]["content"]["application/json"];

export default function Grapes() {
  const activeBrandID = useActiveBrandID();

  const [activeTemplate, setActiveTemplate] = useState<string | null>(null);

  const {
    data: templatesData,
    error: templatesError,
    isLoading: isTemplatesLoading,
  } = useQuery({
    queryKey: ["templates", activeBrandID],
    queryFn: async (): Promise<TemplatesSuccessResponse> => {
      const { data } = await axios.get("/api/v1/emails/templates", {
        params: {},
      });
      return data;
    },
    retry: false,
    enabled: !!activeBrandID,
    staleTime: Infinity,
  });

  const {
    data: brandedTemplateData,
    error: brandedTemplateError,
    isLoading: isBrandedTemplateLoading,
  } = useQuery({
    queryKey: ["branded-template", activeBrandID, activeTemplate],
    queryFn: async (): Promise<SuccessResponse> => {
      const { data } = await axios.get("/api/v1/emails/get-branded-template", {
        params: {
          brand_id: activeBrandID,
          template_id: activeTemplate,
        },
      });
      return data;
    },
    enabled: !!activeBrandID && !!activeTemplate,
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

  const [editor, setEditor] = useState<Editor | null>(null);

  useEffect(() => {
    if (brandedTemplateData?.html) {
      editor?.setComponents(brandedTemplateData.html);
    }
  }, [editor, brandedTemplateData?.html]);

  useEffect(() => {
    if (templatesData?.[0]) {
      setActiveTemplate(templatesData[0].id);
    }
  }, [templatesData]);

  const onEditor = (editor: Editor) => {
    const pfx = editor.getConfig().stylePrefix;

    editor.Components.clear();
    editor.Css.clear();

    editor.Panels.addButton("options", {
      id: "export-template",
      command: "export-template",
      label: `<svg  viewBox="0 0 24 24">
            <path fill="currentColor" d="M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z" />
        </svg>`,
    });
    editor.Commands.add("export-template", {
      containerEl: null as HTMLDivElement | null,
      codeEditorHtml: null as HTMLDivElement | null,

      createCodeViewer(): any {
        return editor.CodeManager.createViewer({
          codeName: "htmlmixed",
          //   theme: opts.codeViewerTheme,
        });
      },

      createCodeEditor() {
        const el = document.createElement("div");
        const codeEditor = this.createCodeViewer();

        el.style.flex = "1 0 auto";
        el.style.boxSizing = "border-box";
        el.className = `${pfx}export-code`;
        el.appendChild(codeEditor.getElement());

        return { codeEditor, el };
      },

      getCodeContainer(): HTMLDivElement {
        let containerEl = this.containerEl;

        if (!containerEl) {
          containerEl = document.createElement("div");
          containerEl.className = `${pfx}export-container`;
          containerEl.style.display = "flex";
          containerEl.style.gap = "5px";
          containerEl.style.flexDirection = "column";
          containerEl.style.justifyContent = "space-between";
          this.containerEl = containerEl;
        }

        return containerEl;
      },

      run(editor: Editor) {
        let { codeEditorHtml } = this as any;
        const container = this.getCodeContainer();

        // Init code viewer if not yet instantiated
        if (!codeEditorHtml) {
          const codeViewer = this.createCodeEditor();
          codeEditorHtml = codeViewer.codeEditor;
          this.codeEditorHtml = codeEditorHtml;

          //   if (opts.modalLabelExport) {
          //     let labelEl = document.createElement("div");
          //     labelEl.className = `${pfx}export-label`;
          //     labelEl.innerHTML = opts.modalLabelExport;
          //     container.appendChild(labelEl);
          //   }

          container.appendChild(codeViewer.el);
        }

        editor.Modal.open({
          title: "test title",
          content: container,
        });

        if (codeEditorHtml) {
          const tmpl = `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title>
  </title>
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    #outlook a {
      padding: 0;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    p {
      display: block;
      margin: 13px 0;
    }
  </style>
  <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
  <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
  <style type="text/css">
  </style>
</head>${editor.getHtml()}<style>${editor.getCss()}</style></html>`;
          //   codeEditorHtml.setContent(
          //     opts.inlineCss ? juice(tmpl, opts.juiceOpts) : tmpl
          //   );
          codeEditorHtml.setContent(juice(tmpl));
          codeEditorHtml.editor.refresh();
        }
      },
    });

    editor.StyleManager.addProperty("typography", {
      property: "text-decoration",
      type: "radio",
      defaults: "none",
      list: [
        { id: "none", name: "None" },
        { id: "underline", name: "underline" },
        {
          id: "line-through",
          name: "Line-through",
        },
      ],
    });
    setEditor(editor);
  };

  const templatePicker = (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <IconButton
          radius="full"
          variant="soft"
          disabled={isTemplatesLoading || templatesError == null}
        >
          <Grape width="18" height="18" />
        </IconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {templatesData &&
          templatesData.map((b) => (
            <DropdownMenu.Item onClick={() => setActiveTemplate(b.id)}>
              {b.id}
            </DropdownMenu.Item>
          ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );

  return (
    <>
      {templatePicker}
      <GjsEditor
        // Pass the core GrapesJS library to the wrapper (required).
        // You can also pass the CDN url (eg. "https://unpkg.com/grapesjs")
        grapesjs={grapesjs}
        // Load the GrapesJS CSS file asynchronously from URL.
        // This is an optional prop, you can always import the CSS directly in your JS if you wish.
        grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
        // GrapesJS init options
        options={{
          height: "100vh",
          storageManager: false,
        }}
        onEditor={onEditor}
        plugins={[
          {
            id: "gjs-blocks-basic",
            src: "https://unpkg.com/grapesjs-blocks-basic",
          },
        ]}
      />
    </>
  );
}

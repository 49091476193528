function getTextWidth(svgElement: SVGTextElement, text: string): number {
  const tempElement = svgElement.cloneNode(true) as SVGTextElement;
  tempElement.style.visibility = "hidden";
  tempElement.textContent = text;

  const svgParent = svgElement.parentNode!;
  svgParent.appendChild(tempElement);

  const width = tempElement.getBBox().width;

  svgParent.removeChild(tempElement);
  return width;
}

function breakString(
  svgElement: SVGTextElement,
  word: string,
  maxWidth: number,
  hyphenCharacter = "-"
) {
  const characters = word.split("");
  const lines: string[] = [];
  let currentLine = "";

  characters.forEach((character, index) => {
    const nextLine = `${currentLine}${character}`;
    const lineWidth = getTextWidth(svgElement, nextLine + hyphenCharacter);

    if (lineWidth > maxWidth && currentLine !== "") {
      lines.push(currentLine + hyphenCharacter);
      currentLine = character;
    } else {
      currentLine = nextLine;
    }

    // Handle the last character
    if (index === characters.length - 1) {
      lines.push(currentLine);
    }
  });

  return { hyphenatedStrings: lines, remainingWord: "" };
}

function wrapLabel(
  svgElement: SVGTextElement,
  label: string,
  maxWidth: number
) {
  const words = label.split(" ");
  const completedLines: string[] = [];
  let nextLine = "";

  words.forEach((word, index) => {
    const wordWidth = getTextWidth(svgElement, word + " ");
    const lineWidth = getTextWidth(svgElement, nextLine);

    if (wordWidth > maxWidth) {
      const { hyphenatedStrings } = breakString(svgElement, word, maxWidth);
      if (nextLine) {
        completedLines.push(nextLine);
      }
      completedLines.push(...hyphenatedStrings);
      nextLine = "";
    } else if (lineWidth + wordWidth > maxWidth) {
      completedLines.push(nextLine);
      nextLine = word;
    } else {
      nextLine = nextLine ? nextLine + " " + word : word;
    }

    // Handle the last word
    if (index === words.length - 1 && nextLine) {
      completedLines.push(nextLine);
    }
  });

  return completedLines.filter((line) => line !== "");
}

function extractMaxDimensions(element: SVGElement) {
  const style = element.getAttribute("style");

  let maxWidth: number = Infinity;
  let maxHeight: number = Infinity;

  if (style) {
    const maxWidthMatch = style.match(/max-width:\s*([0-9.]+)px/);
    if (maxWidthMatch) {
      maxWidth = parseFloat(maxWidthMatch[1]);
    }

    const maxHeightMatch = style.match(/max-height:\s*([0-9.]+)px/);
    if (maxHeightMatch) {
      maxHeight = parseFloat(maxHeightMatch[1]);
    }
  }

  // Fallback to element bounds if max width/height not available in styles
  const boundingRect = element.getBoundingClientRect();
  if (!style || !style.includes("max-width")) {
    maxWidth = boundingRect.width || 100; // Default to 100px if width is zero
  }
  if (!style || !style.includes("max-height")) {
    maxHeight = boundingRect.height || 100; // Default to 100px if height is zero
  }

  return {
    maxWidth,
    maxHeight,
  };
}

function getFontFromElement(element: SVGElement) {
  const computedStyle = window.getComputedStyle(element);
  const fontWeight =
    computedStyle.fontWeight || element.getAttribute("font-weight") || "normal";
  const fontSize =
    computedStyle.fontSize || element.getAttribute("font-size") || "12px";
  const fontFamily =
    computedStyle.fontFamily ||
    element.getAttribute("font-family") ||
    "sans-serif";
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

function getLineHeightFromFont(font: string): number {
  const fontSizeMatch = font.match(/(\d+(\.\d+)?)px/);
  const fontSize = fontSizeMatch ? parseFloat(fontSizeMatch[1]) : 12;
  return fontSize * 1.2;
}

function binarySearchFontSize(
  element: SVGTextElement,
  textContent: string,
  maxWidth: number,
  maxHeight: number,
  minFontSize: number,
  maxFontSize: number
): { fontSize: number; wrappedLines: string[] } {
  let low = minFontSize;
  let high = maxFontSize;
  let bestFit = minFontSize;
  let bestWrappedLines: string[] = [];
  let wrappedLines: string[] = [];

  do {
    const midFontSize = Math.floor((low + high) / 2);
    element.setAttribute("font-size", String(midFontSize));
    const font = getFontFromElement(element);
    const lineHeight = getLineHeightFromFont(font);
    wrappedLines = wrapLabel(element, textContent, maxWidth);

    // Calculate total height of wrapped lines
    const totalHeight = wrappedLines.length * lineHeight;

    if (totalHeight <= maxHeight) {
      bestFit = midFontSize;
      bestWrappedLines = wrappedLines;
      low = midFontSize + 1;
    } else {
      high = midFontSize - 1;
    }
  } while (low <= high);

  return {
    fontSize: bestFit,
    wrappedLines: bestWrappedLines.length ? bestWrappedLines : wrappedLines,
  };
}

export default function layoutAndWrapTextElement(
  element: SVGElement,
  textContent: string,
  {
    fontSize: inputFontSize,
    fontFamily: inputFontFamily,
    fontWeight: inputFontWeight,
  }: {
    fontSize?: number;
    fontFamily?: string;
    fontWeight?: string;
  } = {}
) {
  if (element.tagName.toLowerCase() !== "text") {
    return;
  }

  const initialBounds = element.getBoundingClientRect();

  const { maxWidth, maxHeight } = extractMaxDimensions(element);
  const maxFontSizeAttr = element.getAttribute("max-font-size");
  const maxFontSizeRestriction = maxFontSizeAttr
    ? parseFloat(maxFontSizeAttr)
    : undefined;
  const maxFontSize = maxFontSizeRestriction || 300;

  // avoid auto-sizing if a font size is provided
  const MIN_FONT_SIZE = inputFontSize ? Math.max(inputFontSize - 5, 8) : 8; // Set a reasonable minimum font size
  const MAX_FONT_SIZE = inputFontSize
    ? Math.min(inputFontSize, maxFontSize)
    : maxFontSize;

  if (inputFontFamily) {
    element.setAttribute("font-family", inputFontFamily);
  }
  if (inputFontWeight) {
    element.setAttribute("font-weight", inputFontWeight);
  }

  const { fontSize, wrappedLines } = binarySearchFontSize(
    element as SVGTextElement,
    textContent,
    maxWidth,
    maxHeight,
    MIN_FONT_SIZE,
    MAX_FONT_SIZE
  );

  // Set the optimal font size
  element.setAttribute("font-size", String(fontSize));

  const initialX = parseFloat(element.getAttribute("x") ?? "0");
  const initialY = parseFloat(element.getAttribute("y") ?? "0");

  // Clear the existing text content
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }

  // Append each line as a tspan element
  wrappedLines.forEach((line, index) => {
    const tspan = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "tspan"
    );
    tspan.setAttribute("x", `${initialX}`);
    tspan.setAttribute("dy", `${index === 0 ? 0 : 1}em`);
    tspan.textContent = line;
    element.appendChild(tspan);
  });

  // Adjust the y coordinate while keeping the text vertically centered
  const newBounds = element.getBoundingClientRect();
  const yAdjustment = Math.max(
    initialY + (initialBounds.height - newBounds.height) / 2,
    0
  );
  element.setAttribute("y", yAdjustment.toString());
}

// region images

export function updateImageElement(element: SVGElement, imageUrl: string) {
  const fill = element.getAttribute("fill");
  const fillId = fill?.match(/url\(#(.*)\)/)?.[1];
  if (!fillId) {
    console.error("Image element has no fill id");
    return;
  }
  const fillElement = document.getElementById(fillId)?.children[0];
  if (fillElement?.tagName === "image") {
    fillElement?.setAttribute("href", imageUrl);
    return;
  }
  if (fillElement?.tagName === "use") {
    const imageId = fillElement.getAttribute("xlink:href");
    if (imageId) {
      const imageElement = document.getElementById(imageId.replace("#", ""));
      imageElement?.setAttribute("href", imageUrl);
    }
    return;
  }
  console.error("Failed to find where to update image");
}

// endregion images

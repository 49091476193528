import { Section } from "../../../routes/root";
import { capitalizeFirstLetter } from "../../editor/utils";
import { Link, useMatch } from "react-router-dom";

export function SidePanelNavigator({ section }: { section: Section }) {
  const isSelected = useMatch("/" + section.route);
  return (
    <Link
      to={section.route}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.75rem",
        borderRadius: "0.375rem",
        padding: "0.75rem 0rem",
        color: "inherit",
        transition: "all 0.3s",
        textDecoration: "none",
      }}
    >
      <section.icon
        style={{
          color: isSelected ? "black" : "#5F5F5F",
          height: "1.5rem",
          width: "1.5rem",
          strokeWidth: isSelected ? "2" : "1.5",
        }}
      />
      <span
        className=""
        style={{
          fontWeight: isSelected ? 700 : 500,
          color: isSelected ? "black" : "#5F5F5F",
        }}
      >
        {section.title ?? capitalizeFirstLetter(section.type)}
      </span>
    </Link>
  );
}

import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Button, TextField } from "@radix-ui/themes";
import { Dispatch, SetStateAction } from "react";

const SearchField = ({
  currentText,
  onTextChange,
}: {
  currentText: string;
  onTextChange: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <TextField.Root
      value={currentText}
      onChange={(event) => onTextChange(event.currentTarget.value)}
      style={{
        position: "relative",
        backgroundColor: "transparent",
        border: "1px solid #DDD7D7",
        width: "65%",
        alignItems: "center",
      }}
      radius="large"
      color="teal"
      variant="soft"
      placeholder="Search"
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" />
      </TextField.Slot>
      <TextField.Slot
        style={{
          position: "absolute",
          right: "0px",
        }}
      >
        <Button
          style={{
            backgroundColor: "transparent",
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => onTextChange("")}
        >
          Clear
        </Button>
      </TextField.Slot>
    </TextField.Root>
  );
};
export default SearchField;

import { CampaignState } from "@openapi";
import { Flex, Spinner, Text } from "@radix-ui/themes";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MyCampaignsZeroState from "~/components/campaign/my-campaigns/MyCampaignsZeroState";
import StatusFilters from "~/components/campaign/my-campaigns/StatusFilters";
import CampaignCard from "~/components/campaign/my-campaigns/card/CampaignCard";
import DraperButton from "~/components/core/buttons/DraperButton";
import useGetCampaignList from "~/hooks/campaign/useGetCampaignList";

const Root = styled(Flex)`
  padding: 24px;
  width: 100%;
`;

const MyCampaigns = () => {
  const [selectedStatus, setSelectedStatus] = useState<
    CampaignState | null | undefined
  >(CampaignState.active);
  const {
    campaignsQuery: { data, hasNextPage, fetchNextPage, isFetched },
    metricsQuery,
  } = useGetCampaignList({
    status: selectedStatus,
  });

  return (
    <Root gap="24px" direction="column" align="center">
      <Flex direction="column" gap="24px" minWidth="80%">
        <Flex justify="between" align="center">
          <Text size="6" weight="medium">
            My Campaigns
          </Text>
          <Link to="/campaigns/wizard/new">
            <DraperButton
              text="New Campaign"
              variant="dark"
              radius="large"
              size="3"
              icon={<PlusIcon />}
            />
          </Link>
        </Flex>

        <StatusFilters
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
        />
        <InfiniteScroll
          style={{
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "24px",
          }}
          next={fetchNextPage}
          hasMore={hasNextPage ?? false}
          dataLength={data?.pages.flatMap((page) => page.campaigns).length ?? 0}
          loader={
            <Flex height="100%" width="100%" justify="center" align="center">
              <Spinner />
            </Flex>
          }
          scrollThreshold={"90%"}
        >
          {data?.pages?.flatMap((page) =>
            page.campaigns.map((campaign) => (
              <CampaignCard
                campaign={campaign}
                key={campaign.id}
                metrics={metricsQuery.data ?? {}}
              />
            ))
          )}
        </InfiniteScroll>
        {isFetched && data?.pages?.length === 0 && <MyCampaignsZeroState />}
      </Flex>
    </Root>
  );
};

export default MyCampaigns;

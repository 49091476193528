import ModifierContainer from "./ModifierContainer";
import { Grid } from "@radix-ui/themes";
import { PencilIcon, RefreshCcwIcon } from "lucide-react";
import React from "react";
import DraperButton from "~/components/core/buttons/DraperButton";

const TextModifier = () => {
  return (
    <ModifierContainer title="Text">
      <Grid columns="2" gap="12px">
        <DraperButton variant="outlined" text="Edit" icon={<PencilIcon />} />
        <DraperButton
          variant="outlined"
          text="Regenerate"
          icon={<RefreshCcwIcon />}
        />
      </Grid>
    </ModifierContainer>
  );
};

export default TextModifier;

import { Button } from "../base/Button";
import { Column } from "../base/Column";
import { Section } from "../base/Section";
import Text from "../base/Text";
import { Element, useNode } from "@craftjs/core";

const Hero = (logo?: React.ReactElement) => {
  // TODO: Why doesn't creating with non function component work?
  return (
    <Element canvas is={Section} className="email-announcement-background">
      <Element canvas is={Column} width={`${100.0}`} background="white">
        <Element is={Text} fontSize="24" textAlign="center" />
        <Element is={Text} textAlign="center" tagName="h2" />
        <Element is={Button} />
      </Element>
    </Element>
  );
};

export default Hero;

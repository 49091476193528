import PrivateRoute from "../../components/core/auth/PrivateRoute";
import { CampaignWizardProvider } from "../../contexts/CampaignWizardContext";
import AdEditorPage from "../campaign/ads/edit";
import CampaignOverviewRoute from "../campaign/overview";
import CampaignsRoute from "../campaign/root";
import CampaignWizardPage from "../campaign/wizard";
import NewCampaignWizardPage from "../campaign/wizard/new";
import { HOME_ROUTE, STYLE_LIBRARY_ROUTE, CAMPAIGNS_ROUTE } from "../constants";
import HomeRoute from "../home";
import EmailEditorPage from "../intern/email-editor";
import RootRoute from "../root";
import StyleLibraryRoute from "../style-library";
import TemplateEditorPage from "../style-library/template-editor";
import { Theme } from "@radix-ui/themes";
import { Outlet, RouteObject } from "react-router-dom";

export default function getRoutes(): RouteObject[] {
  return [
    {
      path: "",
      element: (
        <Theme>
          <PrivateRoute>
            <RootRoute />
          </PrivateRoute>
        </Theme>
      ),
      children: [
        {
          path: HOME_ROUTE,
          element: <HomeRoute />,
        },
        {
          path: STYLE_LIBRARY_ROUTE,
          element: <StyleLibraryRoute />,
        },
        {
          path: CAMPAIGNS_ROUTE,
          children: [{ path: "", element: <CampaignsRoute /> }],
        },
      ],
    },
    {
      path: "campaign/:id",
      element: (
        <PrivateRoute>
          <CampaignOverviewRoute />
        </PrivateRoute>
      ),
    },

    {
      path: "ad/:id",
      element: (
        <Theme>
          <PrivateRoute>
            <AdEditorPage />
          </PrivateRoute>
        </Theme>
      ),
    },
    {
      path: "email-editor",
      element: (
        <Theme>
          <PrivateRoute isInternal>
            <EmailEditorPage />
          </PrivateRoute>
        </Theme>
      ),
    },
    {
      path: "style-library/template-editor",
      element: (
        <PrivateRoute>
          <TemplateEditorPage />
        </PrivateRoute>
      ),
    },
    {
      path: "campaigns",
      element: (
        <Theme>
          <PrivateRoute>
            <CampaignWizardProvider>
              <Outlet />
            </CampaignWizardProvider>
          </PrivateRoute>
        </Theme>
      ),
      children: [
        {
          path: "wizard/:recommendationID",
          element: <CampaignWizardPage />,
        },
        // {
        //   path: "wizard/:recommendationID/generate",
        //   element: <GeneratePage />,
        // },
        {
          path: "wizard/new",
          element: <NewCampaignWizardPage />,
        },
      ],
    },
    {
      path: "/intern/email-editor",
      element: (
        <Theme>
          <PrivateRoute isInternal>
            <EmailEditorPage />
          </PrivateRoute>
        </Theme>
      ),
    },
  ];
}

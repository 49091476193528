import {
  AdMediaAspectRatio,
  AdMediaElementType,
  AdMediaImageElementType,
  AdMediaTemplateCampaignType,
  AdMediaTemplateCategory,
  AdMediaTextElementType,
  AdMediaType,
  components,
  PaletteColor,
  TypographyCategory,
} from "@openapi";
import {
  Baseline,
  Circle,
  Code,
  Component,
  Group,
  Image,
  Spline,
  Square,
  Type,
} from "lucide-react";

export interface Position {
  x: number;
  y: number;
}

export type AdMediaImageElementSchema = Omit<
  components["schemas"]["AdMediaImageElementSchema"],
  "type"
> & {
  type: AdMediaElementType.image;
};

export type AdMediaTextElementSchema = Omit<
  components["schemas"]["AdMediaTextElementSchema"],
  "type"
> & {
  type: AdMediaElementType.text;
};

export type AdMediaShapeElementSchema = Omit<
  components["schemas"]["AdMediaShapeElementSchema"],
  "type"
> & {
  type: AdMediaElementType.shape;
};

export type AdMediaProductGroupElementSchema = Omit<
  components["schemas"]["AdMediaProductGroupElementSchema"],
  "type"
> & {
  type: AdMediaElementType.product_group;
};

export type AdMediaCollectionGroupElementSchema = Omit<
  components["schemas"]["AdMediaCollectionGroupElementSchema"],
  "type"
> & {
  type: AdMediaElementType.collection_group;
};

export type AdMediaElementUnionSchema =
  | AdMediaImageElementSchema
  | AdMediaTextElementSchema
  | AdMediaShapeElementSchema
  | AdMediaProductGroupElementSchema
  | AdMediaCollectionGroupElementSchema;

export type AdMediaGroupElementUnionSchema =
  | AdMediaProductGroupElementSchema
  | AdMediaCollectionGroupElementSchema;

export const AdMediaTemplateCampaignTypeStrings: Record<
  AdMediaTemplateCampaignType,
  string
> = {
  [AdMediaTemplateCampaignType.custom]: "Custom",
  [AdMediaTemplateCampaignType.new_arrivals]: "New Arrivals",
  [AdMediaTemplateCampaignType.sales]: "Sales",
};

export const AdMediaTemplateCategoryStrings: Record<
  AdMediaTemplateCategory,
  string
> = {
  [AdMediaTemplateCategory.product]: "Product",
  [AdMediaTemplateCategory.lifestyle]: "Lifestyle",
};

export const AdMediaTypeStrings: Record<AdMediaType, string> = {
  [AdMediaType.image]: "Image",
  [AdMediaType.video]: "Video",
};

export const AdMediaAspectRatioStrings: Record<AdMediaAspectRatio, string> = {
  [AdMediaAspectRatio.full_screen_vertical]: "Full Screen Vertical (9:16)",
  [AdMediaAspectRatio.landscape]: "Landscape (1.91:1)",
  [AdMediaAspectRatio.portrait]: "Portrait (4:5)",
  [AdMediaAspectRatio.square]: "Square (1:1)",
};

export const AdMediaElementTypeStrings: Record<AdMediaElementType, string> = {
  [AdMediaElementType.text]: "Text",
  [AdMediaElementType.image]: "Image",
  [AdMediaElementType.shape]: "Shape",
  [AdMediaElementType.product_group]: "Product Group",
  [AdMediaElementType.collection_group]: "Collection Group",
};

export const AdMediaTextElementTypeStrings: Record<
  AdMediaTextElementType,
  string
> = {
  [AdMediaTextElementType.ai_generated]: "AI Generated",
  [AdMediaTextElementType.collection_title]: "Collection Title",
  [AdMediaTextElementType.discount]: "Discount",
  [AdMediaTextElementType.discount_code]: "Discount Code",
  [AdMediaTextElementType.product_title]: "Product Title",
  [AdMediaTextElementType.static]: "Static",
  [AdMediaTextElementType.url]: "URL",
};

export const PaletteColorStrings: Record<PaletteColor, string> = {
  [PaletteColor.primary]: "Primary",
  [PaletteColor.secondary]: "Secondary",
  [PaletteColor.background]: "Background",
  [PaletteColor.dark_background]: "Dark Background",
  [PaletteColor.text_color]: "Text Color",
};

export const AdMediaImageElementTypeStrings: Record<
  AdMediaImageElementType,
  string
> = {
  [AdMediaImageElementType.background]: "Background",
  [AdMediaImageElementType.lifestyle]: "Lifestyle",
  [AdMediaImageElementType.collection]: "Collection",
  [AdMediaImageElementType.product]: "Product",
  [AdMediaImageElementType.product_cutout]: "Product Cutout",
  [AdMediaImageElementType.brand_logo]: "Brand Logo",
};

export const TypographyCategoryStrings: Record<TypographyCategory, string> = {
  [TypographyCategory.header]: "Header",
  [TypographyCategory.paragraph]: "Paragraph",
};

export type AdCreative = components["schemas"]["AdCreativeSchema"];

export type AdMediaTemplate = components["schemas"]["AdMediaTemplateSchema"];

export type AdMediaTemplateVariant =
  components["schemas"]["AdMediaTemplateVariantSchema"];

export type SVGElementSelectionAttributeMappingType = {
  stroke: string[];
  styleOutline: string[];
};

export enum SVGElementSelectionAttributeType {
  Stroke = "stroke",
  StyleOutline = "style_outline",
}

export const SVGElementSelectionAttributeMapping: {
  [key: string]: SVGElementSelectionAttributeType;
} = {
  rect: SVGElementSelectionAttributeType.Stroke,
  circle: SVGElementSelectionAttributeType.Stroke,
  ellipse: SVGElementSelectionAttributeType.Stroke,
  line: SVGElementSelectionAttributeType.Stroke,
  polyline: SVGElementSelectionAttributeType.Stroke,
  polygon: SVGElementSelectionAttributeType.Stroke,
  path: SVGElementSelectionAttributeType.Stroke,
  text: SVGElementSelectionAttributeType.StyleOutline,
  image: SVGElementSelectionAttributeType.StyleOutline,
  tspan: SVGElementSelectionAttributeType.StyleOutline,
};

export const SVGElementTypeIconMapping: { [key: string]: React.ReactNode } = {
  svg: <Component size="18px" />,
  rect: <Square size="18px" />,
  circle: <Circle size="18px" />,
  path: <Spline size="18px" />,
  g: <Group size="18px" />,
  image: <Image size="18px" />,
  text: <Type size="18px" />,
  tspan: <Type size="18px" />,
  defs: <Code size="18px" />,
  title: <Baseline size="18px" />,
};

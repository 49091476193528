import { useNode, Node } from "@craftjs/core";
import React from "react";
import styled from "styled-components";

type ColumnProps = {
  children?: React.ReactNode;
  background?: string;
  color?: string;
  width: string;
};

const CustomColumn = styled.div<ColumnProps>`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  width: ${(props) => props.width}% !important;
  max-width: ${(props) => props.width}%;
  font-size: 0px;
  text-align: left;
  direction: ltr;
  display: inline-block;
  vertical-align: top;
  padding: 10px 0;
`;

export const Column = ({
  children,
  background = "inherit",
  color = "black",
  width,
}: ColumnProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <CustomColumn
      background={background}
      color={color}
      width={width}
      ref={(ref) => ref && connect(drag(ref))}
    >
      <table
        align="center"
        border={0}
        cellPadding="0"
        cellSpacing="0"
        role="presentation"
        width={"100%"}
      >
        <tbody>{children}</tbody>
      </table>
    </CustomColumn>
  );
};

Column.craft = {
  displayName: "Column",
  props: {},
  rules: {
    canDrag: () => true,
    canDrop: (targetNode: Node, currentNode: Node) => {
      return targetNode.id === currentNode.data.parent;
    },
  },
};

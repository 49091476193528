import { EmailCreative } from "../hooks/useGetEmailCreativeData";
import { EmailSection } from "./EmailEditorContext";
import { EmailSectionType } from "@openapi";
import { EmailEditorViews } from "~/components/editor/layout/EmailEditorAside";
import { isSomeEnum } from "~/utils/helpers";

export const reduceSectionsState = (email: EmailCreative) => {
  const mappedSections = email.sections.reduce(
    (acc: any, section: EmailSection) => {
      acc[section.id] = section;
      return acc;
    },
    {}
  );
  return { ...email, sections: mappedSections };
};

export const getCurrentEmailSection = (emailsState: any) => {
  return;
};

export const sectionTypeToEnum = (
  sectionType: string
): EmailEditorViews | null => {
  if (isSomeEnum(EmailEditorViews, sectionType)) {
    return sectionType as EmailEditorViews;
  }

  switch (sectionType) {
    case EmailSectionType.hero:
      return EmailEditorViews.Hero;
    default:
      return null;
  }
};

import {
  useCurrentUserDispatch,
  useCurrentUserState,
} from "../../../contexts/CurrentUserContext";
import { Text, DropdownMenu, IconButton, Flex } from "@radix-ui/themes";
import Cookies from "js-cookie";
import { CircleUser } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function CurrentUser() {
  const { user } = useCurrentUserState();
  const currentUserDispatch = useCurrentUserDispatch();
  let navigate = useNavigate();

  const onLogout = () => {
    fetch("/api/v1/user/auth/logout/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
    })
      .then((res) => res.json())
      .then((_) => {
        currentUserDispatch({
          type: "SET_USER",
          payload: {
            status: "LOGGED_OUT",
          },
        });
        navigate("/login");
      });
  };

  if (user.status !== "LOGGED_IN") {
    return null;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Flex
          direction="row"
          align="center"
          gap="1"
          style={{ cursor: "pointer" }}
        >
          <IconButton
            radius="full"
            variant="soft"
            style={{ cursor: "inherit" }}
          >
            <CircleUser width="18" height="18" />
          </IconButton>
          <Text>My Account</Text>
        </Flex>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <Text
          style={{
            padding: "10px",
            color: "gray",
          }}
        >
          {user.email}
        </Text>
        <DropdownMenu.Item onClick={onLogout}>Log Out</DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

import { ResizeableBox } from "../../components/editor/blocks/ResizeableBox";
import { Body } from "../../components/editor/blocks/base/Body";
import { Button } from "../../components/editor/blocks/base/Button";
import { Column } from "../../components/editor/blocks/base/Column";
import Image from "../../components/editor/blocks/base/Image";
import { Section } from "../../components/editor/blocks/base/Section";
import Text from "../../components/editor/blocks/base/Text";
import Footer from "../../components/editor/blocks/email_sections/Footer";
import Header from "../../components/editor/blocks/email_sections/Header";
import Hero from "../../components/editor/blocks/email_sections/Hero";
import Products2 from "../../components/editor/blocks/email_sections/Products2";
import { RenderNode } from "../../components/editor/editor/RenderNode";
import Viewport from "../../components/editor/editor/Viewport";
import { Editor, Frame, Element } from "@craftjs/core";

const EditorRoute = () => {
  return (
    <Editor
      resolver={{
        ResizeableBox,
        Button,
        Text,
        Section,
        Body,
        Column,
        // Header,
        Image,
      }}
      enabled={false}
      onRender={RenderNode}
      // indicator={{
      //   success: "#2d9d78", // green
      //   error: "transparent", // red
      // }}
    >
      <Viewport>
        <Frame>
          <Element canvas is={Body}>
            {Header()}
            {Hero()}
            {Products2()}
            {Products2()}
            {Footer()}

            {/* <Element canvas is={Section}>
              <Text
                fontSize="23"
                fontWeight="400"
                text="Craft.js is a React framework for building powerful &amp; feature-rich drag-n-drop page editors."
              ></Text>
              <Text
                fontSize="14"
                fontWeight="400"
                text="Everything you see here, including the editor, itself is made of React components. Craft.js comes only with the building blocks for a page editor; it provides a drag-n-drop system and handles the way user components should be rendered, updated and moved, among other things. <br /> <br /> You control the way your editor looks and behave."
              ></Text>
            </Element> */}
          </Element>
        </Frame>
      </Viewport>
    </Editor>
  );
};

export default EditorRoute;

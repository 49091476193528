import DraperButton from "../buttons/DraperButton";
import { Box, Flex, Text } from "@radix-ui/themes";
import { UploadIcon } from "lucide-react";
import { CSSProperties } from "react";
import { DropzoneState } from "react-dropzone";

interface DropzoneContainerLinkProps {
  variant: "link";
}
interface DropzoneContainerSolidProps {
  variant: "button";
  label: string;
  buttonText?: string;
}
export type DropzoneContainerCustomProps = {
  color?: CSSProperties["color"];
  style?: CSSProperties;
} & (DropzoneContainerLinkProps | DropzoneContainerSolidProps);
type DropzoneContainerProps = DropzoneState & DropzoneContainerCustomProps;

const DropzoneContainer: React.FC<DropzoneContainerProps> = ({
  getRootProps,
  getInputProps,
  isDragAccept,
  color,
  style,
  ...props
}) => {
  return (
    <Box
      {...getRootProps()}
      style={{
        border: "2px dashed",
        borderRadius: "12px",
        textAlign: "center",
        borderColor: isDragAccept ? "#3b82f6" : "#d1d5db",
        backgroundColor: "#f9fafb",
        color: color || "#4b5563",
        ...(props.variant === "link" && {
          cursor: "pointer",
        }),
        ...style,
      }}
    >
      <input {...getInputProps()} />
      <Flex
        width="100%"
        height="100%"
        direction="column"
        justify="center"
        align="center"
        p="5"
      >
        <UploadIcon />
        {props.variant === "link" && (
          <>
            <Text mt="16px" as="p">
              <Text as="span" style={{ color: "#3b82f6", cursor: "pointer" }}>
                Upload a file
              </Text>{" "}
              or drag and drop here
            </Text>
            <Text
              as="p"
              style={{
                color: "#9ca3af",
                fontSize: "14px",
                marginTop: "4px",
              }}
            >
              files accepted jpg, png, svg, gif
            </Text>
          </>
        )}
        {props.variant === "button" && (
          <>
            <Text mt="16px" as="p">
              {props.label}
            </Text>
            <DraperButton
              style={{ marginTop: "16px" }}
              variant="dark"
              text={props.buttonText || "Upload"}
              icon={<UploadIcon />}
            />
          </>
        )}
      </Flex>
    </Box>
  );
};

export default DropzoneContainer;

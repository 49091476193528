import classes from "./BrandAssets.module.css";
import BrandImageAsset, { ImageAssetSchema } from "./BrandImageAsset";
import { Tooltip, Text, Box } from "@radix-ui/themes";
import React from "react";

const BrandAsset = ({
  asset,
  overlay,
  onClick,
}: {
  asset: ImageAssetSchema;
  onClick?: () => void;
  overlay?: React.ReactNode;
}) => {
  const { original_filename } = asset;

  return (
    <Tooltip key={asset.id} content={original_filename} side={"bottom"}>
      <Box className={classes.assetContainer} onClick={onClick}>
        <Box position={"relative"}>
          <BrandImageAsset url={asset.url} />
          {overlay}
        </Box>

        <Text size={"3"} className={classes.filename}>
          {original_filename}
        </Text>
      </Box>
    </Tooltip>
  );
};

export default BrandAsset;

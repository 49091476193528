import Editor, { OnMount } from "@monaco-editor/react";
import { Flex, Switch, Text } from "@radix-ui/themes";
import { useRef } from "react";
import ProgressionButton from "~/components/core/buttons/ProgressionButton";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";
import {
  EmailTemplateFooterState,
  EmailTemplateHeaderState,
} from "~/types/template-editor";

const CustomHTMLView = ({
  options: state,
}: {
  options: EmailTemplateHeaderState | EmailTemplateFooterState;
}) => {
  const updateState = useUpdateTemplateEditorState(state.type);

  const editorRef = useRef<any>(null);

  const handleEditorDidMount: OnMount = (editor) => {
    editorRef.current = editor;
  };

  return (
    <Flex direction="column" gap="24px">
      <Flex pt="24px" px="24px" justify="between" align="center">
        <Text weight="medium">Use Custom HTML</Text>
        <Switch
          color="gray"
          highContrast
          checked={state.customHTML.enabled}
          onCheckedChange={(c) =>
            updateState({
              ...state,
              customHTML: {
                ...state.customHTML,
                enabled: c,
              },
            })
          }
        />
      </Flex>

      <Editor
        height="62vh"
        defaultLanguage="html"
        defaultValue={state.customHTML.html}
        onMount={handleEditorDidMount}
        theme="vs-dark"
      />

      <Flex justify="end" pb="24px" px="24px">
        <ProgressionButton
          onClick={() =>
            updateState({
              ...state,
              customHTML: {
                ...state.customHTML,
                html: editorRef?.current?.getValue(),
              },
            })
          }
          isDarkMode
          text="Save Changes"
        />
      </Flex>
    </Flex>
  );
};

export default CustomHTMLView;

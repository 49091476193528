import { getFacebookRedirectUrl } from "../meta/authentication/FacebookLogin";
import { operations } from "@openapi";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type ConnectMetaResponse =
  operations["meta_api_connect_meta_account"]["responses"]["200"]["content"]["application/json"];
type ConnectMetaRequestData =
  operations["meta_api_connect_meta_account"]["requestBody"]["content"]["application/json"];

const MetaCallback = () => {
  let [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const sv = JSON.parse(state ?? "{}");
  const maybeBrandID = sv.brand_id;

  const connectMetaAccountMutation = useDraperApiPostMutation<
    ConnectMetaResponse,
    ConnectMetaRequestData
  >({
    path: "/meta/connect",
    onError: (error) => {
      const message = {
        success: false,
        brand_id: maybeBrandID,
        state: sv.state,
        error: error.message,
      };
      if (window.opener) {
        window.opener.postMessage(message, window.location.origin);
        window.close();
      } else {
        console.error("No opener found. Cannot postMessage.");
      }
    },
    onSuccess: (data) => {
      const message = {
        success: true,
        brand_id: maybeBrandID,
        state: sv.state,
      };
      if (window.opener) {
        window.opener.postMessage(message, window.location.origin);
        window.close();
      } else {
        console.error("No opener found. Cannot postMessage.");
      }
    },
  });

  useEffect(() => {
    if (code && maybeBrandID) {
      connectMetaAccountMutation.mutate({
        brand_id: maybeBrandID,
        token_code: code,
        redirect_uri: getFacebookRedirectUrl(),
      });
    } else {
      const error = searchParams.get("error");
      const errorCode = searchParams.get("error_code");
      const errorDescription = searchParams.get("error_description");
      const errorReason = searchParams.get("error_reason");

      const message = {
        success: false,
        brand_id: maybeBrandID,
        state: sv.state,
        error: `Error: ${error} ${errorCode} ${errorDescription} ${errorReason}`,
      };
      if (window.opener) {
        window.opener.postMessage(message, window.location.origin);
        window.close();
      } else {
        console.error("No opener found. Cannot postMessage.");
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <h1>Connecting...</h1>
    </div>
  );
};

export default MetaCallback;

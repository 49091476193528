import { AdMediaTextElementSchema } from "../../types/ads";
import { useAdMediaContext } from "./AdMediaContext";
import { AdMediaElementType } from "@openapi";
import { Container, Spinner } from "@radix-ui/themes";
import React, { useCallback, useRef, useEffect, useState } from "react";
import { SvgLoader } from "react-svgmt";
import layoutAndWrapTextElement, { updateImageElement } from "~/utils/ads/svg";
import { assertNever } from "~/utils/typeUtils";

const FULL_AD_WIDTH = 600;

interface AdMediaSVGCanvasProps {
  svgUrl: string | null | undefined;
  /** Between 1 and 100 */
  zoom?: number;
}

const AdMediaSVGCanvas: React.FC<AdMediaSVGCanvasProps> = ({
  svgUrl,
  zoom,
}) => {
  const { elements, setElements } = useAdMediaContext();

  const svgContainerRef = useRef<HTMLDivElement | null>(null);

  const updateElementAttributes = useCallback(() => {
    if (!elements.length) {
      return;
    }

    elements.forEach((element) => {
      const targetElement = svgContainerRef.current!.querySelector<SVGElement>(
        `#${element.target_element_id}`
      );
      if (targetElement) {
        if ("is_enabled" in element) {
          targetElement.style.display = element.is_enabled ? "" : "none";
        }
        switch (element.type) {
          case AdMediaElementType.text:
            const textElement = element as AdMediaTextElementSchema;
            layoutAndWrapTextElement(targetElement, textElement.text, {
              fontSize: parseFloat(textElement.font_size),
              fontFamily: textElement.font_family,
              fontWeight: textElement.font_weight.toString(),
            });

            // match the state with the actual restricted font size
            const fontSizeAttr = targetElement.getAttribute("font-size");
            if (fontSizeAttr) {
              const fontSize = parseFloat(fontSizeAttr);
              if (fontSize !== parseFloat(textElement.font_size)) {
                setElements((prevElements) =>
                  prevElements.map((prevElement) =>
                    prevElement.id === element.id
                      ? {
                          ...prevElement,
                          font_size: `${fontSize}px`,
                        }
                      : prevElement
                  )
                );
              }
            }
            break;
          case AdMediaElementType.image:
            if (element.uploadedFile) {
              const imageUrl = URL.createObjectURL(element.uploadedFile);
              const previousHref = targetElement.getAttribute("href");
              if (previousHref !== imageUrl) {
                targetElement.setAttribute("href", imageUrl);

                updateImageElement(targetElement, imageUrl);
              }
            }
            break;
        }
      }
    });
  }, [elements]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length) {
          updateElementAttributes();
          observer.disconnect();
          break;
        }
      }
    });

    if (svgContainerRef.current) {
      observer.observe(svgContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [updateElementAttributes]);

  useEffect(() => {
    updateElementAttributes();
  }, [updateElementAttributes]);

  if (!svgUrl) {
    return (
      <Container>
        <Spinner size="3" />
      </Container>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        ...(zoom
          ? {
              width: `${FULL_AD_WIDTH}px`,
              minWidth: `${FULL_AD_WIDTH}px`,
              zoom: `${zoom}%`,
            }
          : { width: "100%" }),
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          borderRadius: "8px",
          borderWidth: "2px",
          borderColor: "#afafaf",
          overflow: "hidden",
        }}
        ref={svgContainerRef}
      >
        <SvgLoader width="100%" height="100%" path={svgUrl} />
      </div>
    </div>
  );
};

export default AdMediaSVGCanvas;

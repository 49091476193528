import { Flex, Text } from "@radix-ui/themes";
import { PlusIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import DraperButton from "~/components/core/buttons/DraperButton";

const Container = styled(Flex)`
  border: 1px solid var(--border);
  border-radius: 16px;
  padding: 32px;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const MyCampaignsZeroState = () => {
  return (
    <Container>
      <Text size="4">There are no live or scheduled campaigns</Text>
      <Flex gap="24px">
        <DraperButton
          radius="large"
          variant="outlined"
          text="View Draft Campaigns"
        />
        <DraperButton
          radius="large"
          variant="dark"
          text="New Campaign"
          icon={<PlusIcon />}
        />
      </Flex>
    </Container>
  );
};

export default MyCampaignsZeroState;

import {
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
} from "../../../contexts/CampaignWizardContext";
import AudienceCard from "./CampaignAudience";
import CampaignChannel from "./CampaignChannel";
import CampaignCreatives from "./CampaignCreatives";
import CampaignCustomAssets from "./CampaignCustomAssets";
import CampaignDetails from "./CampaignDetails";
import CampaignLandingDestination from "./CampaignLandingDestination";
import CampaignProducts from "./CampaignProducts";
import CampaignPromotion from "./CampaignPromotion";
import CampaignSummary from "./CampaignSummary";
import CampaignType from "./CampaignType";

export const RecommendedCampaignCards: Record<
  RecommendedCampaignWizardSteps,
  React.ReactNode
> = {
  [RecommendedCampaignWizardSteps.SUMMARY]: <CampaignSummary />,
  [RecommendedCampaignWizardSteps.CHANNEL]: <CampaignChannel />,
  [RecommendedCampaignWizardSteps.AUDIENCE]: <AudienceCard />,
  [RecommendedCampaignWizardSteps.PROMOTION]: <CampaignPromotion />,
  [RecommendedCampaignWizardSteps.PRODUCT]: <CampaignProducts />,
  [RecommendedCampaignWizardSteps.DESTINATION]: <CampaignLandingDestination />,
  [RecommendedCampaignWizardSteps.CREATIVE_CONCEPT]: <CampaignCreatives />,
  [RecommendedCampaignWizardSteps.GENERATE]: <CampaignSummary />,
};

export const NewCampaignCards: Record<NewCampaignWizardSteps, React.ReactNode> =
  {
    [NewCampaignWizardSteps.TYPE]: <CampaignType />,
    [NewCampaignWizardSteps.CUSTOM_ASSETS]: <CampaignCustomAssets />,
    [NewCampaignWizardSteps.DETAILS]: <CampaignDetails />,
    [NewCampaignWizardSteps.LANDING_DESTINATION]: (
      <CampaignLandingDestination />
    ),
    [NewCampaignWizardSteps.CHANNEL]: <CampaignChannel />,
    [NewCampaignWizardSteps.AUDIENCE]: <AudienceCard />,
    [NewCampaignWizardSteps.PRODUCT]: <CampaignProducts />,
    [NewCampaignWizardSteps.PROMOTION]: <CampaignPromotion />,
    [NewCampaignWizardSteps.CREATIVE_CONCEPT]: <CampaignCreatives />,
    [NewCampaignWizardSteps.GENERATE]: <CampaignSummary />,
  };

import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CampaignWizardCardContent from "../../../components/campaign/wizard/cards/CampaignWizardCardContent";
import SearchableExpandedSelect from "../../../components/campaign/wizard/inputs/SearchableExpandedSelect";
import useCampaignLandingDestinationProps from "./hooks/useCampaignLandingDestinationProps";
import { DestinationType } from "@openapi";
import * as Form from "@radix-ui/react-form";
import { Button, Flex, Text, Box } from "@radix-ui/themes";
import FormErrorText from "~/components/auth/FormErrorText";
import DraperButton from "~/components/core/buttons/DraperButton";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";

const CampaignLandingDestination = () => {
  const {
    cardProps,
    inputProps: {
      destination,
      isSelecting,
      setDestination,
      setIsSelecting,
      search,
      setSearch,
      isLandingDestinationEnabled,
      setIsLandingDestinationEnabled,
    },
  } = useCampaignLandingDestinationProps();
  const { wizardType } = useCampaignWizardState();
  const activeBrandID = useActiveBrandID();
  const { brandData } = useBrandQuery(activeBrandID);

  const inputComponent = (
    <Flex direction={"row"} align={"end"} gap={"4"}>
      <Box width={"100%"}>
        <Form.Field name="landing-destination">
          <Form.Label>
            <Text size={"1"}>Landing Destination URL</Text>
          </Form.Label>
          <Flex align="end" gap="3">
            <Form.Control asChild>
              <input
                id="landing-destination"
                type="url"
                style={{
                  padding: "8px",
                  fontSize: "14px",
                  borderRadius: "12px",
                  border: "1px solid var(--border, #DDD7D7)",
                  width: "100%",
                  height: "45px",
                  backgroundColor: "white",
                  color: "black",
                  cursor: "default",
                }}
                value={destination?.url}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value) {
                    setDestination({
                      id: "",
                      name: "",
                      handle: e.target.value,
                      type: DestinationType.custom,
                      url: e.target.value,
                    });
                    setIsLandingDestinationEnabled(true);
                  } else {
                    setDestination(null);
                  }
                }}
              />
            </Form.Control>
            <DraperButton
              text={"Collections"}
              variant="outlined"
              style={{
                backgroundColor: isSelecting ? "#F7ECDC80" : "white",
              }}
              onClick={() => {
                setIsSelecting((prev) => !prev);
              }}
            />
          </Flex>
          <Form.Message match={"typeMismatch"}>
            <FormErrorText>Please provide a valid URL</FormErrorText>
          </Form.Message>
        </Form.Field>
      </Box>
    </Flex>
  );

  const text =
    wizardType === "NEW"
      ? "Do you have a landing page already created for this campaign?"
      : destination
      ? `Do you have a landing page already created for this campaign? If not, the destination of this campaign will be the following ${
          destination.type === DestinationType.collection
            ? "collection"
            : "product"
        } page.`
      : "Do you have a landing page already created for this campaign? If not, we will create a new custom collection that features these products. You will be able to configure the name of the collection before generating the campaign.";

  return (
    <Form.Root onSubmit={(e) => e.preventDefault()}>
      <CampaignWizardCard {...cardProps}>
        <CampaignWizardCardContent>
          <Text size="4">{text}</Text>
          <Flex gap="16px">
            <Button
              onClick={() => {
                setIsLandingDestinationEnabled(false);
                setDestination(null);
              }}
              style={{
                backgroundColor:
                  isLandingDestinationEnabled === false
                    ? "#F7ECDC80"
                    : undefined,
              }}
              radius="large"
              size="4"
              variant="outline"
              color="gray"
            >
              No
            </Button>
            <Button
              onClick={() => {
                setIsLandingDestinationEnabled(true);
                setIsSelecting(true);
              }}
              style={{
                backgroundColor:
                  isLandingDestinationEnabled === true
                    ? "#F7ECDC80"
                    : undefined,
              }}
              radius="large"
              size="4"
              variant="outline"
              color="gray"
            >
              Yes
            </Button>
          </Flex>

          {isLandingDestinationEnabled && inputComponent}

          {isLandingDestinationEnabled && isSelecting && (
            <SearchableExpandedSelect
              onSelect={(item) => {
                setDestination({
                  id: item.id,
                  name: item.title,
                  handle: item.handle,
                  type: DestinationType.collection,
                  url: `${brandData?.domain}/collections/${item.handle}`,
                });
              }}
              search={search}
              setSearch={(query) => setSearch(query)}
            />
          )}
        </CampaignWizardCardContent>
      </CampaignWizardCard>
    </Form.Root>
  );
};

export default CampaignLandingDestination;

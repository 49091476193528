import ChatMessageContainer from "../chat/ChatMessageContainer";
import DraperButton from "../core/buttons/DraperButton";
import FacebookLogin from "../meta/authentication/FacebookLogin";
import OnboardingCard from "./OnboardingCard";
import { Avatar, Box, Card, Flex, Text } from "@radix-ui/themes";
import { CircleUser } from "lucide-react";
import styled from "styled-components";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

const MetaCard = styled(Card)`
  padding: 24px;
  width: 100%;
`;

const OnboardingMeta = ({
  onNext,
  onPrev,
}: {
  onNext: () => void;
  onPrev: () => void;
}) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);
  const isMetaConnected = (adAccounts?.length ?? 0) > 0;
  const firstAccount = adAccounts?.[0];

  const notConnectedContent = (
    <MetaCard>
      <Flex gap="4" align="center" justify={"between"}>
        <Box>
          <Text weight={"bold"}>Meta Account</Text>
          <br />
          <Text>Will open a popup to connect your Meta account</Text>
        </Box>
        <FacebookLogin />
      </Flex>
    </MetaCard>
  );

  const connectedContent = (
    <Flex width={"100%"} direction={"column"} gap={"4"}>
      <MetaCard>
        <Flex gap="4" align="center" justify={"between"}>
          <Flex gap="2" align="center">
            <Avatar
              src={firstAccount?.profile_picture_url ?? undefined}
              fallback={firstAccount?.name?.[0] ?? <CircleUser size={20} />}
            />
            <Text size={"3"}>{firstAccount?.name}</Text>
          </Flex>
          <FacebookLogin showAdAccounts={false} />
        </Flex>
      </MetaCard>
      <Text size={"1"}>
        You can add multiple Meta accounts later in My Account.
      </Text>
    </Flex>
  );

  return (
    <Flex direction={"column"} justify={"center"} align={"center"} gap="8">
      <OnboardingCard
        primary={
          <DraperButton
            variant="dark"
            text="Continue"
            onClick={onNext}
            disabled={!isMetaConnected}
          />
        }
        secondary={
          <DraperButton variant="outlined" text="Back" onClick={onPrev} />
        }
        pillText="Meta Account"
      >
        <ChatMessageContainer
          message={
            <>
              Now let’s connect your Meta account. <br /> I’m going to take a
              look at all your ads and get a sense for what’s performed well to
              inform our ad campaigns.
            </>
          }
        />

        {!isMetaConnected ? notConnectedContent : connectedContent}
      </OnboardingCard>
    </Flex>
  );
};

export default OnboardingMeta;

import EditorLayout from "./EditorLayout";
import EditorPreview from "./EditorPreview";
import EditorSidePanel from "./EditorSidePanel";
import EditorTopBar from "./EditorTopBar";

export const AdEditorComponents = {
  Layout: EditorLayout,
  TopBar: EditorTopBar,
  SidePanel: EditorSidePanel,
  Preview: EditorPreview,
};

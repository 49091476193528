import { components, EmailSectionType, EmailSectionWidthType } from "@openapi";

export enum CustomizationViews {
  TypeSelect,
  HeaderOptions,
  FooterOptions,
  MenuLinks,
  SocialLinks,
}

export type EmailHeaderSectionSchema = Omit<
  components["schemas"]["EmailHeaderSectionSchema"],
  "type"
> & {
  type: EmailSectionType.header;
};
export type EmailFooterSectionSchema = Omit<
  components["schemas"]["EmailFooterSectionSchema"],
  "type"
> & {
  type: EmailSectionType.footer;
};

export type EmailSectionBackgroundImageState =
  | Pick<components["schemas"]["EmailBackgroundPropertySchema"], "id" | "image">
  | File;

export type EmailSectionLinkElementState = Pick<
  components["schemas"]["EmailLinkElementSchema"],
  "enabled" | "text" | "link_url" | "opens_new_tab"
> & {
  id?: components["schemas"]["EmailLinkElementSchema"]["id"];
};

export type EmailSectionSocialLinkState = EmailSectionLinkElementState;

type EmailSectionTextElementState = Pick<
  components["schemas"]["EmailTextElementSchema"],
  "enabled" | "text"
> & {
  id?: components["schemas"]["EmailTextElementSchema"]["id"];
};

export type EmailSectionImageElementState =
  | (Pick<
      components["schemas"]["EmailImageElementSchema"],
      "enabled" | "image_url"
    > & {
      id?: components["schemas"]["EmailImageElementSchema"]["id"];
    })
  | File;

export const isFileType = (file: any): file is File => {
  return "lastModified" in file;
};

export type EmailTemplateStateCommon = {
  id: string;
  widthType: EmailSectionWidthType;
  availableTemplates: {
    id: string;
    templateHtml: string;
  }[];
  selectedTemplateIndex: number;
  colors: {
    background: string;
    foreground: string;
    backgroundImage?: EmailSectionBackgroundImageState;
  }[];

  menuLinks: {
    enabled: boolean;
    links: (EmailSectionLinkElementState & { dragId: string })[];
  };

  customHTML: {
    enabled: boolean;
    html: string;
  };
};

export type EmailTemplateHeaderState = EmailTemplateStateCommon & {
  type: EmailSectionType.header;
  logo?: EmailSectionImageElementState;
  tagline: EmailSectionTextElementState & { isEditing?: boolean };
};

export type EmailTemplateFooterState = EmailTemplateStateCommon & {
  type: EmailSectionType.footer;
  socialLinks: {
    enabled: boolean;
    facebook: EmailSectionSocialLinkState;
    twitter: EmailSectionSocialLinkState;
    instagram: EmailSectionSocialLinkState;
    tiktok: EmailSectionSocialLinkState;
    youtube: EmailSectionSocialLinkState;
  };
};

export type EmailBrandData = {
  unsubscribeLink: string;
  organization: {
    name: string;
    fullAddress: string;
  };
};

export interface TemplateEditorInterface {
  currentView: CustomizationViews;
  viewType?: EmailSectionType.header | EmailSectionType.footer;
  headerOptions: EmailTemplateHeaderState;
  footerOptions: EmailTemplateFooterState;
  brandData: EmailBrandData;
  previewMode: "desktop" | "mobile";
}

export const CustomizationLabels: Record<CustomizationViews, string> = {
  [CustomizationViews.TypeSelect]: "Campaign Template",
  [CustomizationViews.HeaderOptions]: "Customize Header",
  [CustomizationViews.FooterOptions]: "Customize Footer",
  [CustomizationViews.MenuLinks]: "Menu Links",
  [CustomizationViews.SocialLinks]: "Social Links",
};

import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import {
  NewCampaignWizardSteps,
  useCampaignWizardDispatch,
} from "../../../../contexts/CampaignWizardContext";
import { useEffect, useMemo, useState } from "react";

const useCampaignDetailsProps = () => {
  const dispatch = useCampaignWizardDispatch();
  const [description, setDescription] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children">>(
    () => ({
      badgeTitle: "Campaign Type",
      backButtonProps: {
        onClick: () => {
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: { currentStep: NewCampaignWizardSteps.TYPE },
          });
        },
      },
      nextButtonProps: {
        text: isEditing ? "Reply" : "Continue",
        onClick: () => {
          if (isEditing) {
            setIsEditing(false);
          } else {
            dispatch({
              type: "SET_CURRENT_STEP",
              payload: {
                currentStep: NewCampaignWizardSteps.LANDING_DESTINATION,
              },
            });
          }
        },
        disabled: !description,
      },
    }),
    [isEditing, description, dispatch]
  );

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: { description_input: description },
    });
  }, [description, dispatch]);

  const inputProps = useMemo(
    () => ({
      value: description,
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setDescription(e.target.value),
      isEditing,
      setIsEditing,
    }),
    [description, isEditing]
  );

  return { cardProps, inputProps };
};

export default useCampaignDetailsProps;

import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type GetCollectionProductsReponse =
  operations["shopify_integration_api_get_collection_products"]["responses"][200]["content"]["application/json"];
type GetCollectionProductsRequestData =
  operations["shopify_integration_api_get_collection_products"]["parameters"]["query"];

const useShopifyCollectionProductsQuery = (
  brandID: string | null,
  collectionID: string | null
) => {
  const {
    data: collectionProductsData,
    isLoading,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: ["shopify/collection/products", brandID, collectionID],
    queryFn: async ({ signal }) => {
      if (!signal.aborted) {
        const { data } = await axios.get<GetCollectionProductsReponse>(
          "/api/v1/shopify/collection/products",
          {
            params: {
              brand_id: brandID,
              collection_id: collectionID,
            },
          }
        );
        return data;
      }
    },
    retry: false,
    enabled: !!brandID && !!collectionID,
  });

  return {
    collectionProductsData,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useShopifyCollectionProductsQuery;

import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type CampaignRecommendationResponse =
  operations["recommender_api_get_campaign_recommendation"]["responses"][200]["content"]["application/json"];

const useCampaignRecommendationQuery = (
  brandID: string | null,
  recommendationID: string | null
) => {
  const {
    data: campaignRecommendation,
    isLoading,
    isSuccess,
    isError,
  } = useQuery<CampaignRecommendationResponse>({
    queryKey: [
      "recommender-campaign-recommendation",
      brandID,
      recommendationID,
    ],
    queryFn: async (): Promise<CampaignRecommendationResponse> => {
      const { data } = await axios.get(
        `/api/v1/recommender/campaign-recommendation`,
        {
          params: {
            campaign_recommendation_id: recommendationID,
            brand_id: brandID,
          },
        }
      );
      return data;
    },
    enabled: !!recommendationID && !!brandID,
  });

  return {
    campaignRecommendation,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useCampaignRecommendationQuery;

import { assertNever } from "../utils/typeUtils";
import { CampaignType, components } from "@openapi";
import { createContext, useReducer, useMemo, useContext } from "react";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { LandingDestination } from "~/views/campaign/wizard/hooks/useCampaignLandingDestinationProps";

export enum RecommendedCampaignWizardSteps {
  SUMMARY,
  CHANNEL,
  AUDIENCE,
  PROMOTION,
  PRODUCT,
  DESTINATION,
  CREATIVE_CONCEPT,
  GENERATE,
}

export enum NewCampaignWizardSteps {
  TYPE,
  DETAILS,
  LANDING_DESTINATION,
  CUSTOM_ASSETS,
  CHANNEL,
  AUDIENCE,
  PRODUCT,
  PROMOTION,
  CREATIVE_CONCEPT,
  GENERATE,
}

export enum ChannelType {
  EMAIL = "Email",
  META_ADS = "Meta Ads",
  // SMS,
  // PUSH_NOTIFICATIONS,
}

export type CampaignWizardSteps =
  | RecommendedCampaignWizardSteps
  | NewCampaignWizardSteps;

export const RecommendedCampaignWizardStepsLabels: Record<number, string> = {
  [RecommendedCampaignWizardSteps.SUMMARY]: "Summary",
  [RecommendedCampaignWizardSteps.CHANNEL]: "Channel",
  [RecommendedCampaignWizardSteps.AUDIENCE]: "Audience",
  [RecommendedCampaignWizardSteps.PROMOTION]: "Promotion",
  [RecommendedCampaignWizardSteps.PRODUCT]: "Product",
  [RecommendedCampaignWizardSteps.DESTINATION]: "Destination",
  [RecommendedCampaignWizardSteps.CREATIVE_CONCEPT]: "Creative concept",
  [RecommendedCampaignWizardSteps.GENERATE]: "Generate",
};

export const NewCampaignWizardStepsLabels: Record<number, string> = {
  [NewCampaignWizardSteps.TYPE]: "Campaign type",
  [NewCampaignWizardSteps.CUSTOM_ASSETS]: "Custom assets",
  [NewCampaignWizardSteps.DETAILS]: "Campaign details",
  [NewCampaignWizardSteps.LANDING_DESTINATION]: "Landing destination",
  [NewCampaignWizardSteps.CHANNEL]: "Channel",
  [NewCampaignWizardSteps.AUDIENCE]: "Audience",
  [NewCampaignWizardSteps.PRODUCT]: "Product",
  [NewCampaignWizardSteps.PROMOTION]: "Promotion",
  [NewCampaignWizardSteps.CREATIVE_CONCEPT]: "Creative concept",
  [NewCampaignWizardSteps.GENERATE]: "Generate",
};

type NewCampaignData = Partial<
  components["schemas"]["CampaignCreateDraftNewRequestData"]
>;
type RecommendedCampaignData = Partial<
  components["schemas"]["CampaignCreateDraftRecommendationRequestData"]
>;

type CampaignData = NewCampaignData | RecommendedCampaignData;

interface CampaignWizardInterface {
  currentStep: CampaignWizardSteps;
  currentRequiredStep: CampaignWizardSteps;
  wizardType: "NEW" | "RECOMMENDED";
  campaignData: CampaignData;
}

const initialState: CampaignWizardInterface = {
  currentStep: RecommendedCampaignWizardSteps.SUMMARY,
  currentRequiredStep: RecommendedCampaignWizardSteps.SUMMARY,
  wizardType: "RECOMMENDED",
  campaignData: {
    asset_ids: [],
    audiences: [],
    campaign_type: CampaignType.other,
    channels: {
      emails: 0,
      meta_ads: 0,
      sms: 0,
      push_notifications: 0,
    },
    products: [],
    creative_id: "",
    brand_id: "",
    description_input: "",
  },
};

interface SetCurrentstep {
  type: "SET_CURRENT_STEP";
  payload: {
    currentStep: CampaignWizardSteps;
  };
}

interface SetWizardType {
  type: "SET_WIZARD_TYPE";
  payload: {
    wizardType: CampaignWizardInterface["wizardType"];
  };
}

interface ResetState {
  type: "RESET_STATE";
}

interface UpdateCampaignData {
  type: "UPDATE_CAMPAIGN_DATA";
  payload: CampaignData;
}

export type CampaignWizardAction =
  | SetCurrentstep
  | ResetState
  | UpdateCampaignData
  | SetWizardType;

function reducer(
  state: CampaignWizardInterface,
  action: CampaignWizardAction
): CampaignWizardInterface {
  switch (action.type) {
    case "SET_WIZARD_TYPE":
      return {
        ...state,
        wizardType: action.payload.wizardType,
        currentRequiredStep:
          action.payload.wizardType === "RECOMMENDED"
            ? RecommendedCampaignWizardSteps.SUMMARY
            : NewCampaignWizardSteps.TYPE,
        currentStep:
          action.payload.wizardType === "RECOMMENDED"
            ? RecommendedCampaignWizardSteps.SUMMARY
            : NewCampaignWizardSteps.TYPE,
      };
    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload.currentStep,
        currentRequiredStep:
          action.payload.currentStep > state.currentRequiredStep
            ? action.payload.currentStep
            : state.currentRequiredStep,
      };
    case "UPDATE_CAMPAIGN_DATA":
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          ...action.payload,
        },
      };
    case "RESET_STATE":
      return initialState;
    default:
      assertNever(action);
  }
}

const CampaginWizardContext =
  createContext<CampaignWizardInterface>(initialState);
const DispatchContext =
  createContext<React.Dispatch<CampaignWizardAction> | null>(null);

export const CampaignWizardProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <CampaginWizardContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </CampaginWizardContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useCampaignWizardState() {
  return useContext(CampaginWizardContext);
}

export function useCampaignWizardDispatch(): React.Dispatch<CampaignWizardAction> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "useCampaignWizardDispatch must be used within a CampaignWizardProvider"
    );
  }
  return dispatch;
}

import MarkAvatar from "../core/avatars/MarkAvatar";
import { Flex, Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const ChatBubble = styled.div`
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  width: 100%;
  padding: 12px;
  background: #ffffff;
`;

const ChatMessageContainer = ({
  message,
}: {
  message: string | React.ReactNode;
}) => {
  return (
    <Flex direction={"row"} gap="3" align={"start"}>
      <MarkAvatar />
      <ChatBubble>
        <Text>{message}</Text>
      </ChatBubble>
    </Flex>
  );
};

export default ChatMessageContainer;

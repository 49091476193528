import CloseIconButton from "../../../core/buttons/CloseIconButton";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  Checkbox,
  Dialog,
  Flex,
  ScrollArea,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";
import DraperButton from "~/components/core/buttons/DraperButton";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

const AssetSelectorDialog = ({
  isDialogOpen,
  onToggleDialogOpen,
  selectedAssets,
  setSelectedAssets,
}: {
  isDialogOpen: boolean;
  onToggleDialogOpen: (open: boolean) => void;
  selectedAssets: ImageAssetSchema[];
  setSelectedAssets: (asset: ImageAssetSchema[]) => void;
}) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<ImageAssetSchema[]>([]);
  const { data: brandStyling, isError } = useBrandStyle();

  const logoAssets = brandStyling?.logos ?? [];
  const backgroundAssets = brandStyling?.background_images ?? [];
  const imageAssets = brandStyling?.image_assets ?? [];
  const allAssets = [...logoAssets, ...backgroundAssets, ...imageAssets];

  useEffect(() => {
    isDialogOpen && setSelected(selectedAssets);
  }, [isDialogOpen]);

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={onToggleDialogOpen}>
      <Dialog.Content maxWidth="816px" style={{ padding: 0 }}>
        <Flex
          justify="between"
          align="center"
          p="16px"
          pb="12px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="medium" mb="0">
            Add from asset library
          </Dialog.Title>
          <Dialog.Close>
            <CloseIconButton />
          </Dialog.Close>
        </Flex>
        <Flex direction="column" p="24px" pr="0">
          <TextField.Root
            mb="16px"
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            style={{ backgroundColor: "#F1F1F0", width: "calc(100% - 24px)" }}
            radius="large"
            color="teal"
            variant="soft"
            placeholder="Search…"
          >
            <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <ScrollArea>
            <Flex pr="16px" wrap="wrap" gap="24px" maxHeight="304px">
              {allAssets.length === 0 && <Text>No assets found</Text>}
              {isError && <Text>Failed to fetch assets</Text>}

              {allAssets
                .filter((asset) =>
                  asset.original_filename
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map((asset) => (
                  <BrandAsset
                    key={asset.id}
                    asset={asset}
                    overlay={
                      !!selected.find((file) => file.id === asset.id) ? (
                        <Checkbox
                          size="3"
                          color="gray"
                          highContrast
                          checked
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            right: "8px",
                          }}
                        />
                      ) : undefined
                    }
                    onClick={() =>
                      !!selected.find((file) => file.id === asset.id)
                        ? setSelected(
                            selected.filter((file) => file.id !== asset.id)
                          )
                        : setSelected([...selected, asset])
                    }
                  />
                ))}
            </Flex>
          </ScrollArea>
        </Flex>
        <Flex p="24px" justify="end">
          <Flex gap="3">
            <Dialog.Close>
              <DraperButton variant="light" text="Cancel" />
            </Dialog.Close>
            <Dialog.Close>
              <DraperButton
                onClick={() => setSelectedAssets(selected)}
                text="Add"
                variant="dark"
              />
            </Dialog.Close>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AssetSelectorDialog;

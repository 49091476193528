import {
  AdMediaElementTypeStrings,
  AdMediaImageElementTypeStrings,
  AdMediaTextElementTypeStrings,
  PaletteColorStrings,
  TypographyCategoryStrings,
} from "../../../types/ads";
import {
  AdMediaElementType,
  AdMediaTextElementType,
  operations,
  components,
  AdMediaImageElementType,
  PaletteColor,
  TypographyCategory,
} from "@openapi";
import {
  Box,
  Button,
  Container,
  Dialog,
  Flex,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";

type AdMediaTemplateElementsApiResponse =
  operations["ads_api_get_ad_media_template_elements"]["responses"][200]["content"]["application/json"];

type CreateAdMediaTemplateElementParams =
  operations["ads_api_create_ad_media_template_element"]["requestBody"]["content"]["application/json"];

type CreateAdMediaTemplateTextElementParams =
  components["schemas"]["CreateAdMediaTemplateTextElementRequestData"];

type CreateAdMediaTemplateImageElementParams =
  components["schemas"]["CreateAdMediaTemplateImageElementRequestData"];

type CreateAdMediaTemplateShapeElementParams =
  components["schemas"]["CreateAdMediaTemplateShapeElementRequestData"];

type CreateAdMediaTemplateProductGroupElementParams =
  components["schemas"]["CreateAdMediaTemplateProductGroupElementRequestData"];

type CreateAdMediaTemplateCollectionGroupElementParams =
  components["schemas"]["CreateAdMediaTemplateCollectionGroupElementRequestData"];

type CreateAdMediaTemplateElementApiResponse =
  operations["ads_api_create_ad_media_template_element"]["responses"][200]["content"]["application/json"];

type AdMediaTemplateProductGroupElementSchema =
  components["schemas"]["AdMediaTemplateProductGroupElementSchema"];

type AdMediaTemplateCollectionGroupElementSchema =
  components["schemas"]["AdMediaTemplateCollectionGroupElementSchema"];

type AdMediaTemplateGroupElementUnionSchema =
  | AdMediaTemplateProductGroupElementSchema
  | AdMediaTemplateCollectionGroupElementSchema;

interface InternAdMediaTemplateElementCreationDialogProps {
  templateId: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  onCreate: () => void;
}

const InternAdMediaTemplateElementCreationDialog: React.FC<
  InternAdMediaTemplateElementCreationDialogProps
> = ({ templateId, isDialogOpen, setIsDialogOpen, onCreate }) => {
  const [elementType, setElementType] = useState<AdMediaElementType>(
    AdMediaElementType.product_group
  );

  const [targetElementId, setTargetElementId] = useState<string | null>(null);

  const [groupId, setGroupId] = useState<string | null>(null);

  const [groupName, setGroupName] = useState<string | null>(null);

  const [textType, setTextType] = useState<AdMediaTextElementType>(
    AdMediaTextElementType.ai_generated
  );

  const [imageType, setImageType] = useState<AdMediaImageElementType>(
    AdMediaImageElementType.background
  );

  const [fillPaletteColor, setFillPaletteColor] = useState<PaletteColor | null>(
    null
  );

  const [strokePaletteColor, setStrokePaletteColor] =
    useState<PaletteColor | null>(null);

  const [typographyCategory, setTypographyCategory] =
    useState<TypographyCategory>(TypographyCategory.header);

  const [description, setDescription] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const query = useQuery({
    queryKey: ["ad-media-template-elements", templateId],
    queryFn: async (): Promise<AdMediaTemplateElementsApiResponse> => {
      const { data } = await axios.get(
        `/api/v1/ads/ad-media-template/${templateId}/elements`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const mutation = useMutation<
    CreateAdMediaTemplateElementApiResponse,
    AxiosError,
    CreateAdMediaTemplateElementParams
  >({
    mutationFn: async (
      params: CreateAdMediaTemplateElementParams
    ): Promise<CreateAdMediaTemplateElementApiResponse> => {
      setIsLoading(true);
      const { data } = await axios.post(
        `/api/v1/ads/ad-media-template/${templateId}/element`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsLoading(false);
      if (data.success) {
        alert("Template element created successfully!");
        setGroupId(null);
        setTargetElementId(null);
        setDescription(null);
        setIsDialogOpen(false);
        onCreate();
      } else {
        alert(data.error_message);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      alert(error.response?.data);
      console.error("Error creating template element:", error);
    },
  });

  const handleCreateClick = () => {
    if (!elementType) {
      alert("Element type is required");
      return;
    }

    let params: CreateAdMediaTemplateElementParams;

    switch (elementType) {
      case AdMediaElementType.text:
        if (!targetElementId) {
          alert("Target element ID is required.");
          return;
        }
        params = {
          type: elementType,
          target_element_id: targetElementId,
          group_id: groupId,
          text_type: textType,
          description: description,
          typography_category: typographyCategory,
        } as CreateAdMediaTemplateTextElementParams;
        break;
      case AdMediaElementType.image:
        if (!targetElementId) {
          alert("Target element ID is required.");
          return;
        }
        params = {
          type: elementType,
          target_element_id: targetElementId,
          group_id: groupId,
          image_type: imageType,
        } as CreateAdMediaTemplateImageElementParams;
        break;
      case AdMediaElementType.shape:
        if (!targetElementId) {
          alert("Target element ID is required.");
          return;
        }
        params = {
          type: elementType,
          target_element_id: targetElementId,
          fill_palette_color: fillPaletteColor,
          stroke_palette_color: strokePaletteColor,
        } as CreateAdMediaTemplateShapeElementParams;
        break;
      case AdMediaElementType.product_group:
        if (!groupName) {
          alert("Name is required.");
          return;
        }
        params = {
          type: elementType,
          name: groupName,
        } as CreateAdMediaTemplateProductGroupElementParams;
        break;
      case AdMediaElementType.collection_group:
        if (!groupName) {
          alert("Group name is required.");
          return;
        }
        params = {
          type: elementType,
          name: groupName,
        } as CreateAdMediaTemplateCollectionGroupElementParams;
        break;
      default:
        alert("Unsupported element type");
        return;
    }

    mutation.mutate(params);
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Container>
        <Dialog.Content>
          <Dialog.Title>Create Template Element</Dialog.Title>
          <Dialog.Description size="1" mb="4">
            Template elements represent elements in the SVG templates files.
            These template elements allow our gen-AI pipeline to target specific
            elements via target element IDs to generate content for.
            <br />
            <br />
            Each media template variant SVG file must have matching element IDs.
            For example, a text element with a target element ID of "hdl-1" and
            text type of "headline" will generate headline content and target
            the SVG element with ID matching "hdl-1".
            <br />
            <br />
            Product / Collection groups allow us to logically group text and
            image elements together to allow the media generation pipeline to
            generate the appropriate content for those elements within the
            context of the assigned product or collection.
          </Dialog.Description>
          <Flex direction="column" gap="3">
            <Box>
              <Text as="div" size="1" mb="1" weight="bold">
                Type
              </Text>
              <Select.Root
                defaultValue={elementType}
                onValueChange={(value) =>
                  setElementType(value as AdMediaElementType)
                }
              >
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  <Select.Group>
                    {Object.values(AdMediaElementType).map((type) => (
                      <Select.Item key={type} value={type}>
                        {AdMediaElementTypeStrings[type]}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Box>
            {/* Additional input for text type */}
            {elementType === AdMediaElementType.text && (
              <Flex direction="column" align="start" gap="8px">
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Target Element ID
                  </Text>
                  <TextField.Root
                    placeholder="Enter an SVG element ID to target"
                    onChange={(event) => setTargetElementId(event.target.value)}
                  />
                </Box>
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Text Type
                  </Text>
                  <Select.Root
                    defaultValue={textType}
                    onValueChange={(value) =>
                      setTextType(value as AdMediaTextElementType)
                    }
                  >
                    <Select.Trigger style={{ width: "100%" }} />
                    <Select.Content>
                      <Select.Group>
                        {Object.values(AdMediaTextElementType).map((type) => (
                          <Select.Item key={type} value={type}>
                            {AdMediaTextElementTypeStrings[type]}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Box>
                {textType === AdMediaTextElementType.ai_generated && (
                  <Box style={{ width: "100%" }}>
                    <Text as="div" size="1" mb="1" weight="bold">
                      AI Description
                    </Text>
                    <TextField.Root
                      placeholder="Enter a description of what kind of text to generate"
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </Box>
                )}
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Typography Category
                  </Text>
                  <Select.Root
                    defaultValue={TypographyCategory.header}
                    onValueChange={(value) =>
                      setTypographyCategory(value as TypographyCategory)
                    }
                  >
                    <Select.Trigger style={{ width: "100%" }} />
                    <Select.Content>
                      <Select.Group>
                        {Object.values(TypographyCategory).map((category) => (
                          <Select.Item key={category} value={category}>
                            {TypographyCategoryStrings[category]}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Box>
              </Flex>
            )}
            {/* Additional input for image type */}
            {elementType === AdMediaElementType.image && (
              <Flex direction="column" align="start" gap="8px">
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Target Element ID
                  </Text>
                  <TextField.Root
                    placeholder="Enter an SVG element ID to target"
                    onChange={(event) => setTargetElementId(event.target.value)}
                  />
                </Box>
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Image Type
                  </Text>
                  <Select.Root
                    defaultValue={imageType}
                    onValueChange={(value) =>
                      setImageType(value as AdMediaImageElementType)
                    }
                  >
                    <Select.Trigger style={{ width: "100%" }} />
                    <Select.Content>
                      <Select.Group>
                        {Object.values(AdMediaImageElementType).map((type) => (
                          <Select.Item key={type} value={type}>
                            {AdMediaImageElementTypeStrings[type]}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Box>
              </Flex>
            )}
            {(elementType === AdMediaElementType.text ||
              elementType === AdMediaElementType.image) && (
              <Flex direction="column" align="start" gap="8px">
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Group
                  </Text>
                  <Select.Root
                    defaultValue={"none"}
                    onValueChange={(value) =>
                      setGroupId(value !== "none" ? value : null)
                    }
                  >
                    <Select.Trigger style={{ width: "100%" }} />
                    <Select.Content>
                      <Select.Group>
                        <Select.Item key={"none"} value={"none"}>
                          -----
                        </Select.Item>
                        {query.data?.elements
                          .filter((element) =>
                            [
                              AdMediaElementType.collection_group,
                              AdMediaElementType.product_group,
                            ].includes(element.type)
                          )
                          .map((element) => (
                            <Select.Item key={element.id} value={element.id}>
                              {
                                (
                                  element as AdMediaTemplateGroupElementUnionSchema
                                ).name
                              }
                            </Select.Item>
                          ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Box>
              </Flex>
            )}
            {elementType === AdMediaElementType.shape && (
              <Flex direction="column" align="start" gap="8px">
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Target Element ID
                  </Text>
                  <TextField.Root
                    placeholder="Enter an SVG element ID to target"
                    onChange={(event) => setTargetElementId(event.target.value)}
                  />
                </Box>
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Fill Palette Color
                  </Text>
                  <Select.Root
                    defaultValue="none"
                    onValueChange={(value) =>
                      setFillPaletteColor(value as PaletteColor)
                    }
                  >
                    <Select.Trigger style={{ width: "100%" }} />
                    <Select.Content>
                      <Select.Group>
                        <Select.Item key="none" value="none">
                          None
                        </Select.Item>
                        {Object.values(PaletteColor).map((color) => (
                          <Select.Item key={color} value={color}>
                            {PaletteColorStrings[color]}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Box>
                <Box style={{ width: "100%" }}>
                  <Text as="div" size="1" mb="1" weight="bold">
                    Stroke Palette Color
                  </Text>
                  <Select.Root
                    defaultValue="none"
                    onValueChange={(value) =>
                      setStrokePaletteColor(value as PaletteColor)
                    }
                  >
                    <Select.Trigger style={{ width: "100%" }} />
                    <Select.Content>
                      <Select.Group>
                        <Select.Item key="none" value="none">
                          None
                        </Select.Item>
                        {Object.values(PaletteColor).map((color) => (
                          <Select.Item key={color} value={color}>
                            {PaletteColorStrings[color]}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Box>
              </Flex>
            )}
            {/* Inputs for product and collection group elements */}
            {(elementType === AdMediaElementType.product_group ||
              elementType === AdMediaElementType.collection_group) && (
              <Box style={{ width: "100%" }}>
                <Text as="div" size="1" mb="1" weight="bold">
                  Name
                </Text>
                <TextField.Root
                  placeholder="Enter a group name"
                  onChange={(event) => {
                    setGroupName(
                      event.target.value.length === 0
                        ? null
                        : event.target.value
                    );
                  }}
                />
              </Box>
            )}
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button onClick={handleCreateClick} loading={isLoading}>
              Create
            </Button>
          </Flex>
        </Dialog.Content>
      </Container>
    </Dialog.Root>
  );
};

export default InternAdMediaTemplateElementCreationDialog;

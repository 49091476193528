import { fadedDark } from "../../../../../../utils/constants";
import styles from "./NumberedPaginator.module.css";
import { Button, Flex, IconButton } from "@radix-ui/themes";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { Pagination } from "react-headless-pagination";

const NumberedPaginator = ({
  totalPages,
  currentPage,
  setCurrentPage,
}: {
  totalPages: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => {
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const PageButton = (props: any) => {
    return (
      <Button
        {...props}
        style={{
          cursor: "pointer",
          border: "1px solid #DDD7D7",
          background:
            props.children === currentPage + 1 ? fadedDark : "transparent",
          color: props.children === currentPage + 1 ? "white" : "#4C4747",
        }}
        title={String(props.children)}
      >
        {props.children}
      </Button>
    );
  };
  return (
    <Pagination
      totalPages={totalPages}
      middlePagesSiblingCount={1}
      edgePageCount={1}
      currentPage={currentPage}
      setCurrentPage={handlePageChange}
      className={styles.container}
      truncableClassName={styles.truncable}
      truncableText="..."
    >
      <Pagination.PrevButton className={styles.button}>
        <IconButton
          style={{
            cursor: currentPage === 0 ? "default" : "pointer",
            backgroundColor: "transparent",
            border: "1px solid #DDD7D7",
          }}
        >
          <ChevronLeft color={currentPage === 0 ? "#DDD7D7" : "#595D62"} />
        </IconButton>
      </Pagination.PrevButton>

      <nav className={styles.nav}>
        <Flex gap="2">
          <Pagination.PageButton as={<PageButton />} />
        </Flex>
      </nav>

      <Pagination.NextButton className={styles.button}>
        <IconButton
          style={{
            cursor: currentPage === totalPages - 1 ? "default" : "pointer",
            backgroundColor: "transparent",
            border: "1px solid #DDD7D7",
          }}
        >
          <ChevronRight
            color={currentPage === totalPages - 1 ? "#DDD7D7" : "#595D62"}
          />
        </IconButton>
      </Pagination.NextButton>
    </Pagination>
  );
};

export default NumberedPaginator;

import { SVGElementTypeIconMapping } from "../../../types/ads";
import { Box, Button, Flex, Heading } from "@radix-ui/themes";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";

interface InternAdMediaTemplateVariantSVGLayersTabProps {
  svgContainerId: string;
  selectedElement: SVGElement | null;
  onElementSelect?: (element: SVGElement) => void;
}

interface TreeData {
  name: string;
  children?: TreeData[];
  id: string;
  element: SVGElement;
}

const getNodeDetails = (node: SVGElement): TreeData => {
  const childrenArray = Array.from(node.children) as SVGElement[];
  return {
    name: node.tagName,
    id: node.tagName + Math.random(),
    element: node,
    children: childrenArray.length
      ? childrenArray.map(getNodeDetails)
      : undefined,
  };
};

const TreeNode: React.FC<{
  node: TreeData;
  onSelect?: (element: SVGElement) => void;
  selectedElement: SVGElement | null;
}> = ({ node, onSelect, selectedElement }) => {
  const [expanded, setExpanded] = useState(true);

  const hasChildren = node.children && node.children.length > 0;

  const handleNodeClick = () => {
    onSelect && onSelect(node.element);
  };

  const handleToggleExpansionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const isSelected = node.element === selectedElement;
  const icon = SVGElementTypeIconMapping[node.name] || null;

  return (
    <Box>
      <Flex
        align="center"
        justify="between"
        onClick={handleNodeClick}
        style={{
          cursor: "pointer",
          width: "100%",
          padding: "6px",
          marginBottom: "4px",
          backgroundColor: isSelected ? "#d0e0ff" : "transparent",
        }}
        onMouseOver={(e) => {
          if (!isSelected) e.currentTarget.style.backgroundColor = "#f0f0f0";
        }}
        onMouseOut={(e) => {
          if (!isSelected)
            e.currentTarget.style.backgroundColor = "transparent";
        }}
      >
        <Flex align="center" gap="0.5rem">
          {icon}
          <Heading size="2">{node.name}</Heading>
        </Flex>

        {hasChildren && (
          <Button size="1" variant="ghost" onClick={handleToggleExpansionClick}>
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </Button>
        )}
      </Flex>
      {expanded && hasChildren && (
        <Box ml="1rem">
          {node.children?.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              onSelect={onSelect}
              selectedElement={selectedElement}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const InternAdMediaTemplateVariantSVGLayersTab: React.FC<
  InternAdMediaTemplateVariantSVGLayersTabProps
> = ({ svgContainerId, selectedElement, onElementSelect }) => {
  const [treeData, setTreeData] = useState<TreeData | null>(null);

  const parseSVG = useCallback(() => {
    const container = document.getElementById(svgContainerId);
    if (container) {
      const svgElement = container.querySelector("svg");
      if (svgElement) {
        const tree = getNodeDetails(svgElement as SVGElement);
        setTreeData(tree);
      }
    }
  }, [svgContainerId]);

  useEffect(() => {
    const container = document.getElementById(svgContainerId);

    if (container) {
      const observer = new MutationObserver(parseSVG);
      observer.observe(container, { childList: true, subtree: true });
      parseSVG();

      return () => {
        observer.disconnect();
      };
    }
  }, [svgContainerId, parseSVG]);

  return (
    <Flex direction="column" gap="0.5rem">
      {treeData && (
        <TreeNode
          node={treeData}
          onSelect={onElementSelect}
          selectedElement={selectedElement}
        />
      )}
    </Flex>
  );
};

export default InternAdMediaTemplateVariantSVGLayersTab;

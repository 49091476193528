import { EmailContextProvider } from "./email_editor/context/EmailEditorContext";
import React from "react";
import { EmailEditorComponents } from "~/components/editor/layout";

const EmailEditorPage = () => {
  return (
    <EmailContextProvider>
      <EmailEditorComponents.Layout>
        <EmailEditorComponents.TopBar />
        <EmailEditorComponents.Preview />
        <EmailEditorComponents.Aside />
      </EmailEditorComponents.Layout>
    </EmailContextProvider>
  );
};

export default EmailEditorPage;

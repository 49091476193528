import * as Form from "@radix-ui/react-form";
import { Avatar, Badge, Box, Flex, Text } from "@radix-ui/themes";
import React from "react";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import DraperButton from "~/components/core/buttons/DraperButton";

export interface CampaignWizardCardProps {
  children: React.ReactNode;
  badgeTitle: string;
  nextButtonProps: {
    icon?: React.ReactNode;
    onClick: () => void;
    text?: React.ReactNode | string;
    disabled?: boolean;
    validate?: boolean;
  };
  backButtonProps: {
    icon?: React.ReactNode;
    onClick: () => void;
    text?: string;
    hide?: boolean;
    disabled?: boolean;
  };
  additionalButtons?: React.ReactNode;
  hideFooter?: boolean;
}

const CampaignWizardCard = ({
  children,
  badgeTitle,
  nextButtonProps,
  backButtonProps,
  additionalButtons,
  hideFooter,
}: CampaignWizardCardProps) => {
  return (
    <Flex
      direction="column"
      maxWidth="800px"
      style={{
        border: "1px solid #DEDEDE",
        borderRadius: "24px",
        boxShadow: "0px 12px 120px 0px #00000014",
        color: "#5C5858",
      }}
    >
      <Badge
        variant="soft"
        style={{
          width: "max-content",
          margin: "40px auto",
          padding: "6px 12px 6px 12px",
          backgroundColor: "#F3EAE780",
          color: "#7c7b79",
          fontSize: "14px",
          border: "1px solid #0000000A",
          borderRadius: "16px",
        }}
      >
        {badgeTitle}
      </Badge>
      <Flex
        direction="row"
        gap="24px"
        style={{ padding: "0px 48px", marginBottom: "48px" }}
      >
        <MarkAvatar />
        <Box width="100%">{children}</Box>
      </Flex>
      {!hideFooter && (
        <Flex
          style={{
            padding: "24px",
            borderTop: "1px solid #0000001F",
          }}
          gap="16px"
          direction="row"
          justify="end"
        >
          {!backButtonProps.hide && (
            <DraperButton
              onClick={backButtonProps.onClick}
              text={backButtonProps.text ?? "Back"}
              icon={backButtonProps.icon}
              variant="light"
              disabled={backButtonProps.disabled}
            />
          )}
          {additionalButtons}
          {nextButtonProps.validate ? (
            <Form.Submit asChild>
              <DraperButton
                onClick={nextButtonProps.onClick}
                text={nextButtonProps.text ?? "Looks good"}
                variant="dark"
                size={"4"}
                disabled={nextButtonProps.disabled}
                icon={nextButtonProps.icon}
              />
            </Form.Submit>
          ) : (
            <DraperButton
              onClick={nextButtonProps.onClick}
              text={nextButtonProps.text ?? "Looks good"}
              variant="dark"
              size={"4"}
              disabled={nextButtonProps.disabled}
              icon={nextButtonProps.icon}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default CampaignWizardCard;

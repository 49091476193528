import { Flex, IconButton, Select, Text } from "@radix-ui/themes";
import { ArrowLeftIcon, DownloadIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DraperButton from "~/components/core/buttons/DraperButton";
import {
  AD_ZOOM_OPTIONS,
  AdEditorState,
  useAdEditorDispatch,
  useAdEditorState,
} from "~/contexts/AdEditorContext";

const FlexSide = styled(Flex)`
  flex: 1;
`;

const EditorTopBar = () => {
  const state = useAdEditorState();
  const adEditorDispatch = useAdEditorDispatch();
  const selectedAd =
    state.status === "loaded"
      ? state.availableAds[state.selectedAdIndex]
      : undefined;
  const isDisabled = state.status !== "loaded";
  const navigate = useNavigate();

  const handleAdChange = (adId: string) => {
    if (state.status !== "loaded") {
      return;
    }
    const index = state.availableAds.findIndex((ad) => ad.data.id === adId);
    if (index >= state.availableAds.length || index < 0) {
      console.warn("Ad not found");
      return;
    }

    adEditorDispatch({
      type: "SELECT_AD_INDEX",
      payload: index,
    });
  };

  const handleZoomChange = (zoom: string) => {
    const zoomNum = parseInt(zoom, 10);
    adEditorDispatch({
      type: "CHANGE_ZOOM",
      payload: zoomNum as AdEditorState["zoom"],
    });
  };

  const handlePreview = () => {
    // TODO:
    console.log("Preview");
  };

  const handleDownload = () => {
    // TODO:
    console.log("Download");
  };

  const handleSave = () => {
    // TODO:
    console.log("Save");
  };

  return (
    <Flex align="center" gap="4" width="100%" justify="between" py="3" px="4">
      <FlexSide align="center" gap="4" justify="start">
        <IconButton
          size="3"
          radius="large"
          variant="outline"
          color="gray"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftIcon />
        </IconButton>
        {state.status === "loaded" && (
          <Select.Root
            onValueChange={handleAdChange}
            value={selectedAd?.data.id ?? ""}
            size="3"
          >
            <Select.Trigger
              disabled={isDisabled}
              variant="ghost"
              color="gray"
            />
            <Select.Content highContrast>
              {state.availableAds?.map((ad) => (
                <Select.Item key={ad.data.id} value={ad.data.id}>
                  {ad.data.headline}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        )}
      </FlexSide>

      <Select.Root
        onValueChange={handleZoomChange}
        value={state.zoom.toString()}
        size="3"
      >
        <Select.Trigger
          disabled={isDisabled}
          variant="ghost"
          style={{
            border: "1px solid var(--border, #ddd7d7)",
            borderRadius: "12px",
          }}
        />
        <Select.Content highContrast>
          {AD_ZOOM_OPTIONS.map((zoom) => (
            <Select.Item key={zoom} value={zoom.toString()}>
              {zoom}%
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <FlexSide align="center" gap="4" justify="end">
        <IconButton
          size="3"
          radius="large"
          variant="outline"
          color="gray"
          onClick={handleDownload}
        >
          <DownloadIcon />
        </IconButton>
        <DraperButton
          text="Preview"
          variant="outlined"
          disabled={isDisabled}
          onClick={handlePreview}
        />
        <DraperButton
          text="Save"
          variant="dark"
          disabled={isDisabled}
          onClick={handleSave}
        />
      </FlexSide>
    </Flex>
  );
};

export default EditorTopBar;

import PlusButton from "../../../audience/common/PlusButton";
import Paginator from "../paginator/Paginator";
import CategorySelector from "./components/CategorySelector";
import ProductsTable from "./components/ProductsTable";
import SearchField from "./components/SearchField";
import useGetFilteredProducts from "./hooks/useGetFilteredProducts";
import useGetCategories from "./hooks/useGetProductCategories";
import { useProductsFilters } from "./hooks/useProductsFilters";
import { Dialog, Flex, IconButton, Text } from "@radix-ui/themes";
import { X } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import DraperButton from "~/components/core/buttons/DraperButton";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

const ITEMS_PER_PAGE = 9;
export const MAX_FEATURED_PRODUCTS = 6;

const ProductSelector = ({
  selectedProducts,
  setSelectedProducts,
  onAddProducts,
  onFeatureToggle,
}: {
  selectedProducts: Map<string, MaybeFeaturedProduct>;
  setSelectedProducts: Dispatch<
    SetStateAction<Map<string, MaybeFeaturedProduct>>
  >;
  onAddProducts: (products: Map<string, MaybeFeaturedProduct>) => void;
  onFeatureToggle: (product: MaybeFeaturedProduct) => void;
}) => {
  const { filters, setters, sorters } = useProductsFilters();

  const {
    products: filteredProducts,
    hasNextPage,
    hasPreviousPage,
  } = useGetFilteredProducts(ITEMS_PER_PAGE, filters, sorters);
  const categories = useGetCategories();
  const selectProduct = (product: MaybeFeaturedProduct) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(product.product_id, product);
      return updatedMap;
    });
  };
  const deselectProduct = (product: MaybeFeaturedProduct) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(product.product_id);
      return updatedMap;
    });
  };

  const header = (
    <Flex direction="row" justify="between" align="center">
      <Text size="4" weight="bold">
        Add Products
      </Text>
      <Dialog.Close>
        <IconButton
          style={{
            borderRadius: "8px",
            cursor: "pointer",
          }}
          color="gray"
          variant="outline"
        >
          <X color="#000000A6" />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );
  const content = (
    <Flex direction="column">
      <Flex justify="between" align="center">
        <SearchField
          currentText={filters.name}
          onTextChange={setters.setName}
        />
        <CategorySelector
          categories={categories}
          selectedCategoryId={filters.categoryId}
          setCategoryId={setters.setCategoryId}
        />
      </Flex>
      {filteredProducts && (
        <ProductsTable
          selectedProducts={selectedProducts}
          products={filteredProducts.map((product) => ({
            ...product,
            is_featured:
              selectedProducts.get(product.product_id)?.is_featured ?? false,
          }))}
          selectProduct={selectProduct}
          deselectProduct={deselectProduct}
          setNameSort={setters.setNameSort}
          nameSort={sorters.nameSort}
          onFeatureToggle={onFeatureToggle}
        />
      )}
    </Flex>
  );
  const footer = (
    <Flex direction="row" justify="between" align="center">
      <Paginator
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        currentPage={filters.page}
        updatePage={setters.updatePage}
      />
      <Flex gap="3">
        <Dialog.Close>
          <DraperButton text={"Cancel"} variant="light"></DraperButton>
        </Dialog.Close>
        <Dialog.Close>
          <DraperButton
            onClick={() => onAddProducts(selectedProducts)}
            text={"Add"}
            variant="dark"
          />
        </Dialog.Close>
      </Flex>
    </Flex>
  );
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <PlusButton
          text="Add product(s)"
          customRadius="large"
          customStyles={{
            paddingTop: "12px",
            paddingBottom: "12px",
            height: "fit-content",
          }}
        />
      </Dialog.Trigger>
      <Dialog.Content style={{ padding: "14px", width: "50%" }}>
        <Flex direction="column" gap="4">
          {header}
          <div
            style={{ borderBottom: "1px solid #DDD7D7", margin: "0px -14px" }}
          ></div>
          {content}
          {footer}
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ProductSelector;

import { components, EmailSectionWidthType, operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  getBrandEmailSectionsQueryKey,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";
import {
  EmailSectionBackgroundImageState,
  EmailSectionImageElementState,
  EmailTemplateStateCommon,
  isFileType,
} from "~/types/template-editor";

type UpdateBrandEmailSectionsRequestData =
  operations["emails_api_update_brand_email_sections"]["requestBody"]["content"]["multipart/form-data"];

const getStateImageRequestData = (
  state:
    | EmailSectionBackgroundImageState
    | EmailSectionImageElementState
    | undefined
):
  | Pick<
      components["schemas"]["UpsertEmailBackgroundPropertyRequestData"],
      "image"
    >
  | undefined => {
  if (!state || isFileType(state)) {
    return undefined;
  }
  const image_url = "image_url" in state ? state.image_url : state.image;
  if (!image_url) {
    return undefined;
  }

  return {
    image: {
      url: image_url,
    },
  };
};

const getStateBackgroundRequestData = (
  state: EmailTemplateStateCommon["colors"][number]
): components["schemas"]["UpdateBrandEmailHeaderSectionRequestData"]["background"] => {
  return {
    color: state.background,
    image: getStateImageRequestData(state.backgroundImage)?.image,
  };
};

const getStateMenuLinksRequestData = (
  state: EmailTemplateStateCommon["menuLinks"]
): components["schemas"]["UpdateBrandEmailHeaderSectionRequestData"]["menu_links"] => {
  if (!state.enabled) {
    return [];
  }
  return state.links;
};

const useUpdateBrandEmailSections = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const state = useTemplateEditorState();
  const updateBrandEmailSections = useMutation<
    void,
    AxiosError,
    UpdateBrandEmailSectionsRequestData
  >({
    mutationFn: async (params) => {
      const { data } = await axios.post(
        `/api/v1/emails/update-brand-email-sections`,
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getBrandEmailSectionsQueryKey(activeBrandID),
        exact: true,
      });
      onSuccess?.();
    },
    onError: (error) => {
      console.log("Brand email sections update failed:", error);
      const errorMessage =
        error.status != 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });

  return {
    ...updateBrandEmailSections,
    mutate: () => {
      if (!state.headerOptions.logo) {
        onError?.("Please upload a logo");
        return;
      }

      const hasLogoUpload =
        !state.headerOptions.customHTML.enabled &&
        isFileType(state.headerOptions.logo);
      const hasHeaderBackgroundUpload =
        !state.headerOptions.customHTML.enabled &&
        state.headerOptions.colors[0].backgroundImage &&
        isFileType(state.headerOptions.colors[0].backgroundImage);

      const hasFooterBackground1Upload =
        !state.footerOptions.customHTML.enabled &&
        state.footerOptions.colors[0].backgroundImage &&
        isFileType(state.footerOptions.colors[0].backgroundImage);
      const hasFooterBackground2Upload =
        !state.footerOptions.customHTML.enabled &&
        state.footerOptions.colors[1].backgroundImage &&
        isFileType(state.footerOptions.colors[1].backgroundImage);

      const json_data: UpdateBrandEmailSectionsRequestData["request_data"] = {
        header: state.headerOptions.customHTML.enabled
          ? {
              id: state.headerOptions.id,
              custom_html: state.headerOptions.customHTML.html,
            }
          : {
              id: state.headerOptions.id,
              template_id:
                state.headerOptions.availableTemplates[
                  state.headerOptions.selectedTemplateIndex
                ].id,
              width_type: state.headerOptions.widthType,
              logo: getStateImageRequestData(state.headerOptions.logo)?.image,
              background: getStateBackgroundRequestData(
                state.headerOptions.colors[0]
              ),
              color: state.headerOptions.colors[0].foreground,
              menu_links: getStateMenuLinksRequestData(
                state.headerOptions.menuLinks
              ),
              tagline: state.headerOptions.tagline.enabled
                ? state.headerOptions.tagline.text
                : undefined,
            },
        footer: state.footerOptions.customHTML.enabled
          ? {
              id: state.footerOptions.id,
              custom_html: state.footerOptions.customHTML.html,
            }
          : {
              id: state.footerOptions.id,
              template_id:
                state.footerOptions.availableTemplates[
                  state.footerOptions.selectedTemplateIndex
                ].id,
              width_type: state.footerOptions.widthType,
              background_1: getStateBackgroundRequestData(
                state.footerOptions.colors[0]
              ),
              color_1: state.footerOptions.colors[0].foreground,
              background_2: getStateBackgroundRequestData(
                state.footerOptions.colors[1]
              ),
              color_2: state.footerOptions.colors[1].foreground,
              menu_links: getStateMenuLinksRequestData(
                state.footerOptions.menuLinks
              ),
              show_social_links: state.footerOptions.socialLinks.enabled,
              facebook_link: state.footerOptions.socialLinks.facebook,
              instagram_link: state.footerOptions.socialLinks.instagram,
              twitter_link: state.footerOptions.socialLinks.twitter,
              youtube_link: state.footerOptions.socialLinks.youtube,
              tiktok_link: state.footerOptions.socialLinks.tiktok,
            },
      };
      updateBrandEmailSections.mutate({
        header_logo: hasLogoUpload
          ? (state.headerOptions.logo as unknown as string)
          : undefined,
        header_background: hasHeaderBackgroundUpload
          ? (state.headerOptions.colors[0].backgroundImage as unknown as string)
          : undefined,
        footer_background_1: hasFooterBackground1Upload
          ? (state.footerOptions.colors[0].backgroundImage as unknown as string)
          : undefined,
        footer_background_2: hasFooterBackground2Upload
          ? (state.footerOptions.colors[1].backgroundImage as unknown as string)
          : undefined,
        request_data: JSON.stringify(
          json_data
        ) as unknown as UpdateBrandEmailSectionsRequestData["request_data"],
      });
    },
  };
};

export default useUpdateBrandEmailSections;

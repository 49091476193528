import StyleLibraryCardContent from "../StyleLibraryCardContent";
import EmailSectionPreview from "./preview/EmailSectionPreview";
import { Button, Flex, Text } from "@radix-ui/themes";
import Handlebars from "handlebars";
import { PencilIcon } from "lucide-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import { useTemplateEditorState } from "~/contexts/TemplateEditorContext";
import {
  getEmailFooterSectionHandlebarsFromState,
  getEmailHeaderSectionHandlebarsFromState,
} from "~/types/template-vars";

const TemplateCard = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  padding: 16px;
  font-size: 14px;
`;

interface Template {
  html: JSX.Element;
  id: string;
  label: string;
}

const CampaignTemplateCardSection = () => {
  const { isLoading: styleIsLoading } = useBrandStyle();
  const { headerOptions, footerOptions, brandData } = useTemplateEditorState();
  const isLoading =
    styleIsLoading ||
    headerOptions.availableTemplates.length === 0 ||
    footerOptions.availableTemplates.length === 0;

  const headerTemplate = useMemo(() => {
    const template = headerOptions.availableTemplates[
      headerOptions.selectedTemplateIndex
    ] ?? {
      templateHtml: "<p>Loading...</p>",
    };
    return Handlebars.compile(
      headerOptions.customHTML.enabled
        ? headerOptions.customHTML.html
        : template.templateHtml
    );
  }, [headerOptions]);

  const footerTemplate = useMemo(() => {
    const template = footerOptions.availableTemplates[
      footerOptions.selectedTemplateIndex
    ] ?? {
      templateHtml: "<p>Loading...</p>",
    };
    return Handlebars.compile(
      footerOptions.customHTML.enabled
        ? footerOptions.customHTML.html
        : template.templateHtml
    );
  }, [footerOptions]);

  const templates: Template[] = useMemo(() => {
    if (isLoading) return [];
    const headerHandleBars =
      getEmailHeaderSectionHandlebarsFromState(headerOptions);
    const footerHandleBars = getEmailFooterSectionHandlebarsFromState(
      footerOptions,
      brandData
    );
    return [
      {
        id: headerOptions.id,
        label: "Header",
        html: (
          <EmailSectionPreview
            template={headerTemplate}
            vars={headerHandleBars}
          />
        ),
      },
      {
        id: footerOptions.id,
        label: "Footer",
        html: (
          <EmailSectionPreview
            template={footerTemplate}
            vars={footerHandleBars}
          />
        ),
      },
    ];
  }, [headerOptions, footerOptions, brandData, isLoading]);

  return (
    <StyleLibraryCardContent
      actions={
        <Link to="/style-library/template-editor">
          <Button variant="ghost">
            <PencilIcon width={20} height={20} strokeWidth={1.25} /> Edit
          </Button>
        </Link>
      }
      title="Campaign Template"
      isLoading={isLoading}
    >
      {templates.map((template) => (
        <TemplateCard direction="column" key={template.id}>
          <Text>{template.label}</Text>
          {template.html}
        </TemplateCard>
      ))}
    </StyleLibraryCardContent>
  );
};

export default CampaignTemplateCardSection;

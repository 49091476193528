import { CampaignState } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import styled from "styled-components";
import { capitalizeFirstLetter } from "~/utils/helpers";

const StatusPill = styled(Text)<{ isActive: boolean }>`
  padding: 8px 12px;
  border-radius: 120px;
  background-color: ${({ isActive }) => (isActive ? "#F3E6D3" : "transparent")};
  color: var(--text-secondary);
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.4s;
`;

const StatusFilters = ({
  selectedStatus,
  setSelectedStatus,
}: {
  selectedStatus: CampaignState | null | undefined;
  setSelectedStatus: (status: CampaignState | null | undefined) => void;
}) => {
  return (
    <Flex gap="12px">
      {Object.values(CampaignState).map((status) => (
        <StatusPill
          key={status}
          isActive={selectedStatus === status}
          onClick={() => setSelectedStatus(status)}
        >
          {capitalizeFirstLetter(status)}
        </StatusPill>
      ))}
    </Flex>
  );
};

export default StatusFilters;

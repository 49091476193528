import {
  AdMediaElementUnionSchema,
  AdMediaImageElementSchema,
} from "../../types/ads";
import React, { createContext, useContext, useState, ReactNode } from "react";

type AdMediaUpdateableImageElement = AdMediaImageElementSchema & {
  uploadedFile?: File;
};

export type AdMediaUpdateableElement =
  | Exclude<AdMediaElementUnionSchema, AdMediaImageElementSchema>
  | AdMediaUpdateableImageElement;

interface AdMediaContextType {
  elements: AdMediaUpdateableElement[];
  setElements: React.Dispatch<React.SetStateAction<AdMediaUpdateableElement[]>>;
  updateElement: (updatedElement: AdMediaUpdateableElement) => void;
}

const AdMediaContext = createContext<AdMediaContextType | undefined>(undefined);

export const AdMediaContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [elements, setElements] = useState<AdMediaUpdateableElement[]>([]);

  const updateElement = (updatedElement: AdMediaUpdateableElement) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  };

  return (
    <AdMediaContext.Provider value={{ elements, setElements, updateElement }}>
      {children}
    </AdMediaContext.Provider>
  );
};

export const useAdMediaContext = () => {
  const context = useContext(AdMediaContext);

  if (context === undefined) {
    throw new Error("useAdMediaContext must be used within an AdMediaProvider");
  }
  return context;
};

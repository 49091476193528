import { Flex, Switch, Text, TextField } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";
import DropdownSelect from "~/components/core/inputs/DropdownSelect";

const SectionTitle = styled(Text)`
  color: var(--text-secondary);
`;

const LinkToModifier = ({
  options,
  hideTextField = false,
}: {
  options: {
    label: string;
    value: string;
  }[];
  hideTextField?: boolean;
}) => {
  return (
    <Flex direction="column" gap="16px">
      <SectionTitle weight="medium">Link to</SectionTitle>
      <DropdownSelect defaultValue={options[0].value} options={options} />
      {!hideTextField && (
        <TextField.Root
          size="3"
          radius="large"
          color="gray"
          placeholder="https://example.com"
        />
      )}
      <Flex gap="16px" align="center">
        <Text size="2">Open in new tab</Text>
        <Switch color="gray" highContrast defaultChecked />
      </Flex>
    </Flex>
  );
};

export default LinkToModifier;

import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import {
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import { CampaignType, operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { WandSparklesIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationCreativeMessagesQuery, {
  CampaignRecommendationCreativeMessagesResponse,
} from "~/hooks/campaign/useCampaignCreativeRecommendationMessagesQuery";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useCenterCampaignCard from "~/hooks/campaign/useCenterCampaignCard";
import useCreateCampaignMutation from "~/hooks/campaign/useCreateCampaignMutation";
import nullthrows from "~/utils/nullthrows";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type GenCreactiveRecommendationsResponse =
  operations["recommender_api_gen_creative_recommendations"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationsRequestData =
  operations["recommender_api_gen_creative_recommendations"]["requestBody"]["content"]["application/json"];

type GenCreactiveRecommendationsFromCRResponse =
  operations["recommender_api_gen_creative_recommendation_from_campaign_recommendation"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationsFromCRRequestData =
  operations["recommender_api_gen_creative_recommendation_from_campaign_recommendation"]["requestBody"]["content"]["application/json"];

type GenCreactiveRecommendationUpdateResponse =
  operations["recommender_api_gen_creative_recommendation_update"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationUpdateRequestData =
  operations["recommender_api_gen_creative_recommendation_update"]["requestBody"]["content"]["application/json"];

const useCampaignCreativesProps = () => {
  const { centerCurrentCard } = useCenterCampaignCard();
  const { campaignData, wizardType } = useCampaignWizardState();
  const campaignType = campaignData?.campaign_type;
  const description =
    "description_input" in campaignData
      ? campaignData.description_input
      : undefined;

  const activeBrandID = useActiveBrandID();
  const dispatch = useCampaignWizardDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<null | string>(null);
  const [prompt, setPrompt] = useState("");
  const newMutationRefDone = useRef<boolean>(false);
  const [creatives, setCreatives] =
    useState<GenCreactiveRecommendationsResponse>([]);
  const activeMessageRef = useRef<HTMLDivElement | null>(null);
  const { recommendationID } = useParams();
  const { campaignRecommendation, isSuccess } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );

  const { createCampaign, isLoading: isCreatingCampaign } =
    useCreateCampaignMutation({
      onSuccess: (data) => {
        navigate(`/campaign/${data.id}`);
      },
      onError: (error) => {
        console.error(error);
      },
    });

  const {
    mutate: genCreactiveRecommendations,
    isPending: isGeneratingCreativeRecommendations,
  } = useDraperApiPostMutation<
    GenCreactiveRecommendationsResponse,
    GenCreactiveRecommendationsRequestData
  >({
    mutationKey: ["/recommender/creative-recommendations"],
    path: "/recommender/creative-recommendations",
    onError: (error) => {
      // TODO
    },
    onMutate: () => {
      newMutationRefDone.current = true;
    },
    onSuccess: (data) => {
      setCreatives(data);
    },
  });

  const {
    mutate: genCreactiveRecommendationsFromCR,
    isPending: isCreativeReommendationsFromCRPending,
  } = useDraperApiPostMutation<
    GenCreactiveRecommendationsFromCRResponse,
    GenCreactiveRecommendationsFromCRRequestData
  >({
    mutationKey: [
      "/recommender/creative-recommendations-from-campaign-recommendation",
    ],
    path: "/recommender/creative-recommendations-from-campaign-recommendation",
    onError: (error) => {
      // TODO
    },
    onSuccess: (data) => {
      setCreatives(data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "recommender-campaign-recommendation",
          activeBrandID,
          recommendationID,
        ],
      });
    },
  });
  const queryClient = useQueryClient();

  const {
    mutate: genCreactiveRecommendationUpdate,
    isPending: isCreativeUpdating,
    isSuccess: isCreativeUpdateSuccess,
  } = useDraperApiPostMutation<
    GenCreactiveRecommendationUpdateResponse,
    GenCreactiveRecommendationUpdateRequestData
  >({
    mutationKey: ["/recommender/creative-recommendations/update"],
    path: "/recommender/creative-recommendations/update",
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          creatives[0]?.id,
        ],
        context.previousCreative
      );
    },
    onSuccess: (data) => {
      if (data.message?.next_recommendation) {
        setSelectedOption(data.message.next_recommendation?.id ?? null);
      }
    },
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          creatives[0]?.id,
        ],
      });

      const previousCreative:
        | CampaignRecommendationCreativeMessagesResponse
        | undefined =
        queryClient.getQueryData([
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          creatives[0]?.id ?? "",
        ]) ?? [];

      console.log(previousCreative, newMessage);

      // Optimistically update to the new message
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          creatives[0]?.id,
        ],
        [
          ...previousCreative,
          {
            message: {
              message: prompt,
              next_recommendation: null,
            },
          },
        ]
      );

      return { previousCreative };
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          creatives[0]?.id,
        ],
      });
    },
  });

  const genCreatives = useCallback(() => {
    if (!campaignType || !description) {
      console.error("Missing campaign type or description");
      return;
    }
    return genCreactiveRecommendations({
      brand_id: activeBrandID,
      campaign_type: campaignType,
      description: description,
      commerce_platform_item_ids: (campaignData?.products ?? []).map(
        (product) => product.commerce_platform_id
      ),
      discount_amount: campaignData?.discount?.amount,
    });
  }, [
    activeBrandID,
    campaignType,
    description,
    campaignData,
    genCreactiveRecommendations,
  ]);

  const { campaignRecommendationCreativeMessages } =
    useCampaignRecommendationCreativeMessagesQuery(
      activeBrandID,
      creatives[0]?.id
    );

  useEffect(() => {
    if (
      wizardType === "NEW" &&
      creatives.length === 0 &&
      !newMutationRefDone.current
    ) {
      console.log("GEN CREATIVES", wizardType);
      genCreatives();
    }
  }, [wizardType, creatives, genCreatives]);

  useEffect(() => {
    if (isSuccess && campaignRecommendation) {
      // Only gets here for Recommended campaigns
      if (campaignRecommendation.creative_recommendations.length === 0) {
        console.log("GEN CREATIVES FROM CR");
        genCreactiveRecommendationsFromCR({
          campaign_recommendation_id: nullthrows(recommendationID),
          brand_id: activeBrandID,
        });
      } else {
        setCreatives([campaignRecommendation.creative_recommendations[0]]);
      }
    }
  }, [campaignRecommendation, isSuccess, activeBrandID, recommendationID]);

  useEffect(() => {
    if (selectedOption) {
      dispatch({
        type: "UPDATE_CAMPAIGN_DATA",
        payload: { creative_id: nullthrows(selectedOption) },
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    isCreativeUpdateSuccess && centerCurrentCard();
  }, [centerCurrentCard, isCreativeUpdateSuccess]);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children">>(
    () => ({
      badgeTitle: "Creative Concept",
      backButtonProps: {
        onClick: () => {
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: {
              currentStep:
                wizardType === "NEW"
                  ? NewCampaignWizardSteps.PROMOTION
                  : RecommendedCampaignWizardSteps.DESTINATION,
            },
          });
        },
      },
      nextButtonProps: {
        icon: <WandSparklesIcon />,
        text: "Generate Campaign Graphics",
        onClick: () => {
          if (wizardType === "NEW") {
            createCampaign({
              asset_ids: campaignData.asset_ids ?? [],
              audiences: campaignData.audiences ?? [],
              channels: campaignData.channels ?? {
                emails: 0,
                sms: 0,
                meta_ads: 0,
                push_notifications: 0,
              },
              products: campaignData.products ?? [],
              destination: campaignData.destination ?? null,
              creative_id: nullthrows(selectedOption),
              brand_id: activeBrandID,
              campaign_type: campaignData.campaign_type ?? CampaignType.other,
              description_input: nullthrows(description),
              discount: campaignData.discount ?? null,
            });
          } else if (wizardType === "RECOMMENDED") {
            createCampaign({
              asset_ids: campaignData.asset_ids ?? [],
              audiences: campaignData.audiences ?? [],
              channels: campaignData.channels ?? {
                emails: 0,
                sms: 0,
                meta_ads: 0,
                push_notifications: 0,
              },
              products: campaignData.products ?? [],
              destination: campaignData.destination ?? null,
              brand_id: activeBrandID,
              creative_id: nullthrows(selectedOption),
              campaign_type: campaignData.campaign_type ?? CampaignType.other,
              campaign_recommendation_id: nullthrows(recommendationID),
              discount: campaignData.discount ?? null,
            });
          }
        },
        disabled:
          !selectedOption ||
          isGeneratingCreativeRecommendations ||
          isCreatingCampaign,
      },
    }),
    [
      dispatch,
      selectedOption,
      navigate,
      wizardType,
      isGeneratingCreativeRecommendations,
    ]
  );

  return {
    cardProps,
    setSelectedOption,
    selectedOption,
    creatives,
    isGenerating:
      isGeneratingCreativeRecommendations ||
      isCreativeReommendationsFromCRPending,
    isCreativeUpdating,
    onSendPrompt: () => {
      genCreactiveRecommendationUpdate({
        brand_id: activeBrandID,
        creative_recommendation_id: creatives[0].id,
        prompt: prompt,
      });
      setPrompt("");
    },
    onGenerateClick: () => {
      if (wizardType === "NEW") {
        genCreatives();
      } else {
        genCreactiveRecommendationsFromCR({
          campaign_recommendation_id: nullthrows(recommendationID),
          brand_id: activeBrandID,
        });
      }
    },
    prompt,
    setPrompt,
    campaignRecommendationCreativeMessages,
    activeMessageRef,
  };
};

export default useCampaignCreativesProps;

import ChatMessageContainer from "../chat/ChatMessageContainer";
import DraperText from "../core/DraperText";
import BrandButton from "../core/buttons/BrandButton";
import DraperButton from "../core/buttons/DraperButton";
import { Text, Flex, Grid, Skeleton, Button, Box } from "@radix-ui/themes";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import {
  useActiveBrandID,
  useAuthenticatedUserState,
} from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";
import useBrandUpdateMutation from "~/hooks/brand/useBrandUpdateMutation";
import { HOME_ROUTE } from "~/routes/constants";

const GridRoot = styled(Grid)`
  box-shadow: 0px 12px 120px 0px #00000014;
  background: #ffffff;
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  box-sizing: border-box;
`;

enum GridStep {
  LOGO = 0,
  COLOR_PALETTE = 1,
  TYPOGRAPHY = 2,
  BUTTON = 3,
  FIREWORKS = 4,
}

const LogoLoading = () => {
  return <Skeleton height={"64px"} width={"128px"} />;
};

const ColorLoading = () => {
  return (
    <Flex gap="4">
      <Skeleton height={"48px"} width={"48px"} loading={true} />
      <Skeleton height={"48px"} width={"48px"} loading={true} />
      <Skeleton height={"48px"} width={"48px"} loading={true} />
    </Flex>
  );
};

const TypographyLoading = () => {
  return (
    <Flex gap="4" direction={"column"} align={"start"}>
      <Skeleton height={"20px"} width={"80px"} loading={true} />
      <Skeleton height={"10px"} width={"240px"} loading={true} />
      <Skeleton height={"10px"} width={"240px"} loading={true} />
    </Flex>
  );
};

const ButtonLoading = () => {
  return (
    <Flex gap="4" direction={"row"} align={"center"}>
      <Button disabled={true}>Button</Button>
      <Button disabled={true}>Button</Button>
    </Flex>
  );
};

const GridChild = ({
  type,
  isActive,
}: {
  type: GridStep;
  isActive: boolean;
}) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const { data } = useBrandStyle();
  const { brandData, isLoading, isSuccess } = useBrandQuery(activeBrandID);

  let style = {};
  let loading = null;
  let text = null;
  let content = null;

  switch (type) {
    case GridStep.LOGO:
      style = {
        borderBottom: "1px solid #ddd7d7",
      };
      loading = <LogoLoading />;
      text = "Logo";
      if (data?.logos?.[0]?.url) {
        content = (
          <div
            style={{
              backgroundImage: `url(${data.logos[0].url})`,
              width: "60%",
              height: "60%",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "var(--radius-3)",
              alignContent: "center",
              overflow: "hidden",
            }}
          />
        );
      }
      break;
    case GridStep.COLOR_PALETTE:
      style = {
        borderBottom: "1px solid #ddd7d7",
        borderLeft: "1px solid #ddd7d7",
      };
      text = "Colors";
      loading = <ColorLoading />;
      if (data?.palettes?.[0]) {
        content = (
          <Grid columns={"3"} rows={"2"} gap={"4"}>
            {Object.entries(data.palettes[0]).map(([key, value]) => {
              if (!value) return null;
              return (
                <Box
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "6px",
                    border: "2px solid #00000014",
                    backgroundColor: value,
                  }}
                />
              );
            })}
          </Grid>
        );
      }
      break;
    case GridStep.TYPOGRAPHY:
      style = {};
      text = "Typography";
      loading = <TypographyLoading />;
      if (data?.typography) {
        content = (
          <Flex direction={"column"} gap="4" p="2">
            <DraperText
              clamp={1}
              size={"6"}
              style={{
                fontFamily: data.typography.header_font_family?.name,
                fontWeight: data.typography.header_font_family?.font_weight,
                color: "var(--text-secondary)",
              }}
            >
              {brandData?.name}
            </DraperText>
            <DraperText
              clamp={3}
              size={"3"}
              style={{
                fontFamily: data.typography.paragraph_font_family?.name,
                fontWeight: data.typography.paragraph_font_family?.font_weight,
                color: "var(--text-secondary)",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </DraperText>
          </Flex>
        );
      }
      break;
    case GridStep.BUTTON:
      style = {
        borderLeft: "1px solid #ddd7d7",
      };
      text = "Buttons";
      loading = <ButtonLoading />;
      if (data?.button_styles && data.button_styles.length > 0) {
        content = (
          <Grid
            columns={data.button_styles.length === 1 ? "1" : "2"}
            gap={"4"}
            align={"center"}
            justify={"center"}
          >
            {data.button_styles.slice(0, 4).map((style) => {
              return (
                <BrandButton
                  brandButtonStyle={style}
                  style={{
                    margin: "4px",
                    textAlign: "center",
                  }}
                  key={style.id}
                >
                  Style
                </BrandButton>
              );
            })}
          </Grid>
        );
      }
      break;
    default:
      break;
  }

  const checkOrX =
    content === null && brandData ? (
      <X color="red" size={20} />
    ) : content !== null && brandData ? (
      <Check color="green" size={20} />
    ) : null;

  return (
    <Flex
      align={"center"}
      justify={"center"}
      gap="4"
      width={"380px"}
      height={"250px"}
      style={style}
      position={"relative"}
      p={"4"}
    >
      <Text
        size={"1"}
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
        }}
      >
        {text}
      </Text>
      {isActive && (
        <Text
          size={"1"}
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
        >
          {checkOrX}
        </Text>
      )}
      {isActive && content ? content : loading}
    </Flex>
  );
};

const OnboardingStyleLibraryLoading = ({ onNext }: { onNext: () => void }) => {
  const [activeStep, setActiveStep] = useState(GridStep.LOGO);
  const [isExploding, setIsExploding] = useState(false);
  const { data, isLoading } = useBrandStyle();
  const navigate = useNavigate();

  const activeBrandID = useActiveBrandID();
  const { updateBrand, isLoading: isUpdatingBrand } = useBrandUpdateMutation({
    brandID: activeBrandID,
    onSuccess: () => {
      localStorage.removeItem("draper_onboarding_step");
      navigate(HOME_ROUTE);
    },
  });

  const logo = data?.logos?.[0]?.url ?? null;
  const colors = data?.palettes ?? [];
  const typography = data?.typography ?? null;
  const buttons = data?.button_styles ?? [];

  const isStylingComplete =
    logo !== null &&
    colors.length > 0 &&
    typography !== null &&
    buttons.length > 0;
  const isLastStep = activeStep === GridStep.FIREWORKS;

  // set 1 second interval
  useEffect(() => {
    if (isLoading) return;
    const interval = setInterval(() => {
      if (activeStep + 1 <= GridStep.FIREWORKS) {
        setActiveStep(activeStep + 1);
      } else {
        clearInterval(interval);
        if (isStylingComplete) setIsExploding(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [activeStep, isStylingComplete, isLoading]);

  let actionButtons = null;
  if (isLastStep) {
    if (isStylingComplete) {
      actionButtons = (
        <Flex direction={"row"} gap="4" align={"center"} justify={"end"}>
          <DraperButton
            style={{
              background: "white",
            }}
            variant="outlined"
            text="Edit Assets"
            onClick={onNext}
          />
          <DraperButton
            variant="dark"
            text="Finish"
            onClick={() => {
              updateBrand({
                is_onboarded: true,
              });
            }}
            disabled={isUpdatingBrand}
            loading={isUpdatingBrand}
          />
        </Flex>
      );
    } else {
      actionButtons = (
        <Flex direction={"row"} gap="4" align={"center"} justify={"end"}>
          <DraperButton
            variant="dark"
            text="Resolve Missing Assets"
            onClick={onNext}
          />
        </Flex>
      );
    }
  }

  let chatMessageText = "Getting your brand library ready...";
  if (isLastStep) {
    if (isStylingComplete) {
      chatMessageText =
        "Looks like everything is in order. You can edit your stylebook later if you need to.";
    } else {
      chatMessageText =
        "We couldn't get all the assets. Let's fix the missing ones.";
    }
  }
  return (
    <>
      <Flex
        position={"fixed"}
        direction={"column"}
        justify={"center"}
        align={"center"}
        width={"100vw"}
        height={"100vh"}
      >
        {isExploding && (
          <ConfettiExplosion
            duration={3000}
            force={0.8}
            width={1600}
            particleCount={250}
            zIndex={1}
            onComplete={() => setIsExploding(false)}
          />
        )}
      </Flex>
      <Flex
        direction={"column"}
        justify={"center"}
        align={"center"}
        gap="6"
        style={{
          zIndex: 1000,
        }}
      >
        <ChatMessageContainer message={chatMessageText} />
        {actionButtons}

        <GridRoot columns={"2"} rows={"2"}>
          {[
            GridStep.LOGO,
            GridStep.COLOR_PALETTE,
            GridStep.TYPOGRAPHY,
            GridStep.BUTTON,
          ].map((type) => (
            <GridChild type={type} isActive={type < activeStep} key={type} />
          ))}
        </GridRoot>
      </Flex>
    </>
  );
};

export default OnboardingStyleLibraryLoading;

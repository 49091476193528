import ChatMessageContainer from "./ChatMessageContainer";
import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

const Root = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  width: 500px;
  height: 100%;
  padding: 16px;
`;

const ChatContainer = () => {
  return (
    <Root direction={"column"} gap="2">
      <ChatMessageContainer message="You have 2 emails to schedule, or you can send them to Klaviyo as drafts." />
      <ChatMessageContainer message="Should we schedule them as well ? or move on to Meta ads?" />
    </Root>
  );
};

export default ChatContainer;

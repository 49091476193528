import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CampaignWizardCardContent from "../../../components/campaign/wizard/cards/CampaignWizardCardContent";
import useCampaignDetailsProps from "./hooks/useCampaignDetailsProps";
import { CampaignType } from "@openapi";
import { Button, Flex, Text, TextArea } from "@radix-ui/themes";
import { PencilIcon } from "lucide-react";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";

const CampaignDetails = () => {
  const {
    campaignData: { campaign_type },
  } = useCampaignWizardState();
  const { cardProps, inputProps } = useCampaignDetailsProps();

  const text =
    campaign_type === CampaignType.other
      ? ""
      : campaign_type === CampaignType.sales_event
      ? "Sales Event"
      : campaign_type === CampaignType.inventory_clearance
      ? "Inventory Clearance"
      : campaign_type === CampaignType.new_products
      ? "New Products"
      : "";

  return (
    <CampaignWizardCard {...cardProps}>
      <CampaignWizardCardContent>
        <Text size="5">
          {`Tell us in a few words what this ${text} campaign is about`}
        </Text>
        {inputProps.isEditing ? (
          <Flex direction="column" gap="8px">
            <TextArea
              placeholder="Give a brief description of your campaign"
              radius="large"
              size="3"
              color="gray"
              value={inputProps.value}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  inputProps.onChange(e);
                }
              }}
              style={{ width: "100%", height: "136px" }}
              maxLength={30}
            />
            <Text size="2" style={{ alignSelf: "flex-end", color: "#666" }}>
              {30 - (inputProps.value?.length || 0)} characters left
            </Text>
          </Flex>
        ) : (
          <Flex direction="column" gap="24px" align="end" width="100%">
            <Text
              style={{
                backgroundColor: "#F0EDEB",
                padding: "12px 16px",
                borderRadius: "24px",
                borderBottomRightRadius: "0px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "608px",
              }}
            >
              {inputProps.value}
            </Text>
            <Button
              onClick={() => inputProps.setIsEditing(true)}
              style={{ width: "max-content" }}
              variant="outline"
              color="gray"
              size="4"
              radius="large"
            >
              <PencilIcon /> Edit
            </Button>
          </Flex>
        )}
      </CampaignWizardCardContent>
    </CampaignWizardCard>
  );
};

export default CampaignDetails;

import CurrentUser from "../auth/CurrentUser";
import { Box, Flex } from "@radix-ui/themes";

export default function SidePane({
  color,
  header,
  navigation,
}: {
  color?: string;
  header: React.ReactNode;
  navigation: React.ReactNode;
}) {
  return (
    <Flex
      direction="column"
      style={{
        overflow: "scroll",
        position: "fixed",
        top: "15px",
        left: "15px",
        height: "85vh",
        padding: "1.5rem 0rem 1rem 0rem",
        border: "1px solid #DDD7D7A3",
        borderRadius: "12px",
        boxShadow:
          "0px 12px 120px 0px #00000014, 0px 12px 32px -16px #0009320A",
        zIndex: 2,
        width: "12rem",
        flexShrink: 0,
        backgroundColor: color ?? "rgba(229, 231, 235, 0.4)",
      }}
    >
      {header}
      {navigation}
      <div
        style={{
          marginTop: "auto",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CurrentUser />
      </div>
    </Flex>
  );
}

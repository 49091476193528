import React, { useMemo } from "react";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { EmailTemplateHeaderState } from "~/types/template-editor";
import { getEmailStateImageUrl } from "~/types/template-vars";

const LogoSelector = ({
  img,
  onUpload,
}: {
  img: EmailTemplateHeaderState["logo"];
  onUpload: (img: File) => void;
}) => {
  const imageUrl = useMemo(() => {
    return getEmailStateImageUrl(img);
  }, [img]);

  return (
    <ImageSelector
      value={imageUrl}
      onUpload={onUpload}
      buttonText="Change Logo"
      placeholderImage="https://via.placeholder.com/150"
      noImageText="Logo not set"
    />
  );
};

export default LogoSelector;

import { Checkbox, Flex, IconButton, Text } from "@radix-ui/themes";
import { Minus, Plus } from "lucide-react";

export interface CheckboxWithQuantityProps {
  label: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  icon?: React.ReactNode;
  hideQuantityControls?: boolean;
}

const CheckboxWithQuantity = ({
  label,
  checked,
  setChecked,
  quantity,
  setQuantity,
  icon,
  hideQuantityControls,
}: CheckboxWithQuantityProps) => {
  return (
    <Flex
      onClick={() => setChecked(!checked)}
      style={{
        padding: "20px 24px",
        border: "1px solid #00000029",
        borderRadius: "16px",
        minHeight: "74px",
        maxWidth: "480px",
      }}
      align="center"
    >
      <Checkbox
        size="3"
        style={{ borderRadius: "8px", marginRight: "24px" }}
        color="gray"
        highContrast
        checked={checked}
      />
      {icon}
      <Text style={{ marginLeft: "16px" }}>{label}</Text>
      {checked && !hideQuantityControls && (
        <Flex style={{ marginLeft: "auto" }} align="center" gap="12px">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (quantity === 1) {
                setChecked(false);
              }
              setQuantity(Math.max(quantity - 1, 0));
            }}
            variant="outline"
            color="gray"
            style={{
              border: "1px solid #00000029",
              boxShadow: "none",
              borderRadius: "100%",
              color: "#595D62",
            }}
          >
            <Minus />
          </IconButton>
          <Text>{quantity}</Text>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setQuantity(Math.min(quantity + 1, 10));
            }}
            color="gray"
            variant="outline"
            style={{
              border: "1px solid #00000029",
              boxShadow: "none",
              borderRadius: "100%",
              color: "#595D62",
            }}
          >
            <Plus />
          </IconButton>
        </Flex>
      )}
    </Flex>
  );
};

export default CheckboxWithQuantity;

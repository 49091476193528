import {
  AdMediaElementTypeStrings,
  AdMediaImageElementTypeStrings,
  AdMediaTextElementTypeStrings,
} from "../../../types/ads";
import InternAdMediaTemplateElementCreationDialog from "./InternAdMediaTemplateElementCreationDialog";
import { AdMediaElementType, operations, components } from "@openapi";
import {
  AlertDialog,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";

interface InternAdMediaTemplateElementsTabProps {
  templateId: string;
}

type AdMediaTemplateElementsApiResponse =
  operations["ads_api_get_ad_media_template_elements"]["responses"][200]["content"]["application/json"];

type AdMediaTemplateImageElementSchema =
  components["schemas"]["AdMediaTemplateImageElementSchema"];

type AdMediaTemplateTextElementSchema =
  components["schemas"]["AdMediaTemplateTextElementSchema"];

type AdMediaTemplateShapeElementSchema =
  components["schemas"]["AdMediaTemplateShapeElementSchema"];

type AdMediaTemplateProductGroupElementSchema =
  components["schemas"]["AdMediaTemplateProductGroupElementSchema"];

type AdMediaTemplateCollectionGroupElementSchema =
  components["schemas"]["AdMediaTemplateCollectionGroupElementSchema"];

type AdMediaTemplateElementUnionSchema =
  | AdMediaTemplateImageElementSchema
  | AdMediaTemplateTextElementSchema
  | AdMediaTemplateShapeElementSchema
  | AdMediaTemplateProductGroupElementSchema
  | AdMediaTemplateCollectionGroupElementSchema;

type AdMediaTemplateGroupElementUnionSchema =
  | AdMediaTemplateProductGroupElementSchema
  | AdMediaTemplateCollectionGroupElementSchema;

type AdMediaTemplateElementDeleteApiResponse =
  operations["ads_api_delete_ad_media_template_element"]["responses"][200]["content"]["application/json"];

const InternAdMediaTemplateElementsTab: React.FC<
  InternAdMediaTemplateElementsTabProps
> = ({ templateId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [isDeleteElementDialogOpen, setIsDeleteElementDialogOpen] =
    useState<boolean>(false);

  const [elementIdForDeletion, setElementIdForDeletion] = useState<
    string | null
  >(null);

  const query = useQuery({
    queryKey: ["ad-media-template-elements", templateId],
    queryFn: async (): Promise<AdMediaTemplateElementsApiResponse> => {
      const { data } = await axios.get(
        `/api/v1/ads/ad-media-template/${templateId}/elements`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const deleteElementMutation = useMutation<
    AdMediaTemplateElementDeleteApiResponse,
    AxiosError
  >({
    mutationFn: async (): Promise<AdMediaTemplateElementDeleteApiResponse> => {
      const { data } = await axios.delete(
        `/api/v1/ads/ad-media-template/${templateId}/element/${elementIdForDeletion}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsDeleteElementDialogOpen(false);
      if (data.success) {
        setElementIdForDeletion(null);
        query.refetch();
      } else {
        alert(data.error_message);
      }
    },
    onError: (error) => {
      setIsDeleteElementDialogOpen(false);
      alert(error.response?.data);
      console.error("Error deleting element:", error);
    },
  });

  const handleOnElementCreate = () => {
    query.refetch();
  };

  const handleDeleteElementClick = (elementId: string) => {
    setElementIdForDeletion(elementId);
    setIsDeleteElementDialogOpen(true);
  };

  if (!query.data) {
    return (
      <Flex align="center">
        <Spinner />
      </Flex>
    );
  }

  const getElementComponent = (element: AdMediaTemplateElementUnionSchema) => {
    return (
      <Card key={element.id}>
        <Flex direction="column" gap="0.5rem">
          <Flex direction="row" justify="between">
            <Flex direction="column">
              <Heading size="2" style={{ marginBottom: "6px" }}>
                {AdMediaElementTypeStrings[element.type]}
              </Heading>
              {element.type === AdMediaElementType.text && (
                <>
                  <Text size="1">
                    <b>element id</b>: {element.target_element_id}
                  </Text>
                  <Text size="1">
                    <b>type</b>:{" "}
                    {
                      AdMediaTextElementTypeStrings[
                        (element as AdMediaTemplateTextElementSchema).text_type
                      ]
                    }
                  </Text>
                  <Text size="1">
                    <b>font</b>:{" "}
                    {
                      (element as AdMediaTemplateTextElementSchema)
                        .typography_category
                    }
                  </Text>
                </>
              )}
              {element.type === AdMediaElementType.image && (
                <>
                  <Text size="1">
                    <b>element id</b>: {element.target_element_id}
                  </Text>
                  <Text size="1">
                    <b>type</b>:{" "}
                    {
                      AdMediaImageElementTypeStrings[
                        (element as AdMediaTemplateImageElementSchema)
                          .image_type
                      ]
                    }
                  </Text>
                </>
              )}
              {element.type === AdMediaElementType.shape && (
                <>
                  <Text size="1">
                    <b>element id</b>: {element.target_element_id}
                  </Text>
                  <Text size="1">
                    <b>fill palette color</b>:{" "}
                    {
                      (element as AdMediaTemplateShapeElementSchema)
                        .fill_palette_color
                    }
                  </Text>
                  <Text size="1">
                    <b>stroke palette color</b>:{" "}
                    {
                      (element as AdMediaTemplateShapeElementSchema)
                        .stroke_palette_color
                    }
                  </Text>
                </>
              )}
              {element.type === AdMediaElementType.collection_group && (
                <Text size="1">
                  <b>name</b>:{" "}
                  {
                    (element as AdMediaTemplateCollectionGroupElementSchema)
                      .name
                  }
                </Text>
              )}
              {element.type === AdMediaElementType.product_group && (
                <Text size="1">
                  <b>name</b>:{" "}
                  {(element as AdMediaTemplateProductGroupElementSchema).name}
                </Text>
              )}
            </Flex>
            <Button
              variant="outline"
              size="1"
              onClick={() => handleDeleteElementClick(element.id)}
            >
              Delete
            </Button>
          </Flex>
          {(element.type === AdMediaElementType.product_group ||
            element.type === AdMediaElementType.collection_group) &&
            (element as AdMediaTemplateGroupElementUnionSchema).elements.map(
              (element) => getElementComponent(element)
            )}
        </Flex>
      </Card>
    );
  };

  return (
    <Box>
      <AlertDialog.Root open={isDeleteElementDialogOpen}>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Delete Element</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you'd like to permanently delete this template element?
          </AlertDialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <Button
              variant="soft"
              color="gray"
              onClick={() => {
                setElementIdForDeletion(null);
                setIsDeleteElementDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <AlertDialog.Action>
              <Button
                variant="solid"
                color="red"
                onClick={() => {
                  deleteElementMutation.mutate();
                }}
              >
                Delete Element
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
      <InternAdMediaTemplateElementCreationDialog
        templateId={templateId}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onCreate={handleOnElementCreate}
      />
      <Flex align="center" justify="between" style={{ marginBottom: "10px" }}>
        <Heading size="4">Elements</Heading>
        <Button
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          + Add Element
        </Button>
      </Flex>
      <Flex
        direction="column"
        gap="0.5rem"
        style={{
          overflowY: "auto",
          justifyContent: "stretch",
        }}
      >
        {query.data.elements.map((element) => getElementComponent(element))}
      </Flex>
    </Box>
  );
};

export default InternAdMediaTemplateElementsTab;

import {
  RecommendedCampaignWizardSteps,
  useCampaignWizardState,
} from "../../../../../../../contexts/CampaignWizardContext";
import { useAuthenticatedUserState } from "../../../../../../../contexts/CurrentUserContext";
import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type ProductCategory = {
  id: string;
  name: string;
};
type GetCategoriesResponse =
  operations["shopify_integration_api_get_categories_endpoint"]["responses"][200]["content"]["application/json"];

const useGetCategories = (): Map<string, ProductCategory> => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();
  const { currentStep } = useCampaignWizardState();
  const { data: categories } = useQuery({
    queryKey: ["product-categories", activeBrandID],
    queryFn: async (): Promise<GetCategoriesResponse> => {
      const response = await axios.get("/api/v1/shopify/categories", {
        params: {
          brand_id: activeBrandID,
        },
      });
      return response.data;
    },
    enabled:
      !!activeBrandID && currentStep === RecommendedCampaignWizardSteps.PRODUCT,
  });
  categories?.unshift({ id: "all", name: "All" });
  return new Map(categories?.map((category) => [category.id, category]));
};
export default useGetCategories;

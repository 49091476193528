import Sidepane from "../../components/core/sidepane/Sidepane";
import {
  useAuthenticatedUserState,
  useCurrentUserDispatch,
} from "../../contexts/CurrentUserContext";
import { DynamicHeaderProvider } from "../../contexts/DynamicHeaderContext";
import { Flex } from "@radix-ui/themes";
import {
  Martini,
  Layers,
  Mail,
  Palette,
  Apple,
  Grape,
  Cherry,
  SwatchBook,
  Library,
} from "lucide-react";
import { Link, NavLink, Outlet } from "react-router-dom";
import AdminBar from "~/components/core/layout/AdminBar";

enum InternSectionType {
  RECOMMENDER = "recommender",
  STYLES = "styles",
  EMAIL = "email",
  EDITOR = "editor",
  GRAPES = "grapes",
  CHERRY = "cherry",
  ADS = "ads",
  CAMPAIGNS = "campaigns",
}

const SECTIONS = [
  { type: InternSectionType.RECOMMENDER, icon: Layers },
  { type: InternSectionType.STYLES, icon: Palette },
  { type: InternSectionType.CAMPAIGNS, icon: Library },
  { type: InternSectionType.ADS, icon: SwatchBook },
  { type: InternSectionType.EMAIL, icon: Mail },
  { type: InternSectionType.EDITOR, icon: Apple },
  { type: InternSectionType.GRAPES, icon: Grape },
  { type: InternSectionType.CHERRY, icon: Cherry },
];

export function SidepaneComponent() {
  return (
    <Sidepane
      color={"#FFF"}
      header={
        <Link
          to={"/intern"}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            fontWeight: "600",
            textDecoration: "none",
            color: "inherit",
            marginLeft: "1.7rem",
          }}
        >
          <Martini
            style={{
              height: "1.5rem",
              width: "1.5rem",
            }}
          />
          <span>Draper</span>
        </Link>
      }
      navigation={
        <nav
          style={{
            display: "grid",
            gridTemplateRows: "auto",
            justifyItems: "start",
            padding: "0.5rem",
            fontSize: "0.875rem",
            fontWeight: "500",
          }}
        >
          {SECTIONS.map((s) => {
            return (
              <NavLink
                to={"/intern/" + s.type}
                key={s.type}
                style={({ isActive }) => {
                  return {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.75rem",
                    borderRadius: "0.375rem",
                    padding: "0.75rem 1rem",
                    color: "inherit",
                    background: isActive
                      ? "rgba(229, 231, 235, 0.8)"
                      : "inherit",
                    transition: "all 0.3s",
                    textDecoration: "none",
                    boxSizing: "border-box",
                  };
                }}
              >
                <s.icon
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    paddingLeft: "0.25rem",
                  }}
                />
                <span className="">
                  {s.type.charAt(0).toUpperCase() + s.type.slice(1)}
                </span>
              </NavLink>
            );
          })}
        </nav>
      }
    />
  );
}

export function MainContentComponent() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: "#fbfbfb",
        width: "calc(100vw - 16rem)",
        paddingLeft: "13rem",
      }}
    >
      <AdminBar autohide={false} />
      <main
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: "#fbfbfb",
          overflow: "auto",
        }}
      >
        <Outlet />
      </main>
    </div>
  );
}

export default function InternRoute() {
  const { brands } = useAuthenticatedUserState();

  const currentUserDispatch = useCurrentUserDispatch();
  if (brands == null) return <div>No brands returned</div>;

  return (
    <Flex height="100vh" width="100%" display="flex" direction="row">
      <SidepaneComponent />
      <DynamicHeaderProvider>
        <MainContentComponent />
      </DynamicHeaderProvider>
    </Flex>
  );
}

import BackgroundModifier from "./modifiers/BackgroundModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import LinkToModifier from "./modifiers/LinkToModifier";
import ProductImageSelector from "./modifiers/ProductImageSelector";
import { Flex } from "@radix-ui/themes";
import { PencilIcon } from "lucide-react";
import React from "react";
import DraperButton from "~/components/core/buttons/DraperButton";

const FeaturedProductView = () => {
  return (
    <Flex direction="column" gap="24px">
      <GeneratedVariantSelector />

      <LinkToModifier
        options={[
          { label: "Product", value: "product" },
          { label: "Collection", value: "collection" },
        ]}
        hideTextField
      />

      <DraperButton
        radius="large"
        text="Change Product"
        variant="outlined"
        icon={<PencilIcon />}
      />

      <ProductImageSelector />

      <BackgroundModifier hideSwitch />
    </Flex>
  );
};

export default FeaturedProductView;

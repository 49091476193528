import {
  NewCampaignWizardStepsLabels,
  RecommendedCampaignWizardStepsLabels,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import useCenterCampaignCard from "../../../../hooks/campaign/useCenterCampaignCard";
import CloseIconButton from "../../../core/buttons/CloseIconButton";
import classes from "./CampaignWizardLayout.module.css";
import { Box, Flex, ScrollArea, Tooltip } from "@radix-ui/themes";
import React, { useEffect } from "react";
import { useNavigate, useNavigationType } from "react-router-dom";
import { HOME_ROUTE } from "~/routes/constants";

const CampaignWizardLayout = ({ children }: { children: React.ReactNode }) => {
  const { currentStep, currentRequiredStep, wizardType } =
    useCampaignWizardState();
  const { centerCurrentCard } = useCenterCampaignCard();
  const dispatch = useCampaignWizardDispatch();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const isNewCampaign = wizardType === "NEW";

  useEffect(() => {
    centerCurrentCard();
  }, [centerCurrentCard]);

  return (
    <Flex
      justify="between"
      px="24px"
      height="100vh"
      width="100%"
      position={"relative"}
      style={{ backgroundColor: "#FCFBFA", overflow: "hidden" }}
    >
      <Flex
        direction="column"
        height="100%"
        gap="8px"
        justify="center"
        align="center"
      >
        {!!currentRequiredStep &&
          Array.from({ length: currentRequiredStep + 1 }).map((_, index) => (
            <Tooltip
              side="right"
              key={index}
              content={
                isNewCampaign
                  ? NewCampaignWizardStepsLabels[index]
                  : RecommendedCampaignWizardStepsLabels[index]
              }
            >
              <Box
                onClick={() =>
                  dispatch({
                    type: "SET_CURRENT_STEP",
                    payload: { currentStep: index },
                  })
                }
                className={classes.dot}
              >
                <Box
                  style={{
                    width: index === currentStep ? "12px" : "8px",
                    height: index === currentStep ? "12px" : "8px",
                    borderRadius: "50%",
                    backgroundColor:
                      index === currentStep ? "#4F4343" : "#D2C3C3",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Tooltip>
          ))}
      </Flex>
      <ScrollArea
        style={{ overflow: "hidden" }}
        type="auto"
        scrollbars="horizontal"
      >
        <Flex
          direction="column"
          align="center"
          py="50%"
          gap="100px"
          height="100%"
          style={{ overflow: "hidden" }}
        >
          {children}
        </Flex>
      </ScrollArea>
      <CloseIconButton
        onClick={() => {
          if (navigationType === "PUSH") {
            navigate(-1);
          } else {
            navigate(HOME_ROUTE);
          }
        }}
        style={{
          right: "24px",
          top: "24px",
          position: "absolute",
          borderRadius: "8px",
        }}
      />
    </Flex>
  );
};

export default CampaignWizardLayout;

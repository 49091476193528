import { Flex, Box, Text } from "@radix-ui/themes";
import React, { CSSProperties } from "react";
import ColorPicker from "~/components/core/inputs/ColorPicker";

const ColorBox = ({
  color,
  label,
  onSaveColor,
  style,
  disabled,
}: {
  style?: CSSProperties;
  color: string;
  label: string;
  onSaveColor: (color: string) => void;
  disabled?: boolean;
}) => {
  return (
    <ColorPicker defaultColor={color} onSaveColor={onSaveColor}>
      <Flex
        align="center"
        gap="8px"
        style={{
          border: "1px solid #DDD7D7",
          borderRadius: "10px",
          cursor: disabled ? "not-allowed" : "pointer",
          ...style,
        }}
        p="4px"
        pr="8px"
      >
        <Box
          style={{
            width: "36px",
            height: "36px",
            borderRadius: "6px",
            border: "2px solid #00000014",
            backgroundColor: color,
          }}
        />
        <Text size="4" weight="medium">
          {label}
        </Text>
      </Flex>
    </ColorPicker>
  );
};

export default ColorBox;

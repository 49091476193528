export default function ErrorPageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f8f8f8",
        color: "#333",
        textAlign: "center",
      }}
    >
      <h1 style={{ fontSize: "6rem", margin: "0" }}>404</h1>
      <h2 style={{ fontSize: "2rem", margin: "0" }}>Page Not Found</h2>
      <p style={{ fontSize: "1.25rem", marginTop: "1rem" }}>
        The page you are looking for doesn't exist or has been moved.
      </p>
    </div>
  );
}

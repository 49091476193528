import { Button, Flex, Text } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import { useHeaderFooterState } from "~/contexts/TemplateEditorContext";

const SelectContainer = styled(Flex)`
  background-color: #f0edeb;
  border-radius: 12px;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
`;

const Btn = styled(Button)`
  color: #4c4747;
  font-weight: 500;
  background-color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border-radius: 8px;
`;

const VariantSelector = ({
  option,
  onChange,
}: {
  option: number;
  onChange: (option: number) => void;
}) => {
  const state = useHeaderFooterState();
  return (
    <SelectContainer>
      <Btn
        variant="outline"
        color="gray"
        disabled={option <= 0}
        onClick={() => onChange(option - 1)}
      >
        <ChevronLeftIcon /> Prev
      </Btn>
      <Text style={{ color: "#4c4747" }} weight="medium">
        Option {option + 1}
      </Text>
      <Btn
        variant="outline"
        color="gray"
        disabled={option + 1 >= state.availableTemplates.length}
        onClick={() => onChange(option + 1)}
      >
        Next <ChevronRightIcon />
      </Btn>
    </SelectContainer>
  );
};

export default VariantSelector;

import DialogSeparator from "./DialogSeparator";
import { IconButton, Box, Dialog } from "@radix-ui/themes";
import { X } from "lucide-react";
import React from "react";

type DialogHeaderProps = {
  title: string;
  disabled?: boolean;
} & Pick<React.ComponentProps<typeof Box>, "p" | "px" | "m" | "style">;

const DialogHeader: React.FC<DialogHeaderProps> = ({
  title,
  disabled = false,
  style,
  ...boxProps
}) => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          ...style,
        }}
        {...boxProps}
      >
        <Dialog.Title style={{ alignContent: "center", marginBottom: 0 }}>
          {title}
        </Dialog.Title>
        <Dialog.Close disabled={disabled}>
          <IconButton
            style={{
              borderRadius: "8px",
              cursor: "pointer",
            }}
            color="gray"
            variant="outline"
          >
            <X color="#000000A6" />
          </IconButton>
        </Dialog.Close>
      </Box>
      <DialogSeparator />
    </>
  );
};

export default DialogHeader;

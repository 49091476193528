import { useActiveBrandID } from "../../../contexts/CurrentUserContext";
import { META_APP_ID, META_CONFIG_ID } from "../../../utils/constants";
import { useDraperApiPostMutation } from "../../../utils/useDraperMutation";
import { operations } from "@openapi";
import { Button, Flex } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import styled from "styled-components";
import MetaLogo from "~/components/logos/MetaLogo";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

type DisconnectMetaResponse =
  operations["meta_api_disconnect_meta_account"]["responses"]["200"]["content"]["application/json"];
type DisconnectMetaRequestData =
  operations["meta_api_disconnect_meta_account"]["requestBody"]["content"]["application/json"];

type SelectAdAccountResponse =
  operations["meta_api_select_ad_account"]["responses"]["200"]["content"]["application/json"];
type SelectAdAccountRequestData =
  operations["meta_api_select_ad_account"]["requestBody"]["content"]["application/json"];

const FacebookButton = styled(Button)<{ isConnected: boolean }>`
  background-color: ${(props) => (props.isConnected ? "#ffffff" : "#0064e0")};
  color: ${(props) => (props.isConnected ? "#4C4747" : "#fffff")};
  border: ${(props) => (props.isConnected ? "1px solid #4C4747" : "none")};
  font-weight: bold;
  border-radius: 10px;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
`;

export type FacebookSuccessResponse = {
  /** An access token for the person using the webpage. */
  accessToken: string;
};

export type FacebookFailResponse = {
  error: string;
  errorReason: string | null;
  errorDescription: string | null;
};

export type FacebookLoginProps = {
  onSuccess?: (response: FacebookSuccessResponse) => void;
  onFail?: (error: FacebookFailResponse) => void;
  isLoading?: boolean;
};

const META_REDIRECT_URL_PATHS =
  process.env.REACT_APP_META_REDIRECT_URL_PATHS?.split(";") || [
    "/auth/meta/callback",
  ];

export function getFacebookRedirectUrl() {
  const urlPath = window.location.pathname;
  const whitelistedPath =
    META_REDIRECT_URL_PATHS.find((path) => urlPath.startsWith(path)) ??
    META_REDIRECT_URL_PATHS[0];
  return `${window.location.origin}${whitelistedPath}`;
}

const FacebookLogin = ({
  showAdAccounts = true,
}: {
  showAdAccounts?: boolean;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();

  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAdAccount, setSelectedAdAccount] = useState<string | null>(
    null
  );

  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);

  const { isPending: isDisconnecting, mutate: disconnectAccount } =
    useDraperApiPostMutation<DisconnectMetaResponse, DisconnectMetaRequestData>(
      {
        path: "/meta/disconnect",
        onError: (error) => {
          setErrorMessage(error.message);
        },
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ["meta-ad-accounts", activeBrandID],
          });
        },
      }
    );

  const selectAdAccountMutation = useDraperApiPostMutation<
    SelectAdAccountResponse,
    SelectAdAccountRequestData
  >({
    path: "/meta/ad-accounts/select",
    onError: (error) => {
      setErrorMessage(error.content);
    },
    onSuccess: (data) => {
      setSelectedAdAccount(data.account_id);
      setErrorMessage("");
    },
  });

  const redirectToFacebook = () => {
    setIsWindowOpen(true);
    const redirect_uri = getFacebookRedirectUrl();
    const CALLBACK_STATE = {
      state: "kt_meta_login", // TODO: should this be dynamic?
      brand_id: activeBrandID,
    };

    const url =
      `https://facebook.com/dialog/oauth` +
      `?client_id=${META_APP_ID}` +
      `&config_id=${META_CONFIG_ID}` +
      `&state=${JSON.stringify(CALLBACK_STATE)}` +
      `&redirect_uri=${redirect_uri}`;

    const width = 750;
    const height = 750;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const authWindow = window.open(
      url,
      "Meta Login",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    // Check if the window is closed
    const checkWindowClosed = setInterval(() => {
      if (authWindow && authWindow.closed) {
        clearInterval(checkWindowClosed);
        console.log("Facebook dialog closed.");
        // Handle any actions you need to take when the window is closed
        setIsWindowOpen(false);
      }
    }, 300); // Check every 300ms; adjust as needed

    window.addEventListener("message", (event) => {
      if (event.origin !== window.location.origin) return;

      // Handle the data sent back from the dialog
      const data = event.data;
      const success = data.success;
      if (data.state === "kt_meta_login" && data.brand_id === activeBrandID) {
        console.log(data);
        if (success) {
          queryClient.invalidateQueries({
            queryKey: ["meta-ad-accounts", activeBrandID],
          });
        } else {
          setErrorMessage(data.error);
        }
      }
    });
  };

  const buttonTitle = adAccounts?.length ? "Remove Account" : "Connect";
  const buttonAction = () => {
    adAccounts?.length
      ? disconnectAccount({
          brand_id: activeBrandID,
        })
      : redirectToFacebook();
  };

  return (
    <Flex direction="column" flexShrink={"0"}>
      <FacebookButton
        isConnected={Boolean(adAccounts?.length)}
        onClick={buttonAction}
        loading={adAccounts?.length ? isDisconnecting : isWindowOpen}
      >
        {!Boolean(adAccounts?.length) && <MetaLogo />}
        {buttonTitle}
      </FacebookButton>
      {!!errorMessage && <p style={{ color: "red" }}>Error: {errorMessage}</p>}
      {showAdAccounts && (
        <div>
          {adAccounts?.map((adAccount) => {
            return (
              <Button
                style={{ width: "fit-content" }}
                highContrast={adAccount.is_selected}
                onClick={() =>
                  selectAdAccountMutation.mutate({
                    brand_id: activeBrandID,
                    ad_account_id: adAccount.account_id,
                  })
                }
              >
                {adAccount.name}
              </Button>
            );
          })}
        </div>
      )}
    </Flex>
  );
};

export default FacebookLogin;

import { Card, Flex, Heading, Switch } from "@radix-ui/themes";
import React from "react";

type AdEditorCardProps = {
  title: string;
} & React.ComponentProps<typeof Card> &
  (
    | {
        checkable: false;
      }
    | {
        checkable: true;
        checked: boolean;
        onCheckedChange: (checked: boolean) => void;
      }
  );

const AdEditorCard: React.FC<AdEditorCardProps> = ({
  children,
  title,
  ...props
}) => {
  const enabled = props.checkable ? props.checked : true;
  return (
    <Card
      style={{
        // required for fixed elements to render correctly
        contain: "unset",
        overflow: "visible",
      }}
    >
      <Flex direction="column" gap="3">
        <Flex direction="row" justify="between">
          <Heading size="2">{title}</Heading>
          {props.checkable && (
            <Switch
              color="gray"
              highContrast
              checked={props.checked}
              onCheckedChange={props.onCheckedChange}
            />
          )}
        </Flex>
        {enabled && children}
      </Flex>
    </Card>
  );
};

export default AdEditorCard;

export const toggleShowElement = (element?: HTMLElement | null) => {
  if (!element) {
    return;
  }
  if (element.style.visibility === "visible") {
    element.style.visibility = "hidden";
  } else {
    element.style.visibility = "visible";
  }
};

export const setToolbarPosition = (
  editor: HTMLElement | null,
  toolbar: HTMLElement | null
) => {
  if (toolbar && editor) {
    const toolbarPosition = toolbar?.getBoundingClientRect();
    const editorPosition = editor?.getBoundingClientRect();
    const widthDifference =
      (toolbarPosition?.width ?? 0) - (editorPosition?.width ?? 0);
    toolbar.style.left = 0 - widthDifference / 2 + "px";
  }
};

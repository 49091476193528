import { FontWeight } from "@openapi";

export const getFontWeightDescription = (weight: FontWeight): string => {
  switch (weight) {
    case FontWeight.Value400:
      return "Regular";
    case FontWeight.Value700:
      return "Bold";
    default:
      return weight.toString();
  }
};

import {
  EmailBrandData,
  EmailSectionLinkElementState,
  EmailSectionImageElementState,
  EmailTemplateFooterState,
  EmailTemplateHeaderState,
  EmailSectionBackgroundImageState,
} from "./template-editor";
import { EmailSectionType, EmailSectionWidthType } from "@openapi";
import { CSSProperties } from "react";
import {
  quillImports,
  wrapEditableHandlebarsValue,
} from "~/routes/intern/email_editor/text_editor/htmlUtils";

export type EmailBrandHeaderSectionHandlebars = {
  type: EmailSectionType.header;
  max_width_size: CSSProperties["maxWidth"];
  // NOTE: can actually pass this as a Partial<CSSProperties>, which we can handle with a custom handler
  background_image?: string;
  colors: {
    background: CSSProperties["backgroundColor"];
    foreground: CSSProperties["color"];
  };
  logo_url: string;
  tagline?: string;
  menu?: {
    url: string;
    target: Required<React.AnchorHTMLAttributes<HTMLAnchorElement>["target"]>;
    label: string;
  }[];
};

export type EmailSocialLinkHandlebars = {
  url: string;
  target: Required<React.AnchorHTMLAttributes<HTMLAnchorElement>["target"]>;
  enabled: boolean;
};

export type EmailBrandFooterSectionHandlebars = {
  type: EmailSectionType.footer;
  max_width_size: CSSProperties["maxWidth"];
  colors: {
    background1: CSSProperties["backgroundColor"];
    text1: CSSProperties["color"];
    background2: CSSProperties["backgroundColor"];
    text2: CSSProperties["color"];
  };
  background_image_1?: string;
  background_image_2?: string;
  menu?: {
    url: string;
    target: string;
    label: string;
  }[];
  unsubscribe_url: string;
  organization: {
    name: string;
    full_address: string;
  };
  show_social_links: boolean;
  social_links: {
    facebook: EmailSocialLinkHandlebars;
    twitter: EmailSocialLinkHandlebars;
    instagram: EmailSocialLinkHandlebars;
    tiktok: EmailSocialLinkHandlebars;
    youtube: EmailSocialLinkHandlebars;
  };
};

export const getEmailStateImageUrl = (
  image:
    | EmailSectionImageElementState
    | EmailSectionBackgroundImageState
    | undefined
): string | undefined => {
  if (!image) return undefined;
  if ("lastModified" in image) {
    return URL.createObjectURL(image);
  }
  if ("image_url" in image) {
    return image.image_url;
  }
  return image.image ?? undefined;
};

const getEmailTemplateLinkTarget = (opensNewTab: boolean): string =>
  opensNewTab ? "_blank" : "_self";

const getEmailSocialLinkHandlebars = (
  link: EmailSectionLinkElementState
): EmailSocialLinkHandlebars => ({
  url: link.link_url,
  target: getEmailTemplateLinkTarget(link.opens_new_tab),
  enabled: link.enabled,
});

const getEmailMenuHandlebars = (menuLinks: {
  enabled: boolean;
  links: EmailSectionLinkElementState[];
}): EmailBrandFooterSectionHandlebars["menu"] => {
  if (!menuLinks.enabled) return undefined;
  return menuLinks.links
    .filter((link) => link.enabled && !!link.text.trim())
    .map((link) => ({
      url: link.link_url,
      target: getEmailTemplateLinkTarget(link.opens_new_tab),
      label: link.text,
    }));
};

export const getEmailHeaderSectionHandlebarsFromState = (
  state: EmailTemplateHeaderState
): EmailBrandHeaderSectionHandlebars => ({
  type: state.type,
  max_width_size:
    state.widthType === EmailSectionWidthType.contained ? "600px" : undefined,
  background_image: getEmailStateImageUrl(state.colors[0].backgroundImage),
  colors: {
    background: state.colors[0].background,
    foreground: state.colors[0].foreground,
  },
  logo_url:
    getEmailStateImageUrl(state.logo) ?? "https://via.placeholder.com/150",
  tagline: state.tagline.enabled ? state.tagline.text : undefined,
  menu: getEmailMenuHandlebars(state.menuLinks),
});

export const getEmailFooterSectionHandlebarsFromState = (
  state: EmailTemplateFooterState,
  brandData: EmailBrandData
): EmailBrandFooterSectionHandlebars => ({
  type: state.type,
  max_width_size:
    state.widthType === EmailSectionWidthType.contained ? "600px" : undefined,
  colors: {
    background1: state.colors[0].background,
    text1: state.colors[0].foreground,
    background2: state.colors[1].background,
    text2: state.colors[1].foreground,
  },
  background_image_1: getEmailStateImageUrl(state.colors[0].backgroundImage),
  background_image_2: getEmailStateImageUrl(state.colors[1].backgroundImage),
  menu: getEmailMenuHandlebars(state.menuLinks),
  unsubscribe_url: brandData.unsubscribeLink,
  organization: {
    name: brandData.organization.name,
    full_address: brandData.organization.fullAddress,
  },
  show_social_links: state.socialLinks.enabled,
  social_links: {
    facebook: getEmailSocialLinkHandlebars(state.socialLinks.facebook),
    twitter: getEmailSocialLinkHandlebars(state.socialLinks.twitter),
    instagram: getEmailSocialLinkHandlebars(state.socialLinks.instagram),
    tiktok: getEmailSocialLinkHandlebars(state.socialLinks.tiktok),
    youtube: getEmailSocialLinkHandlebars(state.socialLinks.youtube),
  },
});

import { AdMediaUpdateableElement } from "../AdMediaContext";
import AdMediaImageElementComponent from "../elements/AdMediaImageElementComponent";
import InternAdMediaTextElementComponent from "./InternAdMediaTextElementComponent";
import { AdMediaElementType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import React from "react";
import { assertNever } from "~/utils/typeUtils";

interface InternAdMediaElementsTabProps {
  elements: AdMediaUpdateableElement[];
  onElementUpdate: (element: AdMediaUpdateableElement) => void;
}

const InternAdMediaElementsTab: React.FC<InternAdMediaElementsTabProps> = ({
  elements,
  onElementUpdate,
}) => {
  return (
    <Flex
      direction="column"
      gap="16px"
      style={{
        overflowY: "auto",
        justifyContent: "stretch",
      }}
    >
      {elements.map((element) => {
        switch (element.type) {
          case AdMediaElementType.collection_group:
            return null;
          case AdMediaElementType.image:
            return (
              <AdMediaImageElementComponent
                key={element.id}
                element={element}
                onElementUpdate={onElementUpdate}
              />
            );
          case AdMediaElementType.product_group:
            return null;
          case AdMediaElementType.text:
            return (
              <InternAdMediaTextElementComponent
                key={element.id}
                element={element}
                onElementUpdate={onElementUpdate}
              />
            );
          case AdMediaElementType.shape:
            return null;
          default:
            assertNever(element);
        }
      })}
    </Flex>
  );
};

export default InternAdMediaElementsTab;

import { Avatar, Box, Button, Flex, Text } from "@radix-ui/themes";
import { StarsIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import { useTemplateEditorDispatch } from "~/contexts/TemplateEditorContext";
import { CustomizationViews } from "~/types/template-editor";

const TextContainer = styled(Flex)`
  box-shadow: 0px 12px 48px 0px #00000014;
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
`;

const OptionContainer = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  padding: 32px;
  gap: 24px;
  width: 100%;
  cursor: pointer;
  align-items: center;
  color: #4c4747;
`;

const SoftBar = styled(Box)`
  width: 56px;
  height: 16px;
  background-color: #d9d9d9;
  border-radius: 4px;
`;

const SolidBar = styled(Box)`
  width: 56px;
  height: 16px;
  background-color: #4b4b4b;
  border-radius: 4px;
`;

const MiddleBar = styled(Flex)`
  font-size: 12px;
  width: 56px;
  border-radius: 4px;
  height: 28px;
  color: rgba(0, 0, 0, 0.2);
  background-color: #d9d9d9;
  justify-content: center;
  align-items: center;
`;

const GenerateButton = styled(Button)`
  color: #4c4747;
  width: max-content;
`;

const CustomizationTypeSelect = () => {
  const dispatch = useTemplateEditorDispatch();

  return (
    <Flex direction="column" gap="24px">
      <Flex gap="16px">
        <MarkAvatar />
        <TextContainer>What would you like to customize?</TextContainer>
      </Flex>
      <OptionContainer
        onClick={() =>
          dispatch({
            type: "CHANGE_CURRENT_VIEW",
            payload: CustomizationViews.HeaderOptions,
          })
        }
      >
        <Flex direction="column" gap="4px">
          <SolidBar />
          <MiddleBar>content</MiddleBar>
          <SoftBar />
        </Flex>
        <Text weight="medium">Customize Email Header</Text>
      </OptionContainer>
      <OptionContainer
        onClick={() =>
          dispatch({
            type: "CHANGE_CURRENT_VIEW",
            payload: CustomizationViews.FooterOptions,
          })
        }
      >
        <Flex direction="column" gap="4px">
          <SoftBar />
          <MiddleBar>content</MiddleBar>
          <SolidBar />
        </Flex>
        <Text weight="medium">Customize Email Footer</Text>
      </OptionContainer>
      <GenerateButton size="4" radius="large" variant="outline" color="gray">
        <StarsIcon /> Generate another version
      </GenerateButton>
    </Flex>
  );
};

export default CustomizationTypeSelect;

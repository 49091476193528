import { operations } from "@openapi";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type CampaignsApiResponse =
  operations["campaign_api_my_campaigns"]["responses"][200]["content"]["application/json"];
type CampaignsApiParams =
  operations["campaign_api_my_campaigns"]["parameters"]["query"];
type CampaignsApiMetricsResponse =
  operations["cdp_api_klaviyo_metrics"]["responses"][200]["content"]["application/json"];

const useGetCampaignList = ({
  page = 0,
  limit = 3,
  status,
}: {
  page?: number;
  limit?: number;
  status?: CampaignsApiParams["state"];
}) => {
  const activeBrandID = useActiveBrandID();
  const campaignsQuery = useInfiniteQuery({
    queryKey: ["campaigns-list", activeBrandID, status],
    queryFn: async ({ pageParam }): Promise<CampaignsApiResponse> => {
      const { data } = await axios.get("api/v1/campaign/my-campaigns", {
        params: {
          brand_id: activeBrandID,
          offset: pageParam * 3,
          limit: limit,
          status,
        },
      });
      return data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.has_more ? lastPageParam + 1 : undefined,
    enabled: !!activeBrandID,
  });

  const metricsQuery = useQuery({
    queryKey: ["email-metrics", activeBrandID],
    queryFn: async (): Promise<CampaignsApiMetricsResponse> => {
      const { data } = await axios.get("api/v1/cdp/klaviyo/metrics", {
        params: {
          brand_id: activeBrandID,
        },
      });
      return data;
    },
    enabled: !!activeBrandID,
  });

  return { campaignsQuery, metricsQuery };
};

export default useGetCampaignList;

import { Text, TextProps } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const TextClamp = styled(Text)<{
  clamp?: number;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type DraperTextProps = TextProps & {
  clamp?: number;
  colorOverride?: string;
};

const DraperText = React.forwardRef<HTMLDivElement, DraperTextProps>(
  (props, ref) => {
    return (
      <TextClamp {...props} ref={ref} style={{ color: props.colorOverride }}>
        {props.children}
      </TextClamp>
    );
  }
);

export default DraperText;

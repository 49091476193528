import ModifierContainer from "./ModifierContainer";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { CropIcon, ImageIcon } from "lucide-react";
import styled from "styled-components";
import DraperButton from "~/components/core/buttons/DraperButton";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import { getFilenameFromUrl } from "~/utils/helpers";

// TODO: Use ImageSelector if possible
const Filename = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 230px;
  color: #888282;
  font-size: 14px;
`;

const LogoPreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  padding: 16px;
  border: 1px dashed #ddd7d7;
`;

const img =
  "https://images.unsplash.com/photo-1560980395-b24f709c3774?q=80&w=2068&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const FeaturedImageSelector = () => {
  const dimensions = useGetImageDimensionsAndSize(img);

  return (
    <ModifierContainer title="Featured Image">
      <Flex gap="16px">
        <LogoPreview src={img} alt="logo" />
        <Flex direction="column" gap="12px">
          <Filename>{getFilenameFromUrl(img)}</Filename>
          <Filename>{dimensions}</Filename>
        </Flex>
      </Flex>
      <Grid columns="2" gap="12px">
        <DraperButton variant="outlined" text="Change" icon={<ImageIcon />} />
        <DraperButton variant="outlined" text="Crop" icon={<CropIcon />} />
      </Grid>
    </ModifierContainer>
  );
};

export default FeaturedImageSelector;

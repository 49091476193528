import { Flex, Text } from "@radix-ui/themes";
import React from "react";

const AddButtonSection = ({
  children,
  title,
  rightSlot,
}: {
  children: React.ReactNode;
  title: string;
  rightSlot?: React.ReactNode;
}) => {
  return (
    <Flex direction="column" gap="8px" pb="24px" px="24px">
      <Flex justify="between" align="center">
        <Text weight="medium">{title}</Text>
        {rightSlot}
      </Flex>
      {children}
    </Flex>
  );
};

export default AddButtonSection;

import { Flex, TextField } from "@radix-ui/themes";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import React from "react";

type InputProps = React.ComponentProps<typeof TextField.Root> & {
  onValueChange: (value: number) => void;
};

const NumberInput = ({ onValueChange, ...props }: InputProps) => {
  return (
    <TextField.Root
      onChange={(e) => onValueChange(Number(e.currentTarget.value))}
      type="number"
      size="3"
      {...props}
    >
      <TextField.Slot style={{ margin: "0px", padding: "0px" }} side="right">
        <Flex
          py="4px"
          direction="column"
          style={{ borderLeft: "1px solid #DDD7D7", cursor: "pointer" }}
        >
          <Flex
            onClick={() => onValueChange(Number(props.value) + 1)}
            style={{ padding: "0px 4px" }}
          >
            <ChevronUpIcon width="16px" height="16px" />
          </Flex>
          <Flex
            onClick={() => onValueChange(Number(props.value) - 1)}
            style={{ padding: "0px 4px", borderTop: "1px solid #DDD7D7" }}
          >
            <ChevronDownIcon width="16px" height="16px" />
          </Flex>
        </Flex>
      </TextField.Slot>
    </TextField.Root>
  );
};

export default NumberInput;

import { operations } from "../../../openapi";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type CreateCampaignResponse =
  operations["campaign_api_create_campaign"]["responses"][200]["content"]["application/json"];

type CreateCampaignRequestData =
  operations["campaign_api_create_campaign"]["requestBody"]["content"]["application/json"];

const useCreateCampaignMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: CreateCampaignResponse) => void;
  onError: (error: Error) => void;
}) => {
  const createCampaign = useDraperApiPostMutation<
    CreateCampaignResponse,
    CreateCampaignRequestData
  >({
    mutationKey: ["campaign"],
    path: "/campaign/",
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });

  return {
    createCampaign: createCampaign.mutate,
    isLoading: createCampaign.isPending,
  };
};

export default useCreateCampaignMutation;

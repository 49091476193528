import { Select } from "@radix-ui/themes";
import React from "react";

const DropdownSelect = ({
  options,
  variant,
  ...props
}: React.ComponentProps<typeof Select.Root> & {
  variant?: "classic" | "ghost" | "surface" | "soft";
  options: {
    label: string;
    value: string;
  }[];
}) => {
  return (
    <Select.Root size="3" {...props}>
      <Select.Trigger variant={variant} radius="large" color="gray" />
      <Select.Content color="gray" highContrast>
        {options.map((option) => (
          <Select.Item key={option.value} value={option.value}>
            {option.label}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

export default DropdownSelect;

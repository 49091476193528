import useGetCampaignRecommendations from "../../hooks/recommendations/useGetCampaignRecommendations";
import RecommenderCard from "./RecommenderCard";
import RecommenderSelector from "./RecommenderSelector";
import { Flex, Skeleton } from "@radix-ui/themes";
import { useState } from "react";

const RecommenderSection = () => {
  const { data, isLoading } = useGetCampaignRecommendations({ page: 0 });
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const cardWidth = document.getElementById(
    data?.pages[0]?.campaign_recommendations[0]?.id ?? ""
  )?.clientWidth;
  const cardsGap = 48;

  const allRecommendations = data?.pages.flatMap(
    (page) => page.campaign_recommendations
  );

  if (isLoading) {
    return (
      <Skeleton
        width="826px"
        height="286px"
        style={{
          borderRadius: "12px",
        }}
      />
    );
  }

  return (
    <Flex direction="column" gap="5" width="100%" overflow="hidden">
      <Flex
        overflow="visible"
        width="100%"
        style={{
          gap: `${cardsGap}px`,
          transform: `translate(-${
            selectedCardIndex * ((cardWidth ?? 0) + cardsGap)
          }px)`,
          transition: "transform 0.5s ease",
        }}
      >
        {allRecommendations?.map((recommendation) => (
          <RecommenderCard
            key={recommendation.id}
            recommendation={recommendation}
          />
        ))}
      </Flex>
      <Flex gap="3">
        {allRecommendations?.map((_, index) => (
          <RecommenderSelector
            key={index}
            index={index}
            selectedCardIndex={selectedCardIndex}
            setSelectedCardIndex={setSelectedCardIndex}
          />
        ))}
      </Flex>
    </Flex>
  );
};
export default RecommenderSection;

import { Column } from "../base/Column";
import Image from "../base/Image";
import { Section } from "../base/Section";
import { Element } from "@craftjs/core";

const Header = (logo?: React.ReactElement) => {
  // TODO: Why doesn't creating with non function component work?
  return (
    <Element canvas is={Section} className="email-palette">
      <Element canvas is={Column} width={`${100.0}`}>
        {logo ? (
          logo
        ) : (
          <Image imgUrl="https://d3k81ch9hvuctc.cloudfront.net/company/Pb3wug/images/6b4bfbdf-052b-48e2-9473-e20619c3839e.png" />
        )}
      </Element>
    </Element>
  );
};

export default Header;

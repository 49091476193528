import "./TextEditorToolbar.css";

const TextEditorToolbar = ({ id }: { id: string }) => {
  return (
    <div id={id}>
      <select className="ql-header ql-picker">
        <option value="1">Heading 1</option>
        <option value="2">Heading 2</option>
        <option value="3">Heading 3</option>
        <option value="4">Heading 4</option>
        <option value="5">Heading 5</option>
        <option value="6">Heading 6</option>
        <option value="">Normal</option>
      </select>
      <select className="ql-size ql-picker">
        <option value="" selected>
          Normal
        </option>
        <option value="small">Small</option>
        <option value="large">Large</option>
        <option value="huge">Huge</option>
      </select>
      <select className="ql-font ql-picker">
        <option value="sans-serif" selected>
          Sans Serif
        </option>
        <option value="roboto">Roboto</option>
        <option value="monospace">Monospace</option>
      </select>
      <button className="ql-bold">Bold</button>
      <button className="ql-italic">Italic</button>
      <button className="ql-underline">Underline</button>
      <button className="ql-strike">Strike</button>
      <select className="ql-color"></select>
      <select className="ql-background"></select>
      <button className="ql-link"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-clean"></button>
    </div>
  );
};

export default TextEditorToolbar;

import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type AdAccountsResponse =
  operations["meta_api_ad_accounts"]["responses"][200]["content"]["application/json"];

const useMetaAdAccountsQuery = (brandID: string | null) => {
  const {
    data: adAccounts,
    error,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["meta-ad-accounts", brandID],
    queryFn: async (): Promise<AdAccountsResponse> => {
      const response = await axios.get("/api/v1/meta/ad-accounts", {
        params: {
          brand_id: brandID,
        },
      });
      return response.data;
    },
    enabled: !!brandID,
    staleTime: 1000 * 60 * 5,
  });

  return {
    adAccounts,
    isLoading,
    isSuccess,
  };
};

export default useMetaAdAccountsQuery;

import StyleLibraryCardContent from "../StyleLibraryCardContent";
import ColorBox from "./ColorBox";
import { Box } from "@radix-ui/themes";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandStylingPaletteUpdateMutation from "~/hooks/brand/useBrandStylingPaletteUpdateMutation";

const ColorPaletteCardSection = () => {
  const activeBrandID = useActiveBrandID();
  const { data: brandStyle, isLoading } = useBrandStyle();

  const palette = brandStyle?.palettes?.[0] ?? {
    primary: "#FFFFFF",
    background: "#000000",
    secondary: "#FFFFFF",
    dark_background: "#FFFFFF",
    text_color: "#000000",
  };
  const isInitUnset = brandStyle?.palettes?.[0] == null;

  const { updateBrandStylingPalette, isLoading: isUpdating } =
    useBrandStylingPaletteUpdateMutation({
      brandID: activeBrandID,
    });

  const colors = Object.entries(palette).map(([key, value]) => {
    if (!value) return null;
    return (
      <ColorBox
        key={key}
        onSaveColor={(color) => {
          console.log({ [key]: color });
          updateBrandStylingPalette({
            ...palette,
            [key]: color,
          });
        }}
        color={value}
        label={key.replace(/_/g, " ")}
        disabled={isUpdating}
      />
    );
  });

  return (
    <StyleLibraryCardContent title="Color Palette" isLoading={isLoading}>
      <Box
        m="2"
        p="4"
        style={{
          background: "white",
          border: "1px solid rgb(228, 228, 231)",
          borderRadius: "var(--radius-3)",
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          flexWrap: "wrap",
        }}
      >
        {colors}
      </Box>
    </StyleLibraryCardContent>
  );
};

export default ColorPaletteCardSection;

import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import RadioWithText from "../../../components/campaign/wizard/inputs/RadioWithText";
import useCampaignCreativesProps from "./hooks/useCampaignCreativesProps";
import * as Label from "@radix-ui/react-label";
import {
  Box,
  Button,
  Flex,
  RadioGroup,
  ScrollArea,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { MessageSquareText, StarsIcon } from "lucide-react";
import { useEffect } from "react";
import DraperButton from "~/components/core/buttons/DraperButton";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";

const CampaignCreatives = () => {
  const {
    cardProps,
    setSelectedOption,
    selectedOption,
    creatives,
    isGenerating,
    onGenerateClick,
    onSendPrompt,
    prompt,
    setPrompt,
    isCreativeUpdating,
    campaignRecommendationCreativeMessages,
    activeMessageRef,
  } = useCampaignCreativesProps();
  const { campaignData } = useCampaignWizardState();

  const promptComponent = (
    <Flex direction={"row"} align={"end"} gap={"4"}>
      <Box width={"100%"}>
        <Label.Root htmlFor="prompt">
          <Text as="div" size={"1"} mb={"2"}>
            Prompt
          </Text>
        </Label.Root>
        <input
          id="prompt"
          type="text"
          placeholder={"eg. make the tone of voice more informal"}
          style={{
            padding: "8px",
            fontSize: "14px",
            borderRadius: "12px",
            border: "1px solid var(--border, #DDD7D7)",
            width: "100%",
            height: "45px",
          }}
          value={prompt}
          onChange={(e) => setPrompt(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevents any default behavior like submitting forms
              onSendPrompt();
            }
          }}
        />
      </Box>
      <DraperButton
        onClick={onSendPrompt}
        text={"Send"}
        icon={<MessageSquareText size={20} />}
        variant="light"
        disabled={prompt === "" || isCreativeUpdating}
      />
    </Flex>
  );

  const regenButton = (
    <Box position={"absolute"} right={"24px"} bottom={"-18px"}>
      <Button
        style={{
          color: isGenerating ? undefined : "#4C4747",
          background: "#ffffff",
          width: "max-content",
          cursor: "pointer",
        }}
        color="gray"
        variant="outline"
        radius="full"
        size="3"
        disabled={isGenerating}
        onClick={onGenerateClick}
      >
        <Spinner loading={isGenerating}>
          <StarsIcon size={20} />
        </Spinner>
        <Text size={"2"} weight={"bold"}>
          {isGenerating ? "Generating..." : "Regenerate"}
        </Text>
      </Button>
    </Box>
  );

  useEffect(() => {
    if (isCreativeUpdating) {
      const timer = setTimeout(() => {
        if (activeMessageRef.current) {
          activeMessageRef.current.scrollTop =
            activeMessageRef.current.scrollHeight;
        }
      }, 100); // Delay to ensure DOM update

      return () => clearTimeout(timer);
    }
  }, [isCreativeUpdating, activeMessageRef]);

  return (
    <CampaignWizardCard {...cardProps}>
      <Flex
        direction="column"
        gap="24px"
        style={{ maxHeight: window.innerHeight / 1.5, width: "650px" }}
      >
        <Text size="4" style={{ lineHeight: "24px", color: "#4C4747" }}>
          Here are is the concept for the campaign, want to do some adjustments
          ?
        </Text>
        <ScrollArea ref={activeMessageRef}>
          <RadioGroup.Root
            style={{
              padding: "20px 0",
            }}
            color="gray"
            highContrast
            value={selectedOption ?? undefined}
            onValueChange={(value) => setSelectedOption(value)}
          >
            <Flex
              direction="column"
              gap="24px"
              pr="4"
              position={"relative"}
              align={"end"}
            >
              {creatives.map((option, key) => (
                <RadioWithText
                  loading={isGenerating}
                  onClick={() => setSelectedOption(option.id)}
                  key={option.id}
                  value={option.id}
                  title={option.title}
                  description={option.description}
                />
              ))}
              {(campaignRecommendationCreativeMessages ?? []).map((c) => {
                const next = c.message.next_recommendation;
                return (
                  <>
                    <Box
                      style={{
                        borderRadius: "20px 8px 20px 20px",
                        border: "1px solid #DDD7D7",
                        padding: "20px",
                        backgroundColor: "#F0EDEB",
                      }}
                    >
                      {c.message.message}
                    </Box>

                    <RadioWithText
                      onClick={() => setSelectedOption(next?.id ?? "")}
                      key={next?.id}
                      value={next?.id}
                      title={next?.title}
                      description={next?.description}
                    />
                  </>
                );
              })}

              {(campaignRecommendationCreativeMessages?.length ?? 0) === 0 &&
                regenButton}
            </Flex>
          </RadioGroup.Root>
        </ScrollArea>

        {promptComponent}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignCreatives;

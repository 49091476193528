import { useNode, useEditor } from "@craftjs/core";
import { Tooltip } from "@radix-ui/themes";
import { Trash2, Grip } from "lucide-react";
import React, { useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const IndicatorDiv = styled.div`
  margin-top: -29px;

  line-height: 0.75rem;
  background: rgb(38, 128, 235);
  display: flex;
  position: fixed;
  padding: 0.5rem;
  font-size: 0.75rem;
  align-items: center;
  color: #ffffff;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

export const RenderNode = ({ render }: { render: React.ReactElement }) => {
  const { id } = useNode();
  const { actions, query, isActive } = useEditor((_, query) => ({
    isActive: query.getEvent("selected").contains(id),
  }));

  const {
    isHover,
    dom,
    displayName,
    moveable,
    deletable,
    connectors: { drag },
    name,
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    displayName: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    props: node.data.props,
    name: node.data.name,
  }));

  const currentRef = useRef<HTMLDivElement | null>(null);
  const actionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (dom) {
      if (isActive) {
        dom.classList.add("component-selected");
      } else if (isHover) {
        dom.classList.add("component-hover");
      } else {
        dom.classList.remove("component-selected");
        dom.classList.remove("component-hover");
      }
    }
  }, [dom, isActive, isHover, name]);

  const getPos = useCallback((dom: HTMLElement) => {
    const { top, left, bottom, right } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0, right: 0 };

    return {
      top: `${top > 0 ? top : bottom}px`,
      left: `${left}px`,
      bottom: `${bottom > 0 ? bottom : top}px`,
      right: `calc(100vw - ${right}px)`,
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;
    const { current: actionDOM } = actionRef;

    if (!dom) return;

    const { top, left, right } = getPos(dom);
    if (currentDOM) {
      currentDOM.style.top = top;
      currentDOM.style.left = left;
    }

    if (actionDOM) {
      actionDOM.style.top = top;
      actionDOM.style.right = right;
    }
  }, [dom, getPos]);

  useEffect(() => {
    document
      ?.getElementById("main-scrollable")
      ?.addEventListener("scroll", scroll);

    return () => {
      document
        ?.getElementById("main-scrollable")
        ?.removeEventListener("scroll", scroll);
    };
  }, [scroll]);

  const pageContainer = document.getElementById("page-container");

  return (
    <>
      {(isHover || isActive) && dom && pageContainer
        ? ReactDOM.createPortal(
            <>
              <IndicatorDiv
                ref={currentRef}
                style={{
                  left: getPos(dom).left,
                  top: getPos(dom).top,
                  zIndex: 9999,
                }}
              >
                <span className="flex-1 mr-4">{displayName}</span>
              </IndicatorDiv>
              {isActive && (
                <IndicatorDiv
                  ref={actionRef}
                  style={{
                    right: getPos(dom).right,
                    top: getPos(dom).top,
                    zIndex: 9999,
                  }}
                >
                  {moveable ? (
                    <Tooltip content="Drag block">
                      <Btn
                        style={{
                          marginRight: "4px",
                          cursor: "move",
                        }}
                        ref={(ref) => ref && drag(ref)}
                      >
                        <Grip />
                      </Btn>
                    </Tooltip>
                  ) : null}
                  {deletable ? (
                    <Tooltip content="Delete block">
                      <Btn
                        style={{
                          cursor: "pointer",
                        }}
                        onMouseDown={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          actions.delete(id);
                        }}
                      >
                        <Trash2 />
                      </Btn>
                    </Tooltip>
                  ) : null}
                </IndicatorDiv>
              )}
            </>,
            pageContainer
          )
        : null}
      {render}
    </>
  );
};

import { Flex, Skeleton, Text } from "@radix-ui/themes";
import { PencilIcon, SquareArrowOutUpRightIcon } from "lucide-react";
import DraperButton from "~/components/core/buttons/DraperButton";

const dataKeyToLabelMap: Record<string, string> = {
  orders: "Order",
  revenue: "Revenue",
  delivered: "Delivered",
  open_rate: "Open Rate",
  ctr: "CTR",
  spent: "Spent",
  estimated_profit: "Estimated Profit",
  date: "Date",
};

const CampaignCardContentRow = ({
  title,
  cells,
  showEditButton = false,
}: {
  title: string;
  cells: Record<string, any> | undefined;
  showEditButton?: boolean;
}) => {
  return (
    <Flex gapX="58px" gapY="24px" wrap="wrap">
      <Flex width="150px" gap="15px" align="center">
        <Skeleton width="48px" height="48px" style={{ borderRadius: "8px" }} />
        <Text weight="medium">{title}</Text>
        <SquareArrowOutUpRightIcon width="20px" height="20px" color="#136EC1" />
      </Flex>
      {cells &&
        Object.entries(cells).map(([key, value]) => (
          <Flex gap="3px" key={key} direction="column">
            <Text size="2">{dataKeyToLabelMap[key]}</Text>
            <Text weight="medium">{value}</Text>
          </Flex>
        ))}
      {showEditButton && (
        <DraperButton
          ml="auto"
          radius="large"
          text="Edit"
          icon={<PencilIcon />}
          variant="outlined"
        />
      )}
    </Flex>
  );
};

export default CampaignCardContentRow;

import { useNode, Node } from "@craftjs/core";
import React from "react";
import styled from "styled-components";

type SectionProps = {
  className?: string;
  children?: React.ReactNode;
  background?: string;
  color?: string;
};

const CustomSection = styled.div<SectionProps>`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  margin: 0px auto;
  max-width: 600px;
  width: 100%;
`;

const DivRoot = styled.div`
  display: table;
  width: 100%;
`;

const Td = styled.td`
  direction: ltr;
  font-size: 0px;
  padding: 10px;
  text-align: center;
`;

export const Section = ({
  children,
  className = "",
  background = "white",
  color = "black",
}: SectionProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <DivRoot ref={(ref) => ref && connect(drag(ref))}>
      <CustomSection
        className={className}
        background={background}
        color={color}
      >
        <table
          align="center"
          border={0}
          cellPadding="0"
          cellSpacing="0"
          role="presentation"
          width={"100%"}
        >
          <tbody>
            <tr>
              <Td>{children}</Td>
            </tr>
          </tbody>
        </table>
      </CustomSection>
    </DivRoot>
  );
};

Section.craft = {
  displayName: "Section",
  props: {},
  rules: {
    canDrag: () => true,
    canDrop: (targetNode: Node, currentNode: Node) => {
      return targetNode.data.name === "Body";
    },
  },
};

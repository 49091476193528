import { operations } from "../../../openapi";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type UpdateUserResponse =
  operations["user_api_update_user"]["responses"][200]["content"]["application/json"];

type UpdateUserRequestData =
  operations["user_api_update_user"]["requestBody"]["content"]["application/json"];

const useUserUpdateMutation = ({
  userID,
  onSuccess,
  onError,
}: {
  userID: string;
  onSuccess?: (data: UpdateUserResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const updateUser = useDraperApiPostMutation<
    UpdateUserResponse,
    UpdateUserRequestData
  >({
    mutationKey: ["user-update"],
    path: `/user/`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
  });

  return {
    updateUser: updateUser.mutate,
    isLoading: updateUser.isPending,
  };
};

export default useUserUpdateMutation;

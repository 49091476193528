import {
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import {
  COUPON_DEFAULTS,
  ITEM_SET_COUPON_DEFAULTS,
} from "../CampaignPromotion";
import { CampaignWizardCardProps } from "@components/campaign/wizard/cards/CampaignWizardCard";
import {
  CampaignType,
  ItemSetType,
  ShopifyDiscountType,
  ShopifyDiscountValueType,
} from "@openapi";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";

const useCampaignPromotionProps = () => {
  const dispatch = useCampaignWizardDispatch();
  const { wizardType, campaignData } = useCampaignWizardState();
  const [initDiscountValue, setInitDiscountValue] = useState<number | null>(
    null
  );
  const [discountValue, setDiscountValue] = useState<number | null>(null);
  const [discountType, setDiscountType] = useState<ShopifyDiscountValueType>(
    ShopifyDiscountValueType.percentage
  );
  const [showDiscountUI, setShowDiscountUI] = useState<boolean>(false);

  const { recommendationID } = useParams();
  const activeBrandID = useActiveBrandID();

  const { campaignRecommendation } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );

  useEffect(() => {
    const couponValue =
      wizardType === "NEW"
        ? COUPON_DEFAULTS[campaignData?.campaign_type as CampaignType]
        : wizardType === "RECOMMENDED"
        ? ITEM_SET_COUPON_DEFAULTS[
            campaignRecommendation?.item_set_type ?? ItemSetType.new_products
          ] ?? 0
        : null;
    if (couponValue) {
      setInitDiscountValue(couponValue ?? 0);
      setDiscountValue(couponValue ?? 0);
      setShowDiscountUI(true);
    } else {
      setInitDiscountValue(null);
      setDiscountValue(null);
      setShowDiscountUI(false);
    }
  }, [campaignData?.campaign_type, campaignRecommendation, wizardType]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        discount:
          discountValue && showDiscountUI
            ? {
                type: ShopifyDiscountType.coupon,
                value_type: discountType,
                amount: discountValue,
              }
            : null,
      },
    });
  }, [discountType, discountValue]);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children">>(
    () => ({
      badgeTitle: "Promotion",
      backButtonProps: {
        onClick: () => {
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: {
              currentStep:
                wizardType === "NEW"
                  ? NewCampaignWizardSteps.PRODUCT
                  : RecommendedCampaignWizardSteps.AUDIENCE,
            },
          });
        },
      },
      nextButtonProps: {
        disabled: showDiscountUI && !discountValue,
        onClick: () => {
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: {
              currentStep:
                wizardType === "NEW"
                  ? NewCampaignWizardSteps.CREATIVE_CONCEPT
                  : RecommendedCampaignWizardSteps.PRODUCT,
            },
          });
        },
      },
    }),
    [dispatch, wizardType]
  );
  const inputProps = useMemo(
    () => ({
      discountValue,
      setDiscountValue,
      discountType,
      setDiscountType,
      setShowDiscountUI,
      showDiscountUI,
      initDiscountValue,
    }),
    [
      discountValue,
      setDiscountValue,
      discountType,
      setDiscountType,
      showDiscountUI,
      setShowDiscountUI,
      initDiscountValue,
    ]
  );

  return { cardProps, inputProps };
};

export default useCampaignPromotionProps;

import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import LoadingDots from "../../../components/campaign/wizard/misc/LoadingDots";
import {
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
} from "../../../contexts/CampaignWizardContext";
import { Flex, Text } from "@radix-ui/themes";
import { useParams } from "react-router-dom";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";

const CampaignSummary = () => {
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();
  const dispatch = useCampaignWizardDispatch();

  const { campaignRecommendation, isLoading } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );

  return (
    <CampaignWizardCard
      badgeTitle="Campaign Summary"
      backButtonProps={{
        text: "All good. Let's see the creatives!",
        onClick: () => {
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: {
              currentStep: RecommendedCampaignWizardSteps.CREATIVE_CONCEPT,
            },
          });
        },
      }}
      nextButtonProps={{
        text: "Yes, let’s review.",
        onClick: () => {
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: { currentStep: RecommendedCampaignWizardSteps.CHANNEL },
          });
        },
      }}
      hideFooter={isLoading}
    >
      <Flex direction="column" gap="16px">
        <Text size="3" style={{ lineHeight: "24px", color: "#4C4747" }}>
          {campaignRecommendation?.summary ?? "Lorem ipsum dolor sit amet "}
        </Text>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <Text size="3" style={{ lineHeight: "24px", color: "#4C4747" }}>
            Do you want to quickly review and tweak key campaign choices?
          </Text>
        )}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignSummary;

import SocialLinkContainer from "./modifiers/SocialLinkContainer";
import { Flex } from "@radix-ui/themes";
import React, { useMemo } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import {
  useTemplateEditorDispatch,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";
import {
  EmailSectionSocialLinkState,
  EmailTemplateFooterState,
} from "~/types/template-editor";

const SocialLinksCustomization = () => {
  const { footerOptions } = useTemplateEditorState();
  const dispatch = useTemplateEditorDispatch();

  const links: (EmailSectionSocialLinkState & {
    selector: keyof Omit<EmailTemplateFooterState["socialLinks"], "enabled">;
  })[] = useMemo(
    () => [
      { ...footerOptions.socialLinks.facebook, selector: "facebook" },
      { ...footerOptions.socialLinks.instagram, selector: "instagram" },
      { ...footerOptions.socialLinks.twitter, selector: "twitter" },
      { ...footerOptions.socialLinks.tiktok, selector: "tiktok" },
      { ...footerOptions.socialLinks.youtube, selector: "youtube" },
    ],
    [footerOptions]
  );

  return (
    <DragDropContext onDragEnd={() => {}}>
      <StrictModeDroppable droppableId="social-links-droppable">
        {(provided) => (
          <Flex
            gap="16px"
            direction="column"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {links.map((link, index) => (
              <Draggable
                key={link.selector}
                draggableId={link.selector}
                // having an order for the social links would be complicated to handle on the template,
                //  as the image comes from the template, while the order would come from the options
                //  alternatively, the order can be adjusted directly in the template, if needed
                isDragDisabled={true}
                index={index}
              >
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                  >
                    <SocialLinkContainer
                      values={link}
                      onChange={(values) => {
                        dispatch({
                          type: "UPDATE_EDITOR_STATE",
                          payload: {
                            footerOptions: {
                              ...footerOptions,
                              socialLinks: {
                                ...footerOptions.socialLinks,
                                [link.selector]: {
                                  // ...footerOptions.socialLinks[link.selector],
                                  ...values,
                                },
                              },
                            },
                          },
                        });
                      }}
                      dragHandleProps={draggableProvided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Flex>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export default SocialLinksCustomization;

import { Flex, Grid } from "@radix-ui/themes";
import {
  MousePointerClickIcon,
  PresentationIcon,
  ShoppingCartIcon,
  SparkleIcon,
  SquareMenuIcon,
  StarIcon,
  TypeIcon,
} from "lucide-react";
import React from "react";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";

const TextContainer = styled(Flex)`
  box-shadow: 0px 12px 48px 0px #00000014;
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 20px;
`;

const BlockContainer = styled(Flex)`
  padding: 24px 16px 24px 16px;
  border: 1px solid#ddd7d7;
  border-radius: 12px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    border: 1px solid#9f9594;
    box-shadow: 0px 2px 4px 0px #0000000a;
    box-shadow: 0px 4px 16px 0px #00000014;
  }
`;

const BLOCKS = [
  {
    name: "Featured Product",
    icon: <SparkleIcon />,
  },
  {
    name: "Products",
    icon: <ShoppingCartIcon />,
  },
  {
    name: "Review",
    icon: <StarIcon />,
  },
  {
    name: "Call to Action",
    icon: <MousePointerClickIcon />,
  },
  {
    name: "Hero",
    icon: <PresentationIcon />,
  },
  {
    name: "Content",
    icon: <TypeIcon />,
  },
  {
    name: "Menu",
    icon: <SquareMenuIcon />,
  },
];

const AddBlockView = () => {
  return (
    <Flex direction="column" gap="24px" mb="24px">
      <Flex gap="16px">
        <MarkAvatar />
        <TextContainer>
          Select what type of block you want to insert
        </TextContainer>
      </Flex>
      <Grid gap="16px" columns="2">
        {BLOCKS.map((block) => (
          <BlockContainer key={block.name}>
            {block.icon}
            {block.name}
          </BlockContainer>
        ))}
      </Grid>
    </Flex>
  );
};

export default AddBlockView;

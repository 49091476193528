import LinkContainer from "./modifiers/LinkContainer";
import { Button, Flex } from "@radix-ui/themes";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import { useHeaderFooterState } from "~/contexts/TemplateEditorContext";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";

const AddLinkContainer = styled(Flex)`
  border: 1px dashed #ddd7d7;
  border-radius: 12px;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;
const MenuLinksCustomization = () => {
  const state = useHeaderFooterState();
  const updateState = useUpdateTemplateEditorState(state.type);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const links = [...state.menuLinks.links];
    const [movedLink] = links.splice(result.source.index, 1);
    links.splice(result.destination.index, 0, movedLink);

    updateState({
      ...state,
      menuLinks: {
        ...state.menuLinks,
        links,
      },
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <StrictModeDroppable droppableId="menu-links-droppable" type="group">
        {(provided) => (
          <Flex
            gap="16px"
            direction="column"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {state.menuLinks.links.map((link, index) => (
              <Draggable
                key={link.dragId}
                draggableId={link.dragId}
                index={index}
              >
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                  >
                    <LinkContainer
                      values={link}
                      onDelete={() => {
                        const links = state.menuLinks.links.filter(
                          (l) => l.dragId !== link.dragId
                        );
                        updateState({
                          ...state,
                          menuLinks: {
                            ...state.menuLinks,
                            links,
                          },
                        });
                      }}
                      onChange={(values) => {
                        const links = state.menuLinks.links.map((l) =>
                          l.dragId === link.dragId ? values : l
                        );
                        updateState({
                          ...state,
                          menuLinks: {
                            ...state.menuLinks,
                            links,
                          },
                        });
                      }}
                      dragHandleProps={draggableProvided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <AddLinkContainer>
              <Button
                onClick={() =>
                  updateState({
                    ...state,
                    menuLinks: {
                      ...state.menuLinks,
                      links: [
                        ...state.menuLinks.links,
                        {
                          text: `Link ${state.menuLinks.links.length + 1}`,
                          link_url: "",
                          dragId: uuidv4(),
                          opens_new_tab: true,
                          enabled: true,
                        },
                      ],
                    },
                  })
                }
                size="4"
                variant="outline"
                color="gray"
                radius="large"
              >
                Add Link
              </Button>
            </AddLinkContainer>
          </Flex>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export default MenuLinksCustomization;

import { Flex, Radio, RadioGroup, Skeleton, Text } from "@radix-ui/themes";
import React from "react";

const RadioWithText = ({
  value,
  title,
  description,
  onClick,
  loading,
}: {
  value?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
  loading?: boolean;
}) => {
  if (loading || !value || !title || !description) {
    return (
      <Flex
        direction="column"
        p="20px"
        gap="12px"
        style={{
          border: "1px solid #DDD7D7",
          borderRadius: "20px",
          width: "100%",
        }}
        height={"175px"}
      >
        <Flex gap="8px" align="center" width={"100%"}>
          <Skeleton>
            <Radio value=""></Radio>
          </Skeleton>
          <Skeleton
            height="17px"
            width="50%"
            style={{ borderRadius: "25px" }}
          />
        </Flex>
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
      </Flex>
    );
  }

  return (
    <Flex
      onClick={onClick}
      direction="column"
      p="20px"
      gap="12px"
      style={{
        border: "1px solid #DDD7D7",
        borderRadius: "20px",
        cursor: "pointer",
      }}
    >
      <RadioGroup.Item value={value}>
        <Text style={{ fontSize: "20px", color: "#121111", fontWeight: 500 }}>
          {title}
        </Text>
      </RadioGroup.Item>
      <Text>{description}</Text>
    </Flex>
  );
};

export default RadioWithText;

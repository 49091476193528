export default function ErrorPageUnauthorized({
  title,
  message,
}: {
  title?: string;
  message?: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f8f8f8",
        color: "#333",
        textAlign: "center",
      }}
    >
      <h2 style={{ fontSize: "2rem", margin: "0" }}>
        {title ?? "Not Authorized"}
      </h2>
      <p style={{ fontSize: "1.25rem", marginTop: "1rem" }}>
        {message ?? "Please ask an admin to give you access permissions."}
      </p>
    </div>
  );
}

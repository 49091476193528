import {
  Box,
  Button,
  Flex,
  ScrollArea,
  SegmentedControl,
  Text,
} from "@radix-ui/themes";
import { ArrowLeft, ChevronLeft } from "lucide-react";
import { useRef, useState } from "react";
import { styled } from "styled-components";
import CampaignOverviewCard from "~/components/campaign/overview/CampaignOverviewCard";
import CampaignPublishDialog from "~/components/campaign/overview/CampaignPublishDialog";

const Header = styled.div`
  padding: 12px;
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px;
`;

const HorizontalScrollableBody = styled.div`
  display: flex;
  padding: 0 16px;
`;

const HorizontalScrollGrouping = styled.div`
  display: flex;
  scroll-margin-left: 16px;
`;

const CampaignOverviewRoute = () => {
  const [campaignType, setCampaignType] = useState("email");
  const emailRef = useRef<HTMLDivElement | null>(null);
  const adsRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (campaignType: string) => {
    if (campaignType === "email" && emailRef.current) {
      emailRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "start",
      });
    } else if (campaignType === "ads" && adsRef.current) {
      adsRef.current.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  };

  const campaignHomeButton = (
    <Button
      radius="large"
      color="gray"
      variant="outline"
      style={{
        cursor: "pointer",
        height: "40px",
      }}
    >
      <ChevronLeft size="20" />
      <Text as="span">Campaigns</Text>
    </Button>
  );

  return (
    <Box py={"3"}>
      <Header>
        <Flex direction="row" gap="3" align="center">
          {campaignHomeButton}
          <Text weight={"bold"}>Campaign Header</Text>
        </Flex>

        <SegmentedControl.Root
          defaultValue={campaignType}
          radius="full"
          onValueChange={(value) => {
            if (value) {
              setCampaignType(value);
              handleScroll(value);
            }
          }}
        >
          <SegmentedControl.Item value="email">
            <Text>Email</Text>
          </SegmentedControl.Item>
          <SegmentedControl.Item value="ads">Ads</SegmentedControl.Item>
        </SegmentedControl.Root>

        <CampaignPublishDialog />
      </Header>

      <ScrollArea scrollbars="horizontal">
        <HorizontalScrollableBody>
          <HorizontalScrollGrouping ref={emailRef}>
            <CampaignOverviewCard title="Email title 1" />
            <CampaignOverviewCard title="Email title 2" />
            <CampaignOverviewCard title="Email title 3" />
            <CampaignOverviewCard title="Email title 3" />
            <CampaignOverviewCard title="Email title 3" />
            <CampaignOverviewCard title="Email title 3" />
          </HorizontalScrollGrouping>
          <HorizontalScrollGrouping ref={adsRef}>
            <CampaignOverviewCard title="Ad title 1" />
            <CampaignOverviewCard title="Ad title 1" />
            <CampaignOverviewCard title="Ad title 1" />
            <CampaignOverviewCard title="Ad title 1" />
            <CampaignOverviewCard title="Ad title 1" />
            <CampaignOverviewCard title="Ad title 1" />
          </HorizontalScrollGrouping>
        </HorizontalScrollableBody>
      </ScrollArea>
    </Box>
  );
};

export default CampaignOverviewRoute;

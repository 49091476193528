import AssistantMessage from "../components/home/components/AssistantMessage";
import HomeActionPanel from "../components/home/components/HomeActionPanel";
import CampaignIdeasSection from "../components/home/components/campaignIdeasSection/CampaignIdeasSection";
import CampaignDraftSection from "../components/home/components/draftSection/CampaignDraftSection";
import RecommenderSection from "../components/home/components/recommenderSection/RecommenderSection";
import { Flex } from "@radix-ui/themes";

export default function HomeRoute() {
  return (
    <Flex
      gap="7"
      direction="column"
      style={{
        overflow: "visible",
        marginTop: "30px",
        marginLeft: "7rem",
      }}
    >
      <AssistantMessage withBackground />
      <RecommenderSection />
      <HomeActionPanel title="Have something else in mind?" />
      <CampaignDraftSection />
      <CampaignIdeasSection />
    </Flex>
  );
}

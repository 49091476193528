import { getTimeDifference } from "../../../../utils/timeUtils";
import { Campaign } from "../../hooks/useGetCampaigns";
import { Flex, IconButton, Text } from "@radix-ui/themes";
import {
  ArrowRight,
  BellDot,
  Clock4,
  EllipsisVertical,
  Infinity,
  Mail,
  MessageSquareText,
} from "lucide-react";

const CampaignDraftCard = ({ campaign }: { campaign: Campaign }) => {
  const { days, hours } = getTimeDifference(campaign.created_at);
  return (
    <Flex
      id={campaign.id}
      style={{
        boxShadow: "0px 12px 120px 0px #00000014",
        borderRadius: "12px",
        opacity: "100%",
        width: "540px",
        height: "420px",
        overflow: "hidden",
      }}
      direction="column"
    >
      <Flex style={{ height: "60%", width: "100%", overflow: "hidden" }}>
        {campaign.ad_images.slice(0, 3).map((image, index) => (
          <div
            style={{
              background: index % 2 ? "red" : "green",
              backgroundImage: `url(${image})`,
              flex: "1 1 auto",
            }}
          ></div>
        ))}
      </Flex>
      <Flex
        direction="column"
        style={{ padding: "16px 24px 30px 24px" }}
        height="40%"
        align="start"
        justify="between"
        wrap="wrap"
      >
        <Flex direction="column" gap="0" align="start">
          <Text
            as="span"
            style={{ fontWeight: 500, fontSize: "20px", color: "black" }}
          >
            {campaign.ad_title}
          </Text>
          <Flex gap="2" align="center">
            <Clock4 color="#4C4747" size="16" />
            <Text as="span" style={{ color: "#4C4747" }}>
              {days
                ? `${days} ${days === 1 ? "day" : "days"} ago`
                : `${hours} ${hours === 1 ? "hour" : "hours"} ago`}
            </Text>
            {!!campaign.channels?.emails && <Mail color="#4C4747" size="20" />}
            {!!campaign.channels?.meta_ads && (
              <Infinity color="#4C4747" size="20" />
            )}
            {!!campaign.channels?.sms && (
              <MessageSquareText color="#4C4747" size="20" />
            )}
            {!!campaign.channels?.push_notifications && (
              <BellDot color="#4C4747" size="20" />
            )}
          </Flex>
        </Flex>
        <Flex justify="between" align="center" width="100%">
          <Flex
            gap="2"
            style={{ cursor: "default" }}
            onClick={() => alert("Resume editing")}
          >
            <ArrowRight color="#4C4747" />
            <Text
              as="span"
              style={{ fontSize: "16px", fontWeight: 500, color: "#4C4747" }}
            >
              Resume Editing
            </Text>
          </Flex>
          <IconButton variant="ghost" onClick={() => alert("Card options")}>
            <EllipsisVertical color="#4C4747" />
          </IconButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default CampaignDraftCard;

import { operations } from "@openapi";
import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

export type AdCreativeApiResponse =
  operations["ads_api_get_ad_creative"]["responses"][200]["content"]["application/json"];

export type AdCreativeMediaApiResponse =
  operations["ads_api_get_ad_creative_media"]["responses"][200]["content"]["application/json"];

const useGetAdCreativeWithMedia = (adCreativeId: string | undefined) => {
  const queries = useQueries({
    queries: [
      {
        queryKey: ["ad-creative", adCreativeId],
        queryFn: async (): Promise<AdCreativeApiResponse> => {
          const { data } = await axios.get(
            `/api/v1/ads/ad-creative/${adCreativeId}`,
            {
              headers: {
                "X-CSRFToken": Cookies.get("csrftoken") ?? "",
              },
            }
          );
          return data;
        },
        enabled: !!adCreativeId,
        retry: false,
        staleTime: Infinity,
      },
      {
        queryKey: ["ad-creative-media", adCreativeId],
        queryFn: async (): Promise<AdCreativeMediaApiResponse> => {
          const { data } = await axios.get(
            `/api/v1/ads/ad-creative/${adCreativeId}/media`,
            {
              headers: {
                "X-CSRFToken": Cookies.get("csrftoken") ?? "",
              },
            }
          );
          return data;
        },
        enabled: !!adCreativeId,
        retry: false,
        staleTime: Infinity,
      },
    ],
  });

  return {
    creativeQuery: queries[0],
    mediaQuery: queries[1],
  };
};

export default useGetAdCreativeWithMedia;

import { Flex } from "@radix-ui/themes";

export default function CampaignWizardCardContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex direction="column" gap="5" width={"100%"}>
      {children}
    </Flex>
  );
}

import { AdMediaAspectRatio } from "@openapi";

export const getNumericAdMediaAspectRatio = (
  ratio: AdMediaAspectRatio
): number => {
  switch (ratio) {
    case AdMediaAspectRatio.full_screen_vertical:
      return 16.0 / 9.0;
    case AdMediaAspectRatio.landscape:
      return 1.0 / 1.91;
    case AdMediaAspectRatio.portrait:
      return 5.0 / 4.0;
    case AdMediaAspectRatio.square:
      return 1.0 / 1.0;
    default:
      throw new Error("Invalid AdMediaAspectRatio");
  }
};

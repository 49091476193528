import OnboardingStyleLibraryLoading from "./OnboardingStyleLibraryLoading";
import StyleLibraryReview from "./StyleLibraryReview";
import { useState } from "react";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";

enum StyleLibraryStep {
  LOADING = 0,
  REVIEW = 1,
}

const getStepContent = (
  step: StyleLibraryStep,
  setStep: (step: StyleLibraryStep) => void
) => {
  let content = null;
  switch (step) {
    case StyleLibraryStep.LOADING:
      content = (
        <OnboardingStyleLibraryLoading
          onNext={() => setStep(StyleLibraryStep.REVIEW)}
        />
      );
      break;
    case StyleLibraryStep.REVIEW:
      content = <StyleLibraryReview />;
      break;
    default:
      throw Error("Onboarding step not implemented");
  }

  return content;
};

const StyleLibraryContainer = () => {
  const [step, setStep] = useState<StyleLibraryStep>(StyleLibraryStep.LOADING);
  const content = getStepContent(step, setStep);
  return <BrandStylingProvider>{content}</BrandStylingProvider>;
};
export default StyleLibraryContainer;

import TemplateEditorLayout from "./TemplateEditorLayout";
import TemplateEditorModifierAside from "./TemplateEditorModifierAside";
import TemplateEditorPreview from "./TemplateEditorPreview";
import TemplateEditorTopControlBar from "./TemplateEditorTopControlBar";

export const TemplateEditorComponents = {
  Layout: TemplateEditorLayout,
  TopBar: TemplateEditorTopControlBar,
  Aside: TemplateEditorModifierAside,
  Preview: TemplateEditorPreview,
};

import { Flex, Grid } from "@radix-ui/themes";
import styled from "styled-components";

const Page = styled(Grid)`
  height: 100vh;
  width: 100vw;
`;

const Left = styled(Flex)`
  border-radius: 0 3rem 3rem 0;
  background: #f7f5f2;
  border-right: 1px solid #ddd7d7;
`;

const Right = styled(Flex)`
  align-items: center;
  background: #ffffff;
`;

const SplitPage = ({
  left,
  right,
}: {
  left: React.ReactNode;
  right: React.ReactNode;
}) => {
  return (
    <Page columns={{ initial: "1", md: "60% 1fr" }} rows={"1"}>
      <Left
        display={{
          initial: "none",
          md: "flex",
        }}
        justify={"start"}
      >
        {left}
      </Left>
      <Right justify={"center"}>{right}</Right>
    </Page>
  );
};

export default SplitPage;

import { useCurrentUserState } from "./CurrentUserContext";
import { createContext, useContext, useState } from "react";

interface DebugInterface {
  debug: boolean;
  onToggleDebug: (show: boolean) => void;
}

const DebugContext = createContext<DebugInterface>({
  debug: false,
  onToggleDebug: () => {},
});

export const DebugProvider = ({ children }: { children: React.ReactNode }) => {
  const [debug, setDebug] = useState(false);
  const toggleDebug = (show: boolean) => {
    setDebug(show);
  };

  return (
    <DebugContext.Provider value={{ debug, onToggleDebug: toggleDebug }}>
      {children}
    </DebugContext.Provider>
  );
};

export const useDebug = () => {
  const { user } = useCurrentUserState();
  const context = useContext(DebugContext);

  if (context === undefined) {
    throw new Error("useDebug must be used within a DebugProvider");
  }

  if (user.status === "LOGGED_IN" && user.isInternal) {
    return context;
  }

  return null;
};

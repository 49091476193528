import AuthPageBase from "./AuthPageBase";
import SignInLink from "./SignInLink";
import { Flex, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmEmail = () => {
  const [isError, setIsError] = useState(false);

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const key = new URLSearchParams(location.search).get("key");

    const emailConfirmData = {
      key: key,
    };
    fetch("/api/v1/user/auth/register/verify-email/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailConfirmData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.detail === "ok") {
          navigate("/login");
        } else {
          setIsError(true);
        }
      })
      .catch((_) => {
        setIsError(true);
      });
  }, [location, navigate]);

  return (
    <AuthPageBase>
      <Flex p="6" direction={"column"} gap={"2"} align={"center"}>
        {isError ? (
          <Text size={"3"} weight={"bold"}>
            There was an error confirming your email. Please try to{" "}
            <SignInLink /> again or contact customer support for help
          </Text>
        ) : (
          <Text size={"2"}>Verifying...</Text>
        )}
      </Flex>
    </AuthPageBase>
  );
};

export default ConfirmEmail;

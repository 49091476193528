import BackgroundModifier from "./modifiers/BackgroundModifier";
import FeaturedImageSelector from "./modifiers/FeaturedImageSelector";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import HeadingModifier from "./modifiers/HeadingModifier";
import LinkToModifier from "./modifiers/LinkToModifier";
import TextModifier from "./modifiers/TextModifier";
import { Flex } from "@radix-ui/themes";

const HeroView = () => {
  return (
    <Flex direction="column" gap="24px">
      <GeneratedVariantSelector />

      <LinkToModifier
        options={[
          { label: "Web", value: "web" },
          { label: "Mobile App", value: "mobile" },
        ]}
      />

      <FeaturedImageSelector />

      <HeadingModifier />

      <TextModifier />

      <BackgroundModifier />
    </Flex>
  );
};

export default HeroView;

import React, { useEffect } from "react";
import {
  useEmailDispatch,
  useEmailsState,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import useGetEmailCreativeData from "~/routes/intern/email_editor/hooks/useGetEmailCreativeData";
import useGetEmailCreativesList from "~/routes/intern/email_editor/hooks/useGetEmailCreativesList";
import useUpdateEmailCreative from "~/routes/intern/email_editor/hooks/useUpdateEmailCreative";

const useTopBarActions = () => {
  const { dispatch } = useEmailDispatch();
  const { selectedEmailId, emails } = useEmailsState();
  const currentEmail = emails?.[selectedEmailId];

  const { creativesData } = useGetEmailCreativesList();
  const { emailCreativeData } = useGetEmailCreativeData(creativesData?.[0]?.id);

  const updateEmailMutation = useUpdateEmailCreative(
    currentEmail
      ? {
          ...currentEmail,
          sections: Object.values(currentEmail?.sections),
        }
      : undefined
  );

  const onEmailChange = (newEmailId: string) => {
    dispatch({
      type: "SET_SELECTED_EMAIL",
      payload: newEmailId,
    });
  };

  useEffect(() => {
    if (!emailCreativeData || !dispatch) return;
    // Default to the first email creative
    dispatch({
      type: "ADD_EMAIL",
      payload: emailCreativeData,
    });
    dispatch({
      type: "SET_SELECTED_EMAIL",
      payload: emailCreativeData.id,
    });
  }, [emailCreativeData]);

  return {
    creativesData,
    updateEmailMutation,
    onEmailChange,
  };
};

export default useTopBarActions;

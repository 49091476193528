import { CtaButtonProps } from "./ButtonEditor";

export const replaceButtonStyles = (
  html: any,
  buttonStyles: CtaButtonProps
) => {
  html = html.replace(/{cta_button_text}/g, buttonStyles?.text || "");
  html = html.replace(
    /{button_background_color}/g,
    buttonStyles?.background_color || ""
  );
  html = html.replace(
    /{button_border_color}/g,
    buttonStyles?.border_color || ""
  );
  html = html.replace(
    /{button_border_style}/g,
    buttonStyles?.border_style || ""
  );
  html = html.replace(
    /{button_border_width}/g,
    buttonStyles?.border_width || ""
  );
  html = html.replace(
    /{button_border_radius}/g,
    buttonStyles?.border_radius || ""
  );
  html = html.replace(/{button_color}/g, buttonStyles?.color || "");

  html = html.replace(
    /{button_font_family}/g,
    buttonStyles?.font_styles?.font_family || ""
  );
  html = html.replace(
    /{button_font_size}/g,
    buttonStyles?.font_styles?.size || ""
  );
  html = html.replace(
    /{button_font_weight}/g,
    buttonStyles?.font_styles?.weight || ""
  );
  html = html.replace(
    /{button_font_style}/g,
    buttonStyles?.font_styles?.style || ""
  );
  //padding
  html = html.replace(
    /{button_padding}/g,
    buttonStyles?.padding || "10px 10px 10px 10px"
  );
  return html;
};

import useGetEmailCreativeData, {
  EmailCreative,
} from "../hooks/useGetEmailCreativeData";
import { Email } from "../hooks/useUpdateEmailCreative";
import { reduceSectionsState } from "./stateUtils";
import { operations } from "@openapi";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { EmailEditorViews } from "~/components/editor/layout/EmailEditorAside";

export type EmailSection =
  operations["emails_api_get_email_creative"]["responses"][200]["content"]["application/json"]["sections"][0];

//TODO create specific type for each email section and specific actions for updating it
type UntypedMappedSections = { [key: string]: any };
type UntypedSectionEmail = Omit<Email, "sections"> & {
  sections: UntypedMappedSections;
};
type EmailsState = {
  emails: { [key: string]: UntypedSectionEmail };
  selectedEmailId: string;
  selectedSectionId: string;
  currentEditorView: EmailEditorViews;
};

interface ActionSetSelectedEmail {
  type: "SET_SELECTED_EMAIL";
  payload: string;
}
interface ActionSetSelectedSection {
  type: "SET_SELECTED_SECTION";
  payload: string;
}
interface ActionAddEmail {
  type: "ADD_EMAIL";
  payload: EmailCreative;
}
interface HandleInputChange {
  type: "HANDLE_INPUT_CHANGE";
  payload: {
    emailId: string;
    sectionId: string;
    field: string;
    innerField?: string;
    value: string;
  };
}
interface ActionSetCurrentEditorView {
  type: "SET_CURRENT_EDITOR_VIEW";
  payload: EmailEditorViews;
}

export type EmailReducerActions =
  | ActionSetSelectedEmail
  | ActionSetSelectedSection
  | ActionAddEmail
  | HandleInputChange
  | ActionSetCurrentEditorView;

const initialState: EmailsState = {
  emails: {},
  selectedEmailId: "",
  selectedSectionId: "",
  currentEditorView: EmailEditorViews.GenerateVariant,
};

const EmailContext = createContext(initialState);
const DispatchContext = createContext<Dispatch<EmailReducerActions> | null>(
  null
);
function reducer(state: EmailsState, action: EmailReducerActions): EmailsState {
  switch (action.type) {
    case "SET_CURRENT_EDITOR_VIEW":
      return { ...state, currentEditorView: action.payload };
    case "SET_SELECTED_SECTION":
      return { ...state, selectedSectionId: action.payload };
    case "SET_SELECTED_EMAIL":
      return { ...state, selectedEmailId: action.payload };
    case "ADD_EMAIL":
      const email = action.payload as EmailCreative;
      const existingEmails = state.emails;
      if (existingEmails[email.id]) return state;
      existingEmails[email.id] = reduceSectionsState(email);
      return { ...state, emails: existingEmails };
    case "HANDLE_INPUT_CHANGE":
      const { emailId, sectionId, field, innerField, value } = action.payload;
      if (!state.emails[emailId]) throw new Error("Email not saved in state");
      if (innerField) {
        return {
          ...state,
          emails: {
            ...state.emails,
            [emailId]: {
              ...state.emails[emailId],
              sections: {
                ...state.emails[emailId].sections,
                [sectionId]: {
                  ...state.emails[emailId].sections[sectionId],
                  [field]: {
                    ...state.emails[emailId].sections[sectionId][field],
                    [innerField]: value,
                  },
                },
              },
            },
          },
        };
      } else {
        return {
          ...state,
          emails: {
            ...state.emails,
            [emailId]: {
              ...state.emails[emailId],
              sections: {
                ...state.emails[emailId].sections,
                [sectionId]: {
                  ...state.emails[emailId].sections[sectionId],
                  [field]: value,
                },
              },
            },
          },
        };
      }
  }
}
export const EmailContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [emails, dispatch] = useReducer(reducer, initialState);
  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <EmailContext.Provider value={useMemo(() => emails, [emails])}>
        {children}
      </EmailContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useEmailDispatch = () => {
  const dispatch = useContext(DispatchContext);
  const emailsState = useEmailsState();
  const { emailCreativeData, emailCreativeError, isEmailCreativeLoading } =
    useGetEmailCreativeData(emailsState?.selectedEmailId);
  useEffect(() => {
    if (!emailCreativeData || !dispatch) return;
    dispatch({
      type: "ADD_EMAIL",
      payload: emailCreativeData,
    });
  }, [emailCreativeData]);

  const handleInputChangeCurrentEmail = ({
    sectionId,
    field,
    innerField,
    value,
  }: {
    sectionId: string;
    field: string;
    innerField?: string;
    value: string;
  }) => {
    if (!emailCreativeData || !dispatch) return;
    dispatch({
      type: "HANDLE_INPUT_CHANGE",
      payload: {
        emailId: emailsState.selectedEmailId,
        sectionId: sectionId,
        field: field,
        innerField: innerField,
        value: value,
      },
    });
  };
  if (!dispatch) {
    throw new Error(
      "Email dispatch context must be used inside the email editor"
    );
  }
  return { dispatch, handleInputChangeCurrentEmail };
};

export const useEmailsState = () => {
  return useContext(EmailContext);
};

export default EmailContext;

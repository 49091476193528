import {
  AdMediaElementUnionSchema,
  Position,
  SVGElementSelectionAttributeMapping,
  SVGElementSelectionAttributeType,
} from "../../../types/ads";
import { Container, Spinner } from "@radix-ui/themes";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { SvgLoader } from "react-svgmt";

interface InternAdMediaTemplateSVGCanvasProps {
  svgUrl: string | null | undefined;
  selectedElement?: SVGElement | null;
  onElementSelect?: (element: SVGElement) => void;
  onElementMove?: (position: Position) => void;
}

interface InternAdMediaTemplateSelectedSVGElementState {
  element: SVGElement | null;
  stroke: string | null;
  strokeWidth: string | null;
  styleOutline: string | null;
}

const InternAdMediaTemplateSVGCanvas: React.FC<
  InternAdMediaTemplateSVGCanvasProps
> = ({
  svgUrl,
  selectedElement: propsSelectedElement = null,
  onElementSelect = (element: SVGElement) => {},
  onElementMove = (position: Position) => {},
}) => {
  const svgContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectedElement, setSelectedElement] =
    useState<InternAdMediaTemplateSelectedSVGElementState>({
      element: null,
      stroke: null,
      strokeWidth: null,
      styleOutline: null,
    });

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const dragStartPoint = useRef<Position | null>(null);

  const handleElementSelection = useCallback(
    (element: SVGElement | null) => {
      if (selectedElement.element) {
        const selectedElementType =
          SVGElementSelectionAttributeMapping[selectedElement.element.tagName];
        if (selectedElementType === SVGElementSelectionAttributeType.Stroke) {
          if (selectedElement.stroke) {
            selectedElement.element.setAttribute(
              "stroke",
              selectedElement.stroke
            );
          } else {
            selectedElement.element.removeAttribute("stroke");
          }
          if (selectedElement.strokeWidth) {
            selectedElement.element.setAttribute(
              "stroke-width",
              selectedElement.strokeWidth
            );
          } else {
            selectedElement.element.removeAttribute("stroke-width");
          }
        } else if (
          selectedElementType === SVGElementSelectionAttributeType.StyleOutline
        ) {
          if (selectedElement.styleOutline) {
            selectedElement.element.style.outline =
              selectedElement.styleOutline;
          } else {
            selectedElement.element.style.outline = "";
          }
        }
      }

      if (!element) return;

      const elementType = SVGElementSelectionAttributeMapping[element.tagName];
      const newState = {
        element: element,
        stroke: element.getAttribute("stroke"),
        strokeWidth: element.getAttribute("stroke-width"),
        styleOutline: element.style.outline,
      };

      if (elementType === SVGElementSelectionAttributeType.Stroke) {
        element.setAttribute("stroke", "#D32F2F");
        element.setAttribute("stroke-width", "4px");
      } else if (
        elementType === SVGElementSelectionAttributeType.StyleOutline
      ) {
        element.style.outline = "2px solid #D32F2F";
      }
      setSelectedElement(newState);
    },
    [
      selectedElement.element,
      selectedElement.stroke,
      selectedElement.strokeWidth,
      selectedElement.styleOutline,
      setSelectedElement,
    ]
  );

  const handleElementClick = (event: React.MouseEvent) => {
    const clickedElement = event.target as SVGElement;

    if (!clickedElement) {
      return;
    }

    handleElementSelection(clickedElement);

    if (onElementSelect) {
      onElementSelect(clickedElement);
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (!selectedElement.element) {
      return;
    }

    const boundingBox = selectedElement.element.getBoundingClientRect();
    if (
      event.clientX >= boundingBox.left &&
      event.clientX <= boundingBox.right &&
      event.clientY >= boundingBox.top &&
      event.clientY <= boundingBox.bottom
    ) {
      dragStartPoint.current = { x: event.clientX, y: event.clientY };
      setIsDragging(true);
    }

    event.preventDefault();
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging || !selectedElement.element || !dragStartPoint.current) {
      return;
    }

    let targetElement: SVGElement | null = selectedElement.element;

    if (targetElement.tagName.toLowerCase() === "tspan") {
      targetElement = targetElement.closest("text");
    }
    if (!targetElement) {
      return;
    }

    const dx = event.clientX - dragStartPoint.current.x;
    const dy = event.clientY - dragStartPoint.current.y;

    let x = parseFloat(targetElement.getAttribute("x") || "0");
    let y = parseFloat(targetElement.getAttribute("y") || "0");

    const transform = targetElement.getAttribute("transform");
    if (transform) {
      const translateMatch = /translate\(([^)]+)\)/.exec(transform);
      if (translateMatch) {
        const [translateX, translateY] = translateMatch[1]
          .split(",")
          .map(Number);
        x += translateX;
        y += translateY;
        targetElement.removeAttribute("transform");
      }
    }

    x += dx;
    y += dy;

    targetElement.setAttribute("x", x.toString());
    targetElement.setAttribute("y", y.toString());

    if (targetElement.tagName.toLowerCase() === "text") {
      targetElement.querySelectorAll("tspan").forEach((tspan) => {
        tspan.setAttribute("x", x.toString());
      });
    }

    if (onElementMove) {
      onElementMove({ x, y });
    }

    dragStartPoint.current = { x: event.clientX, y: event.clientY };
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    dragStartPoint.current = null;
  };

  useEffect(() => {
    handleElementSelection(propsSelectedElement);
  }, [propsSelectedElement, handleElementSelection]);

  if (!svgUrl) {
    return (
      <Container>
        <Spinner size="3" />
      </Container>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          borderRadius: "8px",
          borderWidth: "2px",
          borderColor: "#afafaf",
          overflow: "hidden",
        }}
        onClick={handleElementClick}
        onMouseMove={(event) => handleMouseMove(event.nativeEvent)}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}
        ref={svgContainerRef}
      >
        <SvgLoader width="100%" height="100%" path={svgUrl} />
      </div>
    </div>
  );
};

export default InternAdMediaTemplateSVGCanvas;

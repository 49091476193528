import "./App.css";
import ErrorPageNotFound from "./components/core/errors/ErrorPageNotFound";
import { CurrentUserProvider } from "./contexts/CurrentUserContext";
import { DebugProvider } from "./contexts/DebugContext";
import getInternRoutes from "./routes/utils/getInternRoutes";
import getPublicRoutes from "./routes/utils/getPublicRoutes";
import getRoutes from "./routes/utils/getRoutes";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import type { CSSProp } from "styled-components";

declare module "react" {
  interface Attributes {
    css?: CSSProp | undefined;
  }
}
const router = createBrowserRouter([
  ...getRoutes(),
  ...getPublicRoutes(),
  ...getInternRoutes(),
  {
    path: "/*",
    element: <ErrorPageNotFound />,
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60,
      retry: false,
    },
  },
});

function App() {
  return (
    <Theme>
      <QueryClientProvider client={queryClient}>
        <CurrentUserProvider>
          <DebugProvider>
            <RouterProvider router={router} />
          </DebugProvider>
        </CurrentUserProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Theme>
  );
}

export default App;

import { FontOrigin } from "@openapi";
import { Box, Flex, Text, TextField } from "@radix-ui/themes";
import { ChevronDown, SearchIcon } from "lucide-react";
import React, { useEffect, useState } from "react";

export interface FontSelectItem {
  name: string;
  origin: FontOrigin;
}

export interface FontSelectSection {
  title?: string;
  fonts: readonly FontSelectItem[];
}

type FontSelectProps = Omit<
  React.ComponentProps<typeof TextField.Root>,
  "onChange"
> & {
  containerStyle?: React.CSSProperties;
  onSearch: (query: string) => FontSelectSection[];
  value: string;
  onChange: (value: FontSelectItem) => void;
};

const FontSelect: React.FC<FontSelectProps> = ({
  containerStyle,
  value,
  onChange,
  onSearch,
  placeholder,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<FontSelectSection[]>([]);
  const [focused, setFocused] = useState(false);
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setResults(onSearch(search));
  }, [search]);

  const getDropdownYPosition = (): number => {
    if (!boundingRect) return 0;
    const { bottom } = boundingRect;
    const y = bottom + 4;
    if (y + 320 > window.innerHeight) {
      return window.innerHeight - 320;
    }
    return y;
  };

  useEffect(() => {
    const listener: EventListener = (event) => {
      if (!focused || !inputRef.current) {
        return;
      }
      setBoundingRect(inputRef.current.getBoundingClientRect());
    };
    if (focused) {
      window.addEventListener("scroll", listener, true);
    } else {
      window.removeEventListener("scroll", listener, true);
    }
  }, [focused]);

  return (
    <Box
      style={{
        position: "relative",
        ...containerStyle,
      }}
    >
      <TextField.Root
        ref={inputRef}
        value={focused ? search ?? value : value}
        autoComplete="off"
        onChange={(event) => setSearch(event.currentTarget.value)}
        onFocus={(event) => {
          setBoundingRect(event.target.getBoundingClientRect());
          setFocused(true);
          event.target.focus({ preventScroll: true });
        }}
        onBlur={(e) =>
          setTimeout(() => {
            if (e.target === document.activeElement) return;
            setSearch("");
            setFocused(false);
          }, 100)
        }
        onScrollCapture={() => console.log("onscrollcapture")}
        onScroll={() => console.log("onscroll")}
        radius="large"
        placeholder={placeholder ?? value}
        {...props}
      >
        <TextField.Slot side="right">
          {!focused ? <ChevronDown width={20} /> : <SearchIcon width={20} />}
        </TextField.Slot>
      </TextField.Root>
      {focused && (
        <Box
          p="2"
          mt={"2"}
          style={{
            position: "fixed",
            zIndex: 100,
            border: "1px solid #DDD7D7",
            borderRadius: "8px",
            backgroundColor: "white",
            minWidth: "200px",
            maxHeight: "300px",
            top: getDropdownYPosition(),
            width: boundingRect?.width ?? "inherit",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {results.map((section, index) => (
              <Flex key={index} direction="column" gap="12px" p={"2"}>
                <Text style={{ color: "#838690" }}>
                  {section.title?.toUpperCase()}
                </Text>
                {section.fonts.map((font) => (
                  <Text
                    key={font.name}
                    onClick={() => {
                      onChange(font);
                      setFocused(false);
                    }}
                    ml="2"
                    style={{
                      cursor: "pointer",
                      minWidth: "max-content",
                      color: "#332E2E",
                      width: "100%",
                    }}
                  >
                    {font.name}
                  </Text>
                ))}
              </Flex>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FontSelect;

import { Dispatch, SetStateAction, useMemo, useState } from "react";

export enum ProductSort {
  UNSET,
  ASC,
  DESC,
}
export type ProductsFilters = {
  previousPage: number;
  page: number;
  categoryId: string | null;
  name: string;
};
export type FilterSetters = {
  updatePage: (addend: number) => void;
  setCategoryId: Dispatch<SetStateAction<string | null>>;
  setName: Dispatch<SetStateAction<string>>;
  setNameSort: Dispatch<SetStateAction<ProductSort>>;
};
export type ProductSorters = {
  nameSort: ProductSort;
};

export const useProductsFilters = (): {
  filters: ProductsFilters;
  setters: FilterSetters;
  sorters: ProductSorters;
} => {
  //filters
  const [page, setPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(-1);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [name, setName] = useState("");

  //sorters
  const [nameSort, setNameSort] = useState<ProductSort>(ProductSort.UNSET);

  //setters
  const updatePage = (addend: number) => {
    setPreviousPage(page);
    setPage((prev) => prev + addend);
  };
  const setters = useMemo(() => {
    return {
      updatePage: updatePage,
      setCategoryId: setCategoryId,
      setName: setName,
      setNameSort: setNameSort,
    };
  }, [setCategoryId, setName, setNameSort, updatePage]);
  const filters = useMemo(() => {
    return {
      page: page,
      previousPage: previousPage,
      categoryId: categoryId,
      name: name,
    };
  }, [page, categoryId, name]);
  const sorters = useMemo(() => {
    return {
      nameSort: nameSort,
    };
  }, [nameSort]);
  return useMemo(() => {
    return {
      setters: setters,
      filters: filters,
      sorters: sorters,
    };
  }, [setters, filters, sorters]);
};

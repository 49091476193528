import { Flex, Grid, Text } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import DraperButton from "~/components/core/buttons/DraperButton";

const VariantSelector = styled(Grid)`
  gap: 8px;
  padding: 8px;
  border-radius: 12px;
  background-color: #f0edeb;
`;

const SectionTitle = styled(Text)`
  color: var(--text-secondary);
`;

const GeneratedVariantSelector = ({
  title = "Explore layout variants",
}: {
  title?: string;
}) => {
  return (
    <Flex direction="column" gap="16px">
      <SectionTitle weight="medium">{title}</SectionTitle>
      <VariantSelector columns="2">
        <DraperButton
          style={{ backgroundColor: "#ffffff" }}
          radius="large"
          variant="outlined"
          text="Previous"
          icon={<ChevronLeftIcon />}
        />
        <DraperButton
          iconPosition="right"
          style={{ backgroundColor: "#ffffff" }}
          radius="large"
          variant="outlined"
          text="Next"
          icon={<ChevronRightIcon />}
        />
      </VariantSelector>
    </Flex>
  );
};

export default GeneratedVariantSelector;

import { DropdownMenu, IconButton, Text } from "@radix-ui/themes";
import { CheckIcon, PersonStanding } from "lucide-react";
import { useCallback, useMemo } from "react";
import {
  useCurrentUserDispatch,
  useCurrentUserState,
} from "~/contexts/CurrentUserContext";
import { useDynamicHeader } from "~/contexts/DynamicHeaderContext";
import useUserUpdateMutation from "~/hooks/user/useUserUpdateMutation";

interface AdminBarProps {
  autohide?: boolean;
}

const AdminBar: React.FC<AdminBarProps> = ({ autohide = true }) => {
  const { dynamicHeader } = useDynamicHeader();
  const { user } = useCurrentUserState();
  const currentUserDispatch = useCurrentUserDispatch();
  const isLoading = user.status === "LOADING";
  const activeBrand = user.status === "LOGGED_IN" ? user.activeBrand : null;
  const brands = user.status === "LOGGED_IN" ? user.brands : null;
  const error = useMemo(() => {
    if (user.status === "LOGGED_OUT") {
      return "Please log in";
    } else if (user.status === "UNAUTHORIZED") {
      return "Unauthorized";
    }
    if (user.status === "LOGGED_IN" && !user.brands.length) {
      return "No brands found";
    }
  }, [user]);

  const { updateUser, isLoading: isUpdatingUser } = useUserUpdateMutation({
    userID: user.status === "LOGGED_IN" ? user.id : "",
  });

  const onUpdateUser = useCallback(
    (brandID: string) => {
      if (updateUser && user.status === "LOGGED_IN" && activeBrand) {
        updateUser({
          id: user.id,
          active_brand_id: brandID,
        });
      }
    },
    [updateUser, user, activeBrand]
  );

  if (autohide && (isLoading || error != null || (brands?.length ?? 0) < 2)) {
    return null;
  }

  return (
    <header
      style={{
        top: 0,
        left: "16rem",
        zIndex: 1,
        display: "flex",
        height: "3.5rem",
        alignItems: "center",
        gap: "1rem",
        borderBottom: "1px solid #e5e7eb",
        backgroundColor: "#fbfbfb",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          left: 0,
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {dynamicHeader}
      </div>
      <div
        style={{
          display: "flex",
          right: 0,
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Text size="3" weight="medium">
          {activeBrand?.name}
        </Text>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton
              radius="full"
              variant="soft"
              disabled={
                isLoading || error != null || brands == null || isUpdatingUser
              }
              color={error != null ? "crimson" : undefined}
            >
              <PersonStanding width="18" height="18" />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {brands &&
              brands?.map((b) => (
                <DropdownMenu.Item
                  key={b.id}
                  onClick={() => {
                    currentUserDispatch({
                      type: "SET_ACTIVE_BRAND_ID",
                      payload: b.id,
                    });
                    onUpdateUser(b.id);
                  }}
                  aria-selected={b.id === activeBrand?.id}
                  aria-checked={b.id === activeBrand?.id}
                  //   style={{
                  //     backgroundColor: b.id === activeBrand?.id ? "#f0f0f0" : "",
                  //   }}
                >
                  {b.id === activeBrand?.id ? (
                    <CheckIcon width={12} />
                  ) : (
                    <div style={{ width: "12px" }} />
                  )}
                  {b.name}
                </DropdownMenu.Item>
              ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </header>
  );
};

export default AdminBar;

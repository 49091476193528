const MetaLogo = ({
  color = "white",
  size = 20,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78468 5.0064C5.50055 4.9284 4.47808 5.58506 3.76315 6.40979C3.0442 7.23949 2.5378 8.33866 2.25763 9.43641C1.97906 10.5334 1.90902 11.6979 2.13364 12.6729C2.35263 13.6203 2.90975 14.5947 4.08197 14.9032C5.20025 15.1975 6.2034 14.7791 6.96985 14.2111C7.73791 13.6423 8.40292 12.8311 8.93911 12.0475C9.35937 11.4326 9.71845 10.8065 10.0002 10.2718C10.282 10.8058 10.6411 11.4326 11.0605 12.0475C11.5967 12.8311 12.2617 13.6423 13.0298 14.2111C13.7963 14.7791 14.7994 15.1975 15.9177 14.9032C17.0899 14.5947 17.647 13.6203 17.866 12.6729C18.0914 11.6979 18.0206 10.5334 17.742 9.43641C17.4619 8.33866 16.9555 7.23878 16.2365 6.40979C15.5224 5.58506 14.4999 4.92769 13.2158 5.0064C11.8197 5.0915 10.8246 6.04813 10.2474 6.77074C10.1619 6.87881 10.0794 6.98876 10.0002 7.1005C9.92107 6.98857 9.83838 6.87861 9.75226 6.77074C9.17501 6.04742 8.18071 5.09221 6.78468 5.0064ZM9.0792 8.56984C8.85136 9.07687 8.29826 10.2349 7.55918 11.3163C7.05922 12.0482 6.50209 12.7048 5.93289 13.1261C5.36368 13.5473 4.91686 13.6423 4.5441 13.5445C4.22528 13.4608 3.88633 13.1473 3.71163 12.3907C3.54417 11.6624 3.58523 10.7065 3.82917 9.74701C4.07392 8.78754 4.50384 7.89332 5.04326 7.26998C5.5867 6.64239 6.14543 6.38923 6.67277 6.42185C7.28947 6.45943 7.90457 6.92179 8.43432 7.58626C8.73059 7.95714 8.95199 8.33227 9.07839 8.56913L9.0792 8.56984ZM10.9213 8.56984C11.1483 9.07687 11.7022 10.2349 12.4405 11.3163C12.9412 12.0482 13.4984 12.7048 14.0668 13.1261C14.6368 13.548 15.0828 13.6423 15.4564 13.5445C15.7744 13.4608 16.1133 13.1473 16.288 12.3907C16.4563 11.6624 16.4152 10.7065 16.1705 9.74701C15.9257 8.78754 15.4966 7.89332 14.9564 7.26998C14.413 6.64239 13.855 6.38923 13.3269 6.42185C12.7102 6.45943 12.0951 6.92179 11.5653 7.58626C11.32 7.89752 11.1045 8.22628 10.9213 8.56913V8.56984Z"
        fill={color}
      />
    </svg>
  );
};

export default MetaLogo;

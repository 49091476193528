import { getTimeDifference } from "../../../../utils/timeUtils";
import { CampaignRecommendation } from "../../hooks/recommendations/useGetCampaignRecommendations";
import { campaignRecommendationTypeContent } from "../recommenderSection/RecommenderCard";
import { Flex, Text } from "@radix-ui/themes";
import { ArrowRight, Clock4 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import DraperText from "~/components/core/DraperText";
import { useDebug } from "~/contexts/DebugContext";

const backgroundImage = (
  src: string | undefined | null,
  left: string,
  top: string,
  width: string,
  height: string
) => {
  if (!src) {
    return null;
  }
  return (
    <img
      src={src}
      style={{
        boxShadow: "0px 2px 4px 0px #0000000A, 0px 4px 40px 0px #00000014",
        objectFit: "cover",
        position: "absolute",
        width: width,
        height: height,
        borderRadius: "8px",
        left: left,
        top: top,
      }}
    />
  );
};

const CampaignIdeasCard = ({
  recommendation,
}: {
  recommendation: CampaignRecommendation;
}) => {
  const debug = useDebug();
  const navigate = useNavigate();
  const productsData = recommendation.products ?? [];
  const { days, hours } = getTimeDifference(recommendation.created_at);

  const background =
    campaignRecommendationTypeContent[recommendation.item_set_type]?.background;

  return (
    <Flex
      id={recommendation.id}
      style={{
        boxShadow: "0px 12px 120px 0px #00000014",
        borderRadius: "12px",
        width: "100%",
        height: "414px",
        overflow: "hidden",
        position: "relative",
      }}
      direction="column"
    >
      {debug && debug.debug ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 5,
            fontSize: 10,
          }}
        >
          {recommendation.id}
        </div>
      ) : null}
      <Flex
        style={{
          height: "45%",
          width: "100%",
          background: background,
          position: "relative",
        }}
      >
        {backgroundImage(
          productsData?.[0]?.image_url,
          "4%",
          "35%",
          "28%",
          "50%"
        )}
        {backgroundImage(
          productsData?.[1]?.image_url,
          "36%",
          "5%",
          "28%",
          "70%"
        )}
        {backgroundImage(
          productsData?.[2]?.image_url,
          "68%",
          "40%",
          "28%",
          "54%"
        )}
      </Flex>
      <Flex
        direction="column"
        style={{ padding: "12px 24px 30px 24px" }}
        height="55%"
        align="start"
        justify="between"
        wrap="wrap"
      >
        <Flex direction="column" gap="0" align="start">
          <DraperText
            clamp={1}
            size={"5"}
            weight={"medium"}
            colorOverride="#000000"
          >
            {recommendation.creative_recommendations?.[0]?.title}
          </DraperText>
          <Flex gap="2" align="center">
            <Clock4 color="#4C4747" size="14" />
            <Text as="span" style={{ color: "#4C4747", fontSize: "14px" }}>
              {days
                ? `${days} ${days === 1 ? "day" : "days"} ago`
                : `${hours} ${hours === 1 ? "hour" : "hours"} ago`}
            </Text>
          </Flex>

          <DraperText clamp={5} size={"3"} colorOverride="#000000">
            {recommendation?.summary ?? "Lorem ipsum dolor sit amet "}
          </DraperText>
        </Flex>
        <Flex
          gap="2"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/campaigns/wizard/${recommendation.id}`)}
        >
          <ArrowRight color="#4C4747" />
          <Text
            as="span"
            style={{ fontSize: "16px", fontWeight: 500, color: "#4C4747" }}
          >
            Open Campaign
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default CampaignIdeasCard;

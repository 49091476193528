import {
  AdMediaTemplateCampaignTypeStrings,
  AdMediaTemplateCategoryStrings,
  AdMediaTypeStrings,
} from "../../../types/ads";
import InternAdMediaTemplateCreationDialog from "./InternAdMediaTemplateCreationDialog";
import { operations } from "@openapi";
import { Button, Container, Heading, Table } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { ChevronRight } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const StyledTable = styled(Table.Root)`
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
`;

const StyledHeader = styled(Table.Header)`
  backgroundColor: '#f5f5f5',
  textAlign: 'left',
`;

const StyledHeaderCell = styled(Table.ColumnHeaderCell)`
  padding: "10px 15px";
`;

const StyledRow = styled(Table.Row)`
  borderBottom: '1px solid #ececec',
  '&:last-child': { borderBottom: 'none' },
`;

const StyledCell = styled(Table.Cell)`
  padding: '10px 15px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
`;

type AdMediaTemplatesApiResponse =
  operations["ads_api_get_ad_media_templates"]["responses"][200]["content"]["application/json"];

const InternAdMediaTemplatesComponent = () => {
  const styles = {
    header: {
      display: "flex",
      justifyContent: "space-between",
      margin: "5px 0px 25px",
    },
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const navigate = useNavigate();

  const templatesQuery = useQuery<AdMediaTemplatesApiResponse>({
    queryKey: ["ad-media-templates"],
    queryFn: async (): Promise<AdMediaTemplatesApiResponse> => {
      const response = await axios.get("/api/v1/ads/ad-media-templates", {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return response.data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const handleTableRowClick = (templateId: string) => {
    navigate(`/intern/ads/template/${templateId}`);
  };

  const handleTemplateCreate = () => {
    templatesQuery.refetch();
  };

  return (
    <Container>
      <div style={styles.header}>
        <Heading>Media Templates</Heading>
        <Button onClick={() => setIsDialogOpen(true)}>+ Create Template</Button>
      </div>
      <StyledTable variant="surface">
        <StyledHeader>
          <Table.Row>
            <StyledHeaderCell>Name</StyledHeaderCell>
            <StyledHeaderCell>Description</StyledHeaderCell>
            <StyledHeaderCell>Type</StyledHeaderCell>
            <StyledHeaderCell>Category</StyledHeaderCell>
            <StyledHeaderCell>Campaign Type</StyledHeaderCell>
            <StyledHeaderCell></StyledHeaderCell>
          </Table.Row>
        </StyledHeader>
        <Table.Body>
          {templatesQuery.data?.templates.map((template) => (
            <StyledRow
              key={template.id}
              onClick={() => handleTableRowClick(template.id)}
              style={{ cursor: "pointer" }}
            >
              <StyledCell>{template.name}</StyledCell>
              <StyledCell>{template.description}</StyledCell>
              <StyledCell>{AdMediaTypeStrings[template.type]}</StyledCell>
              <StyledCell>
                {AdMediaTemplateCategoryStrings[template.category]}
              </StyledCell>
              <StyledCell>
                {AdMediaTemplateCampaignTypeStrings[template.campaign_type]}
              </StyledCell>
              <StyledCell>
                <ChevronRight
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    paddingLeft: "0.25rem",
                  }}
                />
              </StyledCell>
            </StyledRow>
          ))}
        </Table.Body>
      </StyledTable>
      <InternAdMediaTemplateCreationDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onCreate={handleTemplateCreate}
      />
    </Container>
  );
};

export default InternAdMediaTemplatesComponent;

import classes from "./BrandAssets.module.css";
import { components } from "@openapi";

export type ImageAssetSchema = components["schemas"]["ImageAssetSchema"];

interface BrandImageAssetProps {
  url: string;
}
const BrandImageAsset: React.FC<BrandImageAssetProps> = ({ url }) => {
  return (
    <div
      className={classes.asset}
      style={{
        backgroundImage: `url(${url})`,
      }}
    />
  );
};

export default BrandImageAsset;

import BrandFontSelect from "../../typography/BrandFontSelect/BrandFontSelect";
import { FontSelectItem } from "../../typography/BrandFontSelect/FontSelect";
import { Flex, IconButton } from "@radix-ui/themes";
import { BoldIcon, ItalicIcon } from "lucide-react";
import React, { CSSProperties } from "react";
import NumberInput from "~/components/core/inputs/NumberInput";

export interface AddButtonFontProps {
  isBold: boolean;
  isItalic: boolean;
  fontSize: number;
  fontFamily: CSSProperties["fontFamily"];
  onBoldChange: (isBold: boolean) => void;
  onItalicChange: (isItalic: boolean) => void;
  onFontSizeChange: (fontSize: number) => void;
  onFontFamilyChange: (fontFamily: FontSelectItem) => void;
}

const AddButtonFont = ({
  fontFamily,
  fontSize,
  isBold,
  isItalic,
  onBoldChange,
  onFontFamilyChange,
  onFontSizeChange,
  onItalicChange,
}: AddButtonFontProps) => {
  return (
    <Flex gap="16px" align="center">
      <NumberInput
        radius="large"
        value={fontSize}
        onValueChange={(v) => onFontSizeChange(v)}
      />
      <BrandFontSelect
        size={"3"}
        value={fontFamily ?? ""}
        onChange={(font) => onFontFamilyChange(font)}
        loadSelectedGoogleFont={true}
      />
      <IconButton
        onClick={() => onBoldChange(!isBold)}
        color="gray"
        variant={isBold ? "solid" : "ghost"}
      >
        <BoldIcon />
      </IconButton>
      <IconButton
        onClick={() => onItalicChange(!isItalic)}
        color="gray"
        variant={isItalic ? "solid" : "ghost"}
      >
        <ItalicIcon />
      </IconButton>
    </Flex>
  );
};

export default AddButtonFont;

import { EmailSectionType } from "@openapi";
import React, { useCallback } from "react";
import { useTemplateEditorDispatch } from "~/contexts/TemplateEditorContext";
import { TemplateEditorInterface } from "~/types/template-editor";

const useUpdateTemplateEditorState = (variant: EmailSectionType) => {
  const dispatch = useTemplateEditorDispatch();

  return useCallback(
    (
      payload: Partial<
        | TemplateEditorInterface["footerOptions"]
        | TemplateEditorInterface["headerOptions"]
      >
    ) =>
      dispatch({
        type: "UPDATE_EDITOR_STATE",
        payload: {
          [`${variant}Options`]: {
            ...payload,
          },
        },
      }),
    [dispatch, variant]
  );
};

export default useUpdateTemplateEditorState;

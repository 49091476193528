import { Flex, Grid, Text } from "@radix-ui/themes";
import React from "react";
import NumberInput from "~/components/core/inputs/NumberInput";

interface Paddings {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface AddButtonPaddingProps {
  onPaddingChange: (padding: Paddings) => void;
  padding: Paddings;
  isAppliedForAllSides: boolean;
}

const AddButtonPadding = ({
  isAppliedForAllSides,
  onPaddingChange,
  padding: { top, right, bottom, left },
}: AddButtonPaddingProps) => {
  // TODO: add designs for displaying custom paddings
  if (isAppliedForAllSides) {
    return (
      <NumberInput
        value={top || right || bottom || left}
        radius="large"
        onValueChange={(v) =>
          onPaddingChange({ top: v, right: v, bottom: v, left: v })
        }
      />
    );
  }

  return (
    <Grid gapX="24px" gapY="16px" columns="2">
      <Flex direction="column" gap="4px">
        <Text style={{ color: "#888282", fontWeight: 400, fontSize: "14px" }}>
          Top
        </Text>
        <NumberInput
          radius="large"
          value={top}
          onValueChange={(v) =>
            onPaddingChange({ top: v, right, bottom, left })
          }
        />
      </Flex>
      <Flex direction="column" gap="4px">
        <Text style={{ color: "#888282", fontWeight: 400, fontSize: "14px" }}>
          Bottom
        </Text>
        <NumberInput
          radius="large"
          value={bottom}
          onValueChange={(v) =>
            onPaddingChange({ top, bottom: v, right, left })
          }
        />
      </Flex>
      <Flex direction="column" gap="4px">
        <Text style={{ color: "#888282", fontWeight: 400, fontSize: "14px" }}>
          Left
        </Text>
        <NumberInput
          radius="large"
          value={left}
          onValueChange={(v) =>
            onPaddingChange({
              top,
              right,
              bottom,
              left: v,
            })
          }
        />
      </Flex>
      <Flex direction="column" gap="4px">
        <Text style={{ color: "#888282", fontWeight: 400, fontSize: "14px" }}>
          Right
        </Text>
        <NumberInput
          radius="large"
          value={right}
          onValueChange={(v) =>
            onPaddingChange({
              top,
              right: v,
              bottom,
              left,
            })
          }
        />
      </Flex>
    </Grid>
  );
};

export default AddButtonPadding;

import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CampaignWizardCardContent from "../../../components/campaign/wizard/cards/CampaignWizardCardContent";
import useCampaignTypeProps, {
  CampaignTypeUnion,
  campaignTypeButtonText,
} from "./hooks/useCampaignTypeProps";
import { Button, Grid, Text } from "@radix-ui/themes";

const CampaignType = () => {
  const { cardProps, setCampaignType, campaignType } = useCampaignTypeProps();
  return (
    <CampaignWizardCard {...cardProps}>
      <CampaignWizardCardContent>
        <Text>What type of campaign will this be?</Text>
        <Grid columns="2" gap="16px">
          {Object.keys(campaignTypeButtonText).map((value) => (
            <Button
              key={value}
              onClick={() => setCampaignType(value as CampaignTypeUnion)}
              variant="outline"
              color="gray"
              radius="large"
              size="4"
              style={{
                width: "310px",
                fontWeight: 500,
                color: "#4C4747",
                fontSize: "16px",
                cursor: "pointer",
                backgroundColor:
                  campaignType === value ? "#F7ECDC80" : undefined,
              }}
            >
              {campaignTypeButtonText[value as CampaignTypeUnion].icon}
              {campaignTypeButtonText[value as CampaignTypeUnion].text}
            </Button>
          ))}
        </Grid>
      </CampaignWizardCardContent>
    </CampaignWizardCard>
  );
};

export default CampaignType;

import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import useCampaignPromotionProps from "./hooks/useCampaignPromotionProps";
import { CampaignType, ItemSetType, ShopifyDiscountValueType } from "@openapi";
import {
  Button,
  Flex,
  SegmentedControl,
  Text,
  TextField,
  Box,
} from "@radix-ui/themes";

export const COUPON_DEFAULTS: Record<CampaignType, number | null> = {
  [CampaignType.inventory_clearance]: 15,
  [CampaignType.sales_event]: 25,
  [CampaignType.new_products]: null, // Unused, need this for typing
  [CampaignType.other]: null, // Unused, need this for typing
};

export const ITEM_SET_COUPON_DEFAULTS = {
  [ItemSetType.slower_moving_goods]: 20,
  [ItemSetType.slowest_moving_goods]: 25,
  [ItemSetType.new_products]: null,
  [ItemSetType.best_sellers]: null,
  [ItemSetType.discounted_products]: null,
  [ItemSetType.new_collection]: null,
  [ItemSetType.purchased_together]: null,
  [ItemSetType.new_hero_product]: null,
};

const CampaignPromotion = () => {
  const {
    cardProps,
    inputProps: {
      discountType,
      discountValue,
      setDiscountType,
      setDiscountValue,
      showDiscountUI,
      setShowDiscountUI,
      initDiscountValue,
    },
  } = useCampaignPromotionProps();

  const yesNoComponent = (
    <Flex direction={"column"} gap="4">
      <Flex gap="4">
        <Button
          color="gray"
          size="4"
          radius="large"
          variant="outline"
          onClick={() => setShowDiscountUI(false)}
          style={{
            backgroundColor: showDiscountUI ? undefined : "#F7ECDC80",
          }}
        >
          No
        </Button>
        <Button
          color="gray"
          size="4"
          radius="large"
          variant="outline"
          onClick={() => setShowDiscountUI(true)}
          style={{
            backgroundColor: showDiscountUI ? "#F7ECDC80" : undefined,
          }}
        >
          Yes
        </Button>
      </Flex>
    </Flex>
  );

  let promotionContent = (
    <Flex direction={"column"} gap="4">
      <Text size="3" style={{ lineHeight: "24px", color: "#4C4747" }}>
        {initDiscountValue
          ? `We recommend a ${initDiscountValue}% promotion for this campaign. Do you
          want to keep this discount?`
          : "Would you like to include a promotion as part of this campaign?"}
      </Text>

      <Flex align={"start"} gap="4" direction="column">
        {yesNoComponent}

        {showDiscountUI ? (
          <Box>
            <Text size="1">Coupon</Text>
            <TextField.Root
              color="gold"
              value={discountValue ?? ""}
              onChange={(e) =>
                setDiscountValue(parseInt(e.currentTarget.value))
              }
              type="number"
              style={{
                fontWeight: 500,
                color: "#4C4747",
                fontSize: "16px",
                height: "50px",
                padding: "0px 4px",
                width: "min-content",
                minWidth: "160px",
              }}
              radius="large"
              size="3"
            >
              <TextField.Slot pl="2px" side="right">
                <SegmentedControl.Root
                  onValueChange={(value) =>
                    setDiscountType(value as ShopifyDiscountValueType)
                  }
                  radius="large"
                  value={discountType}
                >
                  <SegmentedControl.Item
                    style={{
                      color:
                        discountType === "percentage" ? "#121111" : "#888282",
                    }}
                    value="percentage"
                  >
                    %
                  </SegmentedControl.Item>
                  <SegmentedControl.Item
                    style={{
                      color:
                        discountType === "fixed_amount" ? "#121111" : "#888282",
                    }}
                    value="fixed_amount"
                  >
                    $
                  </SegmentedControl.Item>
                </SegmentedControl.Root>
              </TextField.Slot>
            </TextField.Root>
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );

  return (
    <CampaignWizardCard {...cardProps}>
      <Flex direction="column" gap="24px">
        {promotionContent}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignPromotion;

function useCampaignWizard(): {
  wizardType: any;
  campaignData: any;
  campaignRecommendation: any;
} {
  throw new Error("Function not implemented.");
}

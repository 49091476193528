import { replaceButtonStyles } from "./htmlUtils";
import { getButtonSeparatedPaddings, getButtonStringPadding } from "./utils";
import { components } from "@openapi";
import { Flex } from "@radix-ui/themes";
import { useEffect, useState } from "react";

export type CtaButtonProps = components["schemas"]["EmailButtonElementSchema"];
const buttonHtml = `<td align="center" bgcolor="{button_background_color}" role="presentation" style="cursor:auto;font-style:normal;mso-padding-alt:22px 40px 22px 40px;background:{button_background_color};" valign="middle">
  <p style="padding-bottom:0; display:inline-block; background:{button_background_color}; color:{button_color}; font-family:{button_font_family}; font-size:{button_font_size}; font-style:{button_font_style}; font-weight:{button_font_weight};border: {button_border_width} {button_border_style} {button_border_color}; line-height:100%; letter-spacing:0; margin:0; text-decoration:none; text-transform:none; padding:{button_padding}; mso-padding-alt:0;border-radius:{button_border_radius}">
    {cta_button_text}
  </p>
</td>`;
const ButtonEditor = ({
  handleInputChange,
  existingStyles,
  sectionId,
}: {
  handleInputChange: ({
    sectionId,
    field,
    innerField,
    value,
  }: {
    sectionId: string;
    field: string;
    innerField?: string;
    value: any;
  }) => void;
  existingStyles: any;
  sectionId: string;
}) => {
  const [buttonProps, setButtonProps] =
    useState<CtaButtonProps>(existingStyles);
  const [buttonPadding, setButtonPadding] = useState<number[] | undefined>(
    undefined
  );
  useEffect(() => {
    if (buttonProps) {
      return;
    }
    setButtonProps(existingStyles);

    const paddings = getButtonSeparatedPaddings(existingStyles?.padding);
    setButtonPadding(paddings);
  }, existingStyles);
  const handleButtonChange = (field: keyof CtaButtonProps, value: any) => {
    setButtonProps((prevButtonProps) => {
      const newProps = { ...prevButtonProps, [field]: value };
      return newProps;
    });
  };
  const handleButtonPaddingChange = (index: number, value: number) => {
    setButtonPadding((prev) => {
      if (!prev) {
        return prev;
      }
      prev[index] = value;
      handleButtonChange("padding", getButtonStringPadding(prev));
      return prev;
    });
  };
  return (
    <Flex direction="column">
      <label>Title:</label>
      <input
        type="text"
        value={buttonProps?.text || ""}
        onChange={(e) => handleButtonChange("text", e.target.value)}
      />
      <label>Background Color:</label>
      <input
        type="text"
        value={buttonProps?.background_color || ""}
        onChange={(e) => handleButtonChange("background_color", e.target.value)}
      />
      <label>Border color:</label>
      <input
        type="text"
        value={buttonProps?.border_color || ""}
        onChange={(e) => handleButtonChange("border_color", e.target.value)}
      />
      <label>Border style:</label>
      <input
        type="text"
        value={buttonProps?.border_style || ""}
        onChange={(e) => handleButtonChange("border_style", e.target.value)}
      />
      <label>Border width:</label>
      <input
        type="text"
        value={buttonProps?.border_width || ""}
        onChange={(e) => handleButtonChange("border_width", e.target.value)}
      />
      <label>Border radius:</label>
      <input
        type="text"
        value={buttonProps?.border_radius || ""}
        onChange={(e) => handleButtonChange("border_radius", e.target.value)}
      />
      <label>Text color:</label>
      <input
        type="text"
        value={buttonProps?.color || ""}
        onChange={(e) => handleButtonChange("color", e.target.value)}
      />
      <h2 style={{ fontWeight: "bold" }}>Padding</h2>
      <label>Top:</label>
      <input
        type="number"
        value={buttonPadding?.[0] || ""}
        onChange={(e) => handleButtonPaddingChange(0, e.target.valueAsNumber)}
      />
      <label>Right:</label>
      <input
        type="number"
        value={buttonPadding?.[1] || ""}
        onChange={(e) => handleButtonPaddingChange(1, e.target.valueAsNumber)}
      />
      <label>Bottom:</label>
      <input
        type="number"
        value={buttonPadding?.[2] || ""}
        onChange={(e) => handleButtonPaddingChange(2, e.target.valueAsNumber)}
      />
      <label>Left:</label>
      <input
        type="number"
        value={buttonPadding?.[3] || ""}
        onChange={(e) => handleButtonPaddingChange(3, e.target.valueAsNumber)}
      />
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: replaceButtonStyles(buttonHtml, buttonProps),
          }}
        />
      </div>
    </Flex>
  );
};
export default ButtonEditor;

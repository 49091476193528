import { MAX_FEATURED_PRODUCTS } from "../ProductSelectorDialog";
import { ProductSort } from "../hooks/useProductsFilters";
import ProductCheckbox from "./ProductCheckbox";
import styles from "./ProductsTable.module.css";
import * as Avatar from "@radix-ui/react-avatar";
import { Flex, IconButton, Table, Text, Tooltip } from "@radix-ui/themes";
import { ChevronDown, ChevronRight, ChevronUp, Star } from "lucide-react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

const ProductSortIcons = new Map<ProductSort, React.ReactNode>([
  [ProductSort.ASC, <ChevronUp size="18px" />],
  [ProductSort.DESC, <ChevronDown size="18px" />],
  [ProductSort.UNSET, <ChevronRight size="18px" />],
]);

const ProductsTable = ({
  products,
  selectProduct,
  selectProducts,
  deselectProduct,
  selectedProducts,
  setNameSort,
  nameSort,
  onFeatureToggle,
}: {
  selectedProducts: Map<string, MaybeFeaturedProduct>;
  products: MaybeFeaturedProduct[];
  selectProduct: (product: MaybeFeaturedProduct) => void;
  selectProducts?: (product: MaybeFeaturedProduct) => void;
  deselectProduct: (product: MaybeFeaturedProduct) => void;
  setNameSort: Dispatch<SetStateAction<ProductSort>>;
  nameSort: ProductSort;
  onFeatureToggle: (product: MaybeFeaturedProduct) => void;
}) => {
  const nameIcon: React.ReactNode = ProductSortIcons.get(nameSort);

  const featuredProductsCount = useMemo(() => {
    return Array.from(selectedProducts.values()).filter((p) => p.is_featured)
      .length;
  }, [selectedProducts]);

  return (
    <Table.Root variant="ghost">
      <Table.Header style={{ borderBottom: "none", padding: "0px" }}>
        <Table.Row style={{ borderBottom: "none", padding: "0px" }}>
          <Table.ColumnHeaderCell>
            <p>Select</p>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <IconButton
              variant="ghost"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() =>
                setNameSort(
                  (prev) => (prev + 1) % (Object.keys(ProductSort).length / 2)
                )
              }
            >
              <p>Name</p>
              {nameIcon}
            </IconButton>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <p>Stock</p>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <p>Price</p>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <p>Featured</p>
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body className={styles.productsTable}>
        {products.map((product) => {
          const featureButton = (
            <IconButton
              variant="ghost"
              radius="full"
              size={"2"}
              color="gray"
              style={{
                cursor:
                  product.is_featured ||
                  featuredProductsCount < MAX_FEATURED_PRODUCTS
                    ? "pointer"
                    : "not-allowed",
              }}
              disabled={
                !product.is_featured &&
                featuredProductsCount >= MAX_FEATURED_PRODUCTS
              }
              onClick={() => onFeatureToggle(product)}
            >
              <Star
                fill={product.is_featured ? "#595D62" : "none"}
                strokeWidth={1}
                stroke="#595D62"
              />
            </IconButton>
          );

          return (
            <Table.Row align="center" key={product.product_id}>
              <Table.RowHeaderCell>
                <Flex align="center" gap="3">
                  <ProductCheckbox
                    handleCheck={selectProduct}
                    handleMultipleCheck={selectProducts}
                    product={product}
                    handleUncheck={deselectProduct}
                    isChecked={selectedProducts.has(product.product_id)}
                  />
                  <Avatar.Root>
                    <Avatar.Image
                      src={product.image_url ?? ""}
                      alt="image"
                      width="40px"
                      height="40px"
                      style={{
                        borderRadius: "20%",
                        border: "1px solid #0000000A",
                        display: "block",
                      }}
                    ></Avatar.Image>
                    <Avatar.Fallback
                      style={{
                        borderRadius: "20%",
                        display: "block",
                        width: "40px",
                        height: "40px",
                        background: "grey",
                      }}
                    />
                  </Avatar.Root>
                </Flex>
              </Table.RowHeaderCell>
              <Table.Cell>
                <Flex direction="column">
                  <Text
                    as="span"
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    {product.title}
                  </Text>
                  <Text
                    as="span"
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#888282",
                    }}
                  >
                    {product.category_name}
                  </Text>
                </Flex>
              </Table.Cell>
              <Table.Cell>{product.total_inventory}</Table.Cell>
              <Table.Cell>{`${product.price}$`}</Table.Cell>
              <Table.Cell align="center" justify={"center"}>
                {featuredProductsCount >= MAX_FEATURED_PRODUCTS &&
                !product.is_featured ? (
                  <Tooltip content="Only 6 featured products are allowed">
                    {featureButton}
                  </Tooltip>
                ) : (
                  featureButton
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
};
export default ProductsTable;

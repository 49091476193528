export const sleep = (ms = 1000) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getImageMetaFromUrl = async (url: string) => {
  try {
    const img = new Image();
    img.src = url;
    await img.decode();
    return img;
  } catch (error) {
    console.error("Error retrieving image meta:", error);
    return null;
  }
};

export const getFilenameFromUrl = (url: string) => {
  return url.split("/").pop();
};

export async function getFileSizeFromUrl(url: string): Promise<string | null> {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch: ${response.status} ${response.statusText}`
      );
    }

    const contentLength = response.headers.get("Content-Length");

    if (contentLength) {
      const fileSizeInBytes = parseInt(contentLength, 10);
      const fileSizeInKB = fileSizeInBytes / 1024;
      return fileSizeInKB.toFixed(2);
    } else {
      console.warn("Content-Length header is not available.");
      return null;
    }
  } catch (error) {
    console.error("Error retrieving file size:", error);
    return null;
  }
}

export const isSomeEnum = (enumType: any, value: any) => {
  return Object.values(enumType).includes(value);
};

export const timestampToDateString = (timestamp: string) => {
  return new Date(timestamp).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

import { Flex, Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const Root = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  background: #fff;
`;

const Footer = styled(Flex)`
  border-top: 1px solid #ddd7d7;
`;

const Header = styled(Flex)`
  border-bottom: 1px solid #ddd7d7;
`;

const Pill = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  background: #f0edeb;
  color: #888282;
  font-weight: 500;
  padding: 6px 12px;
`;

const OnboardingCard = ({
  children,
  primary,
  secondary,
  pillText,
  header,
  width,
}: {
  children: React.ReactNode;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  pillText?: string;
  header?: React.ReactNode;
  width?: string;
}) => {
  return (
    <Root direction={"column"}>
      {header && (
        <Header p="4" width={"100%"} gap="3" justify={"center"}>
          {header}
        </Header>
      )}
      <Flex
        direction="column"
        justify={"center"}
        align={"center"}
        gap="5"
        width={width ?? "640px"}
        p="7"
      >
        {pillText && (
          <Pill>
            <Text size="2">{pillText}</Text>
          </Pill>
        )}
        {children}
      </Flex>
      <Footer p="4" width={"100%"} justify={"end"} gap="3">
        {secondary}
        {primary}
      </Footer>
    </Root>
  );
};

export default OnboardingCard;

import { Button, Flex, Text } from "@radix-ui/themes";

const ActionAlert = ({
  title,
  subtitle,
  undo,
}: {
  title: string;
  subtitle: string;
  undo: () => void;
}) => {
  return (
    <Flex
      direction="row"
      right="0"
      bottom="1rem"
      gap="2"
      position="absolute"
      align="center"
      style={{
        border: "1px solid #DDD7D7",
        background: "white",
        padding: "12px",
        borderRadius: "20px",
      }}
    >
      <Flex direction="column">
        <Text as="span" size="3" style={{ color: "black" }}>
          {title}
        </Text>
        <Text as="span" size="2">
          {subtitle}
        </Text>
      </Flex>
      <Button
        style={{
          width: "fit-content",
          backgroundColor: "white",
          color: "black",
          fontSize: "16px",
          fontWeight: 500,
          border: "1px solid #7F7776",
          cursor: "pointer",
        }}
        size="3"
        radius="large"
        onClick={undo}
      >
        <Text as="span">Undo</Text>
      </Button>
    </Flex>
  );
};

export default ActionAlert;

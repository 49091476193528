import { Link } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

const SignInLink = () => {
  const navigate = useNavigate();

  const openLogin = (e: any) => {
    e.preventDefault();
    navigate("/login");
  };
  return (
    <Link href="" underline="hover" onClick={openLogin}>
      Sign In
    </Link>
  );
};

export default SignInLink;

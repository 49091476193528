import { Button } from "../base/Button";
import { Column } from "../base/Column";
import { Section } from "../base/Section";
import Text from "../base/Text";
import { Element, useNode } from "@craftjs/core";

const Footer = () => {
  // TODO: Why doesn't creating with non function component work?
  return (
    <Element canvas is={Section} className="email-palette">
      <Element canvas is={Column} width={`${100.0}`}>
        <Element
          is={Text}
          fontSize="12"
          tagName="span"
          textAlign="center"
          text="Questions?"
        />
        <Element
          is={Text}
          fontSize="12"
          tagName="span"
          textAlign="center"
          text="Check out our FAQ or contact us here"
        />
        <Element
          is={Text}
          fontSize="12"
          tagName="span"
          textAlign="center"
          text="Ⓒ 2024 | All rights reserved. No longer want to receive these emails? Unsubscribe."
        />
      </Element>
    </Element>
  );
};

export default Footer;

import { useEffect, useState } from "react";
import { getFileSizeFromUrl, getImageMetaFromUrl } from "~/utils/helpers";

const useGetImageDimensionsAndSize = (img?: string) => {
  const [dimensions, setDimensions] = useState<string>("");

  useEffect(() => {
    const fetchDimensions = async () => {
      if (!img) return;
      const meta = await getImageMetaFromUrl(img);
      const size = await getFileSizeFromUrl(img);
      if (!meta || !size) return;
      setDimensions(
        `${size} kb, ${meta.naturalWidth} x ${meta.naturalHeight} px`
      );
    };
    fetchDimensions();
  }, [img]);

  return dimensions;
};

export default useGetImageDimensionsAndSize;
